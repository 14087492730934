import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import frLocale from "@fullcalendar/core/locales/fr";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllFormation,
  getOneFormation,
} from "../../../features/formation/formationSlice";
import { getOneEvent } from "../../../features/event/eventSlice";
import {
  dispShowModelCaldav,
  dispShowModelDelete,
  dispShowModelEventDetails,
  dispShowModelFormationDetails,
  dispShowModelImport,
  getCaldavLink,
} from "../../../features/agenda/agendaModalForm/agendaModalSlice";
import dayjs from "dayjs";
import {
  extractTimeSlots,
  extractTimeRanges,
  createEventsArray,
} from "./utlis";
import frLocalee from "dayjs/locale/fr";
import { getAllFormulaires } from "../../../features/formulaires/formulaireSlice";

export default function Fullcalendar() {
  const [selectedDate, setSelectedDate] = useState(null);
  const dispatch = useDispatch();
  const fullCalendarRef = useRef(null);
  const { allFormations, liveFormation } = useSelector(
    (state) => state.formation
  );
  const { showModalEditFormationDetails } = useSelector(
    (state) => state.agenda
  );
  const { user } = useSelector((state) => state.auth);
  const { allEvents } = useSelector((state) => state.event);

  const [testArray, setTestArray] = useState([]);
  const [myFormationsList, setMyFormationsList] = useState([]);

  useEffect(() => {
    const array = [];
    // const array = allFormations.map((item) => {
    //   const { morning, evening } = extractTimeRanges(item.time);
    //   const morningSlot = extractTimeSlots(morning);
    //   const eveningSlot = extractTimeSlots(evening);

    //   return {
    //     id: item.id,
    //     title: `Formation: ${item.titre} <br /> Formateur: ${item.animateur.nom} ${item.animateur.prenom}`,
    //     start: item.startedAt,
    //     end: item.finichedAt,
    //     morning: morningSlot,
    //     evening: eveningSlot,
    //     allDay: item.allDay,
    //     backgroundColor: item.backgroundColor,
    //     extendedProps: {
    //       is: "formation",
    //     },
    //   };
    // });

    const array2 = allEvents?.map((item) => {
      const { morning, evening } = extractTimeRanges(item.time);
      const morningSlot = extractTimeSlots(morning);
      const eveningSlot = extractTimeSlots(evening);

      return {
        id: item.id,
        title: item.titre,
        start: item.startedAt,
        end: item.finichedAt,
        morning: morningSlot,
        evening: eveningSlot,
        allDay: item.allDay,
        backgroundColor: item.backgroundColor,
        extendedProps: {
          is: "event",
          belongsToFormation: item.formationId ? item.formationId : false,
        },
      };
    });

    const updatedTestArray = createEventsArray(array);
    const updatedTestArray2 = createEventsArray(array2);
    const updatedMyFormationsList = array.map((item) => ({
      id: item.id,
      title: item.title,
      start: item.start,
      end: item.end,
      allDay: item.allDay,
      backgroundColor: item.backgroundColor,
      extendedProps: {
        is: "formation",
      },
    }));

    const updatedMyFormationsList2 = array2.map((item) => ({
      id: item.id,
      title: item.title,
      start: item.start,
      end: item.end,
      allDay: item.allDay,
      backgroundColor: item.backgroundColor,
      extendedProps: {
        is: "event",
      },
    }));

    const agendaArray = [...updatedTestArray, ...updatedTestArray2];
    const agendaMonthArray = [
      ...updatedMyFormationsList,
      ...updatedMyFormationsList2,
    ];

    setTestArray(agendaArray);
    setMyFormationsList(agendaMonthArray);
  }, [
    allFormations,
    liveFormation.backgroundColor,
    allEvents,
    showModalEditFormationDetails,
  ]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (fullCalendarRef.current && date) {
      fullCalendarRef.current.getApi().gotoDate(date.toDate());
    }
  };

  const handleEventClick = async (eventInfo) => {
    try {
      if (eventInfo.event.extendedProps.is === "formation") {
        dispatch(getOneFormation(eventInfo.event.id));
        dispatch(dispShowModelFormationDetails(true));
      } else {
        dispatch(getOneEvent(eventInfo.event.id));
        dispatch(dispShowModelEventDetails(true));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [events, setEvents] = useState(testArray);
  const [view, setView] = useState("timeGridWeek");

  const handleDatesSet = (dateInfo) => {
    const newView = dateInfo.view.type;

    if (newView === "dayGridMonth") {
      setEvents(myFormationsList);
    } else {
      setEvents(testArray);
    }

    setView(newView);
  };

  useEffect(() => {
    if (fullCalendarRef.current) {
      let array = [...events];
      let result = array.map((formation) => {
        if (formation.id === liveFormation.id) {
          return liveFormation;
        }
        return formation;
      });
      const calendarApi = fullCalendarRef.current.getApi();
      calendarApi.removeAllEventSources();
      calendarApi.addEventSource(events);
    }
  }, [events, liveFormation]);

  useEffect(() => {
    // Set the initial events when the component mounts
    setEvents(testArray);
  }, [testArray]);

  const openCaldav = async () => {
    const params = {
      username: user.name,
      userId: user._id,
    };
    await dispatch(dispShowModelCaldav(true));
    await dispatch(getCaldavLink(params));
  };

  const openImport = () => {
    dispatch(dispShowModelImport(true));
  };
  // const highlight = testArray.map((item) => {
  //   return new Date(item.start).getDate();
  // });

  // console.log(highlight)

  dayjs.locale(frLocalee);

  // function ServerDay(props) {
  //   const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  //   const isSelected =
  //     !props.outsideCurrentMonth &&
  //     highlightedDays.indexOf(props.day.date()) >= 0;

  //   return (
  //     <Badge
  //       key={props.day.toString()}
  //       overlap="circular"
  //       badgeContent={isSelected ? "🌚" : undefined}
  //     >
  //       <PickersDay
  //         {...other}
  //         outsideCurrentMonth={outsideCurrentMonth}
  //         day={day}
  //       />
  //     </Badge>
  //   );
  // }

  // const highlightedEvents = highlight.map((item) => {
  //   return new Date(item.start).getDate();
  // });

  // const [highlightedDays, setHighlightedDays] = React.useState(
  //   highlightedEvents
  // );

  const [showOption, setShowOption] = useState(false);

  const toggleShowOptions = () => {
    setShowOption(!showOption);
  };

  const deleteEvent = (id) => {
    dispatch(getOneEvent(id));
    dispatch(dispShowModelDelete(true));
  };

  useEffect(() => {
    dispatch(getAllFormulaires(user?._id));
  }, []);

  useEffect(() => {
    dispatch(getAllFormation(user?._id));
  }, []);

  return (
    <div className="old-c flex justify-between m-2 w-[80%]">
      <div className="grow">
        {testArray.length >= 0 && (
          <FullCalendar
            events={events}
            ref={fullCalendarRef}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            initialView={view}
            locale={frLocale}
            headerToolbar={{
              start: "prev,next today",
              end: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            height="100%"
            datesSet={handleDatesSet}
            eventClick={handleEventClick}
            eventColor="white"
            eventContent={({ event }) => (
              <p className="p[2px]">
                {event.title.split("<br />").map((item, index) => {
                  return (
                    <span
                      key={Math.random().toString(36)}
                      style={{ display: "block" }}
                    >
                      {" "}
                      {item}
                    </span>
                  );
                })}
                {event.extendedProps.is === "event" ? (
                  <button
                    className="absolute right-0 mx-1 mt-"
                    onClick={(e) => {
                      e.stopPropagation(); // Stop the click event from propagating
                      deleteEvent(event.id); // Call the deleteEvent function with the event's ID
                    }}
                  >
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 10 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.08559 2.5H1"
                        stroke="white"
                        strokeWidth="0.646847"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.93945 4.75V7.75001"
                        stroke="white"
                        strokeWidth="0.646847"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.14648 4.75V7.75001"
                        stroke="white"
                        strokeWidth="0.646847"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.35181 2.5V9.62501C8.35181 9.72447 8.31309 9.81985 8.24417 9.89018C8.17524 9.9605 8.08176 10 7.98429 10H2.10386C2.00638 10 1.9129 9.9605 1.84397 9.89018C1.77505 9.81985 1.73633 9.72447 1.73633 9.62501V2.5"
                        stroke="white"
                        strokeWidth="0.646847"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.88035 2.5V1.75C6.88035 1.55109 6.8029 1.36032 6.66506 1.21967C6.52721 1.07902 6.34024 1 6.14529 1H3.94013C3.74518 1 3.55822 1.07902 3.42037 1.21967C3.28252 1.36032 3.20508 1.55109 3.20508 1.75V2.5"
                        stroke="white"
                        strokeWidth="0.646847"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                ) : (
                  ""
                )}
              </p>
            )}
          />
        )}
      </div>
      <div className="">
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={frLocalee}>
          <DateCalendar value={selectedDate} onChange={handleDateChange} />
        </LocalizationProvider>
        <div className="relative">
          <div className="flex justify-between px-5 items-center">
            <p>OPTIONS</p>
            <button
              className="text-lg font-semibold"
              onClick={toggleShowOptions}
            >
              . . .
            </button>
          </div>
        </div>
        {showOption && (
          <div className="absolute right-0 m-1 p-1 shadow-md rounded-2xl">
            <p className="p-1 hover:bg-gray-100 rounded-t-2xl">
              <button className="text-left" onClick={openCaldav}>
                Informations de connexion au calendrier
                <br /> Level
              </button>
            </p>
            <p className="p-1 hover:bg-gray-100 rounded-b-2xl">
              <button onClick={openImport}>Importer un calendrier</button>
            </p>
          </div>
        )}
      </div>
    </div>
  );
}
