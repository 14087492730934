import React,{useState,useEffect} from "react";
import Line from "../images/workflow-line.svg";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function EtapesRender({ etape, index = 0, handleStart, start = 0 , isRythmeFormateur, isDisabled = false}) {

  const { width } = useWindowDimensions();
  const isMobile = width < 1047;

  return (
    <div className={isMobile? "flex flex-col gap-2 p-6 w-[50%]":"flex flex-col gap-8 p-6 w-[50%]"} key={etape.id}>
      <div className={isMobile?"flex gap-0 justify-start":"flex gap-0"}>
        <div className="formula-number  rounded-2xl h-14 min-w-[80px] p-2 flex items-center justify-center ">
          <span className="text-white">F.N° 0{index + 1}</span>
        </div>
        {start == index ? (
          <>
            <img alt="line" src={Line} className="mb-12" />
            <label htmlFor={"etapes-1" + index} className="flex-1 min-w-[400px] cursor-pointer">
              <div className="but rounded-t-2xl h-14  w-[100%] flex items-center justify-between px-5 " >
                <span className="text-white capitalize-first">{etape.titre}</span>
              </div>
              <div className="bg-white py-1 min-h-12 flex w-[100%] rounded-b-2xl justify-between px-5"style={{border:"2px solid #95C11F", borderTop:"none"}}>
                <span className="pl-5">{etape.titre.includes("pptx")? "Lancement de présentation de " : "Lancement de formulaire"}</span>
                <div className=" ">
                  {!isRythmeFormateur && !isDisabled && <input
                    id={"etapes-1" + index}
                    type="radio"
                    value={index}
                    checked={start == index}
                    name="mode"
                    className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300  accent-green-700 cursor-pointer"
                    onChange={handleStart}
                  />}
                </div>
              </div>
            </label>
          </>
        ) : (
          <>
            <img alt="line" src={Line} className="mb-12"/>
            <label htmlFor={"etapes-2" + index} className="flex-1 min-w-[400px] cursor-pointer">
              <div className="formula-name rounded-t-2xl h-14  w-[100%] flex items-center justify-between px-5 ">
                <span className="text-white">{etape.titre}</span>
              </div>
              <div className="bg-white py-1 min-h-12 flex w-[100%] rounded-b-2xl justify-between px-5">
                <span className="pl-5 text-capitalize">
                 {etape.titre.includes("pptx")? "Commencer avec cette présentation" : "Commencer avec ce formulaire"}
                </span>
                <div className=" ">
                 { !isRythmeFormateur && !isDisabled && <input
                    id={"etapes-2" + index}
                    type="radio"
                    value={index}
                    checked={start == index}
                    name="mode"
                    className="w-4 h-4 text-gray-600 bg-gray-100 border-gray-300  accent-green-700 cursor-pointer"
                    onChange={handleStart}
                  />}
                </div>
              </div>
            </label>
          </>
        )}
      </div>
     {etape?.condition && <div className={isMobile?"formula-condition rounded-2xl w-20 center h-12 flex items-center px-3 justify-center":"formula-condition rounded-2xl w-20 h-12 flex items-center px-3 justify-center"}>
        <div className="flex items-center gap-1 justify-center">
          <span className="text-white text-capitalize">{etape.condition}</span>
        </div>
        {/* <img src={trash} alt="trash" className="h-4" /> */}
      </div>}
    </div>
  );
}
