import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import AdministratifCard from "./detailsFormationCards.js/AdministratifCard";
import AnimateurFormulairesCard from "./detailsFormationCards.js/FormulairesAnimateurCard";
import StagiairesFormulairesCard from "./detailsFormationCards.js/StagiairesFormulairesCard";
import WorkflowCard from "./detailsFormationCards.js/WorkflowCrad";
import { getOneFormation } from "../../../features/formation/formationSlice";

export default function DetailPartaged({ setScreen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { id } = useParams();
  const [currentForm, setCurrentForm] = useState({});
  const { liveFormation } = useSelector((state) => state.formation);

  useEffect(async () => {
    dispatch(getOneFormation(id));
  }, []);

  useEffect(async () => {
    setCurrentForm(liveFormation);
  }, [liveFormation]);

  return (
    <div className=" flex-1 bg-gray-100 flex flex-col px-16 ">
      <div className=" basis-[15%] flex items-center ">
        <div className=" basis-[40%] flex gap-2 items-center">
          <button onClick={() => navigate("/EspaceLevel/formation")}>
            <span className=" text-xl font-semibold">FORMATIONS</span>
            <span className=" text-xl font-medium"> LEVEL</span>
          </button>
          <span className=" text-lg font-medium"> {"> Détails formation"}</span>
        </div>
      </div>
      <div className="  basis-[85%] grid grid-cols-4 gap-4 pl-2  justify-start  overflow-auto py-8">
        <AdministratifCard
          date={currentForm.createdAt}
          id={id}
          setScreen={setScreen}
          toPartage={true}
        />
        {/* <ClientFormulairesCard
          date={currentForm.createdAt}
          id={currentForm.id}
          setScreen={setScreen}
        /> */}
        <AnimateurFormulairesCard
          date={currentForm.createdAt}
          id={currentForm.id}
          setScreen={setScreen}
          toPartage={true}
        />
        <StagiairesFormulairesCard
          date={currentForm.createdAt}
          id={currentForm.id}
          setScreen={setScreen}
          toPartage={true}
        />
        <WorkflowCard
          date={currentForm.createdAt}
          id={currentForm.id}
          setScreen={setScreen}
          toPartage={true}
        />
      </div>
    </div>
  );
}
