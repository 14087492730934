import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CartFormation from "../../../components/CartFormation";
import { useParams, useNavigate } from "react-router-dom";

export default function DetailCateFormation() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentCate, setCurrentCate] = useState({});
  const { categorieFormations } = useSelector(
    (state) => state.categorieFormation
  );

  const formations = () => {
    return currentCate.joCatFormations?.map((element) => {
      return (
        <CartFormation
          name={element.formation?.titre}
          date={element.formation?.createdAt}
          slug={element.formation.slug}
          number={element.formation?.formulaire?.length}
          id={element.formation.id}
          key={element.formation.id}
        />
      );
    });
  };

  useEffect(() => {
    let arr = [...categorieFormations].filter((el) => {
      return el.id == id;
    });
    setCurrentCate(arr[0]);
  }, []);
  return (
    <div className=" flex-1 bg-gray-100 flex flex-col px-16 ">
      <div className=" basis-[15%] flex items-center justify-between ">
        <div className=" flex gap-2 items-center">
          <button onClick={()=>navigate("/EspaceLevel/formation/ListCate") } >
            <span className=" text-xl font-semibold">FORMATION</span>
            <span className=" text-xl font-medium"> LEVEL</span>
          </button>
          <span className=" text-lg font-medium cursor-pointer" onClick={()=>navigate("/EspaceLevel/formation/ListCate") } > {"> Catégories"}  </span>
          <span className=" text-lg font-medium"  >
            {" "}
            {`> Détails ${currentCate?.titre}`}
          </span>
        </div>
      </div>
      <div className="  basis-[85%] grid grid-cols-4 gap-4 pl-2  justify-start  overflow-auto py-8">
        {formations()}
      </div>
    </div>
  );
}
