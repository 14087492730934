import React from "react";
import TimeIcon from "../images/TimeIcon.svg"

export default function TimePicker({option,setHours, setMinutes, Hours, Minutes}) {
  return (
    <div className="h-10 flex-1 bg-white rounded-full border-2 flex justify-center p-2">
      <div className="flex items-center  ">
        <img src={TimeIcon} alt="time" className="h-5 mr-5" />
        <select
          name="hours"
          className="bg-transparent text-xl appearance-none outline-none text-gray-600"
          onChange={(e)=> setHours(e.target.value) }
          value={/^(0[1-9])$/.test(Hours)? Hours?.replace(/^0/, '') : Hours} 
        >
          <option value="00">00</option>
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
          <option value="11">11</option>
          <option value="12">12</option>
          <option value="13">13</option>
          <option value="14">14</option>
          <option value="15">15</option>
          <option value="16">16</option>
          <option value="17">17</option>
          <option value="18">18</option>
          <option value="19">19</option>
          <option value="20">20</option>
          <option value="21">21</option>
          <option value="22">22</option>
          <option value="23">23</option>
        </select>
        <span className="text-xl mr-3">:</span>
        <select
          name="minutes"
          className="bg-transparent text-xl appearance-none outline-none mr-4 text-gray-600"
          onChange={(e)=> setMinutes(e.target.value) }    
          value={Minutes}
       >
          <option value="00">00</option>
          <option value="15">15</option>
          <option value="30">30</option>
          <option value="45">45</option>
        </select>
        <select
          name="ampm"
          className="bg-transparent text-xl appearance-none outline-none text-gray-600"
          
      >   
        </select>
      </div>
    </div>
  );
}
