import intercepterAxios from "../../configAxios";
import axios from "axios";
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

// Register formation
const formationRegister = async (userData) => {
  try {
    const response = await intercepterAxios.post(
      `/formation/register`,
      userData
    );

    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getAllFormations = async (id) => {
  try {
    const response = await intercepterAxios.get(
      `/formation/allFormations${id}`
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getAllFormationspartaged = async (id) => {
  try {
    const response = await intercepterAxios.get(`/formation/partaged${id}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getAllFormationsLive = async (id) => {
  try {
    const response = await axios.get(
      `${apiBaseURL}/formation/allFormationsLive${id}`
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getOneFormation = async (id) => {
  try {
    const response = await intercepterAxios.get(`/formation/oneFormation${id}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getFormationBySlug = async (slug) => {
  try {
    const response = await intercepterAxios.get(`/formation/${slug}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const duplicateFormation = async (data) => {
  try {
    const response = await intercepterAxios.put(`/formation/duplicateForm`, {
      formations: data,
    });
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const deleteFormation = async (data) => {
  try {
    const response = await intercepterAxios.put(`/formation/delete`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const EditFormation = async (data) => {
  try {
    const response = await intercepterAxios.put(`/formation/editForm`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const PartageFormation = async (data) => {
  try {
    const response = await intercepterAxios.post(`/formation/partage`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const PartageMultipleFormation = async (data) => {
  try {
    const response = await intercepterAxios.post(
      `/formation/partagemultiple`,
      data
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const activateFormation = async (data) => {
  try {
    const response = await intercepterAxios.put(`/formation/activate`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const formationService = {
  formationRegister,
  deleteFormation,
  getAllFormations,
  getAllFormationsLive,
  getOneFormation,
  getFormationBySlug,
  duplicateFormation,
  EditFormation,
  PartageFormation,
  activateFormation,
  getAllFormationspartaged,
  PartageMultipleFormation,
};

export default formationService;
