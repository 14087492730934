import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import independentFile from "./independentFileService";

const initialState = {
};

export const deleteFile = createAsyncThunk(
  "deleteFile",
  async ({id, path}, thunkAPI) => {
    try {
      return await independentFile.deleteFile({id, path});
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createFiles = createAsyncThunk(
  "createFiles",
  async (files, thunkAPI) => {
    try {
      return await independentFile.createFiles(files);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const independentFileSlice = createSlice({
  name: "independentFileSlice",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
  },
});

export const {
} = independentFileSlice.actions;
export default independentFileSlice.reducer;
