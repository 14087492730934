import React, { useState } from "react";
import { Link } from "react-router-dom";


export default function SideBarEvaluation() {

  return (
    <aside className=" flex  " aria-label="Sidebar">
      <div className="flex flex-col gap-2 py-3 but shadow  w-[21vw] ">
        <div className={`btn  p-3 text-base hover-form cursor-pointer font-normal  text-white`}>
          <Link to="/evaluation/organisation">
            <div className="flex items-center ">
              <span className="flex-1 ml-3 whitespace-nowrap">Organisation</span>
            </div>
          </Link>
        </div>
        <div className={`btn  p-3 text-base hover-form cursor-pointer font-normal  text-white`}>
          <Link to="/evaluation/organisation">
            <div className="flex items-center ">
              <span className="flex-1 ml-3 whitespace-nowrap">Mises en ...</span>
            </div>
          </Link>
        </div>
        <div className={`btn  p-3 text-base hover-form cursor-pointer font-normal  text-white`}>
          <Link to="/evaluation/organisation">
            <div className="flex items-center ">
              <span className="flex-1 ml-3 whitespace-nowrap">Formateur</span>
            </div>
          </Link>
        </div>
        <div className={`btn  p-3 text-base hover-form cursor-pointer font-normal  text-white`}>
          <Link to="/evaluation/organisation">
            <div className="flex items-center ">
              <span className="flex-1 ml-3 whitespace-nowrap">Général</span>
            </div>
          </Link>
        </div>
      </div>
    </aside>
  );
};