import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartFormation from "../../../components/CartFormation";
import { useParams, useNavigate } from "react-router-dom";
import CartEspaceLevelCom from "../../../components/CartEspaceLevelCom";
import play from "../../../images/play.svg";
import eyes from "../../../images/eyes.svg";
import { activateFormation, getOneFormation, setLiveFormation } from "../../../features/formation/formationSlice";
import { setLiveFormulaire } from "../../../features/formulaires/formulaireSlice";
import { setRoom, setRoomState } from "../../../features/auth/authSlice";


export default function PartagedWithMe() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentCate, setCurrentCate] = useState({});
  const { partaged } = useSelector((state) => state.formation);
  const dispatch = useDispatch();
  const { liveFormulaire } = useSelector((state) => state.formulaires);
  const { categorieFormations } = useSelector(
    (state) => state.categorieFormation
  );
  const redirection = (path) => {
    navigate(`${path}`);
  };
  const sesionButton = async (id) => {
    let form = await dispatch(getOneFormation(id));
    form = form.payload;
    let session = form.slug?.split("_")[0] + form.slug.split("_")[2];
    let data = {
      active: 1,
      slug: form.slug,
      organismeId: form.organismeId,
    };
    let etapes = JSON.parse(form.etapes);
    dispatch(setLiveFormulaire(etapes[0]));
    let roomState = {
      state: false,
      currentForm: liveFormulaire,
    };
    dispatch(activateFormation(data));
    dispatch(setRoom(session));
    dispatch(setLiveFormation(form));
    dispatch(setRoomState(roomState));
    navigate(`/session/${id}`);
  };
  const formations = () => {
    return partaged?.map((element) => {
      return (
        <>
          <CartEspaceLevelCom
            name={element.titre}
            date={element.startedAt}
            id={element.id}
            number={element.joinFormulaFormats?.length}
            checkbox={"formation"}
            category={"formulaires"}
          >
            <div className="flex items-center justify-center gap-2">
              <button
                type="button"
                className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
              >
                <img
                  src={eyes}
                  id={"slug"}
                  onClick={() =>
                    redirection(`/EspaceLevel/formationPartaged/${element.id}`)
                  }
                />
              </button>

              <button
                type="button"
                className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                onClick={() => sesionButton(element.id)}
              >
                <img src={play} id={"slug"} />
              </button>
            </div>
          </CartEspaceLevelCom>
        </>
      );
    });
  };

  useEffect(() => {
    let arr = [...categorieFormations].filter((el) => {
      return el.id == id;
    });
    setCurrentCate(arr[0]);
  }, [categorieFormations]);
  return (
    <div className=" flex-1 bg-gray-100 flex flex-col px-16 ">
      <div className=" basis-[15%] flex items-center ">
        <div className="  flex gap-2 items-center">
          <button onClick={() => navigate("/EspaceLevel/formation/ListCate")}>
            <span className=" text-xl font-semibold md:text-sm">FORMATION</span>
            <span className=" text-xl font-medium md:text-sm"> LEVEL</span>
          </button>
          <span
            className=" text-lg font-medium cursor-pointer md:text-sm"
            onClick={() => navigate("/EspaceLevel/formation/ListCate")}
          >
            {" "}
            {"> Catégories"}{" "}
          </span>
          <span className=" text-lg font-medium md:text-sm">
            {" "}
            {`> Détails ${currentCate?.titre}`}
          </span>
        </div>
      </div>
      <div className="  basis-[85%] grid grid-cols-4 gap-4 pl-2  justify-start  overflow-auto py-8">
        {formations()}
      </div>
    </div>
  );
}
