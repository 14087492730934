import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import edit from "../../../images/edit.svg";
import play from "../../../images/play.svg";
import EtapesRender from "../../../components/EtapesRender";
import { useParams, useNavigate } from "react-router-dom";

export default function WorkflowDetailsSynthetiseur({ setScreen }) {
  let navigate = useNavigate();
  let { id } = useParams();

  const accessFormation = () => {
    setScreen({ affiche: "listeFormations", id: id });
  };
  const accessDetail = () => {
    setScreen({ affiche: "detailFormation", id: id });
  };
  const [currentForm, setCurrentForm] = useState({});
  const [start, setStart] = useState("{}");
  const { allFormationsSynthetiseur } = useSelector((state) => state.synthetiseur);

  useEffect(() => {
    let arr = [...allFormationsSynthetiseur].filter((el) => {
      return el.id == id;
    });
    setCurrentForm(arr[0]);
  }, []);

  const handleStart = (e) => {
    setStart(e.target.value);
  };

  const etapesRender = () => {
    let etapes = JSON.parse(currentForm?.etapes || "[]");
    return etapes.map((el, index) => {
      return (
        <Fragment key={index + "WorkflowSynthetiseurkey"}>
          <EtapesRender
            etape={el}
            index={index}
            handleStart={handleStart}
            start={start}
            isDisabled={true}
          />
        </Fragment>
      );
    });
  };

  return (
    <div className=" flex-1 bg-gray-100 flex flex-col px-16 ">
      <div className="basis-[15%] flex justify-between border-b-2 items-center pb-4">
        <div className="  flex gap-2 items-center">
          <button onClick={() => navigate(`/EspaceLevel/synthetiseur`)}>
            <span className=" text-xl font-semibold">ARCHIVES</span>
            <span className=" text-xl font-medium"> LEVEL</span>
          </button>
          <button
            onClick={() => navigate(`/EspaceLevel/synthetiseurDetail/${id}`)}
          >
            <span className=" text-lg font-medium">
              {" "}
              {"> Détails formation "}
            </span>
          </button>
          <span className=" text-lg font-medium"> {"> Workflow"}</span>
        </div>
        <div>
          <div>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center w-[100%]">{etapesRender()}</div>
    </div>
  );
}
