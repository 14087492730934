import React, { useState } from "react";
import { useSelector } from "react-redux";
import Profil from "../images/Profil.svg";

export default function NavBarModal({handleClick}) {
    // const [hide,setHide] = useState("hidden")
    const {user} = useSelector((state)=>state.auth)
   
  return (
    <div>
      <button
        id="dropdownDividerButton"
        data-dropdown-toggle="dropdownDivider"
        className="text-white   font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-cent"
        type="button"
        onClick={handleClick}
        
      >
        <img src={user?.logo? process.env.REACT_APP_IMG + user?.logo : Profil} alt="Level" className="h-12 rounded-lg" />
      </button>

      
    </div>
  );
}
