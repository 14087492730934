import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import PayementService from "../../features/payement/payementService";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setUser, updateProfile } from "../../features/auth/authSlice";
export const CheckoutForm = () => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const [numClient, setNumClient] = useState();
  const [expiration, setExpiration] = useState();
  const [cardNumber, setCardNumber] = useState();
  const [cvv, setCvv] = useState();
  const { user } = useSelector((state) => state.auth);
  const { pack } = useSelector((state) => state.payement);

  const cardPayement = {
    numClient,
    expiration,
    cardNumber,
    cvv,
    amount: pack.montant * 100,
    userId: user._id,
    prevPoint: user.point
  };
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    navigate(-1)
    try {
      const response = await PayementService.payementCard(cardPayement);
      if (response.data.success) {
        toast.success("Paiement effectué avec succés !");
        let obj = {...user}
        obj.point = response.data.point 
        dispatch(setUser(obj))
      } else {
        toast.error("Paiement Failed !");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleQuitCheckoutForm = (e) => {
    e.preventDefault();
    navigate(-1, { replace: true });
    
  };

  return (
    <form className="w-full flex justify-center">
      <div className="flex flex-col w-[50%]">
        <div className="flex ">
          <div className="w-full md:w-1/2 px-3  md:mb-0">
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
              id="grid-first-name"
              type="text"
              placeholder="Nom du client"
              value={numClient}
              onChange={(e) => setNumClient(e.target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded-full py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 required"
              id="grid-last-name"
              type="text"
              placeholder="Date d’expiration"
              value={expiration}
              onChange={(e) => setExpiration(e.target.value)}
            />
          </div>
        </div>
        <div className="flex ">
          <div className="w-full md:w-1/2 px-3  md:mb-0">
            <input
              id="ccn"
              type="tel"
              inputMode="numeric"
              pattern="[0-9\s]{13,19}"
              autoComplete="cc-number"
              maxLength={19}
              placeholder="xxxx xxxx xxxx xxxx"
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded-full py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white required"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 px-3">
            <input
              className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-500 rounded-full py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 requird"
              id="grid-last-name"
              type="text"
              placeholder="CVV *"
              value={cvv}
              onChange={(e) => setCvv(e.target.value)}
            />
          </div>
        </div>
        <div className="flex justify-around">
          <button
            onClick={handleQuitCheckoutForm}
            className={`group relative w-[40%] flex justify-center items-center border border-[#288911] text-md font-medium  text-black  hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-9`}
          >
            ANNULER
          </button>
          <button
            type="submit"
            className={`group relative w-[40%] flex justify-center items-center border border-transparent text-md font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-9`}
            onClick={handleSubmit}
          >
            PAYER
          </button>
        </div>
      </div>
    </form>
  );
};
