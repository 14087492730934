import React, { useEffect, useState } from "react";
import Formulaire from "../images/formulaire.svg";
import { useDispatch, useSelector } from "react-redux";
import { dispatchSelect } from "../features/formulaires/formulaireSlice";
import { dispatchSelectFormation } from "../features/formation/formationSlice";

export default function CartEspaceLevelCom({
  name,
  date,
  id,
  number,
  checkbox,
  children,
  category,
  dateText,
}) {
  const [check, setCheck] = useState(false);
  const { formulaires, selectedForm, refrechCard } = useSelector(
    (state) => state.formulaires
  );
  const { allFormations, selectedFormations } = useSelector(
    (state) => state.formation
  );
  const dispatch = useDispatch();

  useEffect(() => {
    setCheck(false);
    return () => {
      dispatch(dispatchSelect([]));
      dispatch(dispatchSelectFormation([]));
    };
  }, [allFormations, refrechCard]);

  const handleCheckFormulaire = (e, elid) => {
    setCheck(!check);
    let arr = [...selectedForm];
    if (e.target.checked) {
      let obj = [...formulaires].filter((el) => el.id == elid)[0];
      arr.push(obj);
      dispatch(dispatchSelect(arr));
    } else {
      arr = arr.filter((el) => el.id !== elid);
      dispatch(dispatchSelect(arr));
    }
  };

  const handleCheckFormation = (e, elid) => {
    setCheck(!check);

    var arr = [...selectedFormations];

    if (e.target.checked) {
      let obj = [...allFormations].filter((el) => el.id == elid)[0];

      arr.push(obj);

      dispatch(dispatchSelectFormation(arr));
    } else {
      e.target.checked = true;

      arr = arr.filter((el) => el.id !== elid);

      dispatch(dispatchSelectFormation(arr));
    }
  };
  let now = new Date(date);
  const frenchDate = now.toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
    localeMatcher: "best fit",
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1460);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1460);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className={
        !isMobile
          ? "card swift-transition rounded-2xl  custom-b-sh transition-all  hover:bg-gray-200 h-60 flex flex-col gap-2 bg-white p-4 w-60"
          : "card rounded-2xl   hover:bg-gray-200  h-60 flex flex-col gap-2 bg-white p-4 w-56}"
      }
    >
      {checkbox && (
        <div className="flex items-center justify-end pr-2">
          <input
            checked={check}
            id="link-checkbox"
            type="checkbox"
            value=""
            onChange={
              checkbox === "formation"
                ? (e) => handleCheckFormation(e, id)
                : (e) => handleCheckFormulaire(e, id)
            }
            className="w-4 h-4 accent-green-700 text-green-600 bg-green-700 rounded border-gray-300 focus:ring-green-700 "
          ></input>
        </div>
      )}

      {children}

      <div className="card-body flex flex-col justify-center items-center border-b-2 h-[48%]">
        <h1 className="card-title font-black truncate max-w-[150px] ">
          {name}
        </h1>
        <p className="font-thin">{date && dateText + frenchDate}.</p>
        <div className="py-1 px-1 border-t border-gray-100"></div>
      </div>
      <div className="flex justify-center items-center flex-1 ">
        <ul>
          <li className="flex justify-center items-center">
            <div className="flex items-center text-base font-normal text-black">
              <img src={Formulaire} className="w-6" />
              <span className="flex-1 ml-3 whitespace-nowrap">
                {number} {category}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
