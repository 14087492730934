export function convertStringToDate(yearMonthDay, hours, minutes) {
  const parts = yearMonthDay.split("-");
  const year = parts[0];
  const month = parts[1] - 1;
  const day = parts[2];
  const utcDate = new Date(Date.UTC(year, month, day, hours, minutes));
  return utcDate;
}

export function formatDate(inputDate) {
  if(typeof inputDate === "string" && inputDate.length === 8) {
    const year = inputDate.substring(0, 4);
    const month = inputDate.substring(4, 6);
    const day = inputDate.substring(6, 8);
    const utcDate = new Date(Date.UTC(year, month, day));
    return utcDate;
  }
  return inputDate;
}

export function formatDateToYearMonthDayDate(inputDate, allDay) {
  if(typeof inputDate === "string" && inputDate.length === 8 && allDay) {
    const year = inputDate.slice(0, 4);
    const month = inputDate.slice(4, 6);
    const day = inputDate.slice(6, 8);
    return `${year}-${month}-${day}`;
  }else {
   return inputDate;
  }
}