import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import synthetiseurService from "./synthetiseurService";

const initialState = {
  allFormationsSynthetiseur: [],
};

export const getAllFormationSynthetiseur = createAsyncThunk(
  "getAllsynth",
  async (id, thunkAPI) => {
    try {
      return await synthetiseurService.getAllFormationsSynthetiseur(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const addFeuille = createAsyncThunk(
  "addFeuille",
  async (form, thunkAPI) => {
    try {
      return await synthetiseurService.addfeuille(form);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const synthetiseurSlice = createSlice({
  name: "synthetiseur",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getAllFormationSynthetiseur.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFormationSynthetiseur.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allFormationsSynthetiseur = action.payload;
      })
      .addCase(getAllFormationSynthetiseur.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {} = synthetiseurSlice.actions;
export default synthetiseurSlice.reducer;
