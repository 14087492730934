import axios from "../../configAxios";

const createReponse = async (formData) => {
  try {
    const response = await axios.post(`/reponse/createOne`, formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getOneReponse = async (id) => {
  try {
    const response = await axios.get(`/reponse/oneReponse${id}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};
const updateReponse = async (formData, id) => {
  try {
    const response = await axios.put(`/reponse/updateOne${id}`, formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const deleteOneReponse = async (id) => {
  try {
    const response = await axios.delete(`/reponse/deleteOne${id}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const reponseService = {
  createReponse,
  getOneReponse,
  updateReponse,
  deleteOneReponse,
};

export default reponseService;
