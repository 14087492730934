import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import edit from "../../../images/edit.svg";
import play from "../../../images/play.svg";
import EtapesRender from "../../../components/EtapesRender";
import { useParams, useNavigate } from "react-router-dom";
import { setLiveFormulaire } from "../../../features/formulaires/formulaireSlice";
import { activateFormation, dispatchEditable, getOneFormation, setLiveFormation } from "../../../features/formation/formationSlice";
import { setImpayer, setRoom } from "../../../features/auth/authSlice";
import IndependentFileList from "../../../components/WorkflowBuilder/IndependentFileList";


export default function WorkflowDetails() {
  let navigate = useNavigate();
  let { id } = useParams()
  const dispatch = useDispatch();

  const [currentForm, setCurrentForm] = useState({});
  const [start, setStart] = useState("{}");
  const { liveFormation } = useSelector((state) => state.formation);
  const { user } = useSelector((state) => state.auth);

  useEffect(async () => {
    if ((id && liveFormation.id !== id) || id && !liveFormation) {
      dispatch(getOneFormation(id))
    };
  }, []);

  useEffect(async () => {
    setCurrentForm(liveFormation)
  }, [liveFormation]);

  const handleStart = (e) => {
    setStart(e.target.value);
    let etapes = JSON.parse(currentForm?.etapes || "[]");
    dispatch(setLiveFormulaire(etapes[e.target.value]))
  }
  const editButton = () => {
    dispatch(
      dispatchEditable({
        edit: true,
        step: 5,
        formation: currentForm,
      })
    );
    navigate("/FabriqueFormation");
  };
  const etapesRender = () => {
    let etapes = JSON.parse(currentForm?.etapes || "[]");
    if (etapes.length === 0) return <div className="my-4">Ajouter des étapes à ce workflow pour pouvoir les visualiser ici</div>;
    return etapes.map((el, index) => {
      return <Fragment key={"random" + index}> <EtapesRender etape={el} index={index} handleStart={handleStart} start={start} /> </Fragment>
    });
  };

  const sesionButton = () => {
    if (user.point > 0) {
      let session = currentForm.slug?.split("_")[0] + currentForm.slug.split("_")[2];
      let data = {
        active: 1,
        slug: currentForm.slug,
        organismeId: currentForm.organismeId,
      };
      dispatch(activateFormation(data))
      dispatch(setRoom(session))
      dispatch(setLiveFormation(currentForm))
      navigate(`/session/${id}`)
    } else {
      dispatch(setImpayer(true))
    }
  };

  return (
    <div className=" flex-1 bg-gray-100 flex flex-col px-16  ">
      <div className="basis-[15%] flex justify-between border-b-2 items-center pb-4">
        <div className="  flex gap-2 items-center">
          <button onClick={() => navigate(`/EspaceLevel/formation`)}>

            <span className=" text-xl font-semibold">FORMATIONS</span>
            <span className=" text-xl font-medium"> LEVEL</span>
          </button>
          <button onClick={() => navigate(-1)}>

            <span className=" text-lg font-medium">
              {" "}
              {"> Détails formation "}
            </span>
          </button>
          <span className=" text-lg font-medium"> {"> Workflow"}</span>
        </div>
        <div>
          <div>
            <button
              type="button"
              className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
            >
              <img src={play} className="h-6" onClick={sesionButton
              } />
            </button>
            <button
              type="button"
              className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
              onClick={editButton}
            >
              <img src={edit} className="h-6" />
            </button>
          </div>
        </div>
      </div>
      <div>{etapesRender()}</div>
      <div className="w-[50%] min-w-[600px] mb-[50px]">
        {<IndependentFileList attachments={liveFormation?.fichierIndependants && liveFormation?.fichierIndependants?.length ? liveFormation?.fichierIndependants : []} />}
      </div>
    </div>
  );
}
