import React from "react";



export default function Organisation() {


  return (
    <>
      <div className=" flex-1 bg-gray-100 flex flex-col px-16 py-4 max-h-screen">
        <div class=" rounded-2xl  bg-white  mb-6">
          <div class="px-6 py-4">
            <div class="border-b-2 mb-2 pb-2">Formation Secourisme Le lundi 14/07/2025 de 08H00 à 12H00 à Rue de la Vigie entreprise « LA TARTE AUX FRAISES »</div>
            <div>Candidat /<span class="text-gray-400 text-base"> MARC PAOLI né le 14/09/1981 à Paris</span></div>
          </div>
        </div>
        <div className="px-6 mb-6">
          <div>FORMATEUR</div>
          <p className="text-gray-400 text-base">Les réponses apportées sur ce questionnaire  peuvent nous aider à mieux répondre à les attentes de stagiaires</p>
        </div>
        <div className=" rounded-2xl  p-5 bg-white mb-6">
        
          <div className="flex justify-between  items-center  border-b-2">
            <div className="">Sa pédagogie</div>
            <div className="flex">
              <div className="p-4 border-l-2">
                <input type={"radio"} id="1" name="radioEval"/>
                <label for="1"  className="px-2">1</label>
              </div>
              <div className="p-4 border-l-2">
                <input type={"radio"} id="2" name="radioEval"/>
                <label for="2" className="px-2">2</label>
              </div>
              <div className="p-4 border-l-2">
                <input type={"radio"} id="3" name="radioEval"/>
                <label for="3" className="px-2">3</label>
              </div>
              <div className="p-4 border-l-2">
                <input type={"radio"} id="4" name="radioEval"/>
                <label for="4" className="px-2">4</label>
              </div>
              <div className="p-4 border-l-2">
                <input type={"radio"} id="5" name="radioEval"/>
                <label for="5" className="px-2">5</label>
              </div>
            </div>
          </div>
          <div className="flex justify-between  items-center ">
            <div className="">Ses compétences techniques</div>
            <div className="flex">
              <div className="p-4 border-l-2">
                <input type={"radio"} id="1" name="radioEval"/>
                <label for="1"  className="px-2">1</label>
              </div>
              <div className="p-4 border-l-2">
                <input type={"radio"} id="2" name="radioEval"/>
                <label for="2" className="px-2">2</label>
              </div>
              <div className="p-4 border-l-2">
                <input type={"radio"} id="3" name="radioEval"/>
                <label for="3" className="px-2">3</label>
              </div>
              <div className="p-4 border-l-2">
                <input type={"radio"} id="4" name="radioEval"/>
                <label for="4" className="px-2">4</label>
              </div>
              <div className="p-4 border-l-2">
                <input type={"radio"} id="5" name="radioEval"/>
                <label for="5" className="px-2">5</label>
              </div>
            </div>
          </div>

        </div>
        <div className="w-full">

        <textarea className="rounded-2xl w-full p-5" placeholder="Vos commentaires"></textarea>
        </div>

        <div className="flex justify-end my-5">
        <button type="submit" className=" group relative w-[20%] flex justify-center py-3 px-4 border  border-lime-600  text-sm font-medium    hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full  items-center mr-2 uppercase" id="button">Annuler</button>
        <button type="submit" className="group relative flex justify-center py-3 px-4 border border-transparent text-sm font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-12  w-[20%] uppercase">Suivant</button>
        </div>
        <div class=" rounded-2xl  bg-white  mb-6">
          <div class="px-6 py-4">
            <div class="border-b-2 mb-2 pb-2">
            Organisme de formation Compétences, 1032, Chemin des LUQUETTES 83740 LA CADIERE D’AZUR - contact@of-competences.fr - 0660434702
            </div>
            <div>SIRET 87835533800012 - APE8559A - NDA 938305966383 - V2.0 - 04/01/2022</div>
          </div>
        </div>
      </div>
    </>
  );
}
