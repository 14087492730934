import React from "react";

function DeleteIcon({ color = "black" , ...props }) {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      className="delete-icon "
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.75 5.5H4.58333H19.25"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4154 5.4987V18.332C17.4154 18.8183 17.2222 19.2846 16.8784 19.6284C16.5346 19.9722 16.0683 20.1654 15.582 20.1654H6.41536C5.92913 20.1654 5.46282 19.9722 5.119 19.6284C4.77519 19.2846 4.58203 18.8183 4.58203 18.332V5.4987M7.33203 5.4987V3.66536C7.33203 3.17913 7.52519 2.71282 7.869 2.369C8.21282 2.02519 8.67913 1.83203 9.16536 1.83203H12.832C13.3183 1.83203 13.7846 2.02519 14.1284 2.369C14.4722 2.71282 14.6654 3.17913 14.6654 3.66536V5.4987"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16797 10.082V15.582"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.832 10.082V15.582"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteIcon;
