import React from 'react'

export default function DetailsPayement() {
  return (
    <>
    <div className="flex flex-col justify-center items-center">
      <span className="credits">CREDIT DE LEVEL</span>
      <span className="bienvenu">PAIEMENT</span>
      <span className="offre">Veuillez saisir les données de votre carte</span>
    </div>
  </>
  )
}
