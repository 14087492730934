import axios from "../../configAxios";


const getAllCategorie = async (id) => {
  try {
    const response = await axios.get(`/categorie/allCategorie${id}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const assigneFormulairesToCategories = async (formData) => {
  try {
    
    const response = await axios.post(`/categorie/assigneCategorie`, formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const deleteFormulaireCategorie = async (data) => {
  try {
    const response = await axios.put(`/categorie/delete`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const createCategorie = async (formData) => {

  try {
    const response = await axios.post(`/categorie/register`, formData)
    return response.data
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};


const categorieFormulaireService = {
  getAllCategorie,
  assigneFormulairesToCategories,createCategorie,deleteFormulaireCategorie
};

export default categorieFormulaireService;
