import React from "react";
import "./CardResultats.css";
import Home from "../../images/Home.svg";
import Femme from "../../images/Avatar.svg";
import message from "../../images/message.svg";
import wifiicon from "../../images/Wifi.svg";
import DividerLine from "../../images/Lineverticale.svg";
import Conversation from "../../images/Conversation.svg";
import { useNavigate } from "react-router-dom";
export default function CardResultats(props) {
  const navigate = useNavigate();

  return (
    <>
      <div className="cardestim profile-pic max-w-xs">
        {props.avatar !== "female" ? (
          <img alt="card" src={Home} />
        ) : (
          <img alt="card" src={Femme} />
        )}
      </div>
      <div
        className="max-w-xs rounded overflow-hidden shadow-lg text-center cardestim bg-white hover:bg-gray-200 cursor-pointer"
        onClick={() =>
          navigate(
            `/resultatsFormationCurrent/${props.element.formationId}-${props.element.id}-stag`
          )
        }
      >
        <div className="visual-pic">
          <img alt="card" src={message} />
        </div>
        <div className="">
          <p className="card-info_title">
            {props.element?.Nom} {props.element?.Prenom}
          </p>
          {props.statusResultat && (
            <div className="flex justify-around items-center icons">
              <div className="horiz-bar_wrapp">
                <img alt="wifi" src={wifiicon} />
              </div>
              <div className="horiz-bar_wrapp">
                <img alt="horiz bar" src={DividerLine} />
              </div>
              <div className="horiz-bar_wrapp">
                <img alt="conv" src={Conversation} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
