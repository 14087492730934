import React, { useEffect, useState } from 'react'
import "./styles.css";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, Input, Checkbox, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { deleteCaldavEvent, editCaldavEvent, getAllEventWithCaldav } from '../../../../features/event/eventSlice';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';
import { Menu, MenuItem } from '@mui/material';
import TopHeaderIcon from './icons/TopHeaderIcon';
import CloseIcon from './icons/CloseIcon';
import LocalisationIcon from './icons/LocalisationIcon';
import NotesIcon from './icons/NotesIcon';
import TextArea from 'antd/es/input/TextArea';
import MoreIcon from './icons/MoreIcon';
const { RangePicker } = DatePicker;

function EditEvent({ closeDialog }) {

  const dispatch = useDispatch();
  const [loadings, setLoadings] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { liveEvent } = useSelector(
    (state) => state.event
  )
  const [showTime, setShowTime] = useState(true);
  const open = Boolean(anchorEl);
  const [data, setData] = useState({
    title: liveEvent?.title,
    startedAt: liveEvent?.startedAt,
    finishedAt: liveEvent?.finishedAt,
    description: liveEvent?.description,
    location: liveEvent?.location,
    url: liveEvent?.url
  });

  const handleChange = (e) => {
     if (e?.target?.name) {
      setData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
    else {
      setData((prev) => {
        return { ...prev, startedAt: e ? e[0] : Date.now(), finishedAt: e ? e[1] : Date.now() };
      });
    }
  };

  const deleteEventItem = () => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[1] = true;
      return newLoadings;
    });
    dispatch(deleteCaldavEvent({ url: liveEvent.url })).then((res) => {
      dispatch(getAllEventWithCaldav()).then((res) => {
        setAnchorEl(null);
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[1] = false;
          return newLoadings;
        });
        closeDialog();
      });
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setData({
      title: liveEvent?.title,
      startedAt: liveEvent?.startedAt,
      finishedAt: liveEvent?.finishedAt,
      description: liveEvent?.description,
      location: liveEvent?.location,
      url: liveEvent?.url,
    });

  }, [liveEvent]);

  const modifEvent = () => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[0] = true;
      return newLoadings;
    });
    dispatch(editCaldavEvent(data)).then((res) => {
      if (res.payload) {
        dispatch(getAllEventWithCaldav()).then((res) => {
          setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[0] = false;
            return newLoadings;
          });
          closeDialog();
        });
      }
      else {
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[0] = false;
          return newLoadings;
        });
      }
    });
  }

  const onOk = (value) => {
    setData((prev) => {
      return { ...prev, startedAt: value[0], finishedAt: value[1] };
    });
  };

  return (
    <div className='event-popover__inner'>
      <div className="event-popover__top-right-actions">
        <button aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <MoreIcon className='more' />
        </button>
        <button onClick={closeDialog} type="button" >
          <span className="material-design-icon close-icon"  >
            <CloseIcon />
          </span>
        </button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem className='delete-event-item'>
            <Button type="danger" danger loading={loadings[1]} onClick={deleteEventItem} style={{
              "color": "#ff4d4f",
              "background": "transparent",
              "borderColor": "#ff4d4f",
              "boxShadow": "none"
            }} >
              <span>Supprimer</span>
            </Button>
          </MenuItem>
        </Menu>
      </div>
      <div className="illustration-header">
        <TopHeaderIcon />
      </div>
      <div className="property-title">
        <div className="property-title__input">
          <Input
            type="text"
            value={data.title}
            name='title'
            onChange={handleChange}
            placeholder="Titre de l'événement"
          />
        </div>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" >
        <div className='start-end-dates'>
          <RangePicker format="DD-MM-YYYY HH:mm" style={{ "minWidth": "400px" }} showTime={showTime} onOk={onOk} value={[dayjs(data.startedAt), dayjs(data.finishedAt)]} onChange={handleChange} name="date-range" />
        </div>
      </LocalizationProvider>
      <div className="property-text">
        <span aria-label="Lieu" role="img" className="material-design-icon map-marker-icon property-text__icon dicon">
          <LocalisationIcon />
        </span>
        <div className="property-text__input">
          <TextArea
            placeholder="Ajouter un lieu" rows="1" title="Lieu"
            value={data.location}
            name='location'
            onChange={handleChange}
            autoSize={{ minRows: 2, maxRows: 2 }}
          />
        </div>
      </div>
      <div className="property-text">
        <span aria-label="Description" role="img" className="material-design-icon text-box-outline-icon property-text__icon dicon">
          <NotesIcon />
        </span>
        <div className="property-text__input">
          <TextArea
            placeholder="Ajouter une description" rows="1" title="description"
            value={data.description}
            name='description'
            onChange={handleChange}
            autoSize={{ minRows: 3, maxRows: 3 }}
          />
        </div>
      </div>
      <Button type="primary" loading={loadings[0]} onClick={modifEvent} style={{ margin: "20px auto", display: "block" }} >
        Enregistrer
      </Button>
    </div>
  )
}

export default EditEvent