import axiosInterceptor from "../../configAxios";
import axios from "axios";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;


const createFormulaire = async (form) => {

  let formData = form.formData;
  let token = form.user.token;
  try {
    const response = await axios.post(`${apiBaseURL}/formulaire/createFormulaire`, formData, {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (err) {
    if (err.response.data.message === "Not authorized User.") {
      throw "Not authorized User.";
    }
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getAllFormulaires = async (id) => {
  try {
    const response = await axiosInterceptor.get(`/formulaire/allFormulaire${id}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const updateFormulaire = async ({formData,id}) => {
  try {
    const response = await axiosInterceptor.put(`/formulaire/update${id}`,formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};


const getOneFormulaire = async (slug) => {
  try {
    const response = await axiosInterceptor.get(`/formulaire/oneFormulaire${slug}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const duplicateFormulaire = async (data) => {
  try {
    const response = await axiosInterceptor.put(`/formulaire/duplicate`, {
      formulaires: data,
    });
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const deleteFormulaire = async (data) => {
  try {
    const response = await axiosInterceptor.put(`/formulaire/delete`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const PartageFormulaire = async (data) => {
  try {
    const response = await axiosInterceptor.post(`/formulaire/partage`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const PartageFormulaireMultiple = async (data) => {
  try {
    const response = await axiosInterceptor.post(`/formulaire/partagemultiple`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};


const formulaireService = {
  createFormulaire,
  getAllFormulaires,
  getOneFormulaire,
  duplicateFormulaire,
  deleteFormulaire,
  PartageFormulaire,
  PartageFormulaireMultiple,
  updateFormulaire
};

export default formulaireService;
