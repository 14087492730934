import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import eventService from "./eventService";

const initialState = {
  allEvents: [],
  allEventsWithCaldav: [],
  titre: "",
  description: "",
  emplacement: "",
  listDesInvites: [],
  finichedAtE: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  liveEvent: {},
  startedAtE: "",
  message: "",
  isLoadingEvent: false,
  updatedData: {},
  fetchNextCloudEventsStatus: "idle",
};

export const createCalDavEvent = createAsyncThunk(
  "createCalDavEvent",
  async (event, thunkAPI) => {
    try {
      return await eventService.createCalDavEvent(event);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createIcsEvents = createAsyncThunk(
  "createIcsEvents",
  async (data, thunkAPI) => {
    try {
      return await eventService.createIcsEvents(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllEventWithCaldav = createAsyncThunk(
  "getAllEventWithCaldav",
  async (id, thunkAPI) => {
    try {
      return await eventService.getAllEventWithCaldav();
      
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOneEvent = createAsyncThunk(
  "getOneEvent",
  async (id, thunkAPI) => {
    try {
      return await eventService.getOneEvent(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editEvent = createAsyncThunk(
  "editEvent",
  async (data, thunkAPI) => {
    try {
      return await eventService.editEvent(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editCaldavEvent = createAsyncThunk(
  "editCaldavEvent",
  async (data, thunkAPI) => {
    try {
      return await eventService.editCaldavEvent(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editRelatedEvents = createAsyncThunk(
  "editRelatedEvents",
  async (data, thunkAPI) => {
    try {
      return await eventService.editRelatedEvents(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteEvent = createAsyncThunk(
  "deleteEvent",
  async (data, thunkAPI) => {
    try {
      return await eventService.deleteEvent(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteCaldavEvent = createAsyncThunk(
  "deleteCaldavEvent",
  async (data, thunkAPI) => {
    try {
      return await eventService.deleteCaldavEvent(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteRelatedEvents = createAsyncThunk(
  "deleteRelatedEvents",
  async (data, thunkAPI) => {
    try {
      return await eventService.deleteRelatedEvents(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    startedAtE: (state, action) => {
      state.startedAtE = action.payload;
    },
    finichedAtE: (state, action) => {
      state.finichedAtE = action.payload;
    },
    resetEvent: (state) => {
      state.startedAtE = "";
      state.finichedAtE = "";
      state.liveEvent = {};
      state.isLoadingEvent = false;
      state.titre = "";
      state.description = "";
      state.emplacement = "";
      state.listDesInvites = [];
    },
    setLiveEvent: (state, action) => {
      state.liveEvent = action.payload;
    },
    updatedData: (state, action) => {
      state.updatedData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createIcsEvents.pending, (state) => {
        state.isLoadingEvent = true;
      })
      .addCase(createIcsEvents.fulfilled, (state, action) => {
        state.allEvents = action.payload;
        state.isLoadingEvent = false;
        state.isSuccess = true;
      })
      .addCase(createIcsEvents.rejected, (state, action) => {
        state.isLoadingEvent = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllEventWithCaldav.pending, (state) => {
        state.fetchNextCloudEventsStatus = 'loading';

      })
      .addCase(getAllEventWithCaldav.fulfilled, (state, action) => {
        state.fetchNextCloudEventsStatus = 'sucess';
        state.isSuccess = true;
        state.allEventsWithCaldav = action.payload;
      })
      .addCase(getAllEventWithCaldav.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOneEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.liveEvent = action.payload;
      })
      .addCase(getOneEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allEvents = action.payload;
      })
      .addCase(deleteEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteRelatedEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteRelatedEvents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allEvents = action.payload;
      })
      .addCase(deleteRelatedEvents.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allEvents = action.payload;
        state.liveEvent = {};
      })
      .addCase(editEvent.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editRelatedEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editRelatedEvents.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allEvents = action.payload;
        state.liveEvent = {};
      })
      .addCase(editRelatedEvents.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  startedAtE,
  finichedAtE,
  resetEvent,
  setLiveEvent,
  updatedData,
} = eventSlice.actions;
export default eventSlice.reducer;
