export function groupArrayByquestion(arr, key) {
  return arr.reduce((acc, cur) => {
    const value = cur[key];
    if (!acc[value]) {
      acc[value] = [];
    }
    acc[value].push(cur);
    return acc;
  }, {});
}



function formatTime(duration) {
  
  const totalHours = Math.floor(duration.morningDuration + duration.eveningDuration);
  const totalMinutes = Math.floor(((duration.morningDuration + duration.eveningDuration) % 1) * 60);

  return `${totalHours? totalHours : 0} heure${totalHours !== 1 ? 's' : ''} et ${totalMinutes? totalMinutes : 0} minute${totalMinutes !== 1 ? 's' : ''}`;
}

export function calculateTimeDuration(
 { deHourM,
  deMinuteM,
  aHourM,
  aMinuteM,
  deHourE,
  deMinuteE,
  aHourE,
  aMinuteE},
  startedAt, finishedAt
) {
  const startMorning = new Date();
 
  startMorning.setHours(deHourM, deMinuteM, 0); // Set morning start time

  const endMorning = new Date();
  endMorning.setHours(aHourM, aMinuteM, 0); // Set morning end time

  const startEvening = new Date();
  startEvening.setHours(deHourE, deMinuteE, 0); // Set evening start time

  const endEvening = new Date();
  endEvening.setHours(aHourE, aMinuteE, 0); // Set evening end time
  const durationMorning = (endMorning - startMorning) / (1000 * 60 * 60); // Duration in hours for morning period
 
  const durationEvening = (endEvening - startEvening) / (1000 * 60 * 60); // Duration in hours for evening period
  const startDate = new Date(startedAt);
  const endDate = new Date(finishedAt);
  
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  const timeDifference = endDate.getTime() - startDate.getTime()
  
  let numberOfDays = Math.floor(timeDifference / millisecondsPerDay) ? Math.floor(timeDifference / millisecondsPerDay) + 1 : 1
  

  const duration = {
    morningDuration: durationMorning * numberOfDays,
    eveningDuration: durationEvening *numberOfDays ,
  };
  
  return formatTime(duration)
}
