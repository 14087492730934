import React, { useEffect, useRef } from "react";
import Button from "../../../components/Button";
import commentImg from "../../../images/Comment.svg";

export default function StagiaireCommentaire({
  comment,
  setShow,
  setComment,
}) {

  const comentRef = useRef()
  useEffect(()=>{
      comentRef.current.value = comment || ""
  },[comment])

  return (
    <div
      className={`fixed z-50 w-full h-full bg-gray-700/90 flex justify-center items-center top-0 left-0`}
    >
      <div className="h-[50%] w-[40%] min-w-[500px] py-[25px] bg-white rounded-3xl flex justify-center items-center gap-4 flex-col">
        <img src={commentImg} alt="comment" className="h-10" />
        <div className="flex flex-col justify-center items-center">
          <span className=" text-3xl font-semibold">
          { comment === ''?   'Ajouter un commentaire' : 'Modifier le commentaire'  }
          </span>
          <span className="Time mt-2">{  comment === ''? "Ajouter un commentaire pour ce stagiaire" : "Modifier le commentaire poour ce stagiaire" }</span>
        </div>
        <textarea className="border-2 w-[70%] rounded-2xl h-[90px] max-h-[110px] focus:outline-slate-200 p-[10px]" ref={comentRef}  placeholder="Assez bien , bonne continuation.." />
        {/* <button onClick={handlePopUp}>Confirmer</button> */}
        <div className="flex w-[90%] justify-center gap-2 ">
          <button
            type="submit"
            className={`group relative  flex justify-center w-[30%]  py-2 px-4 border  border-lime-600  text-sm font-medium    hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-10  items-center  gap-2 `}
            id="button"
            onClick={()=> setShow(false) }
          >
            <span>Annuler</span>{" "}
          </button>
          <Button
            text={comment === ''? "Ajouter" : "Modifier"}
            classname={"w-[30%] h-10 py-2"}
            func={()=>{
              setComment(comentRef.current.value)
              setShow(false)
            }}
          />
        </div>
      </div>
    </div>
  );
}
