import React from "react";
import Button from "../../components/Button";
import Noresults from "../../images/Noresults.svg";

export default function Empty({nouveau,type,one,majType,setShowModal}) {
  return (
    <div className=" flex-1 bg-gray-100 flex flex-col ">
        <div className="flex justify-start  items-center py-7 pl-16 border-b-2">
          <span className=" text-xl font-bold ">Espace LEVEL</span>
        </div>
      <div className=" bg-gray-100 flex flex-col justify-center items-center gap-4  flex-1 ">
        <div>
          <div>
            {" "}
            <img src={Noresults} alt="Level" className="h-10 w-16" />
          </div>
        </div>
        <div className="flex flex-col">
          <span className=" text-4xl font-medium" >Qu'attendez-vous pour créer de {nouveau}</span>
          <span className=" text-4xl font-medium flex justify-center" > {type === "formation"? "formations" : type} ?</span>
        </div>
        <div>
          <span className="creer-formulaire" >Créez votre {type == "formulaires"? "premier formulaire" : `premiere ${type}`} pour animer un peu cette interface</span>
        </div>
        <div className="w-60" >
          <Button text={`+ CRÉER ${one} ${majType}`} func={setShowModal} classname={""} />
        </div>
      </div>
    </div>
  );
}
