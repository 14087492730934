import React, { useEffect, useState } from "react";
import CartEspaceLevelCatg from "../../../components/CartEspaceLevelCatg";
import eyes from "../../../images/eyes.svg";
import play from "../../../images/play.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCategorieFormation } from "../../../features/categorieFormation/categorieFormationSlice";
import TableEspaceLevel from "./TableEspaceLevel";
import RowEspaceLevel from "./RowEspaceLevel";

export default function TabCategories() {
  const navigate = useNavigate();
  const { categorieFormations } = useSelector(
    (state) => state.categorieFormation
  );
  const { trierPar, search } = useSelector((state) => state.formulaires);
  let [cate, setCate] = useState([...categorieFormations]);
  const { user, tabMode } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    let categ = [...categorieFormations];
    if (trierPar === "Plus récent") {
      categ.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setCate(categ);
    } else if (trierPar === "Nom A-Z") {
      categ.sort((a, b) => {
        if (a.titre < b.titre) {
          return -1;
        }
        if (a.titre > b.titre) {
          return 1;
        }
        return 0;
      });
      setCate(categ);
    } else if (trierPar === "Plus ancien") {
      setCate(categorieFormations);
    } else if (trierPar === "Nom Z-A") {
      categ.sort((a, b) => {
        if (a.titre < b.titre) {
          return 1;
        }
        if (a.titre > b.titre) {
          return -1;
        }
        return 0;
      });
      setCate(categ);
    }
  }, [categorieFormations, trierPar]);

  useEffect(async () => {
    await dispatch(getAllCategorieFormation(user?._id));
  }, []);

  const redirection = (path) => {
    navigate(`${path}`);
  };

  return (
    <>
      {!tabMode ? (
        <div className="grid grid-cols-4 gap-4  justify-start basis-[100%] overflow-auto py-8">
          {cate?.map((element) => {
            if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
              return (
                <>
                  <CartEspaceLevelCatg
                    name={element.titre}
                    date={element.createdAt}
                    id={element.id}
                    number={element.joCatFormations.length}
                  >
                    <div className="flex items-center justify-center gap-2">
                      <button
                        type="button"
                        className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                      >
                        <img
                          src={eyes}
                          id={"slug"}
                          onClick={() =>
                            redirection(
                              `/EspaceLevel/synthetiseurCateFormation/${element.id}`
                            )
                          }
                        />
                      </button>
                      {/*   <button
                       type="button"
                       className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                       onClick={() =>
                        redirection(`/session/${element.id}`)
                      }
                     >
                       <img src={play} id={"slug"} />
                     </button> */}
                    </div>
                  </CartEspaceLevelCatg>
                </>
              );
            }
          })}
        </div>
      ) : (
        <TableEspaceLevel
          type={"category"}
          nom={"Nom de la catégorie"}
          form={"Formations"}
        >
          {cate?.map((element) => {
            if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
              return (
                <>
                  <RowEspaceLevel
                    type={"category"}
                    name={element.titre}
                    date={element.createdAt}
                    updatedate={element.updatedAt}
                    id={element.id}
                    number={element.joCatFormations?.length}
                    text={"crée le "}
                    archive
                  >
                    <div className="flex items-center justify-left gap-2">
                      <button
                        type="button"
                        className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() =>
                          redirection(
                            `/EspaceLevel/synthetiseurCateFormation/${element.id}`
                          )
                        }
                      >
                        <img src={eyes} id={"slug"} />
                      </button>
                      {/* <button
                        type="button"
                        className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() =>
                          redirection(`/session/${element.id}`)
                        }
                      >
                        <img src={play} id={"slug"} />
                      </button> */}
                    </div>
                  </RowEspaceLevel>
                </>
              );
            }
          })}
        </TableEspaceLevel>
      )}
    </>
  );
}
