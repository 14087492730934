import React, { useState, useEffect } from "react";
// import Partager from "../../components/Partager";
import eyes from "../images/eyes.svg";
import edit from "../images/edit.svg";
import share from "../images/share.svg";
import Trash from "../images/TrashBlack.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Sent from "../images/Sent.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchSelect,
  setPartage,
} from "../features/formulaires/formulaireSlice";
export default function CartSynthetiseurFormation({
  name,
  date,
  slug,
  selected,
  setSelected,
  id,
  number,
  type,
}) {
  let navigate = useNavigate();
  const [check, setCheck] = useState(false);

  const dispatch = useDispatch();
  const { selectedForm, formulaires } = useSelector(
    (state) => state.formulaires
  );
  const handleCheck = (e) => {
    setCheck(!check);
    if (type == "espaceLevel") {
      let arr = [...selectedForm];
      if (e.target.checked) {
        let obj = [...formulaires].filter((el) => el.id == id)[0];
        arr.push(obj);
        dispatch(dispatchSelect(arr));
      } else {
        arr = arr.filter((el) => el.id !== id);
        dispatch(dispatchSelect(arr));
      }
    } else {
      let arr = [...selected];
      if (e.target.checked) {
        arr.push(id);
        setSelected(arr);
      } else {
        arr = arr.filter((el) => el !== id);

        setSelected(arr);
      }
    }
  };

  const deleteSelected = (e) => {
    if (type == "espaceLevel") {
      let arr = [...selectedForm];
      let array = arr.filter((el) => {
        return el.id != e.target.id;
      });
      dispatch(dispatchSelect(array));
    } else {
      let arr = [...selected];
      let array = arr.filter((el) => {
        return el != e.target.id;
      });
      setSelected(array);
    }
  };

  const shareButton = () => {
    dispatch(
      setPartage({
        show: true,
        id: id,
      })
    );
  };

  useEffect(() => {
    setCheck(false);
  }, [formulaires]);

  return (
    <div className="card  rounded-lg  custom-b-sh transition-all  hover:bg-gray-200  flex flex-col gap-2 bg-white py-4 max-h-[300px] min-h-[250px] w-full">
      {type !== "delete" ? (
        <div className="flex items-center justify-end pr-2">
          <input
            checked={check}
            id="link-checkbox"
            type="checkbox"
            value=""
            onChange={handleCheck}
            className="w-4 h-4 accent-green-700 text-green-600 bg-green-700 rounded border-gray-300 focus:ring-green-700 "
          ></input>
        </div>
      ) : (
        <div className="flex items-center justify-end pr-2 ">
          <img src={Trash} alt="trash" className="cursor-pointer" id={id} onClick={deleteSelected} />
        </div>
      )}

      <div className="flex items-center justify-center gap-2">
        <button
          type="button"
          className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
        >
          <img
            src={eyes}
            id={slug}
            onClick={(e) =>
              navigate(`/EspaceLevel/synthetiseurDetail/${id}`)
            }
          />
        </button>
        { type !== "delete" ?
          <>
            <button
              type="button"
              className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
            >
              <img src={share} onClick={shareButton} />
            </button>
            <button
              type="button"
              className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
            >
              <img
                src={edit}
                id={slug}
                onClick={(e) => navigate(`/CreateQuestions/${e.target.id}`)}
              />
            </button>
          </> : null
        }
      </div>
      <div className="card-body flex flex-col justify-center items-center border-b-2 h-[48%]">
        <h1 className="card-title font-black">{name}</h1>
        <p className="font-thin">
          Crée le {moment(date).format("DD-MM-YYYY")}
        </p>
        <div className="py-1 px-1 border-t border-gray-100"></div>
      </div>
      <div className="flex justify-center items-center flex-1 ">
        <ul>
          <li className="flex justify-center items-center">
            <div
             
              className="flex items-center text-base font-normal text-black"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 38 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.6044 2H3.41758C2.63467 2 2 2.64919 2 3.45V29.55C2 30.3508 2.63467 31 3.41758 31H34.6044C35.3873 31 36.0219 30.3508 36.0219 29.55V3.45C36.0219 2.64919 35.3873 2 34.6044 2Z"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 10.7H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 16.5H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 22.3H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {type ? (
                <span className="flex-1 ml-3 whitespace-nowrap">
                  {number} questions
                </span>
              ) : (
                <span
                  className="flex-1 ml-3 whitespace-nowrap cursor-pointer"
                  src={eyes}
                  id={slug}
                  onClick={(e) => navigate(`/CreateQuestions/${e.target.id}`)}
                >
                  {number} questions
                </span>
              )}
            </div>
          </li>
        </ul>
      </div>
      {type === "delete" ? (
        <div className="flex justify-center">
          <button
            type="submit"
            className={`group relative  flex justify-center w-[70%]  py-2 px-4 border  border-lime-600  text-sm font-medium    hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-10  items-center  gap-2 `}
            id="button"
          >
            <img src={Sent}></img>
            {"Programmer l'envoi "}
          </button>
        </div>
      ) : null}
    </div>
  );
}
