import React, { useEffect, useState, useRef } from "react";
import NavbarEspaceLevel from "../../../NavbarEspaceLevel";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PDFExport } from "@progress/kendo-react-pdf";
import { getOneFormation } from "../../../../../features/formation/formationSlice";
import UserTableResultats from "../components/UserTableResultats";
import { Select } from "antd";
import Femme from "../../../../../images/Avatar.svg";
import Homme from "../../../../../images/Home.svg";

export default function StagiaireFormulaireResult() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { formulaireId, formationId } = useParams();
  const { liveFormation } = useSelector((state) => state.formation);

  const [tenta, setTenta] = useState(null);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!liveFormation?.id) {
      dispatch(getOneFormation(formationId));
    }
  }, [liveFormation?.id]);

  const Quit = () => {
    if (tenta) {
      setTenta(null);
    } else {
      navigate(-1);
    }
  };

  const pdfExportComponent = useRef(null);
  const exportPdf = () => {
    pdfExportComponent.current.save();
  };

  const formName = liveFormation?.joinFormulaFormats?.find(
    (el) => el.formulaireId == formulaireId
  )?.formulaire?.titre;

  const stagiairesThatResponded = liveFormation?.stagiaires.filter(
    (s) => s.tentatives.filter((t) => t.formulaireId == formulaireId).length > 0
  );
  const selectOptions =
    stagiairesThatResponded?.length > 0
      ? stagiairesThatResponded.map((s) => ({ value: s.id, label: s.email }))
      : [];
  const [selectedStagiaireId, setSelectedStagiaireId] = useState(
    selectOptions[0]?.value
  );

  const currentFormStagiaireResponses = liveFormation?.stagiaires
    .filter((s) => s.id == selectedStagiaireId)[0]
    ?.tentatives.filter(
      (t) => t.formulaireId == formulaireId
    )[0].tentativeResponses;

  const currentStagiaire = liveFormation?.stagiaires.find(
    (s) => s.id == selectedStagiaireId
  );

  const currentFormulaire = liveFormation?.joinFormulaFormats?.find(
    (el) => el.formulaireId == formulaireId
  )?.formulaire;

  const handleChange = (value) => {
    setSelectedStagiaireId(value);
  };
  console.log(currentStagiaire);
  return (
    <div className="flex flex-col bg-gray-100 min-h-screen">
      <NavbarEspaceLevel />

      <div className="m-8 ">
        <div className=" basis-[10%] flex flex-wrap justify-between border-b-2 items-center ">
          <div className="w-[85%] flex items-center gap-2">
            <button onClick={() => navigate(`/EspaceLevel/formation`)}>
              <span className=" text-xl font-semibold">FORMATIONS</span>
              <span className=" text-xl font-medium"> LEVEL</span>
            </button>
            <button
              onClick={() =>
                navigate(
                  `/EspaceLevel/${
                    liveFormation?.synthese
                      ? "synthetiseurDetail"
                      : "formationDetail"
                  }/${liveFormation?.id}`
                )
              }
            >
              <span className="flex-1 text-lg font-medium">
                {"> "}
                Détails formation
              </span>
            </button>
            <button
              onClick={() =>
                navigate(
                  `/EspaceLevel/formationStagiaires/${liveFormation?.id}`
                )
              }
            >
              <span className="flex-1 text-lg font-medium">
                {"> "}
                Détails stagiaires
              </span>
            </button>
            <button>
              <span className="flex-1 text-lg font-medium">
                {" "}
                {"> Réponses Stagiaires"}
                {formName ? ": " + formName : ""}
              </span>
            </button>
          </div>
          <button
            id="creer"
            className="w-[10%] m-2 rounded-full border-2 py-1 border-transparent text-md font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600"
            onClick={exportPdf}
          >
            Export PDF
          </button>
          {/* </div> */}
        </div>
        <PDFExport
          ref={pdfExportComponent}
          scale={0.8}
          margin="1cm"
          paperSize={[1000, 1250]}
        >
          <div className=" basis-[40%]  flex  justify-start items-start   overflow-auto py-8">
            <div className=" bg-white h-full w-full rounded-2xl p-2 flex flex-col gap-3">
              <div className="flex w-[95%] gap-1 border-b-2 p-4">
                <span className="text-lg font-bold">
                  {`Formation ${liveFormation?.titre} Le ${new Date(
                    liveFormation?.startedAt
                  ).toLocaleDateString("fr-FR", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    localeMatcher: "best fit",
                  })} ${liveFormation?.time}  ${
                    liveFormation?.Adress ? "à " + liveFormation?.Adress : ""
                  }`}
                </span>
              </div>
              <div className="w-[95%]  gap-10 flex  p-4">
                <div className="flex gap-6">
                  <div className="flex gap-1">
                    <span className="text-lg font-bold"> répondu par/</span>
                    <span className="text-lg font-semiBold Time ">
                      <Select
                        defaultValue={selectOptions[0]}
                        style={{ width: 200 }}
                        onChange={handleChange}
                        options={[...selectOptions]}
                        placeholder="Sélectionner un stagiaire"
                        showSearch
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between">
            <div className="w-[75%]">
              <UserTableResultats
                currentFormulaire={currentFormulaire}
                responses={currentFormStagiaireResponses}
                isStagiaireResponses
              />
            </div>
            <div className="w-[25%]">
              <div className="h-full relative flex flex-col ">
                <img
                  src={currentStagiaire?.Genre == "female" ? Femme : Homme}
                  className="h-32 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4"
                  alt="avatar"
                />
                <div className="bg-white rounded-2xl flex flex-col justify-center items-center p-14 gap-8 pt-24">
                  <span className="font text-2xl">
                    {currentStagiaire?.Nom + " " + currentStagiaire?.Prenom}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className=" basis-[40%]  flex  justify-start items-start   overflow-auto py-8">
            <div className=" bg-white h-full w-full rounded-2xl p-2 flex flex-col gap-3">
              <div className="flex w-[95%] gap-1 border-b-2 p-4">
                <span className="text-lg ">
                  {" "}
                  {`Organisme de formation ${user?.name ? user?.name : " "} ${
                    user?.codePostal ? ", " + user?.codePostal : ""
                  } ${
                    user?.adressePostale ? user?.adressePostale + "," : " "
                  } ${user?.email ? " - " + user?.email : " "}  ${
                    user?.phone ? " -" + user?.phone : " "
                  }`}
                </span>
              </div>
              <div className="w-[95%]  gap-10 flex  p-4">
                <div className="flex gap-6">
                  {" "}
                  <div className="flex gap-1">
                    <span className="text-lg font-semiBold ">
                      {" "}
                      {`SIRET ${user?.siret ? user?.siret : " "} - APE${
                        user?.codeApe ? user?.codeApe : " "
                      } - NDA ${user?.codeNda ? user?.codeNda : " "}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PDFExport>
        <div className="flex absolute right-12  m-2 p-1 pb-[10px]">
          <button
            id="creer"
            className="w-full px-4 rounded-full border-2 py-1 border-transparent text-md font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 mt-[-20px] "
            onClick={Quit}
          >
            Quitter
          </button>
        </div>
      </div>
    </div>
  );
}
