import React from "react";
import Pack from "../components/pack";
import checkb from "../images/checkB.svg";
import checkA from "../images/checkA.svg";
import checkOR from "../images/checkOR.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { dispatchPack } from "../features/payement/payementSlice";
export default function UpdateCredtis() {
  const [selected, setSelected] = React.useState(0);
  const [levels, setlevels] = React.useState("10 euros = 100 LEVELS");
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handlePayement = () => {
    if (selected === 1) {
      dispatch(dispatchPack({ montant: 50, icon: "b" }));
    }
    if (selected === 2) {
      dispatch(dispatchPack({ montant: 250, icon: "a" }));
    }
    if (selected === 3) {
      dispatch(dispatchPack({ montant: 500, icon: "or" }));
    }
    navigate("/payement");
  };

  return (
    <div className="flex flex-col justify-center pt-8">
      <div className="flex justify-evenly w-[100%]">
        <Pack
          setSelected={setSelected}
          selected={selected}
          number={1}
          title={"PACK BRONZE"}
          levels={"50 euros = 400 LEVELS"}
          setlevels={setlevels}
          icon={checkb}
        />
        <Pack
          setSelected={setSelected}
          selected={selected}
          number={2}
          title={"PACK ARGENT"}
          levels={"250 euros = 2500 LEVELS"}
          setlevels={setlevels}
          icon={checkA}
        />
        <Pack
          setSelected={setSelected}
          selected={selected}
          number={3}
          title={"PACK OR"}
          levels={"500 euros = 6000 LEVELS"}
          setlevels={setlevels}
          icon={checkOR}
        />
      </div>
      <div className="flex justify-end pt-12 pr-48">
        <button
          type="submit"
          className={
            selected === 0
              ? `relative w-[20%] flex justify-center items-center border border-transparent text-md font-medium  text-white button-gris rounded-full h-9`
              : `relative w-[20%] flex justify-center items-center border border-transparent text-md font-medium  text-white button-color rounded-full h-9`
          }
          onClick={() => handlePayement()}
        >
          Passer au paiement
        </button>
      </div>
    </div>
  );
}
