import { message } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const generatedAndSendResultPdf = (ref, studentId, formulaireId) => {
    const element = ref.current;
    const scale = 0.8;
    const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
    html2canvas(element, { scale: scale }).then(async (canvas) => {
      const imgData = canvas.toDataURL('image/png');

      const doc = new jsPDF({
        orientation: 'landscape',
        unit: 'mm',
        format: 'a4',
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        marginBottom: 0, 
      });
      doc.addImage(imgData, 'PNG', 0, 0);

      // Convert the PDF document to a Blob
      const pdfBlob = doc.output("blob");

      // Create FormData object and append the Blob
      const data = new FormData();
      data.append("file", pdfBlob, "results.pdf"); // You can change the filename if needed

      // Send the PDF data to the server using fetch API
      try {
        const response = await fetch(`${apiBaseURL}/stagiaire/${studentId}/result/${formulaireId}`, {
          method: 'POST',
          body: data
        });
        if (response.ok) {
          message.success("PDF envoyé avec succès.");
        } else {
          console.error('Failed to upload PDF:', response.statusText);
        }
      } catch (error) {
        console.error('Error uploading PDF:', error);
      }
    });

  }