import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import EtapesRender from "../../components/EtapesRender";
import {
  setRoomState,
  setSessionId,
} from "../../features/auth/authSlice";
import NavbarEspaceLevel from "../espaceLevel/NavbarEspaceLevel";
import play from "../../images/playGreen.svg";
import { setIndexLive } from "../../features/formulaires/formulaireSlice";
import EtapesRenderDisabled from "../../components/EtapesRenderDisabled";
import { getStagiaire, setCurrentReponse, setCurrentTentative } from "../../features/stagiares/stagiaireSlice";
import socket from "../../socket.js";
import { getOneFormation } from "../../features/formation/formationSlice.js";
import { message } from "antd";
import QuitConfirmModal from "../vueFormateur/QuitConfirmModal/index.jsx";
import {isUserAlreadyAnswered, shouldBeClosedChecker } from "./utils/helpers.js";
import IndependentFileList from "../../components/WorkflowBuilder/IndependentFileList/IndependentFileList.jsx";

export default function StagiaireWorkflow() {

  const {
    currentLevel,
    terminer,
    currentStagiare,
    currentIndep,
    currentTentative,
  } = useSelector((state) => state.stagiaire);

  const [start, setStart] = useState("wait");
  const [reloaded, setReloaded] = useState(localStorage.getItem("reloaded") || false);
  const { sessionID, room, roomState } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { liveFormation } = useSelector((state) => state.formation);
  const isRythmeFormateur = roomState?.rithme === "Rythme de formateur";
  const isRithmeStagiaire = roomState?.rithme === "Rythme de stagiaire";
  onbeforeunload = (event) => {
    window.setTimeout(function () {
      window.location = `sessionModal/${liveFormation.slug}`;
    }, 0);
    window.onbeforeunload = null;
  };


  useEffect(() => {
    const formationId = localStorage.getItem("LiveFormationId");
    dispatch(getOneFormation(formationId));
    const stagaireId = localStorage.getItem("currentStagiaireId");
    dispatch(getStagiaire(stagaireId));
    localStorage.removeItem("reloaded");
    dispatch(dispatch(setCurrentReponse({})));
    dispatch(setCurrentTentative({}));
    localStorage.removeItem("hasAlreadySentResults");
  }, [liveFormation?.id, dispatch]);
  useEffect(() => {
    if (isRithmeStagiaire) {
      // get the last updated version of formation to get the last updated version of results 
      const formationId = localStorage.getItem("LiveFormationId");
      dispatch(getOneFormation(formationId));
    }
  }, []);

  const handleStart = (e) => {
    let etapes = JSON.parse(liveFormation?.etapes || "[]");

    if (etapes[e.target.value].id !== "presentation") {
      const isUserAnswered = isUserAlreadyAnswered(liveFormation, currentStagiare, etapes[e.target.value].id);
      if (isUserAnswered) {
        message.warning("Vous avez déjà répondu à ce formulaire");
        return;
      }
    }

    const room = {
      ...roomState,
      currentForm: etapes[e.target.value],
      selectedForm: true,
    };
    setStart(e.target.value);
    dispatch(setRoomState(room));
    dispatch(setIndexLive(1));
    dispatch(setCurrentTentative({}));
    localStorage.removeItem("hasAlreadySentResults");
  };
  const etapesRender = () => {
    let etapes = JSON.parse(liveFormation?.etapes || "[]");
    if(etapes?.length === 0) return <div className="my-4 max-w-[400px] text-center">Si Vous être le formateur qui a crié cette formation, ajouter des étapes à ce workflow pour pouvoir les visualiser ici</div>;
      let reelLevel = 0;
      let indepLevel = 0;
      let closeLevelFromIndex = etapes.length;
      return etapes.map((el, index) => {
        if (el.condition?.includes("/") || el.condition == "Lié sans condition") {
          reelLevel += 1;
        } else if (el.condition == "fichier indépendant") {
          indepLevel += 1;
        }
        const shouldBeClosed = shouldBeClosedChecker(index, etapes[index - 1], liveFormation, currentStagiare);
        closeLevelFromIndex = shouldBeClosed ? index : closeLevelFromIndex;
        if (
          shouldBeClosed || index >= closeLevelFromIndex
        ) { return <EtapesRenderDisabled etape={el} index={index} key={index} /> }
        else {
          return (
            <EtapesRender
              etape={el}
              index={index}
              handleStart={handleStart}
              start={start}
              key={index}
              isRythmeFormateur={isRythmeFormateur}
            />
          );
        }

      });
  };

  useEffect(async () => {
    let authDATA = {
      username: currentStagiare.Prenom,
      sessionID: sessionID,
      room: String(liveFormation.organismeId) + String(liveFormation?.id),
      Genre: currentStagiare.Genre,
      userID: currentStagiare.id,
      role: "stagiaire",
    };
    socket.close();
    socket.auth = authDATA;

    socket.connect();
    socket.emit("join_room", authDATA);
    socket.on("session", (session) => {
      dispatch(setSessionId(session.sessionID));
    });
    socket.on("room", (data) => {
      if (data?.rithme === "Rythme de formateur") {
        // todo only for demo purpose
        dispatch(setRoomState(data));
        dispatch(setIndexLive(data.index + 1));
        if (data?.currentForm?.page === 'workflow') {
          navigate("/stagiaireWorkflow");
        } else {
          navigate("/stagiaires");
        }
      }
      else {
        dispatch(setRoomState({
          ...roomState,
          rithme: "Rythme de stagiaire",
        }));
      }

    });


    let tentative = {
      formationId: currentStagiare.formationId,
      stagiaireId: currentStagiare?.id,
      formulaireId: roomState?.currentForm?.id,
      organismeId: liveFormation?.organismeId,
    };

    if (!currentTentative.id) socket.emit("createTentative", tentative);

    socket.on("tentative", (data) => {
      dispatch(setCurrentTentative(data));
    });

  }, [socket]);

  const Commencer = () => {
    if (!roomState?.currentForm) {
      message.warning("Veuillez sélectionner une formation ou une présentation");
      return;
    }
    else if (roomState?.currentForm?.id === "presentation") {
      // message.success("La présentation est lancée avec succès");
    }
    else {
      // message.success("Le formulaire est lancée avec succès");
    }
    navigate("/stagiaires");
  };

  useEffect(() => {
    if (Object.keys(currentStagiare).length === 0) {
      const currentStagiaireId = localStorage.getItem("currentStagiaireId");
      dispatch(getStagiaire(currentStagiaireId));
    }
  },
    []);

  const [showConfirmEnd, setShowConfirmEnd] = useState(false);

  const endTraining = () => {
    navigate(`/logout`)
  }

  return (
    <>
      <div className="flex flex-col h-screen">
        <NavbarEspaceLevel public />
        <div className="   py-5  pl-16 border-b-2 flex justify-between">
          <div className="flex items-center gap-4">
            <img src={play} alt="Level" className="h-6 " />
            <span className=" text-xl font-bold">DIFFUSION D'UNE FORMATION</span>
          </div>
          <button id="danger" className="rounded-full border-2 p-[10px] w-auto inline-block mr-8" onClick={() => setShowConfirmEnd(true)}>Quitter la formation</button>
        </div>
        <div className=" flex-1 bg-gray-200 overflow-auto flex flex-col items-center">
          {etapesRender()}
          <div className="w-[48%] min-w-[400px] mb-[50px]">
            {<IndependentFileList attachments={liveFormation?.fichierIndependants && liveFormation?.fichierIndependants?.length ? liveFormation?.fichierIndependants : []} />}
          </div>
        </div>
        <div>
        </div>

        {/* Start Rythme de stagiaire Button */}
        {(start !== "wait") && !isRythmeFormateur ? (
          <div className="flex flex-col gap-2 items-center justify-center bg-gray-200 h-[15%] ">
            <button
              className="group relative w-[25%] flex justify-center items-center border border-transparent text-sm font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-10 "
              onClick={Commencer}
            >
              Commencer
            </button>
          </div>
        ) : null}
      </div>
      {showConfirmEnd && <QuitConfirmModal onOk={endTraining} setShow={setShowConfirmEnd} isVueFormateur={false} />}
    </>
  );
}
