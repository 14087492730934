import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

const initialState = {
  user: null,
  isError: false,
  isSuccess: false,
  tabMode: false,
  isLoading: false,
  impayer: false,
  adminpay: false,
  message: "",
  messageInscrip: "",
  isErrorInscrip: false,
  sessionID: null,
  room: "",
  RFAllResults: [],
  roomState: {},
  allOrganisme: [],
  connectedStagaires: [],
};

// Register user
export const register = createAsyncThunk("register", async (user, thunkAPI) => {
  try {
    return await authService.register(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Login user
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateProfile = createAsyncThunk(
  "auth/updateProfile",
  async (data, thunkAPI) => {
    try {
      return await authService.UpdateProfile(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAll = createAsyncThunk(
  "auth/getAll",
  async (data, thunkAPI) => {
    try {
      return await authService.getAllOrganisme();
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getMonnaie = createAsyncThunk(
  "auth/getMonnaie",
  async (data, thunkAPI) => {
    try {
      return await authService.getMonnaie(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendMonnaie = createAsyncThunk(
  "sendMonaie",
  async (data, thunkAPI) => {
    try {
      return await authService.sendMonnaie(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    switchMode: (state, action) => {
      state.tabMode = action.payload.tabMode;
    },
    Logout: (state) => {
      state.user = null;
      state.isLoading = false;
      state.tabMode = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.messageInscrip = "";
      state.isErrorInscrip = false;
      localStorage.clear();
    },
    resetData: (state) => {
      state.isSuccess = false;
      state.tabMode = false;
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.messageInscrip = "";
      state.isErrorInscrip = false;
    },
    setSessionId: (state, action) => {
      state.sessionID = action.payload;
    },
    setRoom: (state, action) => {
      state.room = action.payload;
    },
    setRoomState: (state, action) => {
      state.roomState = action.payload;
    },
    setRFResults: (state, action) => {
      state.RFAllResults = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setImpayer: (state, action) => {
      state.impayer = action.payload;
    },
    setAdminPay: (state, action) => {
      state.adminpay = action.payload;
    },
    setConnectedStagaires: (state, action) => {
      state.connectedStagaires = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tabMode = false;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isErrorInscrip = true;
        state.messageInscrip = action.payload;
        state.user = null;
      })
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isErrorInscrip = true;
        state.messageInscrip = action.payload;
        state.user = null;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tabMode = false;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
        state.user = null;
      })
      .addCase(getAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAll.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.tabMode = false;
        state.allOrganisme = action.payload;
      })
      .addCase(getAll.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.user = null;
      })
      .addCase(getMonnaie.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMonnaie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        let obj = { ...state.user };
        obj.point = action.payload.Point;
        state.user = obj;
      })
      .addCase(getMonnaie.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(sendMonnaie.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendMonnaie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(sendMonnaie.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export const {
  Logout,
  resetData,
  setAdminPay,
  setSessionId,
  setRFResults,
  setImpayer,
  setRoom,
  setConnectedStagaires,
  setRoomState,
  switchMode,
  setUser,
} = authSlice.actions;

export default authSlice.reducer;
