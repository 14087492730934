import React, { useState } from "react";
import NavBarModal from "./NavBarModal";
import ProfileDropDown from "../layout/espaceLevel/ProfileDropDown";
import Line from "../images/Line.svg";
import PokerChip from "../images/PokerChip.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function HalfNavbar() {
  const { user } = useSelector((state) => state.auth);
  const [hide, setHide] = useState("hidden");
  let navigate = useNavigate();

  const handleClick = () => {
    hide === "hidden" ? setHide("") : setHide("hidden");
  };
  return (
    <div className=" flex flex-row justify-end basis-1/2 gap-9 px-7 items-center mobileHide">
      <div className="flex flex-row">
        <div className="flex items-center gap-2 flex-row-reverse bg-gray-100 rounded-r-full w-28">
          <div className="flex gap-2 pr-3 cursor-pointer" onClick={() => navigate("/credits")}>
            <span className="reset text-xl ">{user?.point || 0 }</span>
            <span className="reset text-xl ">LEVEL</span>
          </div>
          <img src={PokerChip} alt="Level" className="h-10 w-16" />
        </div>
      </div>
      <div>
        <img src={Line} alt="Level" className="h-16 w-5/6" />
      </div>
      <div>
        <NavBarModal handleClick={handleClick} />
      </div>
      <ProfileDropDown hide={hide} />
    </div>
  );
}
