import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import AdministratifCard from "./detailsFormationCards.js/AdministratifCard";
import ClientFormulairesCard from "./detailsFormationCards.js/ClientFormulairesCard";
import AnimateurFormulairesCard from "./detailsFormationCards.js/FormulairesAnimateurCard";
import StagiairesFormulairesCard from "./detailsFormationCards.js/StagiairesFormulairesCard";
import WorkflowCard from "./detailsFormationCards.js/WorkflowCrad";
import { editFormation, getOneFormation } from "../../../features/formation/formationSlice";
import ResultCards from "./detailsFormationCards.js/ResultCards";

export default function DetailFormation({ setScreen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let { id } = useParams();
  const {liveFormation} = useSelector((state) => state.formation);
  const currentForm = liveFormation;

  useEffect(() => {
    if ((id && liveFormation.id !== id )|| id && !liveFormation) {
      dispatch(getOneFormation(id))
    }; 
  }, []);

  const handleSynthetise = async () => {
    const obj = {
      id: currentForm.id,
      ogranismeId: currentForm.organismeId,
      synthese: 1,
      step: 6,
    };
    await dispatch(editFormation(obj));
    navigate("/EspaceLevel/synthetiseur");
  };
  return (
    <div className=" flex-1 bg-gray-100 flex flex-col px-16 ">
      <div className=" basis-[15%] flex items-center ">
        <div className=" basis-[40%] flex gap-2 items-center">
          <button onClick={() => navigate("/EspaceLevel/formation")}>
            <span className=" text-xl font-semibold">FORMATIONS</span>
            <span className=" text-xl font-medium"> LEVEL</span>
          </button>
          <span className=" text-lg font-medium"> {"> Détails formation"}</span>
        </div>
      </div>
      <div className="  basis-[85%] grid grid-cols-4 gap-4 pl-2  justify-start  overflow-auto py-8">
        <AdministratifCard
          date={currentForm?.createdAt}
          id={currentForm?.id}
          setScreen={setScreen}
        />
        <ClientFormulairesCard
          date={currentForm?.createdAt}
          id={currentForm?.id}
          setScreen={setScreen}
        />
        <AnimateurFormulairesCard
          date={currentForm?.createdAt}
          id={currentForm?.id}
          setScreen={setScreen}
        />
        <StagiairesFormulairesCard
          date={currentForm?.createdAt}
          id={currentForm?.id}
          setScreen={setScreen}
        />
        <WorkflowCard
          date={currentForm?.createdAt}
          id={currentForm?.id}
          setScreen={setScreen}
        />
        <ResultCards
          date={currentForm?.createdAt}
          id={currentForm?.id}
          setScreen={setScreen}
        />
      </div>
      <div className="flex justify-end pt-8 pb-4">
        <button
          type="submit"
          className={`group relative w-[20%] flex justify-center items-center border border-transparent text-md font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-9`}
          onClick={() => handleSynthetise()}
        >
          Archiver
        </button>
      </div>
    </div>
  );
}
