import React, { useEffect, useState } from "react";

import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {dispShowModelForm} from "../../../features/formulaires/formulaireSlice";
import { useSelector, useDispatch } from "react-redux";
import EmptySynthetiseur from "./EmptySynthetiseur";
import { getAllFormationSynthetiseur } from "../../../features/synthetiseur/synthetiseurSlice";

export default function Synthetiseur({ refrech, setRefrech }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let form = location.pathname.includes("ListCate") ? "butop" : "but";
  let catégories = location.pathname.includes("ListCate") ? "but" : "butop";
  const { user } = useSelector((state) => state.auth);
  const { allFormationsSynthetiseur } = useSelector(
    (state) => state.synthetiseur
  );

  

  useEffect(async () => {
    await dispatch(getAllFormationSynthetiseur(user?._id));
  }, []);

  const redirection = (path) => {
    navigate(`${path}`);
  };

  const handleClick = () => {
    dispatch(dispShowModelForm(true));
  };

  return (
    <>
      {allFormationsSynthetiseur.length === 0 ? (
        <EmptySynthetiseur
          nouveau="nouveaux"
          majType={"FORMULAIRE"}
          setShowModal={handleClick}
          type="formulaire"
          one="UN"
        />
      ) : (
        <div className=" flex-1 bg-gray-100 flex flex-col px-16 max-h-screen ">
          <div className="border-b-2 basis-[15%] px-3 flex items-center justify-center">
            <div className=" basis-[40%] flex gap-2">
              <span className=" text-lg font-semibold">ARCHIVES</span>
              <span className=" text-lg font-medium">LEVEL</span>
            </div>
            <div className=" basis-[60%] flex justify-end gap-7  ">

              {/* <Partager/> */}
             {/*  <img src={Partager} alt="Level" className="h-9 " />
              <img src={Line} alt="Level" className="h-10 " />
              <img
                src={Filtre}
                alt="Level"
                className="h-10 cursor-pointer "
                onClick={() => setShow(!show)}
              />
              <DuplicateDelete show={show} /> */}
            </div>
          </div>
          <div className="  basis-[15%] flex justify-center items-center ">
            <div className={`w-[49%] h-16 rounded-l-2xl ${form} flex`}>
              <button
                className="flex-1 rounded-r-lg text-white text-xl"
                onClick={() =>
                  redirection("/EspaceLevel/synthetiseur/ListForm")
                }
              >
                Archives communs
              </button>
            </div>
            <div
              className={`w-[49%] h-16 rounded-r-2xl ${catégories} flex`}
              onClick={() => redirection("/EspaceLevel/synthetiseur/ListCate")}
            >
              <button className="flex-1 text-white text-xl">
                Archives par catégories
              </button>
            </div>
          </div>
          <div className=" flex flex-wrap  justify-start gap-5 overflow-auto py-8 px-3">
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
}
