import axios from "../../configAxios";
import _ from "lodash";

const createStagiaires = async (formData) => {
  try {
    const response = await axios.post(`/stagiaire/creatall`, formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};
const getTentative = async (id) => {
  try {
    const response = await axios.get(`/stagiaire/gettentative/${id}`);

    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";

    throw message;
  }
};

const createStagiaire = async (formData) => {
  try {
    const response = await axios.post(`/stagiaire/creatone`, formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const sendCommentToStagiaire = async (payload) => {
  try {
    const response = await axios.post(`/commentaires`, payload);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const updateCommentStagiaire = async (payload) => {
  try {
    const response = await axios.put(`/commentaires/${payload.commentId}`, _.omit(payload, "commentId"));
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const createSignature = async (formData) => {
  try {
    const response = await axios.post(`/stagiaire/signature`, formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const createSignatureFormateur = async (formData) => {
  try {
    const response = await axios.post(`/stagiaire/signatureFormateur`, formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const createStagiaireReponses = async (data) => {
  try {
    const response = await axios.post(`/stagiaire/reponses`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const updateStagiaireReponses = async (data) => {
  try {
    const response = await axios.put(`/stagiaire/update/${data.id}`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getOrganismeInfos = async (id) => {
  try {
    const response = await axios.get(`/stagiaire/organisme${id}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getStagiaire = async (id) => {
  try {
    const response = await axios.get(`/stagiaire/${id}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const createTentative = async (data) => {
  try {
    const response = await axios.post(`/tentative`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const stagiaireService = {
  createStagiaires,
  createStagiaire,
  getOrganismeInfos,
  createStagiaireReponses,
  getStagiaire,
  updateStagiaireReponses,
  createSignature,
  getTentative,
  createSignatureFormateur,
  sendCommentToStagiaire,
  updateCommentStagiaire,
  createTentative
};

export default stagiaireService;
