import React, { useState } from "react";
import SousNavresult from "../../components/StagComponents/SousNavresult";
import Resultcard from "../../components/StagComponents/Resultcard";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import StagiaireCommentaire from "./StagiaireCommentaire";
import { message } from "antd";
import { sendCommentToStagiaire, updateCommentStagiaire } from "../../features/stagiares/stagiaireSlice";
import { getOneFormation } from "../../features/formation/formationSlice";
import socket from "../../socket";
import { groupArrayByquestion } from "../../helper";
import { setRoomState } from "../../features/auth/authSlice";
import QuitConfirmModal from "../vueFormateur/QuitConfirmModal";

export default function ResumeStagiaires() {
  const [showCommentDialog, setShowCommentDialog] = React.useState(false);
  const { user, sessionID, room } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { RFAllResults, connectedStagaires } = useSelector(
    (state) => state.auth
  );
  const connected = connectedStagaires.filter((el) => el.role === "stagiaire");
  const { liveFormation } = useSelector((state) => state.formation);
  const [currentStagiareId, setCurrentStagiareId] = React.useState("");
  const [commentContent, setCommentContent] = React.useState("");

  const calculateTotal = (questions) => {
    let total = 0;
    questions?.map((quest, ind) => {
      if (quest.Type !== "TexteLibre") {
        total += quest.points;
      }
    });
    return total;
  };

  const sendComment = (content) => {
    const messagePayload = {
      formationId: Number(localStorage.getItem("LiveFormationId")),
      organismeId: user._id,
      stagiaireId: currentStagiareId,
      content: content,
    }
    dispatch(sendCommentToStagiaire(messagePayload)).then(res=>{
      if(res.meta?.requestStatus === 'fulfilled'){
        message.success("Commentaire envoyé avec succès");
        dispatch(getOneFormation(localStorage.getItem("LiveFormationId")));
       
      }
      else {
        message.error("Vous avez déjà ajouté un commentaire à ce stagiaire.");
      }
    });
  }

  const updateComment = (content) => {
    const messagePayload = {
      commentId: liveFormation.stagiaires.find(stag => stag.id === currentStagiareId)?.commentaires[0].id,
      content: content,
    }
    dispatch(updateCommentStagiaire(messagePayload)).then(res=>{
      if(res.meta?.requestStatus === 'fulfilled'){
        message.success("Commentaire a été mis à jour avec succès");
        // socket.emit("addComment", { content: content, stagiaireId: currentStagiareId });
          dispatch(getOneFormation(localStorage.getItem("LiveFormationId")));
      }
      else {
        message.error("Erreur lors de la mise à jour du commentaire");
      }
    });
  }
  const handleClickSend = (message) => {
    if (liveFormation.stagiaires.find(stag => stag.id === currentStagiareId)?.commentaires?.length > 0) {
      updateComment(message);
    } else {
      sendComment(message);
    }
  }

  const calculateScore = (CurrentStagiaireresponses, formulaireQuestions) => {
    if (!formulaireQuestions?.length) return 0;
    console.log(CurrentStagiaireresponses)
    let studentScore = Object.values(CurrentStagiaireresponses)?.reduce((acc, item, currentIndex) => {
      const QuestionScore = item[0]?.points;
      const QuestionCorrectAnswersCount = formulaireQuestions?.find(q => item[0]?.questionId === q?.id)?.reponses?.filter(r => r.status === true).length;
      const AnswerWeight = QuestionScore / QuestionCorrectAnswersCount;
      const correctAnswerWeight = AnswerWeight * 1;
      const wrongAnswerWeight = AnswerWeight * -1;
      let currentQuestionScore = 0;
      item.forEach(element => {
        if (element.status === true) {
          currentQuestionScore += correctAnswerWeight;
        } else {
          currentQuestionScore += wrongAnswerWeight;
        }
      });
      acc += currentQuestionScore < 0 ? 0 : currentQuestionScore;
      return acc < 0 ? 0 : acc;
    },
      0);
    return studentScore;
  }
  const onOpenComment = (id) => {
    setCurrentStagiareId(id);
    setShowCommentDialog(true);
    setCommentContent(liveFormation.stagiaires.find(stag => stag.id === id)?.commentaires?.[0]?.content || '')
  };

  const endTraining = () => {
    let authDATA = {
      username: user.name,
      sessionID: sessionID,
      room: room,
      userID: user._id,
      role: "formateur",
      index: 0,
      formationId : liveFormation.id,
      roomState: {
        state: false,
        rithme: "Rythme de formateur",
        currentForm:  { page: "quit"},
        index: 0,
        time: 0
      },
    };
    dispatch(setRoomState({
      state: false,
      rithme: "Rythme de formateur",
      currentForm:  { page: "quit"},
      time: 0
    }))
    socket.emit("state_room", authDATA);
    navigate(`/EspaceLevel/formation`);
  }

  const [showConfirmEnd, setShowConfirmEnd] = useState(false);


  return (
    <>
    <div className="h-[90%] bg-slate-100 flex flex-col p-[3%] gap-9">
      <SousNavresult title={'LES RÉSULTATS DES STAGIAIRES'} number={connected?.length} />
      <div className="h-[70%] grid grid-cols-6 md:grid-cols-4 gap-5 overflow-auto">
        {
          connectedStagaires.map((element, index) => {
            let totalScore = 0;
            const totalScoreQuestions = calculateTotal(element?.form?.questions) || 0;
            if (RFAllResults) {
              const tentativeResponses = RFAllResults?.find(r => r.stagiaireId === element.userID)?.tentativeResponses || [];
              totalScore = calculateScore(groupArrayByquestion(tentativeResponses, "questionId"), element?.form?.questions);
              
            } 
            const finalScore = totalScore > 0 ? `${totalScore.toFixed(2)}/${totalScoreQuestions}` : 0;
            return (
              <div key={index} className="flex justify-center">
                <Resultcard onOpenComment={onOpenComment} elementId={element.userID} setCurrentStagiareId={setCurrentStagiareId} avatar={element.Genre} username={element.username} id={element.id} finalResult={finalScore} showComment={showCommentDialog} onToggleShowComment={setShowCommentDialog} />
              </div>
            );
          })
        }
         {showCommentDialog && <StagiaireCommentaire setShow={setShowCommentDialog} comment={commentContent} setComment={(message) => handleClickSend(message)}  />}
      </div>
      <div className="flex justify-end gap-2" >
        <button id="creer" className="rounded-full border-2 p-[10px] w-auto inline-block" onClick={() => navigate('/formateurWorkflow')}>Aller à la page de flux d'étude</button>
        <button id="danger-radius" className="rounded-full border-2 p-[10px] w-auto inline-block mr-8" onClick={() => setShowConfirmEnd(true)}>Terminer la formation</button>
      </div>
    </div>
   { showConfirmEnd && <QuitConfirmModal onOk={endTraining} setShow={setShowConfirmEnd} isVueFormateur={true}/>}
    </>
  );
}
