import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import questionService from "./questionService";


const initialState = {
    question: {},
    currentQuestion: {},
    reponses:[],
    prevIndex: 1,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    isLoadingImg: false,
  };


  export const createQuestion = createAsyncThunk(
    "createQuestion",
    async (input, thunkAPI) => {
      const question = input.formData
      const id = input.id
      try {
        return await questionService.createQuestion(question,id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  );

  export const duplicateQuestion = createAsyncThunk(
    "duplicateQuestion",
    async (question, thunkAPI) => {
      try {
        return await questionService.duplicateQuestion(question);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  );

  export const updateQuestion = createAsyncThunk(
    "updateQuestion",
    async (input, thunkAPI) => {
      const id = input.question.id
      try {
        return await questionService.updateQuestion(input,id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  );
  export const updateImageQuestion = createAsyncThunk(
    "updateImageQuestion",
    async (input, thunkAPI) => {
     
      try {
        return await questionService.updateImageQuestion(input);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message)
      }
    }
  );

  export const getOneQuestion = createAsyncThunk(
    "getOnequestion",
    async (id,thunkAPI) => {
      try {
        return await questionService.getOneQuestion(id)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const deleteQuestion = createAsyncThunk(
    "deleteOnequestion",
    async (id,thunkAPI) => {
      try {
        return await questionService.deleteQuestion(id)
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );


  export const questionSlice = createSlice({
    name: "question",
    initialState,
    reducers: {
      resetQuestion: (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = false;
        state.message = "";
        state.currentQuestion = {};
        state.reponses = []
      },
      dispatchCurrent: (state,action)=> {
        state.currentQuestion = action.payload
      },
      dispatchReponses: (state,action) => {   
        state.reponses = action.payload
      },
      dispatchIndex: (state,action) => {
        state.prevIndex = action.payload
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(createQuestion.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(createQuestion.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.question= action.payload
          state.currentQuestion= action.payload
          state.reponses = action.payload.reponses
        })
        .addCase(createQuestion.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(duplicateQuestion.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(duplicateQuestion.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.question= action.payload
          state.currentQuestion= action.payload
          state.reponses = action.payload.reponses
        })
        .addCase(duplicateQuestion.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(getOneQuestion.pending, (state) => {
          state.isLoading = true;
        })
        
        .addCase(getOneQuestion.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.currentQuestion= action.payload
          state.reponses = action.payload.reponses
        })
        .addCase(getOneQuestion.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(updateQuestion.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(updateQuestion.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.currentQuestion = action.payload
        })
        .addCase(updateQuestion.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(deleteQuestion.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(deleteQuestion.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
        })
        .addCase(deleteQuestion.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
        .addCase(updateImageQuestion.pending, (state) => {
          state.isLoadingImg = true;
        })
        .addCase(updateImageQuestion.fulfilled, (state, action) => {
          state.isLoadingImg = false;
          state.isSuccess = true;
        })
        .addCase(updateImageQuestion.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })
     
    },
  });


export const { resetQuestion, dispatchCurrent, dispatchReponses, dispatchIndex } = questionSlice.actions;
export default questionSlice.reducer;
