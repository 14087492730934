import React, { Fragment, useEffect, useState } from "react";
import eyes from "../../../../images/eyes.svg";
import play from "../../../../images/play.svg";
import edit from "../../../../images/edit.svg";

import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CartEspaceLevelCatg from "../../../../components/CartEspaceLevelCatg";
import { getAllCategorieFormation, setModelCateFormation } from "../../../../features/categorieFormation/categorieFormationSlice";
import TableEspaceLevel from "../../synthetiseurDetails/TableEspaceLevel";
import RowEspaceLevel from "../../synthetiseurDetails/RowEspaceLevel";
import { getAllFormationPartaged } from "../../../../features/formation/formationSlice";

export default function NestedCategorieFormation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { categorieFormations } = useSelector(
    (state) => state.categorieFormation
  );
  const { trierPar, search } = useSelector((state) => state.formulaires);
  let [cate, setCate] = useState([...categorieFormations]);
  const { user, tabMode } = useSelector((state) => state.auth);

  useEffect(() => {
    let categ = [...categorieFormations];
    if (trierPar === "Plus récent") {
      categ.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setCate(categ);
    } else if (trierPar === "Nom A-Z") {
      categ.sort((a, b) => {
        if (a.titre < b.titre) {
          return -1;
        }
        if (a.titre > b.titre) {
          return 1;
        }
        return 0;
      });
      setCate(categ);
    } else if (trierPar === "Plus ancien") {
      setCate(categorieFormations);
    } else if (trierPar === "Nom Z-A") {
      categ.sort((a, b) => {
        if (a.titre < b.titre) {
          return 1;
        }
        if (a.titre > b.titre) {
          return -1;
        }
        return 0;
      });
      setCate(categ);
    }
  }, [categorieFormations, trierPar]);

  useEffect(async () => {
    await dispatch(getAllCategorieFormation(user?._id));
    await dispatch(getAllFormationPartaged(user?._id));
  }, []);

  const redirection = (path) => {
    navigate(`${path}`);
  };

  return (
    <>
      {!tabMode ? (
        <div className="flex flex-wrap gap-4  justify-start basis-[100%] overflow-auto py-8">
          {cate?.map((element, index) => {
            if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
              return (
                <Fragment key={index + "cate"}>
                  <CartEspaceLevelCatg
                    name={element.titre}
                    date={element.createdAt}
                    id={element.id}
                    checkbox ={element.titre !== "partager avec moi" ? true : false}
                    number={element?.joCatFormations?.length}
                    key={element.id}
                  >
                    <div className="flex items-center justify-center gap-2">
                      <button
                        type="button"
                        className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                      >
                        <img
                          src={eyes}
                          id={"slug"}
                          onClick={() => {
                            if (element.titre === "partager avec moi") {
                              redirection(
                                `/EspaceLevel/formationCatepartaged/${element.id}`
                              );
                            } else {
                              redirection(
                                `/EspaceLevel/formationCateFormation/${element.id}`
                              );
                            }
                          }}
                        />
                      </button>
                      {element.titre !== "partager avec moi" ?<button
                       type="button"
                       className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                       onClick={() =>
                        dispatch(setModelCateFormation({showModelCateFormation: true, toEditCateFormation: element.id}))          
                      }
                     >
                       <img src={edit} id={"slug"} />
                     </button> : null }
                    </div>
                  </CartEspaceLevelCatg>
                </Fragment>
              );
            }
          })}
        </div>
      ) : (
        <TableEspaceLevel
          type={"category"}
          nom={"Nom de la catégorie"}
          form={"Formations"}
        >
          {cate?.map((element, index) => {
            if (search.length === 0 || element.titre?.toLowerCase().includes(search?.toLowerCase())) {
              return (
                <Fragment key={index + "cate1"}>
                  <RowEspaceLevel
                    type={"category"}
                    name={element.titre}
                    date={element.createdAt}
                    updatedate={element.updatedAt}
                    id={element.id}
                    number={element.joCatFormations?.length}
                    checkbox="cateFormation"
                    text={"crée le "}
                    key={element.id}
                    archive={element.titre == "partager avec moi" ? true : false}
                  >
                    <div className="flex items-center justify-left gap-2">
                      <button
                        type="button"
                        className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() =>
                          redirection(
                            `/EspaceLevel/formationCateFormation/${element.id}`
                          )
                        }
                      >
                        <img src={eyes} id={"slug"} />
                      </button>
                      {element.titre !== "partager avec moi" ? <button
                        type="button"
                        className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() =>                      
                          dispatch(setModelCateFormation({showModelCateFormation: true, toEditCateFormation: element.id}))
                        }
                      >
                        <img src={edit} id={"slug"} />
                      </button> : null }
                    </div>
                  </RowEspaceLevel>
                </Fragment>
              );
            }
          })}
        </TableEspaceLevel>
      )}
    </>
  );
}
