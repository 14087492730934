import React, { useEffect, useState, useRef } from "react";
import NavbarEspaceLevel from "../layout/espaceLevel/NavbarEspaceLevel";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../components/Table";
import Avatar from "../images/Avatar.svg";
import Home from "../images/Home.svg";
import { useSelector } from "react-redux";
import StudentTableResultats from "../components/StudentTableResultats";
import { PDFExport } from "@progress/kendo-react-pdf";

export default function ResultatsFormationResume() {
  const navigate = useNavigate();
  const { allFormationsSynthetiseur } = useSelector(
    (state) => state.synthetiseur
  );
  const [currentForm, setCurrentForm] = useState({});
  const [currentStag, setCurrentStag] = useState({});
  const [tenta, setTenta] = useState(null);
  const { user } = useSelector((state) => state.auth);
  let { id } = useParams();
  useEffect(() => {
    let formId = id.split("-")[0];
    let stagId = id.split("-")[1];
    let arr = [...allFormationsSynthetiseur].filter((el) => {
      return el.id == formId;
    });
    setCurrentForm(arr[0]);

    if (arr.length > 0) {
      let stagiaire = arr[0].stagiaires.filter((el) => {
        return el.id == stagId;
      });
      setCurrentStag(stagiaire[0]);
    }
  }, [allFormationsSynthetiseur]);

  const Quit = () => {
    if (tenta) {
      setTenta(null);
    } else {
      navigate(`/EspaceLevel/synthetiseurStagiaires/${currentForm?.id}`);
    }
  };
  const pdfExportComponent = useRef(null);
  const exportPdf = () => {
    pdfExportComponent.current.save();
  };

  return (
    <div className="flex flex-col bg-gray-100 min-h-screen">
      <NavbarEspaceLevel />

      <div className="m-8 ">
        <div className=" basis-[10%] flex flex-wrap justify-between border-b-2 items-center ">
          <div className="w-[85%] flex items-center gap-2">
            <button onClick={() => navigate(`/EspaceLevel/synthetiseur`)}>
              <span className="flex-1 text-xl font-semibold">ARCHIVES</span>
            </button>
            <button onClick={() => navigate(`/EspaceLevel/synthetiseur`)}>
              <span className="flex-1 text-lg font-medium">
                {"> "}
                Détails formation
              </span>
            </button>
            <button>
              <span className="flex-1 text-lg font-medium">
                {" "}
                {"> TITRE FORMATION "}
              </span>
            </button>
            <button>
              <span className="flex-1 text-lg font-medium">
                {" "}
                {`> ${currentForm?.titre} `}
              </span>
            </button>
          </div>

          {/* <div className="flex justify-start p-8"> */}
          <button
            id="creer"
            className="w-[10%] m-2 rounded-full border-2 py-1 border-transparent text-md font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600"
            onClick={exportPdf}
          >
            Export PDF
          </button>
          {/* </div> */}
        </div>
        <PDFExport
          ref={pdfExportComponent}
          scale={0.8}
          margin="1cm"
          paperSize={[1000, 1250]}
        >
          <div className=" basis-[40%]  flex  justify-start items-start   overflow-auto py-8">
            <div className=" bg-white h-full w-full rounded-2xl p-2 flex flex-col gap-3">
              <div className="flex w-[95%] gap-1 border-b-2 p-4">
                <span className="text-lg font-bold">
                  {" "}
                  {`Formation ${currentForm?.titre} Le ${new Date(
                    currentForm?.startedAt
                  ).toLocaleDateString("fr-FR", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    localeMatcher: "best fit",
                  })} ${currentForm?.time} ${currentForm?.Adress ? "à " + currentForm?.Adress : ""}`}
                </span>
              </div>
              <div className="w-[95%]  gap-10 flex  p-4">
                <div className="flex gap-6">
                  {" "}
                  <div className="flex gap-1">
                    <span className="text-lg font-bold"> Candidat/</span>
                    <span className="text-lg font-semiBold Time">
                      {" "}
                      {currentStag?.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between">
            <div className="w-[75%]">
              {tenta ? (
                <StudentTableResultats tentative={tenta} currentForm={currentForm} />
              ) : (
                <Table stag={currentStag} setTenta={setTenta} currentForm={currentForm} />
              )}
            </div>

            <div className="w-[25%]">
              <div className="h-full relative flex flex-col ">
                <img
                  src={currentStag?.Genre == "female" ? Avatar : Home}
                  className="h-32 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4"
                  alt="avatar"
                />
                <div className="bg-white rounded-2xl flex flex-col justify-center items-center p-14 gap-8 pt-24">
                  <span className="font text-2xl">{currentStag?.Prenom}</span>
                </div>
              </div>
            </div>
          </div>
          <div className=" basis-[40%]  flex  justify-start items-start   overflow-auto py-8">
            <div className=" bg-white h-full w-full rounded-2xl p-2 flex flex-col gap-3">
              <div className="flex w-[95%] gap-1 border-b-2 p-4">
                <span className="text-lg ">
                  {" "}
                  {`Organisme de formation ${user?.name ? user?.name : " "} ${
                    user?.codePostal ? ", " + user?.codePostal : ""
                  } ${
                    user?.adressePostale ? user?.adressePostale + "," : " "
                  } ${user?.email ? " - " + user?.email : " "}  ${
                    user?.phone ? " -" + user?.phone : " "
                  }`}
                </span>
              </div>
              <div className="w-[95%]  gap-10 flex  p-4">
                <div className="flex gap-6">
                  {" "}
                  <div className="flex gap-1">
                    <span className="text-lg font-semiBold ">
                      {" "}
                      {`SIRET ${user?.siret ? user?.siret : " "} - APE${
                        user?.codeApe ? user?.codeApe : " "
                      } - NDA ${user?.codeNda ? user?.codeNda : " "}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PDFExport>
        <div className="flex absolute right-12  m-2 w-[20%]">
          <button
            id="creer"
            className="w-full rounded-full border-2 py-1 border-transparent text-md font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600"
            onClick={Quit}
          >
            Quitter la prévisualisation
          </button>
        </div>
      </div>
    </div>
  );
}
