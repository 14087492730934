import React,{useState,useEffect} from "react";
import Line from "../images/workflow-line.svg";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function EtapesRenderDisabled({ etape, index }) {
  const { width } = useWindowDimensions();
  const isMobile = width < 1047;
  return (
    <div className={isMobile?"flex flex-col gap-2 p-6 w-[50%]":"flex flex-col gap-8 p-6 w-[50%]"} key={etape.id}>
      <div className={isMobile?"flex gap-0 justify-start":"flex gap-0"}>
        <div className="formula-name  rounded-2xl h-14 w-[80px] min-w-[80px] p-2 flex items-center justify-center ">
          <span className="text-white">F.N° 0{index + 1}</span>
        </div>
        <>
        <img alt="line" src={Line} className="mb-12"/>
        <div className="flex-1 min-w-[400px]">
          <div className="formula-name rounded-t-2xl h-14  w-[100%]  flex items-center justify-between px-5">
            <span className="text-white text-capitalize">{etape.titre}</span>
          </div>
          <div className="formula-name py-1 min-h-12 flex w-[100%] rounded-b-2xl items-center justify-between px-5">
            <span className="pl-5 text-white text-capitalize">Formulaire fermé</span>    
          </div>
        </div>
        </>
      </div>
     {etape.condition && <div className={isMobile?"formula-name rounded-2xl w-20 h-12 flex items-center px-3 justify-center":"formula-name rounded-2xl w-20 h-12 flex items-center px-3 justify-center"}>
        <div className="flex items-center gap-1 ">
          <span className="text-white text-capitalize">{etape.condition}</span>
        </div>
        {/* <img src={trash} alt="trash" className="h-4" /> */}
      </div>}
    </div>
  );
}
