import { configureStore } from "@reduxjs/toolkit";

import {
  authSlice,
  formationSlice,
  formulaireSlice,
  questionSlice,
  reponseSlice,
  stagiaireSlice,
  categorieFormulaireSlice,
  categorieFormationSlice,
  payementSlice,
  synthetiseurSlice,
  programSlice,
  agendaSlice,
  eventSlice
} from "../features";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";


const persistConfig = {
  key: "root",
  storage : storage
};
const auth = persistReducer(persistConfig, authSlice);
const formation = persistReducer(persistConfig, formationSlice);
const formulaires = persistReducer(persistConfig, formulaireSlice);
const question = persistReducer(persistConfig, questionSlice);
const reponse = persistReducer(persistConfig, reponseSlice);
const stagiaire = persistReducer(persistConfig, stagiaireSlice);
const categorieFormulaire = persistReducer(
  persistConfig,
  categorieFormulaireSlice
);
const categorieFormation = persistReducer(
  persistConfig,
  categorieFormationSlice
);
const payement = persistReducer(persistConfig, payementSlice);
const synthetiseur = persistReducer(persistConfig, synthetiseurSlice);
const programe = persistReducer(persistConfig,programSlice)
const agenda = persistReducer(persistConfig,agendaSlice)
const event = persistReducer(persistConfig,eventSlice)


export const store = configureStore({
  reducer: {
    auth: auth,
    formation: formation,
    formulaires: formulaires,
    question: question,
    reponse: reponse,
    stagiaire: stagiaire,
    categorieFormulaire: categorieFormulaire,
    categorieFormation: categorieFormation,
    payement: payement,
    synthetiseur: synthetiseur,
    programe: programe,
    agenda: agenda,
    event: event
  },
  middleware: [thunk],
});

export const persistor = persistStore(store);


