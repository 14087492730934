import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pack: {},
};

export const payementSlice = createSlice({
  name: "pack",
  initialState,
  reducers: {
    dispatchPack: (state, action) => {
      state.pack = action.payload;
    },
  },
});

export const { dispatchPack } = payementSlice.actions;
export default payementSlice.reducer;
