import React, { useState } from "react";

import { Outlet } from "react-router-dom";
import NavbarEspaceLevel from "../../layout/espaceLevel/NavbarEspaceLevel";
import editProfil from "../../images/editProfil.svg";
import updateCredits from "../../images/updateCredits.svg";

import { useLocation, useNavigate } from "react-router-dom";

export default function Update() {
  const [userData, setUserData] = useState({});

  const location = useLocation();
  let checkPath = location.pathname;
  let profile = location.pathname.includes("Profile") ? "but" : "butop";
  let credits = location.pathname.includes("Updatecredits") ? "but" : "butop";

  const navigate = useNavigate();
  const onclickCredits = () => {
    navigate("/update/Updatecredits");
  };
  const onclickProfile = () => {
    navigate("/update/Profile");
  };
  return (
    <div className={`flex flex-col  bg-gray-100 h-screen`}>
      <NavbarEspaceLevel />
      <div className="m-8">
        <div className=" basis-[10%] flex justify-between border-b-2 items-center ">
          {checkPath === "/update/Profile" ? (
            <div className="  flex gap-2 items-center">
              <img src={editProfil} className="h-6" />
              <span className=" text-xl font-semibold">
                MODIFIER MON PROFIL
              </span>
            </div>
          ) : (
            <div className="  flex gap-2 items-center">
              <img src={updateCredits} className="h-6" />
              <span className=" text-xl font-semibold">Mes crédits</span>
            </div>
          )}
        </div>
        <div className="  basis-[15%] flex items-center pt-4">
          <div className={`w-[49%] h-16 rounded-l-lg ${profile} flex`}>
            <button
              className="flex-1 rounded-r-lg text-white text-xl"
              onClick={onclickProfile}
            >
              Modifier Mon Profil
            </button>
          </div>
          <div
            className={`w-[49%] h-16 rounded-r-lg ${credits} flex`}
            onClick={onclickCredits}
          >
            <button className="flex-1 text-white text-xl">Mes Crédits</button>
          </div>
        </div>

        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
