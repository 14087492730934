import React from "react";
import LOGO from "../../images/LOGO.svg";
import HalfNavbar from "../../components/HalfNavbar";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Profil from "../../images/Profil.svg";
import { getMonnaie } from "../../features/auth/authSlice";
import socket from "../../socket";

export default function NavbarEspaceLevel(props) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { organisme } = useSelector((state) => state.stagiaire);
  const { user } = useSelector((state) => state.auth);
  const doNothing = () => {
  };
  if(props?.shouldGoToForamteurWorkflow ){
  return (
    <div className="flex flex-row border-b-2 basis-1/12 bg-white ">
      <div className=" px-16 py-2 basis-1/2 mobilenav">
       <img src={LOGO} alt="Level" className="h-16 w-20"/>
      </div>
    </div>
  );
  }
  return (
    <div className="flex flex-row border-b-2 basis-1/12 bg-white ">
      <div className=" px-16 py-2 basis-1/2 mobilenav">
       {props?.isFormateur ? <img src={LOGO} alt="Level" className="h-16 w-20   cursor-pointer " onClick={() =>{ 
        navigate(`/session/${localStorage.getItem("LiveFormationId")}`)
        }
         } /> : 
        <img src={LOGO} alt="Level" className={`h-16 w-20  ${!props.public ? "cursor-pointer": ""}`} onClick={() =>{ 
         !props.public ? navigate("/EspaceLevel/formation") : doNothing()
        }
         } />}
      </div>

      {props.public ? <div className=" flex flex-row justify-end basis-1/2 gap-9 px-7 items-center mobileHide">
        <div className="flex flex-row">
          <img src={organisme?.Logo ? process.env.REACT_APP_IMG + organisme?.Logo : Profil} alt="Level" className="h-12 w-12 rounded-full" />
        </div>

      </div> :
        <HalfNavbar />
      }

    </div>
  );
}
