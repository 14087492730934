import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import CardFormClient from "./CardFormClient";
import edit from "../../../images/edit.svg";
import FeuillePresenceCard from "./detailsSynthetiseurCards/FeuillePresenceCard";

export default function ClientDetailSynthetiseur() {
  const navigate = useNavigate();
  let { id } = useParams();

  // const [currentForm, setCurrentForm] = useState({});
  // const [client, setClient] = useState({});
  const { liveFormation } = useSelector((state) => state.formation);

  // const { allFormationsSynthetiseur } = useSelector(
  //   (state) => state.synthetiseur
  // );
  const currentForm = liveFormation;
  const client = currentForm?.client || {};


  console.log(currentForm)
  // useEffect(() => {
  //   let arr = [...allFormationsSynthetiseur].filter((el) => {
  //     return el.id == id;
  //   });
  //   setCurrentForm(arr[0]);
  //   if (arr[0].Client) {
  //     setClient(arr[0].Client);
  //   }
  // }, []);


  const formulaires = () => {
    let str = currentForm.clientFormulaires || "[]";
    let forms = JSON.parse(str);
    let filter = currentForm.joinFormulaFormats?.filter((el) => {
      return forms.includes(String(el.formulaireId));
    });

    return filter?.map((element) => {
      return (
        <CardFormClient
          name={element.formulaire.titre}
          date={element.formulaire.createdAt}
          slug={element.formulaire.slug}
          number={element.formulaire.questions?.length}
          id={element.formulaire.slug}
          key={element.formulaire.id}
        />
      );
    });
  };

  return (
    <div className=" flex-1 bg-gray-100 flex flex-col px-16  ">
      <div className=" basis-[10%] flex justify-between border-b-2 items-center pl-4 ">
        <div className="  flex gap-2 items-center">
          <button onClick={() => navigate(`/EspaceLevel/synthetiseur`)}>
            <span className=" text-xl font-semibold">ARCHIVES</span>
            <span className=" text-xl font-medium"> LEVEL</span>
          </button>
          <button
            onClick={() => navigate(`/EspaceLevel/synthetiseurDetail/${id}`)}
          >
            <span className=" text-lg font-medium">
              {" "}
              {"> Détails formation "}
            </span>
          </button>
          <span className=" text-lg font-medium">
            {" "}
            {"> Formulaires client"}
          </span>
        </div>
        <div>
          {/* <button
              type="button"
              className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
            >
              <img src={edit} className="h-6" />
            </button> */}
        </div>
      </div>
      <div className=" basis-[40%]  flex  justify-start items-start   overflow-auto py-8 ">
        <div className=" bg-white h-full w-full rounded-2xl p-8 flex flex-col gap-3">
          <div className="flex w-[95%] gap-1 border-b-2 pb-4">
            <span className="text-lg font-bold"> Nom et prénom du client/</span>
            <span className="text-lg font-semiBold Time">
              {""}
              {`${client?.nom || ""} ${client?.prenom || ""}`}
            </span>
          </div>
          <div className="w-[95%]  gap-10 flex  pb-4">
            <div className="flex gap-6">
              {" "}
              <div className="flex gap-1">
                <span className="text-lg font-bold"> Email du client/</span>
                <span className="text-lg font-semiBold Time">
                  {" "}
                  {client?.email}
                </span>
              </div>
            </div>
          </div>
          <div className="flex gap-1">
              <span className="text-lg font-bold">Adresse du client /</span>
              <span className="text-lg font-semiBold Time">
                {(client?.adress !== undefined  && client?.adress ? client?.adress + ", " : "") +
                  (client?.ville !== undefined && client?.ville ? client?.ville + ", " : "") +
                  (client?.codePostale !== undefined && client?.codePostale ? client?.codePostale : "")
                }
              </span>
            </div>
        </div>
      </div>
      <div className=" w-[20%] gap-2 overflow-auto">{formulaires()}</div>
      {/* <div className=" w-[20%] gap-2 overflow-auto">{formulaires()}</div> */}
      {/* <div className="basis-[85%] grid grid-cols-4 gap-4 pl-2  justify-start  overflow-auto py-8">
        <FeuillePresenceCard
          date={currentForm?.createdAt}
          id={currentForm?.id}
        />
      </div> */}
    </div>
  );
}
