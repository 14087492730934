import intercepterAxios from "../../configAxios";

const createCalDavEvent = async (userData) => {
  try {
    const response = await intercepterAxios.post(`/caldav/events`, userData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const createIcsEvents = async (data) => {
  try {
    const response = await intercepterAxios.post(`/evenemets/importIcs`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getAllEventWithCaldav = async (id) => {
  try {
    const response = await intercepterAxios.get(
      `/caldav/events?start=2002-01-01&end=2050-01-01`
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getOneEvent = async (id) => {
  try {
    const response = await intercepterAxios.get(
      `/evenemets/getOneEvenement/${id}`
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const editEvent = async (data) => {
  try {
    const response = await intercepterAxios.put(
      `/evenemets/editEvenement`,
      data
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const editCaldavEvent = async (data) => {
  try {
    const response = await intercepterAxios.put(`/caldav/events`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};
const editRelatedEvents = async (data) => {
  try {
    const response = await intercepterAxios.put(
      `/evenemets/editRelatedEvenement`,
      data
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const deleteEvent = async (id) => {
  try {
    const response = await intercepterAxios.delete(
      `/evenemets/deleteEvenement/${id}`
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const deleteCaldavEvent = async (data) => {
  try {
    const response = await intercepterAxios.put(`/caldav/delete-events/`, data);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const deleteRelatedEvents = async (id) => {
  try {
    const response = await intercepterAxios.delete(
      `/evenemets/deleteRelatedEvenement/${id}`
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const eventService = {
  createIcsEvents,
  getOneEvent,
  editEvent,
  editRelatedEvents,
  deleteEvent,
  deleteRelatedEvents,
  createCalDavEvent,
  getAllEventWithCaldav,
  editCaldavEvent,
  deleteCaldavEvent,
};

export default eventService;
