import React from "react";
import eyes from "../../../../../images/eyes.svg";
import "./index.css";

export default function UserFormulaireCardSkeleton() {

  return (
    <div className="user-form-skeleton card  rounded-lg  custom-b-sh transition-all  hover:bg-gray-200 h-60 flex flex-col gap-2 bg-white py-10 w-full  px-12 cursor-pointer ">
      <div className="flex items-center justify-center gap-2">
        <button
          type="button"
          className="details button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2 w-14 h-10"
        >
        
        </button>
      </div>
      <div className="details card-body flex flex-col justify-center items-center border-b h-[48%]">
        <h1 className="card-title font-black">Formation AI</h1>
        <p className="font-thin">Crée le 7-10-2023</p>
        <div className="py-1 px-1 border-t border-gray-100"></div>
      </div>
      <div className="flex justify-center items-center flex-1 ">
        <ul>
          <li className="flex justify-center items-center details">
            <div className="flex items-center text-base font-normal">
              <span className="flex-1 ml-3 whitespace-nowrap ">
                7 questions
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
