import intercepterAxios from "../../configAxios";

const getAllFormationsSynthetiseur = async (id) => {
  try {
    const response = await intercepterAxios.get(
      `/synthese/allformationSynthese${id}`
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};


const addfeuille = async (form) => {
  try {
    const response = await intercepterAxios.post(
      `/feuille/add`,form
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
}

const synthetiseurService = {
  getAllFormationsSynthetiseur,addfeuille
};

export default synthetiseurService;
