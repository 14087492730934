import React, { useEffect } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import DetailCate from "./layout/espaceLevel/formulaireDeatils/DetailCate";
import DetailCatePartaged from "./layout/espaceLevel/formulaireDeatils/DetailCatePartaged";
import DetailFormation from "./layout/espaceLevel/formationsDetails/DetailFormation";
import DetailPartaged from "./layout/espaceLevel/formationsDetails/DetailPartaged";
import AdministratifDetail from "./layout/espaceLevel/formationsDetails/AdministratifDetail";
import ClientDetails from "./layout/espaceLevel/formationsDetails/ClientDetails";
import AnimateurDetails from "./layout/espaceLevel/formationsDetails/AnimateurDetails";
import WorkflowDetails from "./layout/espaceLevel/formationsDetails/WorkflowsDetails";
import StagiairesDetails from "./layout/espaceLevel/formationsDetails/StagiairesDetails";
import ListeFormulaires from "./layout/espaceLevel/formulaireDeatils/ListeFormulaires";
import Listeformations from "./layout/espaceLevel/formationsDetails/ListeFormation";
import NestedCategoriesFormulaires from "./layout/espaceLevel/formulaireDeatils/NestedCategoriesFormulaires";
import NestedFormulaire from "./layout/espaceLevel/formulaireDeatils/NestedFormulaire";
import NestedFormationList from "./layout/espaceLevel/formationsDetails/detailsFormationCards.js/NestedFormationList";
import NestedCategorieFormation from "./layout/espaceLevel/formationsDetails/detailsFormationCards.js/NestedCategorieFormation";
import DetailCateFormation from "./layout/espaceLevel/formationsDetails/DetailCateFormation";
import PartagedWithMe from "./layout/espaceLevel/formationsDetails/PartagedWithMe";
import QuestionsResultsRF from "./layout/vueStagiaires/QuestionResultRF";
import QuestionsResults from "./layout/vueStagiaires/QuestionsResults";

import ResumeStagiaires from "./layout/vueStagiaires/ResumeStagiaires";
import Synthetiseur from "./layout/espaceLevel/synthetiseurDetails/Synthetiseur";
import Fullcalendar from "./layout/espaceLevel/calendrierDetails/Fullcalendar";
import NextCloudCalendar from "./layout/espaceLevel/NextCloudCalendar/NCFullcalendar";
import TabCommuns from "./layout/espaceLevel/synthetiseurDetails/TabCommuns";
import TabCategories from "./layout/espaceLevel/synthetiseurDetails/TabCategories";
import Evaluation from "./pages/Evaluation";
import Organisation from "./layout/Evaluation/Organisation";
import DetailFormationSynthetiseur from "./layout/espaceLevel/synthetiseurDetails/DetailFormationSynthetiseur";
import AdminstratifSynthetiseurDetail from "./layout/espaceLevel/synthetiseurDetails/AdminstratifSynthetiseurDetail";
import AnimateurDetailSynthetiseur from "./layout/espaceLevel/synthetiseurDetails/AnimateurDetailSynthetiseur";
import ClientDetailSynthetiseur from "./layout/espaceLevel/synthetiseurDetails/ClientDetailSynthetiseur";
import StagiairesDetailsSynthetiseur from "./layout/espaceLevel/synthetiseurDetails/StagiairesDetailsSynthetiseur";
import WorkflowDetailsSynthetiseur from "./layout/espaceLevel/synthetiseurDetails/WorkflowDetailsSynthetiseur";
import DetailCateSynthetiseur from "./layout/espaceLevel/synthetiseurDetails/DetailCateSynthetiseur";
import ResultatsFormations from "./pages/ResultatsFormations";
import ResultatsFormationResume from "./pages/ResultatsFormationResume";
import ResultatsFormationCurrent from "./pages/ResultatsFormationCurrent";
import Credits from "./pages/Credits";
import StripeContainer from "./layout/stripe/StripeContainer";
import UpdateProfile from "./pages/UpdateProfile";
import UpdateCredtis from "./pages/UpdateCredtis";
import Update from "./layout/Update/Update";
import FormationDetailStagiaire from "./layout/espaceLevel/formationsDetails/FormationDetailStagiaire";
import FeuillePresenceDetail from "./layout/espaceLevel/synthetiseurDetails/FeuillePresenceDetail";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { fr } from "date-fns/locale";
import socket from "./socket";
import UserFormulaireResult from "./layout/espaceLevel/formationsDetails/pages/UserFormulaireResult";
import ConnexionCheck from "./layout/vueStagiaires/components/ConnexionCheck";
import StagiaireFormulaireResult from "./layout/espaceLevel/formationsDetails/pages/StagiaireFormulaireResult/StagiaireFormulaireResult";

const Inscript = React.lazy(() => import("./pages/Inscription"));
const RestrouvezVotreComte = React.lazy(() =>
  import("./pages/RestrouvezVotreComte")
);
const Reset = React.lazy(() => import("./pages/Reset"));
const Login = React.lazy(() => import("./pages/Login"));
const Welcome = React.lazy(() => import("./pages/Welcome"));
const CreerFormulaire = React.lazy(() => import("./pages/CreerFormulaire"));
const Theme = React.lazy(() => import("./pages/Theme"));
const PartageFormulaire = React.lazy(() => import("./pages/PartageFormulaire"));
const ResetPassword = React.lazy(() => import("./pages/ResetPassword"));
const EspaceLevel = React.lazy(() => import("./pages/EspaceLevel"));
const FabriqueFormation = React.lazy(() => import("./pages/FabriqueFormation"));
const CreateQuestions = React.lazy(() => import("./pages/CreateQuestions"));

const FormateurDiffusion = React.lazy(() =>
  import("./pages/FormateurDiffusion")
);
const StagiaireWorkflow = React.lazy(() =>
  import("./layout/vueStagiaires/StagiaireWorkflow"));
const FormateurWorkflow = React.lazy(() =>
  import("./layout/vueFormateur/FormateurWorkflow")
);
const PrevisualisationQuestion = React.lazy(() =>
  import("./pages/PrevisualisationQuestion")
);
const FormationQuestion = React.lazy(() => import("./pages/FormationQuestion"));
const Session = React.lazy(() => import("./pages/Session"));
const SingateurFormateur = React.lazy(() =>
  import("./pages/SingateurFormateur")
);
const SingateurStagiare = React.lazy(() => import("./pages/SingateurStagiare"));
const Confirmation = React.lazy(() => import("./pages/Confirmation"));
const Verification = React.lazy(() => import("./pages/Verification"));
const LiveStagiaire = React.lazy(() => import("./pages/LiveStagiaire"));
const LiveFormulaireClient = React.lazy(() => import("./features/FormulaireEmail/formulaireClient/LiveFormulaireClient"));
const LiveFormulaireAnimateur = React.lazy(() => import("./features/FormulaireEmail/formulaireAnimateur/LiveFormulaireClient"));
const LiveFormulaireStagiaire = React.lazy(() => import("./features/FormulaireEmail/formulaireStagiaire/LiveFormulaireStagiaire"));
const ResultStagiaire = React.lazy(() => import("./pages/ResultStagiaire"));
 const ResultStagiaireRF = React.lazy(() => import("./pages/ResultatStagiaireRF"));
const SessionModal = React.lazy(() => import("./layout/Session/SessionModel"));
const LiveListeStagiaires = React.lazy(() =>
  import("./pages/LiveListeStagiaires")
);
const FormationRythmeFormateurResults = React.lazy(() =>
  import("./pages/FormationRythmeFormateurResults")
);
const LiveListeStagiairesRithmeFormateur = React.lazy(() =>
  import("./pages/LiveStagiaireRithmeFormateur")
);
const LiveWorkflow = React.lazy(() => import("./pages/LiveWorkflow"));
const WaitingStagiaire = React.lazy(() => import("./pages/WaitingStagiaire"));
const PendingWorkflowStagiaire = React.lazy(() => import("./pages/PendingWorkflowStagiaire"));
const LogoutStagiaire = React.lazy(() => import("./pages/LogoutStagiaire"));
const Stepper = React.lazy(() =>
  import("./layout/create Formation/StepperCustomer")
);

function App() {

  useEffect(() => {
    return () => {
      socket.close("tentative");
      socket.close("createTentative");
      socket.close("room");
      socket.close("join_room");
      socket.close("session");
        //socket.close("finalResult");
     };
  }, [socket]);

  return (
    <ConnexionCheck>
      <Routes>
        <Route
          path="/"
          element={
            <React.Suspense fallback="Loading...">
              <Login />
            </React.Suspense>
          }
          key="/"
        />
        <Route
          path="Inscription"
          element={
            <React.Suspense fallback="Loading...">
              {" "}
              <Inscript />
            </React.Suspense>
          }
          key="Inscription"
        />
        <Route
          path="Stepper"
          element={
            <React.Suspense fallback="Loading...">
              {" "}
              <Stepper />
            </React.Suspense>
          }
          key="Stepper"
        />
        <Route
          path="Reset"
          element={
            <React.Suspense fallback="Loading...">
              <Reset />
            </React.Suspense>
          }
          key="Reset"
        />
        <Route
          path="retrouvez"
          element={
            <React.Suspense fallback="Loading...">
              <RestrouvezVotreComte />{" "}
            </React.Suspense>
          }
          key="retrouvez"
        />
        <Route
          path="Welcome"
          element={
            <React.Suspense fallback="Loading...">
              <Welcome />{" "}
            </React.Suspense>
          }
          key="Welcome"
        />
        <Route
          path="CreerFormulaire"
          element={
            <React.Suspense fallback="Loading...">
              <CreerFormulaire />{" "}
            </React.Suspense>
          }
          key="CreerFormulaire"
        />
        <Route
          path="Theme"
          element={
            <React.Suspense fallback="Loading...">
              <Theme />{" "}
            </React.Suspense>
          }
          key="Theme"
        />
        <Route
          path="PartageFormulaire"
          element={
            <React.Suspense fallback="Loading...">
              <PartageFormulaire />{" "}
            </React.Suspense>
          }
          key="PartageFormulaire"
        />
        <Route
          path="resetPassword/:token"
          element={
            <React.Suspense fallback="Loading...">
              <ResetPassword />{" "}
            </React.Suspense>
          }
          key="resetPassword"
        />
        <Route
          path="EspaceLevel"
          element={
            <React.Suspense fallback="Loading...">
              <EspaceLevel />{" "}
            </React.Suspense>
          }
          key="EspaceLevel"
        >
          <Route index element={<ListeFormulaires />} key="listeFormulaires"/>
          <Route path="formulaires" element={<ListeFormulaires />} key="formulaires">
            <Route path="ListForm" element={<NestedFormulaire />} key="ListForm"/>
            <Route path="ListCate" element={<NestedCategoriesFormulaires />} key="ListCate"/>
            <Route index element={<NestedFormulaire />} key="nestedFormulaire"/>
          </Route>
          <Route path="formation" element={<Listeformations />} key="formation">
            <Route path="ListForm" element={<NestedFormationList />} key="ListForm"/>
            <Route path="ListCate" element={<NestedCategorieFormation />} key="ListCate"/>
            <Route index element={<NestedFormationList />} key="nestedFormationList"/>
          </Route>
          <Route path="synthetiseur" element={<Synthetiseur />} key="synthetiseur">
            <Route path="ListForm" element={<TabCommuns />} key="ListForm"/>
            <Route path="ListCate" element={<TabCategories />} key="ListCate"/>
            <Route index element={<TabCommuns />} key="tabCommuns"/>
          </Route>
          <Route path="calendrier" element={<Fullcalendar />} key="calendrier"/>
          <Route path="nc-calendar" element={<NextCloudCalendar />} key="nc-calendar"/>
          <Route
            path="formulairePartagedCate"
            element={<DetailCatePartaged />}
            key="formulairePartagedCate"
          />
          <Route path="formulaireCate/:id" element={<DetailCate />} key="formulaireCate"/>
          <Route path="formationAdmin/:id" element={<AdministratifDetail />} key="formationAdmin"/>
          <Route
            path="formationClient/:id"
            element={<ClientDetails />}
            key="formationClient"
          />
          <Route
            path="formationAnimateur/:id"
            element={<AnimateurDetails />}
            key="formationAnimateur"
          />
          <Route path="formationWorkflow/:id" element={<WorkflowDetails />} key="listFormulaires"/>
          <Route
            path="formationStagiaires/:id"
            element={<StagiairesDetails />}
            key="formationStagiaires"
          />
          <Route
            path="formationCateFormation/:id"
            element={<DetailCateFormation />}
            key="formationCateFormation"
          />
          <Route
            path="formationCatepartaged/:id"
            element={<PartagedWithMe />}
            key="formationCatepartaged"
          />
          <Route
            path="formationstagiaireDetails/:id"
            element={<FormationDetailStagiaire />}
            key="formationstagiaireDetails"
          />

          <Route path="formationDetail/:id" element={<DetailFormation />} key="formationDetail"/>
          <Route path="formationPartaged/:id" element={<DetailPartaged />} key="formationPartaged"/>
          <Route
            path="synthetiseurDetail/:id"
            element={<DetailFormationSynthetiseur />}
            key="synthetiseurDetail"
          />
          <Route
            path="synthetiseurAdmin/:id"
            element={<AdminstratifSynthetiseurDetail />}
            key="synthetiseurAdmin"
          />
          <Route
            path="feuillPresence/:id"
            element={<FeuillePresenceDetail />}
            key="feuillPresence"
          />
          <Route
            path="synthetiseurAnimateur/:id"
            element={<AnimateurDetailSynthetiseur />}
            key="synthetiseurAnimateur"
          />
          <Route
            path="synthetiseurClient/:id"
            element={<ClientDetailSynthetiseur />}
            key="synthetiseurClient"
          />
          <Route
            path="synthetiseurStagiaires/:id"
            element={<StagiairesDetailsSynthetiseur />}
            key="synthetiseurStagiaires"
          />
          <Route
            path="synthetiseurWorkflow/:id"
            element={<WorkflowDetailsSynthetiseur />}
            key="synthetiseurWorkflow"
          />
          <Route
            path="synthetiseurCateFormation/:id"
            element={<DetailCateSynthetiseur />}
            key="synthetiseurCateFormation"
          />
        </Route>
        <Route
          path="evaluation"
          element={
            <React.Suspense fallback="Loading...">
              <Evaluation />{" "}
            </React.Suspense>
          }
          key="evaluation"
        >
          <Route path="organisation" element={<Organisation />} key="listFormulaires"/>
        </Route>
        <Route
          path="FabriqueFormation"
          element={
            <React.Suspense fallback="Loading...">
              <FabriqueFormation />{" "}
            </React.Suspense>
          }
          key="FabriqueFormation"
        />
        <Route
          path="formateur/diffusion"
          element={
            <React.Suspense fallback="Loading...">
              <FormateurDiffusion />{" "}
            </React.Suspense>
          }
          key="formateur"
        />
        <Route
          path="FormationRythmeFormateurResults"
          element={
            <React.Suspense fallback="Loading...">
              <FormationRythmeFormateurResults />
            </React.Suspense>
          }
          key="FormationRythmeFormateurResults"
        />
        <Route
          path="CreateQuestions/:slug"
          element={
            <React.Suspense fallback="Loading...">
              <CreateQuestions />{" "}
            </React.Suspense>
          }
          key="CreateQuestions"
        />
        <Route
          path="PrevisualisationQuestion/:slug"
          element={
            <React.Suspense fallback="Loading...">
              <PrevisualisationQuestion />{" "}
            </React.Suspense>
          }
          key="PrevisualisationQuestion"
        />
        <Route
          path="FormationQuestion"
          element={
            <React.Suspense fallback="Loading...">
              <FormationQuestion />{" "}
            </React.Suspense>
          }
          key="FormationQuestion"
        />
        <Route
          path="session/:slug"
          element={
            <React.Suspense fallback="Loading...">
              <Session />{" "}
            </React.Suspense>
          }
          key="session"
        />
        <Route
          path="signature"
          element={
            <React.Suspense fallback="Loading...">
              <SingateurFormateur />{" "}
            </React.Suspense>
          }
          key="signature"
        />
        <Route
          path="sinagteurStagaire"
          element={
            <React.Suspense fallback="Loading...">
              <SingateurStagiare />{" "}
            </React.Suspense>
          }
          key="sinagteurStagaire"
        />
        <Route
          path="Confirmation/:slug"
          element={
            <React.Suspense fallback="Loading...">
              <Confirmation />{" "}
            </React.Suspense>
          }
          key="Confirmation"
        />
        <Route
          path="Verification"
          element={
            <React.Suspense fallback="Loading...">
              <Verification />{" "}
            </React.Suspense>
          }
          key="Verification"
        />
        <Route
          path="stagiaires"
          element={
            <React.Suspense fallback="Loading...">
              <LiveStagiaire />{" "}
            </React.Suspense>
          }
          key="stagiaires"
        />
        {/* Answer formulaire from email (animateur - client - stagiaire) */}
        <Route
          path="formulaire-client/:idFormation/:idForm"
          element={
            <React.Suspense fallback="Loading...">
              <LiveFormulaireClient />{" "}
            </React.Suspense>
          }
          key="formulaireClient"
        />
        <Route
          path="formulaire-animateur/:idFormation/:idForm"
          element={
            <React.Suspense fallback="Loading...">
              <LiveFormulaireAnimateur />{" "}
            </React.Suspense>
          }
          key="LiveFormulaireAnimateur"
        />
        <Route
          path="formulaire-stagiaire/:idFormation/:idForm/:idStagiaire"
          element={
            <React.Suspense fallback="Loading...">
              <LiveFormulaireStagiaire />{" "}
            </React.Suspense>
          }
          key="LiveFormulaireStagiaire"
        />
        <Route
          path="sessionModal/:id"
          element={
            <React.Suspense fallback="Loading...">
              <SessionModal />{" "}
            </React.Suspense>
          }
          key="sessionModal"
        />
        <Route
          path="livestagiaire"
          element={
            <React.Suspense fallback="Loading...">
              <LiveListeStagiaires />{" "}
            </React.Suspense>
          }
          key="livestagiaire"
        />
        <Route
          path="livestagiaireRythmeFormateur"
          element={
            <React.Suspense fallback="Loading...">
              <LiveListeStagiairesRithmeFormateur />{" "}
            </React.Suspense>
          }
          key="livestagiaireRythmeFormateur"
        />
        <Route
          path="liveWorkflow"
          element={
            <React.Suspense fallback="Loading...">
              <LiveWorkflow />{" "}
            </React.Suspense>
          }
          key="liveWorkflow"
        />
        <Route
          path="stagiaireWorkflow"
          element={
            <React.Suspense fallback="Loading...">
              <StagiaireWorkflow />{" "}
            </React.Suspense>
          }
          key="stagiaireWorkflow"
        />
        <Route
          path="formateurWorkflow"
          element={
            <React.Suspense fallback="Loading...">
              <FormateurWorkflow />{" "}
            </React.Suspense>
          }
          key="formateurWorkflow"
        />
        <Route
          path="waiting"
          element={
            <React.Suspense fallback="Loading...">
              <WaitingStagiaire />{" "}
            </React.Suspense>
          }
          key="waiting"
        />
        <Route
          path="pause-formation"
          element={
            <React.Suspense fallback="Loading...">
              <PendingWorkflowStagiaire />{" "}
            </React.Suspense>
          }
          key="pause-formation"
        />
        <Route
          path="logout"
          element={
            <React.Suspense fallback="Loading...">
              <LogoutStagiaire />{" "}
            </React.Suspense>
          }
          key="logout"
        />
        <Route
          path="ResultatsFormations"
          element={
            <React.Suspense fallback="Loading...">
              <ResultatsFormations />{" "}
            </React.Suspense>
          }
          key="ResultatsFormations"
        />
        <Route
          path="resultatsFormationResume/:id"
          element={
            <React.Suspense fallback="Loading...">
              <ResultatsFormationResume />{" "}
            </React.Suspense>
          }
          key="resultatsFormationResume"
        />
        <Route
          path="resultatsFormationCurrent/:id"
          element={
            <React.Suspense fallback="Loading...">
              <ResultatsFormationCurrent />{" "}
            </React.Suspense>
          }
          key="resultatsFormationCurrent"
        />

        <Route
          path="formulaire-animateur-result/:formationId/:formulaireId/:userId"
          element={
            <React.Suspense fallback="Loading...">
              <UserFormulaireResult />{" "}
            </React.Suspense>
          }
          key="formulaire-animateur-result"
        />
        <Route
          path="formulaire-stagiaire-result/:formationId/:formulaireId"
          element={
            <React.Suspense fallback="Loading...">
              <StagiaireFormulaireResult />{" "}
            </React.Suspense>
          }
          key="formulaire-stagiaire-result"
        />
        <Route
          path="formulaire-client-result/:formationId/:formulaireId/:userId"
          element={
            <React.Suspense fallback="Loading...">
              <UserFormulaireResult />{" "}
            </React.Suspense>
          }
          key="formulaire-client-result"
        />
        <Route
          path="credits"
          element={
            <React.Suspense fallback="Loading...">
              <Credits />{" "}
            </React.Suspense>
          }
          key="credits"
        />
        <Route
          path="update"
          element={
            <React.Suspense fallback="Loading...">
              <Update />{" "}
            </React.Suspense>
          }
          key="update"
        >
          <Route path="Profile" element={<UpdateProfile />} key="Profile"/>
          <Route path="Updatecredits" element={<UpdateCredtis />} key="Updatecredits"/>
          <Route index element={<UpdateProfile />} key="updateProfile"/>
        </Route>
        <Route
          path="payement"
          element={
            <React.Suspense fallback="Loading...">
              <StripeContainer />{" "}
            </React.Suspense>
          }
          key="payement"
        />
        <Route
          path="/ResultStagiaire"
          element={
            <React.Suspense fallback="Loading...">
              <ResultStagiaire />{" "}
            </React.Suspense>
          }
          key="ResultStagiaire"
        >
          <Route path="Question" element={<QuestionsResults />} key="Question"/>
          <Route path="Resume" element={<ResumeStagiaires />} key="resume"/>
          <Route index element={<QuestionsResults />} key="questionsResults"/>
        </Route>
        <Route
          path="ResultStagiaireRF"
          element={
            <React.Suspense fallback="Loading...">
              <ResultStagiaireRF />{" "}
            </React.Suspense>
          }
          key="resultStagiaireRF"
        >
          <Route path="Question" element={<QuestionsResultsRF />} key="question"/>
          <Route path="Resume" element={<ResumeStagiaires />} key="resume"/>
          <Route index element={<QuestionsResultsRF />} key="questionsResultsRF"/>
        </Route>
      </Routes>
    </ConnexionCheck>
  );
}

export default App;
