import React, { useEffect, useState } from "react";
import eyes from "../images/eyes.svg";
import edit from "../images/edit.svg";
import share from "../images/share.svg";
import play from "../images/play.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  activateFormation,
  dispatchSelectFormation,
  setLiveFormation,
  setPartageFormation,
} from "../features/formation/formationSlice";
import { setImpayer, setRoom, setRoomState } from "../features/auth/authSlice";
import { setLiveFormulaire } from "../features/formulaires/formulaireSlice";
export default function CartFormation({ name, date, number, id, toPartage }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const { selectedFormations, allFormations } = useSelector(
    (state) => state.formation
  );
  const { user } = useSelector((state) => state.auth);

  const { liveFormulaire } = useSelector((state) => state.formulaires);
  const handleCheck = (e) => {
    setCheck(!check);
    let arr = [...selectedFormations];
    if (e.target.checked) {
      let obj = [...allFormations].filter((el) => el.id == id)[0];
      arr.push(obj);
      dispatch(dispatchSelectFormation(arr));
    } else {
      e.target.checked = true;
      arr = arr.filter((el) => el.id !== id);
      dispatch(dispatchSelectFormation(arr));
    }
  };

  const shareButton = () => {
    dispatch(
      setPartageFormation({
        show: true,
        id: id,
      })
    );
  };

  const sesionButton = () => {
    if(user.point > 0){

      let form = [...allFormations].filter((el) => el.id == id)[0];
      let session = form.slug?.split("_")[0] + form.slug.split("_")[2];
      let data = {
        active: 1,
        slug: form.slug,
        organismeId: form.organismeId,
      };
      let etapes = JSON.parse(form.etapes);
      dispatch(setLiveFormulaire(etapes[0]));
      let roomState = {
        state: false,
        currentForm: liveFormulaire,
      };
      dispatch(activateFormation(data));
      dispatch(setRoom(session));
      dispatch(setLiveFormation(form));
      dispatch(setRoomState(roomState));
      navigate(`/session/${id}`);
    }else{
      dispatch(setImpayer(true))
    }
  };

  useEffect(() => {
    setCheck(false);
  }, [allFormations]);

  return (
    <div className="card  rounded-lg custom-b-sh transition-all   hover:bg-gray-200 h-60 flex flex-col gap-2 bg-white py-2">
      <div className="flex items-center justify-end pr-2 ">
        <input
          checked={check}
          id="link-checkbox"
          type="checkbox"
          value=""
          onChange={handleCheck}
          className="w-4 h-4 accent-green-700 text-green-600 bg-green-700 rounded border-gray-300 focus:ring-green-700 "
        ></input>
      </div>
      <div className="flex items-center justify-center px-9">
        <button
          type="button"
          className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
          onClick={() => {
            if (toPartage) {
              navigate(`/EspaceLevel/formationPartaged/${id}`);
            } else {
              navigate(`/EspaceLevel/formationDetail/${id}`);
            }
          }}
        >
          <img src={eyes} />
        </button>
        <button
          type="button"
          className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
        >
          <img src={share} onClick={shareButton} />
        </button>
        <button
          type="button"
          className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
        >
          <img src={play} onClick={sesionButton} />
        </button>
      </div>
      <div className="card-body flex flex-col justify-center items-center border-b-2 h-[48%]">
        <h1 className="card-title font-black">{"standard"}</h1>
        <h1 className="card-title font-black">{name}</h1>
        <p className="font-thin">
          Formation prévue le {moment(date).format("DD-MM-YYYY")}.
        </p>
        <div className="py-1 px-1 border-t border-gray-100"></div>
      </div>
      <div className="flex justify-center items-center flex-1 ">
        <ul>
          <li className="flex justify-center items-center">
            <div
             
              className="flex items-center text-base font-normal text-black"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 38 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.6044 2H3.41758C2.63467 2 2 2.64919 2 3.45V29.55C2 30.3508 2.63467 31 3.41758 31H34.6044C35.3873 31 36.0219 30.3508 36.0219 29.55V3.45C36.0219 2.64919 35.3873 2 34.6044 2Z"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 10.7H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 16.5H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 22.3H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="flex-1 ml-3 whitespace-nowrap">
                {number} formulaires
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
