import React, { useState, useEffect } from "react";
import eyes from "../../../../images/eyes.svg";

import GraduationCap from "../../../../images/GraduationCap.svg";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

export default function ResultCards({ date, id }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentForm, setCurrentForm] = useState({});
  const { allFormations } = useSelector((state) => state.formation);

  useEffect(() => {
    let arr = [...allFormations].filter((el) => {
      return el.id == id;
    });
    setCurrentForm(arr[0]);
  }, [allFormations, id]);

  let now = new Date(date);
  const frenchDate = now.toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
    localeMatcher: "best fit",
  });

  return (
    <div className="card  rounded-lg  custom-b-sh transition-all  hover:bg-gray-200  flex flex-col gap-2 bg-white py-5 px-9">
      <div className="flex items-center justify-center">
        <button
          type="button"
          className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
          onClick={() =>
            navigate(`/EspaceLevel/formationstagiaireDetails/${id}`)
          }
        >
          <img src={eyes} />
        </button>
      </div>
      <div className="card-body flex flex-col gap-2 justify-center items-center border-b h-[55%]">
        <img src={GraduationCap} className="h-7" />
        <div className="flex flex-col justify-center items-center ">
          <span className="font ">RESULTATS</span>
          <span className="font">STAGIAIRES</span>
        </div>
      </div>
      <div className="flex justify-center items-center flex-1 ">
        <ul>
          <li className="flex justify-center items-center">
            <div className="flex items-center text-base font-normal text-black">
              <span className="flex-1 ml-3 whitespace-nowrap">
                Modifié le {frenchDate}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
