import React, { useEffect, useState } from "react";
// import Partager from "../../components/Partager";
import Line from "../../../images/Line.svg";
import Filtre from "../../../images/Filtre.svg";
import Partager from "../../../images/Partager.svg";
import Modal from "../../../components/Modal";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  dispShowModelForm,
  getAllFormulaires,
  setMultiplePartage,
} from "../../../features/formulaires/formulaireSlice";
import { useSelector, useDispatch } from "react-redux";
import Empty from "../Empty";
import DuplicateDelete from "./DuplicateDelete";
import { setModelCate } from "../../../features/categorieFormulaires/categorieFormulaireSlice";
import ModalDelete from "../../../components/ModalDelete";

export default function ListeFormulaires() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const location = useLocation();
  let form = location.pathname.includes("ListCate") ? "butop" : "but";
  let catégories = location.pathname.includes("ListCate") ? "but" : "butop";
  const { formulaires } = useSelector((state) => state.formulaires);
  const { showModelCate } = useSelector((state) => state.categorieFormulaire);
  const { user } = useSelector((state) => state.auth);

  const onclickCatégories = () => {
    navigate("/EspaceLevel/formulaires/ListCate");
  };

  const onclickFormulaires = () => {
    navigate("/EspaceLevel/formulaires/ListForm");
  };
  const handleClick = () => {
    dispatch(dispShowModelForm(true));
  };
  useEffect(() => {
    dispatch(getAllFormulaires(user?._id));
  }, []);


  return (
    <>
      {formulaires.length === 0 ? (
        <Empty
          nouveau="nouveaux"
          majType={"FORMULAIRE"}
          setShowModal={handleClick}
          type="formulaires"
          one="UN"
        />
      ) : (
        <div className="flex-1 bg-gray-100 flex flex-col px-16 max-h-screen ">
          <div className=" border-b-2 basis-[15%] px-3 flex items-center justify-center py-3">
            <div className=" basis-[40%] flex gap-2">
              <span className=" text-lg font-semibold">FORMULAIRES</span>
              <span className=" text-lg font-medium">LEVEL</span>
            </div>
            <div className=" basis-[60%] flex justify-end gap-7">
              <div className=" w-3/6 ">
                <button
                  type="submit"
                  className={`group relative w-full flex justify-center py-3 px-4 border  border-lime-600  text-sm font-medium    hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-10  items-center transition-all duration-300	`}
                  onClick={() =>
                    dispatch(
                      setModelCate({ showModelCate: true, toEdit: false })
                    )
                  }
                  id="button"
                >
                  {"AJOUTER  UNE CATÉGORIE"}
                </button>
                {showModelCate && (
                  <Modal
                    showModal={showModelCate}
                    setShowModal={setModelCate}
                  />
                )}
                {showModal && (
                  <ModalDelete
                    showModal={showModal}
                    setShowModal={setShowModal}
                  />
                )}
              </div>
              {/* <Partager/> */}
              <img
                src={Partager}
                alt="Level"
                className="h-9 "
                onClick={() => {
                  dispatch(setMultiplePartage(true));
                }}
              />
              <img src={Line} alt="Level" className="h-10 " />
              <img
                src={Filtre}
                alt="Level"
                className="h-10 cursor-pointer "
                onClick={() => setShow(!show)}
              />
              <DuplicateDelete show={show} setShow={setShowModal} />
            </div>
          </div>
          <div className="  basis-[15%] py-3 flex justify-center items-center ">
            <div className={`w-[49%] h-16 rounded-l-lg ${form} flex`}>
              <button
                className="flex-1 rounded-r-lg text-white text-xl"
                onClick={onclickFormulaires}
              >
                Formulaires communs
              </button>
            </div>
            <div
              className={`w-[49%] h-16 rounded-r-lg ${catégories} flex`}
              onClick={onclickCatégories}
            >
              <button className="flex-1 text-white text-xl">
                Formulaires par catégories
              </button>
            </div>
          </div>
          <div className=" flex flex-wrap  justify-start gap-5 overflow-auto py-8 px-3">
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
}
