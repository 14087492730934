import React, { useState, useEffect } from "react";
//  import Partager from "../../../../";
import eyes from "../../../../images/eyes.svg";
import edit from "../../../../images/edit.svg";
import FolderSimpleUser from "../../../../images/FolderSimpleUser.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchEditable,
  stagiaireListe,
} from "../../../../features/formation/formationSlice";
export default function AdministratifCard({ date, id, toPartage }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentForm, setCurrentForm] = useState({});
  const { allFormations } = useSelector((state) => state.formation);
  const editButton = () => {
    dispatch(
      dispatchEditable({
        edit: true,
        step: 1,
        formation: currentForm,
      })
    );
    dispatch(stagiaireListe(currentForm.stagiaires));
    navigate("/FabriqueFormation");
  };

  useEffect(() => {
    let arr = [...allFormations].filter((el) => {
      return el.id == id;
    });
    setCurrentForm(arr[0]);
  }, [allFormations, id]);
  let now = new Date(date);
  const frenchDate = now.toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
    localeMatcher: "best fit",
  });

  return (
    <div className="card  rounded-lg custom-b-sh transition-all   hover:bg-gray-200  flex flex-col gap-2 bg-white py-10 px-9">
      <div className="flex items-center justify-center">
        <button
          type="button"
          className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
          onClick={() => navigate(`/EspaceLevel/formationAdmin/${id}`)}
        >
          <img src={eyes} />
        </button>

        {!toPartage ? (
          <button
            type="button"
            className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
            onClick={editButton}
          >
            <img src={edit} />
          </button>
        ) : null}
      </div>
      <div className="card-body flex flex-col gap-4 justify-center items-center border-b h-[55%]">
        <img src={FolderSimpleUser} className="h-10" />
        <span className="font">ADMINISTRATIF</span>
      </div>
      <div className="flex justify-center items-center flex-1 ">
        <ul>
          <li className="flex justify-center items-center">
            <div className="flex items-center text-base font-normal text-black">
              <span className="flex-1 ml-3 whitespace-nowrap">
                Modifié le {frenchDate}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
