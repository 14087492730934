import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Pack from "../components/pack";
import DetailsCredits from "../layout/credits/DetailsCredits";
import NavbarEspaceLevel from "../layout/espaceLevel/NavbarEspaceLevel";
import LineCredit from "../images/LineCredit.svg";
import plusCredit from "../images/plusCredit.svg";
import checkb from "../images/checkB.svg";
import checkA from "../images/checkA.svg";
import checkOR from "../images/checkOR.svg";
import { useDispatch, useSelector } from "react-redux";
import { dispatchPack } from "../features/payement/payementSlice";
import { ToastContainer } from "react-toastify";
import PaymentAdminModel from "../components/PaymentAdminModel";

export default function Credits() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const { adminpay } = useSelector((state) => state.auth);
  
  const [selected, setSelected] = React.useState(0);
  const [levels, setlevels] = React.useState("10 euros = 100 LEVELS");

  const handlePayement = () => {
    if (selected === 1) {
      dispatch(dispatchPack({ montant: 50, icon: "b" }));
    }
    if (selected === 2) {
      dispatch(dispatchPack({ montant: 250, icon: "a" }));
    }
    if (selected === 3) {
      dispatch(dispatchPack({ montant: 500, icon: "or" }));
    }
    navigate("/payement");
  };
  
  const handleClickOutside = (event) => {
    if (!event.target.closest(".Pack")) {
      setSelected(0);
    }
  };

  useEffect(() => {
    if (selected !== 0) {
      document.addEventListener("click", handleClickOutside);
    }
  }, [selected]);

  return (
    <div className="flex flex-col  bg-gray-100">
      <ToastContainer autoClose={2000} />
      <NavbarEspaceLevel />
      {adminpay && <PaymentAdminModel/>}
      <div className="py-5">
        <DetailsCredits />
        <div className="flex justify-center items-center">
          <img src={LineCredit} />
        </div>
        <div className="Pack" onClick={handleClickOutside}>
          <div className="flex flex-col">
            <div className="flex justify-center">
              <div className="flex justify-evenly w-[100%]">
                <Pack
                  setSelected={setSelected}
                  selected={selected}
                  number={1}
                  title={"PACK BRONZE"}
                  levels={"50 euros = 400 LEVELS"}
                  setlevels={setlevels}
                  icon={checkb}
                />
                <Pack
                  setSelected={setSelected}
                  selected={selected}
                  number={2}
                  title={"PACK ARGENT"}
                  levels={"250 euros = 2500 LEVELS"}
                  setlevels={setlevels}
                  icon={checkA}
                />
                <Pack
                  setSelected={setSelected}
                  selected={selected}
                  number={3}
                  title={"PACK OR"}
                  levels={"500 euros = 6000 LEVELS"}
                  setlevels={setlevels}
                  icon={checkOR}
                />
              </div>
            </div>
            <div className="flex justify-end mt-11 ">
            <button
              type="submit"
              className={
                selected === 0
                  ? `relative w-[20%] flex justify-center items-center border border-transparent text-md font-medium  text-white button-gris rounded-full h-9`
                  : `relative w-[20%] flex justify-center items-center border border-transparent text-md font-medium  text-white button-color rounded-full h-9`
              }
              onClick={() => handlePayement()}
            >
              Passer au paiement
            </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
