import React, { useEffect, useState } from "react";
import NavbarEspaceLevel from "../layout/espaceLevel/NavbarEspaceLevel";
import { useParams, useNavigate } from "react-router-dom";

import edit from "../images/edit.svg";
import CardResultats from "../components/CardResultats/CardResultats";

export default function ResultatsFormations() {
  const [resultat, setResultat] = useState(false);

  const navigate = useNavigate();
  let { id } = useParams();

  return (
    <div className="flex flex-col bg-gray-100">
      <NavbarEspaceLevel />
      <div className="m-8 ">
        <div className=" basis-[10%] flex justify-between border-b-2 items-center ">
          <div className="  flex gap-2 items-center">
            <button onClick={() => navigate(`/EspaceLevel/synthetiseur`)}>
              <span className=" text-xl font-semibold">ARCHIVES</span>
            </button>
            <button onClick={() => navigate(`/EspaceLevel/synthetiseur`)}>
              <span className=" text-lg font-medium">
                {" "}
                {"> Détails formation "}
              </span>
            </button>
            <span className=" text-lg font-medium">
              {" "}
              {"> TITRE FORMATION "}
            </span>
          </div>
          <div>
            <button
              type="button"
              className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
            >
              <img src={edit} className="h-6" />
            </button>
          </div>
        </div>
        <div className=" basis-[40%]  flex  justify-start items-start   overflow-auto py-8">
          <div className=" bg-white h-full w-full rounded-2xl p-8 flex flex-col gap-3">
            <div className="flex w-[95%] gap-1 border-b-2 pb-4">
              <span className="text-lg font-bold">
                {" "}
                Formation Secourisme Le lundi 14/07/2025 de 08H00 à 12H00 à Rue
                de la Vigie entreprise « LA TARTE AUX FRAISES »
              </span>
            </div>
            <div className="w-[95%]  gap-10 flex  pb-4">
              <div className="flex gap-6">
                {" "}
                <div className="flex gap-1">
                  <span className="text-lg font-bold"> Candidat/</span>
                  <span className="text-lg font-semiBold Time"> {"email"}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-6 ">
          <div className="w-[70%]">
            <CardResultats statusResultat={resultat} />
          </div>
          <div className="w-[70%]">
            <CardResultats avatar={"h"} statusResultat={resultat} />
          </div>
          <div className="w-[70%]">
            <CardResultats avatar={"f"} statusResultat={resultat} />
          </div>
          <div className="w-[70%]">
            <CardResultats avatar={"h"} statusResultat={resultat} />
          </div>
          <div className="w-[70%]">
            <CardResultats avatar={"h"} statusResultat={resultat} />
          </div>
          <div className="w-[70%]">
            <CardResultats avatar={"f"} statusResultat={resultat} />
          </div>
          <div className="w-[70%]">
            <CardResultats statusResultat={resultat} />
          </div>
          <div className="w-[70%]">
            <CardResultats avatar={"h"} statusResultat={resultat} />
          </div>
          <div className="w-[70%]">
            <CardResultats avatar={"f"} statusResultat={resultat} />
          </div>
          <div className="w-[70%]">
            <CardResultats avatar={"h"} statusResultat={resultat} />
          </div>
          <div className="w-[70%]">
            <CardResultats avatar={"h"} statusResultat={resultat} />
          </div>
          <div className="w-[70%]">
            <CardResultats avatar={"f"} statusResultat={resultat} />
          </div>
        </div>
        <div className="flex justify-end pt-8 border-b-2">
          <nav aria-label="Page navigation example">
            <ul class="inline-flex items-center -space-x-px">
              <li className="pr-1">
                <a
                  href="#"
                  class="block px-3 py-2 ml-0 rounded-lg leading-tight text-gray-900 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <span class="sr-only">Precedant</span>
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </li>
              <li className="pr-1">
                <a
                  href="#"
                  class="px-3 py-2 leading-tight rounded-lg text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  1
                </a>
              </li>
              <li className="pr-1">
                <a
                  href="#"
                  class="px-3 py-2 leading-tight rounded-lg text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  2
                </a>
              </li>
              <li className="pr-1">
                <a
                  href="#"
                  aria-current="page"
                  class="z-10 px-3 py-2 leading-tight rounded-lg text-white border border-[#288911] bg-[#288911] dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                >
                  3
                </a>
              </li>
              <li className="pr-1">
                <a
                  href="#"
                  class="px-3 py-2 leading-tight rounded-lg text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  4
                </a>
              </li>
              <li className="pr-1">
                <a
                  href="#"
                  class="px-3 py-2 leading-tight rounded-lg text-gray-900 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  5
                </a>
              </li>
              <li className="pr-1">
                <a
                  href="#"
                  class="block px-3 py-2 leading-tight rounded-lg text-gray-900 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <span class="sr-only">Suivant</span>
                  <svg
                    aria-hidden="true"
                    class="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div className="flex justify-end pt-8">
          <button
            type="submit"
            className={`group relative w-[20%] flex justify-center items-center border border-transparent text-md font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-9`}
            onClick={() => navigate(-1)}
          >
            Quitter la prévisualisation
          </button>
        </div>
        <div className=" basis-[40%]  flex  justify-start items-start   overflow-auto py-8">
          <div className=" bg-white h-full w-full rounded-2xl p-8 flex flex-col gap-3">
            <div className="flex w-[95%] gap-1 border-b-2 pb-4">
              <span className="text-lg ">
                {" "}
                Organisme de formation Compétences, 1032, Chemin des LUQUETTES
                83740 LA CADIERE D’AZUR - contact@of-competences.fr - 0660434702
              </span>
            </div>
            <div className="w-[95%]  gap-10 flex  pb-4">
              <div className="flex gap-6">
                {" "}
                <div className="flex gap-1">
                  <span className="text-lg ">
                    {" "}
                    SIRET 87835533800012 - APE8559A - NDA 938305966383 - V2.0 -
                    04/01/2022
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
