import React, { useEffect, useState, useRef } from "react";
import NavbarEspaceLevel from "../layout/espaceLevel/NavbarEspaceLevel";
import { useNavigate, useParams } from "react-router-dom";
import Table from "../components/Table";
import Avatar from "../images/Avatar.svg";
import Home from "../images/Home.svg";
import { useDispatch, useSelector } from "react-redux";
import StudentTableResultats from "../components/StudentTableResultats";
import { PDFExport } from "@progress/kendo-react-pdf";
import { getOneFormation } from "../features/formation/formationSlice";

export default function ResultatsFormationCurrent() {
  const navigate = useNavigate();
  const { allFormations } = useSelector((state) => state.formation)
  const { allFormationsSynthetiseur } = useSelector((state) => state.synthetiseur);
  const {liveFormation} = useSelector((state) => state.formation);
  const dispatch = useDispatch();
  const [currentForm, setCurrentForm] = useState({});
  const [tenta, setTenta] = useState(null);
  const { user } = useSelector((state) => state.auth);
  let { id } = useParams();
  const stagiaire = liveFormation?.stagiaires?.find((el) => el.id == id.split("-")[1]);
  useEffect(() => {
    let formId = id.split("-")[0];
    let arr = [...allFormations, ...allFormationsSynthetiseur].filter((el) => {
      return el.id == formId;
    });
    setCurrentForm(arr[0]);

    if(!liveFormation?.id)
    {
      let formationId = id.split("-")[0];
      dispatch(getOneFormation(formationId));
    }
  }, [allFormations]);

  const Quit = () => {
    if (tenta) {
      setTenta(null);
    } else {
      navigate(-1);
    }
  };

  const pdfExportComponent = useRef(null);
  const exportPdf = () => {
    pdfExportComponent.current.save();
  };

  return (
    <div className="flex flex-col bg-gray-100 min-h-screen">
      <NavbarEspaceLevel />

      <div className="m-8 ">
        <div className=" basis-[10%] flex flex-wrap justify-between border-b-2 items-center ">
          <div className="w-[85%] flex items-center gap-2">
            <button onClick={() => navigate(`/EspaceLevel/formation`)}>
              <span className=" text-xl font-semibold">FORMATIONS</span>
              <span className=" text-xl font-medium"> LEVEL</span>
            </button>
            <button onClick={() => navigate(`/EspaceLevel/${currentForm?.synthese ? "synthetiseurDetail" : "formationDetail"}/${currentForm?.id}`)}>
              <span className="flex-1 text-lg font-medium">
                {"> "}
                Détails formation
              </span>
            </button>
            <button>
              <span className="flex-1 text-lg font-medium">
                {" "}
                {`> ${currentForm?.titre} `}
              </span>
            </button>
          </div>

          {/* <div className="flex justify-start p-8"> */}
          <button
            id="creer"
            className="w-[10%] m-2 rounded-full border-2 py-1 border-transparent text-md font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600"
            onClick={exportPdf}
          >
            Export PDF
          </button>
          {/* </div> */}
        </div>
        <PDFExport
          ref={pdfExportComponent}
          scale={0.8}
          margin="1cm"
          paperSize={[1000, 1250]}
        >
          <div className=" basis-[40%]  flex  justify-start items-start   overflow-auto py-8">
            <div className=" bg-white h-full w-full rounded-2xl p-2 flex flex-col gap-3">
              <div className="flex w-[95%] gap-1 border-b-2 p-4">
                <span className="text-lg font-bold">
                  {" "}
                  {`Formation ${currentForm?.titre} Le ${new Date(
                    currentForm?.startedAt
                  ).toLocaleDateString("fr-FR", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    localeMatcher: "best fit",
                  })} ${currentForm?.time}  ${currentForm?.Adress ? "à " + currentForm?.Adress : ""}`}
                </span>
              </div>
              <div className="w-[95%]  gap-10 flex  p-4">
                <div className="flex gap-6">
                  {" "}
                  <div className="flex gap-1">
                    <span className="text-lg font-bold"> Candidat/</span>
                    <span className="text-lg font-semiBold Time">
                      {" "}
                      {stagiaire?.email}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between">
            <div className="w-[75%]">
              {tenta ? (
                <StudentTableResultats tentative={tenta} currentFormation={currentForm} />
              ) : (
                <Table stag={stagiaire} setTenta={setTenta} currentFormation={currentForm} type={id.split("-")[2]} />
              )}
            </div>

            <div className="w-[25%]">
              <div className="h-full relative flex flex-col ">
                <img
                  src={stagiaire?.Genre == "female" ? Avatar : Home}
                  className="h-32 absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4"
                  alt="avatar"
                />
                <div className="bg-white rounded-2xl flex flex-col justify-center items-center p-14 gap-8 pt-24">
                  <span className="font text-2xl">{id.split("-")[2] == "client" ? stagiaire?.nom : stagiaire?.Prenom}</span>
                  <div className="flex flex-col gap-2 items-center h-full justify-around ">
                    <span className="font-bold mt-[-20px]">Commentaire du Formateur</span>
                    <span>{stagiaire?.commentaires?.[0]?.content || "le formateur n'a pas mis un commentaire"}</span> 
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" basis-[40%]  flex  justify-start items-start   overflow-auto py-8">
            <div className=" bg-white h-full w-full rounded-2xl p-2 flex flex-col gap-3">
              <div className="flex w-[95%] gap-1 border-b-2 p-4">
                <span className="text-lg ">
                  {" "}
                  {`Organisme de formation ${user?.name ? user?.name : " "} ${user?.codePostal ? ", " + user?.codePostal : ""
                    } ${user?.adressePostale ? user?.adressePostale + "," : " "
                    } ${user?.email ? " - " + user?.email : " "}  ${user?.phone ? " -" + user?.phone : " "
                    }`}
                </span>
              </div>
              <div className="w-[95%]  gap-10 flex  p-4">
                <div className="flex gap-6">
                  {" "}
                  <div className="flex gap-1">
                    <span className="text-lg font-semiBold ">
                      {" "}
                      {`SIRET ${user?.siret ? user?.siret : " "} - APE${user?.codeApe ? user?.codeApe : " "
                        } - NDA ${user?.codeNda ? user?.codeNda : " "}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PDFExport>
        <div className="flex absolute right-12  m-2 p-1 pb-[10px]">
          <button
            id="creer"
            className="w-full px-4 rounded-full border-2 py-1 border-transparent text-md font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 mt-[-20px] "
            onClick={Quit}
          >
            Quitter la prévisualisation
          </button>
        </div>
      </div>
    </div>
  );
}
