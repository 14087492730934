import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import CardFormClient from "./CardFormClient";
import edit from "../../../images/edit.svg";
import { useParams, useNavigate } from "react-router-dom";
import {
  dispatchEditable,
  getOneFormation,
} from "../../../features/formation/formationSlice";
import UserFormulaireCardSkeleton from "./components/UserFormulaireCardSkeleton";
import StagiairesResponseCard from "./components/StagiairesResponseCard/StagiairesResponseCard";

export default function StagiaresDetailFormation({ setScreen }) {
  const navigate = useNavigate();
  let { id } = useParams();
  const dispatch = useDispatch();

  const [currentForm, setCurrentForm] = useState({});
  const { liveFormation } = useSelector((state) => state.formation);
  const editButton = () => {
    dispatch(
      dispatchEditable({
        edit: true,
        step: 4,
        formation: currentForm,
      })
    );
    navigate("/FabriqueFormation");
  };
  useEffect(async () => {
    if ((id && liveFormation.id !== id )|| id && !liveFormation) {
      dispatch(getOneFormation(id))
    }; 
  }, []);

  useEffect(async () => {
    setCurrentForm(liveFormation);
  }, [liveFormation]);

  const formulaires = () => {
    let str = currentForm.stagiairesFormulaires || "[]";
    let forms = JSON.parse(str);
    let filter = currentForm.joinFormulaFormats?.filter((el) => {
      return forms.includes(String(el.formulaireId));
    });
    if(filter?.length === 0) return <div className="text-center text-lg font-semibold">Aucun formulaire</div>
    return filter?.map((element) => {
      return (
        <CardFormClient
          name={element.formulaire.titre}
          date={element.formulaire.createdAt}
          slug={element.formulaire.slug}
          number={element.formulaire.questions?.length}
          id={element.formulaire.slug}
          key={element.formulaire.id}
        />
      );
    });
  };

  const repsonsesCard = () => {
    let str = liveFormation?.stagiairesFormulaires || "[]";
    let forms = JSON.parse(str);
    let filter = liveFormation?.joinFormulaFormats?.filter((el) => {
      return forms.includes(String(el.formulaireId));
    });
    if(!filter) return [1,2,3].map(i=> <Fragment  key={i+"repsonsesCard"}><UserFormulaireCardSkeleton/></Fragment>)
    if(filter.length === 0) return <div className="text-center text-lg font-semibold">Aucune réponse</div>
    return filter?.map((el) => {
      let element = el.formulaire;
      const currentFormResponses = liveFormation?.stagiaires?.filter(s=> s?.tentatives?.find((res) => res?.formulaireId === element?.id)) || null;
      return (
        <StagiairesResponseCard
          name={"Réponses: " + element.titre}
          slug={element.slug}
          number={currentFormResponses ? currentFormResponses?.length : 0}
          key={element.id}
          formulaireId={element.id}
        />
      );
    });
  };

  return (
    <div className="flex-1 bg-gray-100 flex flex-col px-16 pt-6 ">
      <div className="basis-[15%] flex justify-between border-b-2 items-center pb-4">
        <div className="flex gap-2 items-center">
          <button onClick={() => navigate(`/EspaceLevel/formation`)}>
            <span className=" text-xl font-semibold">FORMATIONS</span>
            <span className=" text-xl font-medium"> LEVEL</span>
          </button>
          <button onClick={() => navigate(-1)}>
            <span className=" text-lg font-medium">
              {" "}
              {"> Détails formation "}
            </span>
          </button>
          <span className=" text-lg font-medium">
            {"> Détails stagiaires"}
          </span>
        </div>
        <div>
          <button
            type="button"
           className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
            onClick={editButton}
          >
            <img src={edit} className="h-6" />
          </button>
        </div>
      </div>
      <h3 className="text-lg my-2 mb-4">Formulaires stagiaires</h3>
      <div className="gap-8 grid grid-cols-3  mb-8">
        {formulaires()}
      </div>
      <h3 className="text-lg mb-4 mt-4">Réponses stagiaires</h3>
      <div className="gap-8 grid grid-cols-3 mb-8">
        {repsonsesCard()}
      </div>
    </div>
  );
}
