import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { Buffer } from "buffer";
import { PDFExport } from "@progress/kendo-react-pdf";
import { addFeuille } from "../../../features/synthetiseur/synthetiseurSlice";
import download from "../../../images/Download.svg";
import Profil from "../../../images/Profil.svg";

export default function FeuillePresenceDetail() {
  const dispatch = useDispatch();
  const [currentForm, setCurrentForm] = useState({});
  const [selectedFileName, setSelectedFileName] = useState(null);
  const { user } = useSelector((state) => state.auth);
  let duration = 0;

  const [selected, setSelected] = useState();
  const fileInputRef = React.useRef(null);
  const { allFormationsSynthetiseur } = useSelector(
    (state) => state.synthetiseur
  );

  // Set min date as tomorrow's date
  const minDate = new Date();
  minDate.setDate(minDate.getDate());

  const pdfExportComponent = useRef(null);
  const exportPdfPresence = () => {
    const fileName = "FeuilleDePresence.pdf";
    pdfExportComponent.current.save(fileName);
  };

  const [showSendPopup, setShowSendPopup] = useState(false);
  const [sendOption, setSendOption] = useState("envoiInstantane");
  const [sendDate, setSendDate] = useState("");

  let { id } = useParams();
  useEffect(() => {
    let arr = [...allFormationsSynthetiseur].filter((el) => {
      return el.id == id;
    });
    setCurrentForm(arr[0]);
  }, []);

  const hoursDiff = (startTime, endTime) => {
    let startComponents = startTime.split(":");
    let endComponents = endTime.split(":");

    // Extract the hours and minutes from the components
    let startHours = parseInt(startComponents[0]);
    let endHours = parseInt(endComponents[0]);

    // Convert the start and end time to 24-hour format
    if (startTime.includes("PM") && startHours < 12) {
      startHours += 12;
    } else if (startTime.includes("AM") && startHours === 12) {
      startHours = 0;
    }

    if (endTime.includes("PM") && endHours < 12) {
      endHours += 12;
    } else if (endTime.includes("AM") && endHours === 12) {
      endHours = 0;
    }
    const hoursDiffTime = endHours - startHours;
    // Calculate the number of hours between the start and end times
    duration += hoursDiffTime;
    return hoursDiffTime;
  };

  let timeDiff =
    Date.parse(currentForm?.finichedAt) - Date.parse(currentForm?.startedAt);

  let daysDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

  let resultArray = [];
  let startDate = new Date(currentForm?.startedAt);
  for (let i = 0; i <= daysDiff; i++) {
    let currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);

    resultArray.push({
      day: currentDate?.toDateString(),
      period: "matin",
      time: hoursDiff(
        currentForm?.time.split("&&")[0].split("à")[0].split(" ")[1],
        currentForm?.time.split("&&")[0].split("à")[1].split(" ")[1]
      ),
      heure: currentForm?.time.split("&&")[0],
    });

    resultArray.push({
      day: currentDate?.toDateString(),
      period: "après-midi",
      time: hoursDiff(
        currentForm?.time.split("&&")[1].split("à")[0].split(" ")[2],
        currentForm?.time.split("&&")[1].split("à")[1].split(" ")[1]
      ),
      heure: currentForm?.time.split("&&")[1],
    });
    resultArray.push({
      day: currentDate?.toDateString(),
      period: "toute la journée",
    });
  }

  const handleSendOptionChange = (e) => {
    setSendOption(e.target.value);
  };

  const handleSendDateChange = (e) => {
    setSendDate(e.target.value);
  };

  const handleSendButtonClick = async () => {
    try {
      const formData = new FormData();
      const renamedFile = new File(
        [selected],
        `${currentForm?.id}feuilledepresence.pdf`,
        {
          type: selected.type,
        }
      );

      // Append the renamed file to the form data

      formData.append("image", renamedFile);
      formData.append("name", `${currentForm?.id}feuilledepresence.pdf`);
      formData.append("client", currentForm?.client?.email);
      formData.append("clientprenom", currentForm?.client?.prenom);
      formData.append("clientnom", currentForm?.client?.nom);
      formData.append("formationId", currentForm?.id);
      if (sendOption === "envoiInstantane") {
        formData.append("envoi", sendOption);
      } else {
        formData.append("envoi", sendDate);
      }
      await dispatch(addFeuille(formData));
      setShowSendPopup(false);
      setSendOption("");
      setSendDate("");
      setSelected(null);
      setSelectedFileName(null);
    } catch (error) {
      console.error("Error capturing component as image:", error);
    }
  };

  const compareDate = (createdAt, day) => {
    const createdAtDate = new Date(createdAt);
    const dayDate = new Date(day.day);
    const createdAtYear = createdAtDate.getFullYear();
    const createdAtMonth = createdAtDate.getMonth();
    const createdAtDay = createdAtDate.getDate();

    const dayYear = dayDate.getFullYear();
    const dayMonth = dayDate.getMonth();
    const dayDay = dayDate.getDate();

    // Compare the year, month, and day components
    const isSameDay =
      createdAtYear === dayYear &&
      createdAtMonth === dayMonth &&
      createdAtDay === dayDay;

    // Compare the period
    const isSamePeriod =
      (day.period === "matin" && createdAtDate.getHours() < 12) ||
      (day.period === "après-midi" && createdAtDate.getHours() >= 12);
    if (isSameDay && isSamePeriod) {
      return true;
    } else {
      return false;
    }
  };
  const nomBéneficiaire = currentForm?.client?.nom
    ? currentForm?.client?.nom
    : "";
  const prenomBéneficiaire = currentForm?.client?.prenom
    ? currentForm?.client?.prenom
    : "";

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelected(selectedFile);
    setSelectedFileName(selectedFile?.name);

    // Handle the selected file
  };

  return (
    <div className="flex flex-col  flex-1 m-4">
      <div className="flex gap-1 pt-8 pb-2 flex-col place-self-end">
        <button
          type="submit"
          className={`group relative w-[300px] flex justify-center items-center border border-transparent text-md font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-9`}
          onClick={exportPdfPresence}
        >
          Télécharger
        </button>
        <button
          type="submit"
          className={`group relative w-[300px] flex justify-center items-center border border-transparent text-md font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-9`}
          onClick={() => setShowSendPopup(true)}
        >
          Envoyer
        </button>
      </div>
      {showSendPopup && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-900/90">
            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col justify-evenly  bg-white opacity-100 outline-none focus:outline-none pt-9 px-16 w-[40%] h-[50%] ">
              <div className=" flex flex-col justify-center items-center gap-3">
                <div className="flex flex-col items-center">
                  <span className="text-2xl font-semibold">
                    Options d'envoi
                  </span>
                </div>
              </div>

              <div className="w-full flex flex-col gap-4 items-center">
                <div className="flex items-center w-[50%] rounded-full border-2 border-gray-200 justify-center gap-2">
                  <input
                    id="mode1"
                    type="radio"
                    value="envoiProgramme"
                    name="mode"
                    className="w-4 h-4  text-gray-600 bg-gray-100 border-gray-500  accent-green-700    "
                    onChange={handleSendOptionChange}
                    checked={sendOption === "envoiProgramme"}
                  />
                  <label
                    htmlFor="mode1"
                    className="ml-2 text-lg font-medium text-gray-900 dark:text-gray-300"
                  >
                    Envoi programmé
                  </label>
                </div>

                <div className="flex items-center w-[50%] rounded-full border-2 border-gray-200 justify-center gap-2">
                  <input
                    id="mode1"
                    type="radio"
                    name="mode"
                    className="w-4 h-4  text-gray-600 bg-gray-100 border-gray-500  accent-green-700    "
                    value="envoiInstantane"
                    checked={sendOption === "envoiInstantane"}
                    onChange={handleSendOptionChange}
                  />
                  <label
                    htmlFor="mode1"
                    className="ml-2 text-lg font-medium text-gray-900 dark:text-gray-300"
                  >
                    Envoi instantané
                  </label>
                </div>
                <div className="flex items-center w-[50%] rounded-full justify-center gap-2">
                  <input
                    type="file"
                    accept=".pdf"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <button
                    className="border-2  flex justify-evenly w-[100%] rounded-3xl text-md px-4 py-1 border-lime-600"
                    type="submit"
                    onClick={handleButtonClick}
                  >
                    <img src={download} />
                    {!selectedFileName && <p>Charger</p>}
                    {selectedFileName && <p>{selectedFileName}</p>}
                  </button>
                </div>
              </div>

              {sendOption === "envoiProgramme" && (
                <div className="w-full flex flex-col gap-4 items-center">
                  <label>Date d'envoi :</label>

                  <input
                    type={"date"}
                    inputMode="numeric"
                    defaultValue="2000-01-01"
                    onChange={handleSendDateChange}
                    className="button-color relative  flex justify-center w-[50%]  py-2 px-4 border text-white text-sm font-medium   focus:outline-none  rounded-full h-10  items-center  gap-2 outline-none`"
                    value={sendDate}
                    min={minDate.toISOString().slice(0, 10)}
                  />
                </div>
              )}
              <div className="flex  justify-center gap-3 w-full">
                <button
                  type="submit"
                  className={`group relative w-[30%] flex justify-center py-3 px-4 border  border-lime-600  text-sm font-medium    hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full  items-center `}
                  onClick={() => {
                    setShowSendPopup(false);
                    setSelected(null);
                    setSelectedFileName(null);
                  }}
                  id="button"
                >
                  {"Annuler"}
                </button>
                <button
                  type="submit"
                  className={
                    selectedFileName !== null
                      ? `group relative w-[30%] flex justify-center py-3 px-4 border border-transparent text-center font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full whitespace-nowrap `
                      : `group relative w-[30%] flex justify-center py-3 px-4 border border-transparent text-center font-medium  text-black button-gris rounded-full hover:red `
                  }
                  onClick={handleSendButtonClick}
                  id="button"
                >
                  {"Envoyer"}
                </button>
              </div>
              {/*  */}
            </div>
          </div>
        </>
      )}

      <PDFExport
        ref={pdfExportComponent}
        scale={0.8}
        margin="1cm"
        paperSize={[1000, 1250]}
        fileName="FeuilleDePresence.pdf"
      >
        <>
          <div id="feuille">
            <div className="flex justify-center">
              <b>Feuille de présence</b>{" "}
            </div>
            <div className="flex mb-4 justify-between">
              <div className="flex flex-col">
                <span>
                  <b>Nom de la formation</b> : {currentForm?.titre}{" "}
                </span>
                <span>
                  <b>Lieu de la formation</b> :{" "}
                  {currentForm?.Adress != ", " ? currentForm?.Adress : ""}{" "}
                </span>
                <span>
                  <b>Bénéficiaire</b> :{" "}
                  {prenomBéneficiaire + " " + nomBéneficiaire}{" "}
                </span>
                <span>
                  <b>Durée</b> : {duration + " heures"}{" "}
                </span>
              </div>
              <div>
                <img
                  src={
                    user?.logo ? process.env.REACT_APP_IMG + user?.logo : Profil
                  }
                  alt="Level"
                  className="h-12 rounded-lg"
                />
              </div>
            </div>
            <table class="table-tawilind">
              <thead>
                <tr>
                  <th rowSpan={2}>
                    <span className="flex justify-center">
                      Nom de Naissance
                    </span>
                  </th>
                  <th rowSpan={2}>
                    <span className="flex justify-center">Prénom</span>
                  </th>
                  <th rowSpan={2}>
                    <span className="flex justify-center">
                      Date de naissance
                    </span>
                  </th>
                  <th rowSpan={2}>
                    <span className="flex justify-center">
                      Lieu de naissance
                    </span>
                  </th>
                  {resultArray
                    .filter((el) => el.period === "toute la journée")
                    .map((el, index) => {
                      let now = new Date(el.day);
                      const frenchDate = now.toLocaleDateString("fr-FR", {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                        localeMatcher: "best fit",
                      });
                      return (
                        <>
                          <th colSpan={2}>
                            <span className="flex justify-center">
                              {frenchDate}
                            </span>
                          </th>
                        </>
                      );
                    })}
                  <th rowSpan={2}>
                    <span className="flex justify-center">
                      J'ai pris connaissance du règlement intérieur
                    </span>
                  </th>
                </tr>
                <tr>
                  {resultArray
                    .filter((el) => el.period !== "toute la journée")
                    .map((el, index) => {
                      return (
                        <>
                          <th>
                            {" "}
                            <p className="flex justify-center">
                              {el?.period.toLocaleUpperCase()}{" "}
                            </p>
                            <p className="flex justify-center">{el?.heure}</p>
                            <p className="flex justify-center">
                              Durée : {el?.time} Heures{" "}
                            </p>
                          </th>
                        </>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {currentForm?.stagiaires?.map((el, index) => {
                  let now = new Date(el.dateDeNaissance);
                  let signe = el.signatures.length > 0 ? el.signatures[0] : "";
                  let Url = "";
                  if (signe) {
                    const imag = signe.image_data;
                    const base64Im = Buffer.from(imag.data);
                    Url = `${base64Im}`;
                  }
                  const frenchDate = now.toLocaleDateString("fr-FR", {
                    day: "numeric",
                    month: "long",
                    year: "numeric",
                    localeMatcher: "best fit",
                  });
                  return (
                    <>
                      <tr key={index}>
                        <td>{el.Nom}</td>
                        <td>{el.Prenom}</td>
                        <td>{frenchDate}</td>
                        <td>{el.lieuDeNaissance}</td>
                        {resultArray
                          .filter((el) => el.period !== "toute la journée")
                          .map((element, index) => {
                            const signaturesDate = el.signatures.filter((sig) =>
                              compareDate(sig.createdAt, element)
                            );
                            if (signaturesDate.length > 0) {
                              const imageData = signaturesDate[0]?.image_data;
                              const base64Image = Buffer.from(imageData.data);
                              const imageUrl = `${base64Image}`;
                              return (
                                <>
                                  <td key={index}>
                                    {" "}
                                    <img src={imageUrl} />
                                  </td>
                                </>
                              );
                            } else {
                              return <td></td>;
                            }
                          })}

                        {Url ? (
                          <td>
                            {" "}
                            <img src={Url} />
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    </>
                  );
                })}
                {currentForm?.signaturesFormateur?.map((el) => {
                  let Url = "";

                  const imag = el.image_data;
                  const base64Im = Buffer.from(imag.data);
                  Url = `${base64Im}`;

                  return (
                    <>
                      <tr>
                        <td colSpan={4}>
                          <div className="flex justify-between">
                            <span>
                              Formateur : {currentForm?.animateur?.prenom}{" "}
                              {currentForm?.animateur?.nom}
                            </span>
                          </div>
                        </td>
                        {resultArray
                          .filter((el) => el.period !== "toute la journée")
                          .map((element, index) => {
                            const signaturesDate =
                              currentForm?.signaturesFormateur?.filter((sig) =>
                                compareDate(sig.createdAt, element)
                              );
                            if (signaturesDate?.length > 0) {
                              const imageData = signaturesDate[0]?.image_data;
                              const base64Image = Buffer.from(imageData.data);
                              const imageUrl = `${base64Image}`;
                              return (
                                <td key={index}>
                                  {" "}
                                  <img src={imageUrl} />
                                </td>
                              );
                            } else {
                              return <td></td>;
                            }
                          })}

                        {Url ? (
                          <td>
                            {" "}
                            <img src={Url} />
                          </td>
                        ) : (
                          <td></td>
                        )}
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
            <div className="flex mb-4 justify-center items-center">
              <div>
                <span>
                  <b>Organisme de formation</b> :{" "}
                  {user?.name ? user?.name : "-"}{" "}
                </span>
                <span>
                  <b>Email</b> : {user?.email ? user?.email : "-"}{" "}
                </span>
                <span>
                  <b>Numéro de téléphone</b> :{" "}
                  {user?.phone ? +user?.phone : "-"}{" "}
                </span>
                <span>
                  <b>Code Postal</b> :
                  {user?.codePostal ? user?.codePostal : "-"}{" "}
                </span>
                <span>
                  <b>Numéro SIRET</b> :{user?.siret ? user?.siret : "-"}{" "}
                </span>
                <span>
                  <b>Code APE</b> :{user?.codeApe ? user?.codeApe : "-"}{" "}
                </span>
                <span>
                  <b>Code NDA</b> : {user?.codeNda ? user?.codeNda : "-"}{" "}
                </span>
                <span>
                  <b>Numéro de Version</b> : {"V1 2023"}{" "}
                </span>
              </div>
            </div>
          </div>
        </>
      </PDFExport>
    </div>
  );
}
