import React, { Fragment, useEffect, useRef, useState } from "react";
import eyes from "../../../images/eyes.svg";
import share from "../../../images/share.svg";
import edit from "../../../images/edit.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setPartage,
  getAllFormulaires,
} from "../../../features/formulaires/formulaireSlice";
import CartEspaceLevelCom from "../../../components/CartEspaceLevelCom";
import TableEspaceLevel from "../synthetiseurDetails/TableEspaceLevel";
import RowEspaceLevel from "../synthetiseurDetails/RowEspaceLevel";
// import CartFormulaire from "../../../components/CartFormulaire";

export default function NestedFormulaire() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { trierPar, formulaires, search } = useSelector(
    (state) => state.formulaires
  );
  const [listFormulaires, setListFormulaire] = useState(formulaires);
  const { user, tabMode } = useSelector((state) => state.auth);

  useEffect(() => {
    let arr = [...listFormulaires];
    if (trierPar === "Plus récent") {
      arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setListFormulaire(arr);
    } else if (trierPar === "Nom A-Z") {
      arr.sort((a, b) => {
        if (a.titre < b.titre) {
          return -1;
        }
        if (a.titre > b.titre) {
          return 1;
        }
        return 0;
      });
      setListFormulaire(arr);
    } else if (trierPar === "Plus ancien") {
      setListFormulaire(formulaires);
    } else if (trierPar === "Nom Z-A") {
      arr.sort((a, b) => {
        if (a.titre < b.titre) {
          return 1;
        }
        if (a.titre > b.titre) {
          return -1;
        }
        return 0;
      });
      setListFormulaire(arr);
    }
  }, [trierPar, formulaires]);

  useEffect(() => {
    dispatch(getAllFormulaires(user?._id));
  }, []);

  const redirection = (path) => {
    navigate(`${path}`);
  };

  const shareButton = (elid) => {
    dispatch(
      setPartage({
        show: true,
        id: elid,
      })
    );
  };

  return (
    <>
      {!tabMode ? (
        <div className="flex flex-wrap gap-4  justify-start basis-[100%] overflow-auto py-8 ">
          {listFormulaires?.map((element, index) => {
            if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
              return (
                <Fragment key={index + "listFormulaires1"}>
                  <CartEspaceLevelCom
                    name={element.titre}
                    date={element.createdAt}
                    id={element.id}
                    number={element.questions.length}
                    checkbox={"formulaire"}
                    category={"Questions"}
                    dateText={"Crée le "}
                    key={element.id}
                  >
                    <div className="flex items-center justify-center gap-2">
                      <button
                        type="button"
                        className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                      >
                        <img
                          alt="visuel"
                          src={eyes}
                          id={"slug"}
                          onClick={() =>
                            redirection(
                              `/PrevisualisationQuestion/${element.slug}`
                            )
                          }
                        />
                      </button>
                      <button
                        type="button"
                        className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                        onClick={() => shareButton(element.id)}
                      >
                        <img src={share} id={"slug"} />
                      </button>
                      <button
                        type="button"
                        className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                        onClick={() =>
                          redirection(`/CreateQuestions/${element.slug}`)
                        }
                      >
                        <img alt="edit" src={edit} id={"slug"} />
                      </button>
                    </div>
                  </CartEspaceLevelCom>
                </Fragment>
              );
            }
          })}
        </div>
      ) : (
        <TableEspaceLevel
          type={"commun"}
          nom={"Nom de la formulaire"}
          form={"Questions"}
        >
          {listFormulaires?.map((element, index) => {
            if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
              return (
                <Fragment key={index + "listFormulaires"} >
                  <RowEspaceLevel
                    type={"commun"}
                    name={element.titre}
                    date={element.createdAt}
                    updatedate={element.updatedAt}
                    id={element.id}
                    number={element.questions.length}
                    text={"crée le "}
                    checkbox={"formulaire"}
                    key={element.id}
                  >
                    <div className="flex items-center justify-left gap-2">
                      <button
                        type="button"
                        className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() =>
                          redirection(
                            `/PrevisualisationQuestion/${element.slug}`
                          )
                        }
                      >
                        <img alt="viduel" src={eyes} id={"slug"} />
                      </button>
                      <button
                        type="button"
                        className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() => shareButton(element.id)}
                      >
                        <img alt="partager" src={share} id={"slug"} />
                      </button>
                      <button
                        type="button"
                        className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() =>
                          redirection(`/CreateQuestions/${element.slug}`)
                        }
                      >
                        <img alt="edit" src={edit} id={"slug"} />
                      </button>
                    </div>
                  </RowEspaceLevel>
                </Fragment>
              );
            }
          })}
        </TableEspaceLevel>
      )}
    </>
  );
}
