import React from 'react'

export default function EmptySynthetiseur() {
  return (
    <div className=" flex-1 bg-gray-100 flex flex-col ">
        <div className="flex justify-start  items-center py-7 pl-16 border-b-2">
          <span className=" text-xl font-bold ">Archives</span>
        </div>
     
    </div>
  )
}
