import React, { useState, useEffect } from 'react';
import wifiCutWeb from '../../assets/wifi-cut-web.svg';
import wifiCutMobile from '../../assets/wifi-cut-mobile.svg';
import "./index.css";

function ConnexionCheck({ children }) {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  if (!isOnline) {
    return  (
    <div className='main-offline-page-container'>
        {!isMobile && <img src={wifiCutWeb} alt="" />}
        {isMobile && <img src={wifiCutMobile} alt="" />}
        <h1>Vous êtes hors ligne</h1>
        <p>Veuillez vérifier votre connexion internet</p>
    </div>);
  }

  return <>{children}</>;
}

export default ConnexionCheck;
