import React from "react";

export default function UserTableResultats({ currentFormulaire, responses, isStagiaireResponses }) {
  const resultats = () => {
    console.log(responses)

    return currentFormulaire?.questions.map((el, i) => {
      let content = ""
      if (!isStagiaireResponses) { 
        content = responses?.filter((res) => res?.questionId === el.id).map(e => e?.content).join("/");
       }
      else { content = responses?.filter((res) => res?.questionId === el.id).map(e => e.Content).join("/"); }
      return (
        <tr
          className="bg-white"
          style={{ borderBottom: "1.5px solid #E9E9E9" }}
          key={i + 1}
        >
          <td className="text-left px-4 py-2">
            <div className="font-semibold">{`Question ${i + 1} sur ${currentFormulaire?.questions?.length}`}</div>
          </td>
          <td className="text-left ">
            {" "}
            <div className="font-semibold">{"Question: " + el.Content}</div>

          </td>
          <td className="text-left">
            <div className="font-semibold">
              {el.Type === "Niveaux" && <div className="font-semibold">{"⭐️ ".repeat(Number(content) * 5)}</div>}
              {el.Type !== "Niveaux" && <div className="font-semibold">{content || "non renseigné"}</div>}
            </div>
          </td>
        </tr>
      );
    })
  };

  return (
    <>
      <table className="table-auto tableCommunCateg">
        <thead>
          <tr>
            <th className="text-left px-4 py-2">Question N</th>
            <th className="text-left px-8 py-2">Question</th>
            <th className="text-left py-2">Réponse</th>
          </tr>
        </thead>
        <tbody>{resultats()}</tbody>
      </table>
    </>
  );
}
