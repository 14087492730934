import React,{Fragment, useEffect, useState} from "react";
import eyes from "../../../images/eyes.svg";
import edit from "../../../images/edit.svg";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getAllCategorieFormulaires, setModelCate } from "../../../features/categorieFormulaires/categorieFormulaireSlice";
import TableEspaceLevel from "../synthetiseurDetails/TableEspaceLevel";
import RowEspaceLevel from "../synthetiseurDetails/RowEspaceLevel";
import CartEspaceLevelCatg from "../../../components/CartEspaceLevelCatg";


export default function NestedCategoriesFormulaires() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { trierPar, search, formulaires } = useSelector((state) => state.formulaires);
  const { user, tabMode } = useSelector((state) => state.auth);
  const { categorieFormulaires } = useSelector(
    (state) => state.categorieFormulaire
  );
  const [cate, setCate] = useState([]);

  const partaged = formulaires.filter(element=>{
    return element.partager
  })
  useEffect(() => {
    let categ = [...categorieFormulaires];
    if (trierPar === "Plus récent") {
      categ.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setCate(categ);
    } else if (trierPar === "Nom A-Z") {
      categ.sort((a, b) => {
        if (a.titre < b.titre) {
          return -1;
        }
        if (a.titre > b.titre) {
          return 1;
        }
        return 0;
      });
      setCate(categ);
    } else if (trierPar === "Plus ancien") {
      setCate(categorieFormulaires);
    } else if (trierPar === "Nom Z-A") {
      categ.sort((a, b) => {
        if (a.titre < b.titre) {
          return 1;
        }
        if (a.titre > b.titre) {
          return -1;
        }
        return 0;
      });
      setCate(categ);
    }
  }, [trierPar, categorieFormulaires]);

  useEffect(async () => {
    await  dispatch(getAllCategorieFormulaires(user?._id))
  }, []);
 
  

  const redirection = (path) => {
    navigate(`${path}`);
  };

  return (
    <>
      {!tabMode ? (
        <div className="flex flex-wrap gap-4  justify-start basis-[100%] overflow-auto py-8">
          {cate?.map((element, index) => {
        
            if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
              return (
                <Fragment key={index + "cate"}>
                 <CartEspaceLevelCatg
                   name={element.titre}
                   date={element.createdAt}
                   id={element.id}
                   number={element.titre !== "partager avec moi" ? element.joCatFormulas.length : partaged.length}
                   checkbox ={element.titre !== "partager avec moi" ? true : false}
                   type="formulaire"
                    key={element.id}

              >
                   <div className="flex items-center justify-center gap-2">
                     <button
                       type="button"
                       className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                     >
                       <img
                         src={eyes}
                         id={"slug"}
                         onClick={() =>{
                          if(element.titre === "partager avec moi"){
                            redirection(`/EspaceLevel/formulairePartagedCate`)
                          }else{
                            redirection(`/EspaceLevel/formulaireCate/${element.id}`)
                          }                        
                          }
                      }
                       />
                     </button>
                     {element.titre !== "partager avec moi" ?<button
                       type="button"
                       className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                       onClick={() =>
                        dispatch(setModelCate({showModelCate: true, toEdit: element.id}))
                       }
                     >
                       <img src={edit} id={"slug"} />
                     </button> : null }
                   </div>
                 </CartEspaceLevelCatg>
                </Fragment>
              );
            }
          })}
        </div>
      ) : (
        <TableEspaceLevel
        type={'category'}
        nom={"Nom de la Catégorie"}
        form={"formulaires"}
        >
          {cate?.map((element, index) => {
           
            if (search.length === 0 || element.titre?.toLowerCase()?.includes(search.toLowerCase())) {
              return (
                <Fragment key={index + "cate1"}>
                  <RowEspaceLevel
                    type={'category'}
                    name={element.titre}
                    date={element.createdAt}
                    updatedate={element.updatedAt}
                    id={element.id}
                    number={element.joCatFormulas?.length}
                    text={"Crée le "}
                    archive={element.titre == "partager avec moi" ? true : false}
                    checkbox="cateFormulaire"
                    key={element.id}

                 >
                    <div className="flex items-center justify-left gap-2">
                      <button
                        type="button"
                        className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() =>{
                          if(element.titre === "partager avec moi"){
                            redirection(`/EspaceLevel/formulairePartagedCate`)
                          }else{
                            redirection(`/EspaceLevel/formulaireCate/${element.id}`)
                          }                        
                          }
                      }
                      >
                        <img src={eyes} id={"slug"} />
                      </button>
                     {element.titre !== "partager avec moi" ? <button
                        type="button"
                        className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() =>                      
                        dispatch(setModelCate({showModelCate: true, toEdit: element.id}))
                        }
                      >
                        <img src={edit} id={"slug"} />
                      </button> : null }
                    </div>
                  </RowEspaceLevel>
                </Fragment>
              );
            }
          })} 
        </TableEspaceLevel>
      )}
    </>
  );
}
