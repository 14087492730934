import React,{ Fragment, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import play from "../../../../images/play.svg";
import eyes from "../../../../images/eyes.svg";
import share from "../../../../images/share.svg";
import CartEspaceLevelCom from "../../../../components/CartEspaceLevelCom";
import { activateFormation, setLiveFormation, setPartageFormation } from "../../../../features/formation/formationSlice";
import TableEspaceLevel from "../../synthetiseurDetails/TableEspaceLevel";
import RowEspaceLevel from "../../synthetiseurDetails/RowEspaceLevel";
import { useNavigate } from "react-router-dom";
import { setImpayer, setRoom, setRoomState } from '../../../../features/auth/authSlice';
import { setLiveFormulaire } from '../../../../features/formulaires/formulaireSlice';

export default function NestedFormationList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allFormations } = useSelector((state) => state.formation);
  const [lisFormation, setListFormation] = useState(allFormations);
  const { trierPar, search, liveFormulaire } = useSelector((state) => state.formulaires);
  const { tabMode, user } = useSelector((state) => state.auth);


  useEffect(() => {
    let arr = [...lisFormation];
    if (trierPar === "Plus récent") {
      arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setListFormation(arr);

    } else if (trierPar === "Nom A-Z") {
      arr.sort((a, b) => {
        if (a.titre < b.titre) {
          return -1;
        }
        if (a.titre > b.titre) {
          return 1;
        }
        return 0;
      });
      setListFormation(arr);

    } else if (trierPar === "Plus ancien") {
      setListFormation(allFormations);
    } else if (trierPar === "Nom Z-A") {
      arr.sort((a, b) => {
        if (a.titre < b.titre) {
          return 1;
        }
        if (a.titre > b.titre) {
          return -1;
        }
        return 0;
      });
      setListFormation(arr);
    }
  }, [trierPar,allFormations]);

  const sesionButton = async (id) => {
    if(user.point > 0){
      let live = allFormations.filter(element=> element.id == id)[0]
      let form = dispatch(setLiveFormation(live))
      form = form.payload
    let session = form.slug?.split("_")[0] + form.slug.split("_")[2];
    let data = {
      active: 1,
      slug: form.slug,
      organismeId: form.organismeId,
    };
    let etapes = JSON.parse(form.etapes)
    dispatch(setLiveFormulaire(etapes[0]))
    let roomState = {
      state : false,
      currentForm : liveFormulaire
    }
    dispatch(activateFormation(data))
    dispatch(setRoom(session))
    dispatch(setLiveFormation(form))
    dispatch(setRoomState(roomState))
    navigate(`/session/${id}`)
  }else {
    dispatch(setImpayer(true))
  }
  };
  const redirection = (path) => {
    navigate(`${path}`);
  };

  const shareButton = (elid) => {
    dispatch(setPartageFormation({
      show : true,
      id: elid
    }))
  }

  return (
    <> 
      {!tabMode ? (
        <div className="flex flex-wrap gap-4  justify-start basis-[100%] overflow-auto py-8">
          {lisFormation?.map((element) => {
            if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
              return (
                <Fragment key={element.id + "formation4"}>
                 <CartEspaceLevelCom
                   name={element.titre}
                   date={element.startedAt}
                   id={element.id}
                   number={element.joinFormulaFormats?.length}
                   checkbox={"formation"}
                   category={"formulaires"}
                   dateText={"Formation prévu le "}
                   key={element.id + "formation"}
                 >
                   <div className="flex items-center justify-center gap-2">
                     <button
                       type="button"
                       className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                     >
                       <img
                         src={eyes}
                         id={"slug"}
                         onClick={() =>
                           redirection(`/EspaceLevel/formationDetail/${element.id}`)
                         }
                       />
                     </button>
                     <button
                       type="button"
                       className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                       onClick={()=>shareButton(element.id)}
                     >
                       <img src={share} id={"slug"} />
                     </button>
                     <button
                       type="button"
                       className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                       onClick={() =>
                        sesionButton(element.id)
                      }
                     >
                       <img src={play} id={"slug"} />
                     </button>
                   </div>
                 </CartEspaceLevelCom>
                </Fragment>
              );
            }
          })}
        </div>
      ) : (
        <TableEspaceLevel
        type={'commun'}
        nom={"Nom de la formation"}
        form={"Formulaires"}
        
        >
          {lisFormation?.map((element) => {
            if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
              return (
                <Fragment key={element.id + "formation3"}>
                  <RowEspaceLevel
                    type={'commun'}
                    name={element.titre}
                    date={element.startedAt}
                    updatedate={element.updatedAt}
                    id={element.id}
                    number={element.joinFormulaFormats?.length}
                    text={"Formation prévu le "}
                    checkbox={"formation"}
                    key={element.id + "formation2"}
                  >
                    <div className="flex items-center justify-left gap-2">
                      <button
                        type="button"
                        className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() =>
                          redirection(`/EspaceLevel/formationDetail/${element.id}`)
                        }
                      >
                        <img src={eyes} id={"slug"} />
                      </button>
                      <button
                        type="button"
                        className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={()=>shareButton(element.id)}
                      >
                        <img src={share} id={"slug"} />
                      </button>
                      <button
                        type="button"
                        className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() =>
                          sesionButton(element.id)

                        }
                      >
                        <img src={play} id={"slug"} />
                      </button>
                    </div>
                  </RowEspaceLevel>
                </Fragment>
              );
            }
          })} 
        </TableEspaceLevel>
      )}
    </>
  )
}
