import React from "react";

export default function Input({
  label,
  name,
  placeholder,
  id,
  type,
  autoComplete,
  classname,
  divClass,
  onChange,
  value,
  defaulValue
}) {
  return (
    <div className={divClass}>
      <label htmlFor={type} className="sr-only">
        {label}
      </label>
      <input
        id={id}
        name={name}
        type={type}
        autoComplete={autoComplete}
        required
        className={`relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-full focus:outline-none focus:ring-lime-500 focus:border-gray-500 focus:z-10 md:text-sm h-12  ${classname}`}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        defaultValue={defaulValue}
      />
    </div>
  );
}
