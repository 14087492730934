import React from "react";
// import Partager from "../../components/Partager";
import eyes from "../../../images/eyes.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export default function CardFormClient({ name, date, slug, id, number }) {
  let navigate = useNavigate();

  return (
    <div className="card  rounded-lg  custom-b-sh transition-all  hover:bg-gray-200 h-60 flex flex-col gap-2 bg-white py-10 w-full  px-12 cursor-pointer"   onClick={(e) => navigate(`/PrevisualisationQuestion/${id}`)}>
      <div className="flex items-center justify-center gap-2">
        <button
          type="button"
          className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
        >
          <img
            src={eyes}
            id={slug}
          />
        </button>
      </div>
      <div className="card-body flex flex-col justify-center items-center border-b h-[48%]">
        <h1 className="card-title font-black">{name}</h1>
        <p className="font-thin">Crée le {moment(date).format("DD-MM-YYYY")}</p>
        <div className="py-1 px-1 border-t border-gray-100"></div>
      </div>
      <div className="flex justify-center items-center flex-1 ">
        <ul>
          <li className="flex justify-center items-center">
            <div className="flex items-center text-base font-normal text-black">
              <svg
                width="24"
                height="24"
                viewBox="0 0 38 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.6044 2H3.41758C2.63467 2 2 2.64919 2 3.45V29.55C2 30.3508 2.63467 31 3.41758 31H34.6044C35.3873 31 36.0219 30.3508 36.0219 29.55V3.45C36.0219 2.64919 35.3873 2 34.6044 2Z"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 10.7H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 16.5H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 22.3H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="flex-1 ml-3 whitespace-nowrap">
                {number} questions
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
