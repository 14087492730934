import React, { useState, useEffect } from "react";
import Correct from "../../images/Correct.svg";
import False from "../../images/False.svg";

export default function ResultsComp({ length, quest, index, niveaux ,  shouldHideScore}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 815);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 815);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isMobile ? (
        <div className="grid grid-cols-3 h-[8%]">
          <span className="text-xs flex justify-center items-center">
            Question {index + 1} sur {length}
          </span>
          <div className="text-xs flex justify-center items-center">
            {!shouldHideScore && <img
              src={quest.status === false ? False : Correct}
              alt="corect"
              className="h-[70%]"
            />}
          </div>
          <span className="text-xs flex justify-center items-center">
          { !shouldHideScore && quest?.points}
         { shouldHideScore && "-"}
          </span>
        </div>
      ) : (
        <div className="grid grid-cols-3 h-[8%]">
          <span className="text-lg flex justify-center items-center">
            Question {index + 1} sur {length}
          </span>
          <div className="text-lg flex justify-center items-center">
            {!shouldHideScore &&  <img
              src={quest.status == true || niveaux ? Correct : False }
              alt="corect"
              className="h-[70%]"
            />}
          </div>
          <span className="text-lg flex justify-center items-center">
         { !shouldHideScore && quest?.points}
         { shouldHideScore && "-"}
          </span>
        </div>
      )}
    </>
  );
}
