import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";

import CardFormClient from "./CardFormClient";
import edit from "../../../images/edit.svg";
import { dispatchEditable, getOneFormation } from "../../../features/formation/formationSlice";
import UserResponseCard from "./components/UserResponseCard/UserResponseCard";
import UserFormulaireCardSkeleton from "./components/UserFormulaireCardSkeleton";

export default function ClientDetailFormation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const { liveFormation } = useSelector((state) => state.formation);
  const client = liveFormation.client;

  const formulaires = () => {
    let str = liveFormation.clientFormulaires || "[]";
    let forms = JSON.parse(str);
    let filter = liveFormation.joinFormulaFormats?.filter((el) => {
      return forms.includes(String(el.formulaireId));
    });
    if(!filter) return [1,2,3].map(i=> <Fragment  key={i}><UserFormulaireCardSkeleton/></Fragment>)
    if(filter.length === 0) return <div className="text-center text-lg font-semibold">Aucun formulaire</div>
    return filter?.map((element) => {
      return (
        <CardFormClient
          name={element.formulaire.titre}
          date={element.formulaire.createdAt}
          slug={element.formulaire.slug}
          number={element.formulaire.questions?.length}
          id={element.formulaire.slug}
          key={element.formulaire.id}
        />
      );
    });
  };

  const repsonsesCard = () => {
    let str = liveFormation?.clientFormulaires || "[]";
    let forms = JSON.parse(str);
    let filter = liveFormation?.joinFormulaFormats?.filter((el) => {
      return forms.includes(String(el.formulaireId));
    });
    if(!filter) return [1,2,3].map(i=> <Fragment  key={i+"repsonsesCard"}><UserFormulaireCardSkeleton/></Fragment>)
    if(filter.length === 0) return <div className="text-center text-lg font-semibold">Aucune réponse</div>
    return filter?.map((el) => {
      let element = el.formulaire;
      const currentFormResponse = liveFormation?.client?.responses?.filter((res) => res?.formulaireId === element?.id)[0] || null;
      return (
        <UserResponseCard
          name={"Réponse: " + element.titre}
          date={currentFormResponse?.createdAt}
          slug={element.slug}
          number={currentFormResponse ? 1 : 0}
          key={element.id}
          user={client}
          userType="client"
          formulaireId={element.id}

        />
      );
    });
  };


  const editButton = () => {
    dispatch(
      dispatchEditable({
        edit: true,
        step: 2,
        formation: liveFormation,
      })
    );
    navigate("/FabriqueFormation");
  };

  useEffect(() => {
    if ((id && liveFormation.id !== id) || id && !liveFormation) {
      dispatch(getOneFormation(id))
    };
  }, []);

  return (
    <div className=" flex-1 bg-gray-100 flex flex-col px-16  ">
      <div className=" basis-[10%] flex justify-between border-b items-center">
        <div className="  flex gap-2 items-center">
          <button onClick={() => navigate(`/EspaceLevel/formation`)}>
            <span className=" text-xl font-semibold">FORMATIONS</span>
            <span className=" text-xl font-medium"> LEVEL</span>
          </button>
          <button
            onClick={() => navigate(`/EspaceLevel/formationDetail/${id}`)}
          >
            <span className=" text-lg font-medium">
              {" "}
              {"> Détails formation "}
            </span>
          </button>
          <span className=" text-lg font-medium">
            {" "}
            {"> Détails client"}
          </span>
        </div>
        <div>
          <button
            onClick={editButton}
            type="button"
            className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
          >
            <img src={edit} className="h-6" />
          </button>
        </div>
      </div>
      <div className="h-fit bg-white flex justify-start items-start overflow-auto py-9 px-16 w-full rounded-2xl flex-col mt-12 mb-8">
        <div className="w-[95%]  gap-10 flex  py-2">
          <div className="flex gap-6">
            <div className="flex gap-1">
              <span className="text-lg font-bold">
                Nom et prénom du client /
              </span>
              <span className="text-lg font-semiBold Time">
                {`${client?.nom || ""} ${client?.prenom || ""}`}
              </span>
            </div>
          </div>
        </div>
        <div className="w-[95%]  gap-10 flex  border-t py-2">
          <div className="flex gap-6">
            <div className="flex gap-1">
              <span className="text-lg font-bold"> Email du client /</span>
              <span className="text-lg font-semiBold Time">
                {client?.email}
              </span>
            </div>
          </div>
        </div>
        <div className="w-[95%]  gap-10 flex border-t py-2">
          <div className="flex gap-6">
            <div className="flex gap-1">
              <span className="text-lg font-bold">Adresse du client /</span>
              <span className="text-lg font-semiBold Time">
                {(client?.adress !== undefined  && client?.adress ? client?.adress + ", " : "") +
                  (client?.ville !== undefined && client?.ville ? client?.ville + ", " : "") +
                  (client?.codePostale !== undefined && client?.codePostale ? client?.codePostale : "")
                }
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-2"/>
      <h3 className="text-lg my-2 mb-4">Formulaires Client</h3>
      <div className="gap-8 grid grid-cols-3  mb-8">
        {formulaires()}
      </div>
      <h3 className="text-lg mb-4">Réponses Client</h3>
      <div className="gap-8 grid grid-cols-3 mb-8">
        {repsonsesCard()}
      </div>
    </div>
  );
}
