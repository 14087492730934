import axios from "axios";
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;


const getProgram = async (userData) => {
  try {
    const response = await axios.get(`${apiBaseURL}/program/getProgram/${userData}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};


const createTent = async (userData) => {
  try {
    const response = await axios.post(`${apiBaseURL}/program/createTent`, userData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const programService = {
    getProgram,
    createTent
}

export default programService;
