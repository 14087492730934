import React from "react";
import { Collapse, message } from "antd";
import pdfIcon from "./assets/PdfIcon.svg";
import ImageIcon from "./assets/ImageIcon.svg";
import TextFileIcon from "./assets/TextFileIcon.svg";
import PptxIcon from "./assets/PptxIcon.svg";
import ExcelFileIcon from "./assets/ExcelFileIcon.svg";
import DeleteIcon from "./assets/DeleteIcon";
import DownloadIcon from "./assets/DownloadIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  setFormationToEdit,
  updateFiles,
} from "../../../features/formation/formationSlice";
import { deleteFile } from "../../../features/independentFile/independentFileSlice";

// it should be refactored.
const APIURL = process.env.REACT_APP_IMG;
const items = [
  {
    key: "1",
    label: "Liste des fichiers indépendants",
    children: "",
    type: "pdf",
  },
];
const IndependentFileList = ({
  attachments,
  showDelete = false,
}) => {
  const dispatch = useDispatch();
  const { formationToEdit } = useSelector((state) => state.formation);
  const { files } = useSelector((state) => state.formation);
  const deleteNewFile = (index) => {
    let filesToUpdate = Array.isArray(files?.attachments)
      ? [...files?.attachments]
      : [];
    filesToUpdate.splice(index, 1);
    console.log(filesToUpdate);
    dispatch(updateFiles({ attachments: filesToUpdate }));
  };
  const deleteOldFile = (item) => {

    // optimstic randering for the delete of the file
    let filesOfFormationToEdit = Array.isArray(
      formationToEdit?.fichierIndependants
    )
      ? [...formationToEdit?.fichierIndependants]
      : [];
    filesOfFormationToEdit = filesOfFormationToEdit.filter(
      (file) => file?.id !== item?.id
    );

    dispatch(setFormationToEdit({
        ...formationToEdit,
        fichierIndependants: filesOfFormationToEdit,
      })
    );

    dispatch(deleteFile({id: item?.id, path: item?.path})).then((res) => {
      if (res?.meta?.requestStatus === "fulfilled") {
        message.success("Fichier supprimé avec succès");
      } else {
        message.error("Erreur lors de la suppression du fichier");
        dispatch(setFormationToEdit({
          ...formationToEdit,
          fichierIndependants: [...filesOfFormationToEdit, item],
        }));
      }
    });
  };
  items[0].children = attachments?.length ? (
    attachments?.map(
      (file, index) =>
        (file = (
          <MediaItem
            item={file}
            key={index + "file"}
            onDeleteNewFile={deleteNewFile}
            showDelete={showDelete}
            index={index}
            onDeleteOldFile={deleteOldFile}
          />
        ))
    )
  ) : (
    <div className="text-center">Aucun fichier</div>
  );
  return <Collapse items={items} defaultActiveKey={["1"]} />;
};
export default IndependentFileList;

const MediaItem = ({
  item,
  onDeleteOldFile,
  onDeleteNewFile,
  showDelete = false,
  index,
}) => {
  let string1 = item?.path;

  let parts = string1?.split("/");
  let filename = parts?.length ? parts[parts?.length - 1] : "";
  const parts2 = filename?.split("-");
  filename = parts2[parts2?.length - 1];
  let downloadBlob = "";
  if (item?.name) {
    downloadBlob = URL.createObjectURL(item);
  }

  // console.log(APIURL+ item?.path);
  return (
    <div className="flex justify-between items-center">
      <div className="flex gap-[8px] items-center">
        {item?.name && (
          <img
            src={fileIcon(item.type)}
            alt="file icon"
            className="w-[42px] h-[42px]"
          />
        )}
        {!item.name && (
          <img
            src={getFileType(filename)}
            alt="file icon"
            className="w-[42px] h-[42px]"
          />
        )}
        <div className="max-w-[150px] overflow-hidden  h-auto">
          {item?.name && <span className="truncate">{item.name}</span>}
          {!item?.name && <span className="truncate">{filename}</span>}
        </div>
      </div>
      <div className="flex gap-[4px] items-center cursor-pointer hover:text-black	 ">
        <a
          className="flex gap-[4px] items-center cursor-pointer hover:text-blacks download-file-link"
          href={!item?.name ? APIURL + item?.path : downloadBlob}
          download
          target="_blank"
        >
          <span className="underline">Télécharger</span>
          <DownloadIcon />
        </a>
        {showDelete && item?.name && (
          <DeleteIcon onClick={(e) => onDeleteNewFile(index)} />
        )}
        {showDelete && !item?.name && (
          <DeleteIcon onClick={(e) => onDeleteOldFile(item)} />
        )}
      </div>
    </div>
  );
};

const fileIcon = (type) => {
  let pptx =
    "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  let ppt = "application/vnd.ms-powerpoint";
  let xls = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  let xls2 = "application/vnd.ms-excel";
  let docx =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  let word = "application/msword";
  switch (type) {
    case "pdf":
      return pdfIcon;
    case "image/png":
    case "image/jpeg":
      return ImageIcon;
    case "txt":
    case "docs":
    case "docx":
    case word:
    case docx:
      return TextFileIcon;
    case pptx:
    case ppt:
    case "pptx":
      return PptxIcon;
    case "xls":
    case "xls2":
    case xls:
      return ExcelFileIcon;
    default:
      return pdfIcon;
  }
};
const getFileType = (filename) => {
  let parts = filename?.split(".");
  let extention = parts?.length ? parts[parts?.length - 1] : "";
  console.log(filename)
  let pptx =
    "application/vnd.openxmlformats-officedocument.presentationml.presentation";
  let xls = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  switch (extention) {
    case "pdf":
      return pdfIcon;
    case "image/png":
    case "image/jpeg":
    case "jpg":
    case "jpeg":
    case "png":
      return ImageIcon;
    case "txt":
    case "docx":
    case "docx":
      return TextFileIcon;
    case pptx:
    case "pptx":
      return PptxIcon;
    case "xls":
    case "xlsx":
    case xls:
      return ExcelFileIcon;
    default:
      return pdfIcon;
  }
};
