import axios from "../../configAxios";

const createQuestion = async (formData, id) => {
  try {
    const response = await axios.post(`/question/createOne${id}`, formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const duplicateQuestion = async (formData) => {
  try {
    const response = await axios.post(`/question/duplicate`, formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const getOneQuestion = async (id) => {
  try {
    const response = await axios.get(`/question/oneQuestion${id}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const updateQuestion = async (formData, id) => {
  try {
    const response = await axios.put(`/question/updateOne${id}`, formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};
const updateImageQuestion = async (formData) => {
  try {
    const response = await axios.put(`/question/updateImage`, formData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const deleteQuestion = async (id) => {
  try {
    const response = await axios.delete(`/question/deleteOne${id}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const questionService = {
  createQuestion,
  getOneQuestion,
  updateQuestion,
  deleteQuestion,
  updateImageQuestion,
  duplicateQuestion
};

export default questionService;
