import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Close from "../../../images/CloseWhite.svg";
import CalendarPlus from "../../../images/CalendarPlus.svg";
import {
  dispShowModelFormationDetails,
} from "../../../features/agenda/agendaModalForm/agendaModalSlice";
import {
  deleteCaldavEvent,
  editCaldavEvent,
  getAllEventWithCaldav,
  setLiveEvent,
  updatedData,
} from "../../../features/event/eventSlice";
import TimePicker from "../../../components/TimePicker";
import DatePickerA from "../../../components/DatePickerA";
import MyDatePicker from "../../../components/DatePicker";
import { startedAtE as eventStartedAtE } from "../../../features/event/eventSlice";
import { finichedAtE as eventFinichedAtE } from "../../../features/event/eventSlice";
import { getOneFormation } from "../../../features/formation/formationSlice";
import { convertStringToDate, formatDate, formatDateToYearMonthDayDate } from "../../../helpers/dates";
import { message } from "antd";

export default function CalendarEventDetailModal({onClose}) {
  const { liveEvent } = useSelector(
    (state) => state.event
  );

  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();
  const [allDay, setIsAllDay] = useState(true);

  const close = () => {
    dispatch(setLiveEvent({}));
    dispatch(eventStartedAtE(""));
    dispatch(eventFinichedAtE(""));
    setActiveTab(0);
    handleChangeColor("");
    setIsAllDay(false);
    onClose();
  };

  const [deHourM, setdeHourM] = useState("00");
  const [deMinuteM, setdeMinuteM] = useState("00");
  const [aHourM, setaHourM] = useState("00");
  const [aMinuteM, setaMinuteM] = useState("00");
  const [loading, setLoading] = useState(false);
  const [buttonContent, setButtonContent] = useState("Modifier");
  const [startedAt, setStartedAt] = useState("");
  const [finichedAt, setFinichedAt] = useState("");

  const { user } = useSelector((state) => state.auth);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  const handleChange = (e) => {
    if (e.target.name === "isRepeated") {
      dispatch(
        setLiveEvent({ ...liveEvent, [e.target.name]: e.target.checked })
      );
      
    }
    else {
      dispatch(setLiveEvent({ ...liveEvent, [e.target.name]: e.target.value }));
    }
  };

  const handleChangeColor = (color) => {
    dispatch(setLiveEvent({ ...liveEvent, color: color }));
  };

  useEffect(() => {
    if (allDay === "true") {
      setdeHourM("00");
      setdeMinuteM("00");
      setaHourM("00");
      setaMinuteM("00");
    }
  }, [allDay]);

  useEffect(() => {
      dispatch(
        setLiveEvent({
          ...liveEvent,
          startedAt: convertStringToDate(startedAt, deHourM, deMinuteM),
          finishedAt: convertStringToDate(finichedAt, aHourM, aMinuteM),
        })
      );
  }, [startedAt, finichedAt, deHourM, deMinuteM, aHourM, aMinuteM]);

  const modifEvent = async () => {
    setLoading(true);
    setButtonContent("en cours...");

    let updatedEvent = {
      ...liveEvent,
    };
    delete updatedEvent.currentEmail;
    delete updatedEvent.attendee;
    updatedEvent.allDay = allDay;

    dispatch(editCaldavEvent(updatedEvent)).then((res) => {
          message.success("Événement modifié avec succès");
          setLoading(false);
          setButtonContent("Modifier");
          close();
      if (res.payload) {
        dispatch(getAllEventWithCaldav()).then((res) => { 
        });
      } else {
        message.error("Erreur lors de la modification de l'événement");
        setLoading(false);
        setButtonContent("Modifier");
      }
    });
    setActiveTab(0);
  };

  const OpenModifFormation = () => {
    dispatch(getOneFormation(liveEvent.formationId));
    dispatch(dispShowModelFormationDetails(true));
  };

  useEffect(() => {
    if (liveEvent.startedAt) {
      if(liveEvent.startedAt.length > 8){
        setIsAllDay(false);
        const date = new Date(liveEvent.startedAt);
        setdeHourM(date?.getUTCHours().toString().padStart(2, "0"));
        setdeMinuteM(date?.getUTCMinutes().toString().padStart(2, "0"));
      }
      else {
        setIsAllDay(true);
        const date = formatDate(liveEvent.startedAt);
        setdeHourM(date?.getUTCHours().toString().padStart(2, "0"));
        setdeMinuteM(date?.getUTCMinutes().toString().padStart(2, "0"));
      }
    }
    if (liveEvent.finishedAt) {
      if(liveEvent.finishedAt.length > 8){
        const date = new Date(liveEvent.finishedAt);
        setaHourM(date?.getUTCHours().toString().padStart(2, "0"));
        setaMinuteM(date?.getUTCMinutes().toString().padStart(2, "0"));
      }
      else {
        const date = formatDate(liveEvent.finishedAt);
        setaHourM(date?.getUTCHours().toString().padStart(2, "0"));
        setaMinuteM(date?.getUTCMinutes().toString().padStart(2, "0"));
      }
    }
  }, [liveEvent.url]);

  const handleInputKeyDown = (e) => {
    if (e.key === " " && liveEvent.currentEmail.trim() !== "") {
      // Add the currentEmail to the emails list if it's not empty and space is pressed
      dispatch(
        setLiveEvent({
          ...liveEvent,
          inviteeList: [
            ...liveEvent.inviteeList,
            liveEvent.currentEmail.trim(),
          ],
          currentEmail: "",
        })
      );
    } else if (e.key === "Backspace" && liveEvent.currentEmail === "") {
      // Remove the last email from the list if Backspace is pressed and the currentEmail is empty
      dispatch(
        setLiveEvent({
          ...liveEvent,
          inviteeList: liveEvent.inviteeList.slice(0, -1),
        })
      );
    }
  };

  const deleteEmail = (index) => {
    const updatedList = [...liveEvent.inviteeList];
    updatedList.splice(index, 1);
    dispatch(setLiveEvent({ ...liveEvent, inviteeList: updatedList }));
  };

  const deleteEvent = (e) => {
    dispatch(deleteCaldavEvent({ url: e.url })).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
          message.success("Événement supprimé avec succès");
          close();
          dispatch(getAllEventWithCaldav()).then((res) => {
          });
      } else {
        message.error("Erreur lors de la suppression de l'événement");
      }
    });
  };

  return (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-30 outline-none focus:outline-none bg-black/90 ">
            <div className="relative min-w-[60%] my-6 mx-auto h-[95%] bg-white rounded-3xl ">
              <div className="h-[100%] flex flex-col items-center justify-around">
                <div className="flex relative flex-col gap-1 justify-center items-center w-full">
                  <button
                    className="absolute"
                    style={{
                      top: 0,
                      right: "20px",
                      padding: "10px",
                      background: "red",
                      borderRadius: "4px",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      deleteEvent(liveEvent);
                    }}
                  >
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 10 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.08559 2.5H1"
                        stroke="white"
                        strokeWidth="0.646847"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M3.93945 4.75V7.75001"
                        stroke="white"
                        strokeWidth="0.646847"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.14648 4.75V7.75001"
                        stroke="white"
                        strokeWidth="0.646847"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.35181 2.5V9.62501C8.35181 9.72447 8.31309 9.81985 8.24417 9.89018C8.17524 9.9605 8.08176 10 7.98429 10H2.10386C2.00638 10 1.9129 9.9605 1.84397 9.89018C1.77505 9.81985 1.73633 9.72447 1.73633 9.62501V2.5"
                        stroke="white"
                        strokeWidth="0.646847"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6.88035 2.5V1.75C6.88035 1.55109 6.8029 1.36032 6.66506 1.21967C6.52721 1.07902 6.34024 1 6.14529 1H3.94013C3.74518 1 3.55822 1.07902 3.42037 1.21967C3.28252 1.36032 3.20508 1.55109 3.20508 1.75V2.5"
                        stroke="white"
                        strokeWidth="0.646847"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <div className=" flex flex-col justify-center p-[3%]">
                    <img
                      src={CalendarPlus}
                      alt="ajoutEventOuFormation"
                      className="h-16"
                    />
                    <span className="text-2xl font-semibold  flex justify-center">
                      {liveEvent.formationId
                        ? "Modifier la séance"
                        : "Modifier l'événement"}
                    </span>
                    <p className="flex justify-center text-gray-400 font-extralight text-lg">
                      {liveEvent.formationId
                        ? "séance créée"
                        : "événement créé"}{" "}
                      par {user?.name}
                    </p>
                  </div>
                  <div className="w-[70%] flex flex-col gap-2 justify-center">
                    <div className=" w-full flex flex-col items-center justify-center gap-4">
                      <input
                        name="title"
                        placeholder="Nom de l’événement"
                        className="w-[95%] rounded-full border-gray-200 border-2 px-7 p-3"
                        value={liveEvent.title}
                        onChange={handleChange}
                      />
                                         <div
                      className={`flex items-center w-[96%] gap-1 ${
                        allDay ? "cursor-no-drop" : ""
                      } flex-row md:flex-col`}
                    >
                      <div
                        className={`flex-1 flex justify-between items-center gap-2 h-full bg-white ${
                          allDay ? "pointer-events-none " : ""
                        }`}
                      >
                          <span className="Time">DE</span>
                          <div className="flex flex-1 ">
                            <TimePicker
                              setHours={setdeHourM}
                              setMinutes={setdeMinuteM}
                              Hours={deHourM}
                              Minutes={deMinuteM}
                            />
                          </div>
                        </div>
                        <div className=" basis-1/2 flex items-center gap-3">
                          <span className="Time">À</span>
                          <div className="flex flex-1">
                            <TimePicker
                              setHours={setaHourM}
                              setMinutes={setaMinuteM}
                              Hours={aHourM}
                              Minutes={aMinuteM}
                            />
                          </div>
                        </div>
                      </div>
                     
                      <div className="flex items-center gap-4 flex-row lg:flex-col">
                        <div className=" basis-1/2 flex items-center gap-2">
                          <span className="Time">DU</span>
                          <div className="flex flex-1">
                            {liveEvent.startedAt && (
                              <MyDatePicker start={formatDateToYearMonthDayDate(liveEvent.startedAt, liveEvent.startedAt.length === 8)} onChange={(v)=> setStartedAt(v)} />
                            )}
                          </div>
                        </div>
                        <div className=" basis-1/2 flex items-center gap-3">
                          <span className="Time">AU</span>
                          <div className="flex flex-1">
                            {liveEvent.finishedAt && (
                              <DatePickerA end={formatDateToYearMonthDayDate(liveEvent.finishedAt, liveEvent.finishedAt.length === 8)} onChange={(v)=> setFinichedAt(v)} />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-6 p-2 justify-center items-center max-h-30px] flex-row lg:flex-col">
                        <p className="mr-4 text-gray-500">
                          Couleur d'affichage{" "}
                        </p>
                        <div className="color-chooices flex gap-4 items-center">
                          <button
                            onClick={() => handleChangeColor("darkseagreen")}
                          >
                            {liveEvent.color === "darkseagreen" ? (
                              <div className="border-spacing-3 border-2 rounded-full p-1 border-[#92c386]">
                                <div className="rounded-full h-3 w-3 bg-[#92c386] "></div>
                              </div>
                            ) : (
                              <div className="rounded-full h-3 w-3 bg-[#92c386] "></div>
                            )}
                          </button>
                          <button onClick={() => handleChangeColor("green")}>
                            {liveEvent.color === "green" ? (
                              <div className="border-spacing-3 border-2 rounded-full p-1 border-[#288911]">
                                <div className="rounded-full h-3 w-3 bg-[#288911] "></div>
                              </div>
                            ) : (
                              <div className="rounded-full h-3 w-3 bg-[#288911]"></div>
                            )}
                          </button>
                          <button
                            onClick={() => handleChangeColor("yellowgreen")}
                          >
                            {liveEvent.color === "yellowgreen" ? (
                              <div className="border-spacing-3 border-2 rounded-full p-1 border-[#95C11F]">
                                <div className="rounded-full h-3 w-3 bg-[#95C11F] "></div>
                              </div>
                            ) : (
                              <div className="rounded-full h-3 w-3 bg-[#95C11F]"></div>
                            )}
                          </button>
                          <button
                            onClick={() => handleChangeColor("lightskyblue")}
                          >
                            {liveEvent.color === "lightskyblue" ? (
                              <div className="border-spacing-3 border-2 rounded-full p-1 border-[#5CC4F3]">
                                <div className="rounded-full h-3 w-3 bg-[#5CC4F3] "></div>
                              </div>
                            ) : (
                              <div className="rounded-full h-3 w-3 bg-[#5CC4F3]"></div>
                            )}
                          </button>
                          <button
                            onClick={() => handleChangeColor("darkorchid")}
                          >
                            {liveEvent.color === "darkorchid" ? (
                              <div className="border-spacing-3 border-2 rounded-full p-1 border-[#A855F7]">
                                <div className="rounded-full h-3 w-3 bg-[#A855F7] "></div>
                              </div>
                            ) : (
                              <div className="rounded-full h-3 w-3 bg-[#A855F7]"></div>
                            )}
                          </button>
                          <button onClick={() => handleChangeColor("crimson")}>
                            {liveEvent.color === "crimson" ? (
                              <div className="border-spacing-3 border-2 rounded-full p-1 border-[#DB1D3E]">
                                <div className="rounded-full h-3 w-3 bg-[#DB1D3E] "></div>
                              </div>
                            ) : (
                              <div className="rounded-full h-3 w-3 bg-[#DB1D3E]"></div>
                            )}
                          </button>
                          <button onClick={() => handleChangeColor("violet")}>
                            {liveEvent.color === "violet" ? (
                              <div className="border-spacing-3 border-2 rounded-full p-1 border-[#F584FF]">
                                <div className="rounded-full h-3 w-3 bg-[#F584FF] "></div>
                              </div>
                            ) : (
                              <div className="rounded-full h-3 w-3 bg-[#F584FF]"></div>
                            )}
                          </button>
                          <button onClick={() => handleChangeColor("yellow")}>
                            {liveEvent.color === "yellow" ? (
                              <div className="border-spacing-3 border-2 rounded-full p-1 border-[#FFF500]">
                                <div className="rounded-full h-3 w-3 bg-[#FFF500] "></div>
                              </div>
                            ) : (
                              <div className="rounded-full h-3 w-3 bg-[#FFF500]"></div>
                            )}
                          </button>
                        </div>
                      </div>
                      <div className="w-full flex gap-6 justify-center">
                      <div className="flex gap-3 items-center">
                        <input
                          checked={allDay}
                          id="allDay"
                          type="checkbox"
                          value=""
                          name="allDay"
                          onChange={(e) => setIsAllDay(e.target.checked)}
                          className="w-4 h-4 accent-green-700 text-green-600 bg-green-700 rounded border-gray-300 focus:ring-green-700"
                        ></input>
                        <label htmlFor="allDay" className="text-gray-500">
                          Évènement toute la journée
                        </label>
                      </div>
                        <div className="flex gap-3 items-center">
                          <input
                            checked={liveEvent.isRepeated}
                            id="repeat"
                            type="checkbox"
                            value=""
                            name="isRepeated"
                            onChange={handleChange}
                            className="w-4 h-4 accent-green-700 text-green-600 bg-green-700 rounded border-gray-300 focus:ring-green-700"
                          ></input>
                          <label
                            htmlFor="repeat"
                            className="text-gray-500 cursor-pointer"
                          >
                            Répéter l’évènement{" "}
                          </label>
                        </div>
                      </div>
                      {/* the menu */}

                      <div className="flex justify-evenly w-full items-center bg-[#9FC696] gap-2 rounded-lg">
                        <div className={`w-[50%] h-12 rounded-lg flex`}>
                          <button
                            className={`flex-1 rounded-lg text-white text-md ${
                              activeTab === 0 ? "bg-[#288911]" : ""
                            }`}
                            onClick={() => handleTabClick(0)}
                          >
                            Description{" "}
                            {liveEvent.formationId
                              ? "de la séance"
                              : "de l'événement"}
                          </button>
                        </div>
                        <div className={`w-[50%] h-12 rounded-lg  flex`}>
                          <button
                            className={`flex-1 rounded-lg text-white text-md ${
                              activeTab === 1 ? "bg-[#288911]" : ""
                            }`}
                            onClick={() => handleTabClick(1)}
                          >
                            Addresse{" "}
                            {liveEvent.formationId
                              ? "de la séance"
                              : "de l'événement"}
                          </button>
                        </div>
                        <div className={`w-[33%] h-12 rounded-lg  flex`}>
                          <button
                            className={`flex-1 rounded-lg text-white text-md ${
                              activeTab === 2 ? "bg-[#288911]" : ""
                            }`}
                            onClick={() => handleTabClick(2)}
                          >
                            Liste des invités
                          </button>
                        </div>
                      </div>
                      {/* Content based on active tab */}
                      {activeTab === 0 && (
                        <div className="w-full">
                          <input
                            name="description"
                            placeholder={`Description ${
                              liveEvent.formationId
                                ? "de la séance"
                                : "de l'événement"
                            }`}
                            className="w-[100%] rounded-full border-gray-200 border-2 px-7 p-3"
                            value={liveEvent.description}
                            onChange={handleChange}
                          />
                        </div>
                      )}

                      {activeTab === 1 && (
                        <div className="w-full flex flex-col max-h-[15%] overflow-y-auto">
                          <input
                            name="emplacement"
                            placeholder={`Addresse ${
                              liveEvent.formationId
                                ? "de la séance"
                                : "de l'événement"
                            }`}
                            className="w-[100%] rounded-full border-gray-200 border-2 px-7 p-3"
                            value={liveEvent.emplacement}
                            onChange={handleChange}
                          />
                        </div>
                      )}

                      {activeTab === 2 && (
                        <div
                          className="w-full flex flex-col gap-2 max-h-[18%] overflow-y-auto min-h-28	"
                          style={{ minHeight: "115px" }}
                        >
                          <input
                            name="currentEmail"
                            placeholder="Entrez les emails des invités"
                            className="w-[100%] rounded-full border-gray-200 border-2 px-7 p-3"
                            value={liveEvent.currentEmail}
                            onChange={handleChange}
                            onKeyDown={handleInputKeyDown}
                          />
                          <div className="flex flex-wrap gap-2 max-h-[20px]">
                            {liveEvent?.inviteeList?.length > 0 &&
                              liveEvent?.inviteeList?.map((email, index) => (
                                <div
                                  key={index}
                                  className="border border-gray-300 rounded px-2 py-1 flex gap-1 items-center"
                                >
                                  {email}
                                  <button
                                    className="p-1 text-gray-400"
                                    onClick={() => deleteEmail(index)}
                                  >
                                    X
                                  </button>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex w-[80%] justify-center gap-4 mt-2  ">
                    <button
                      type="submit"
                      className={`group relative w-[25%] flex justify-center py-3 px-4 border  border-lime-600  text-sm font-medium    hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full  items-center md:w-[40%] `}
                      onClick={close}
                      id="button"
                    >
                      {"Annuler"}
                    </button>
                    <button
                      type="submit"
                      className={`w-[25%] md:w-[40%] group relative flex justify-center items-center py-3 px-4 rounded-full 
                        bg-lime-600 text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600  whitespace-nowrap ${
                          loading ? "cursor-not-allowed" : ""
                        }
                        `}
                      onClick={modifEvent}
                    >
                      {buttonContent}
                    </button>
                  </div>
                </div>
                {liveEvent.formationId && (
                  <button
                    type="submit"
                    className={`w-[25%] md:w-[50%] group relative flex justify-center items-center py-3 px-4 rounded
                        "bg-lime-600 text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600  whitespace-nowrap"
                        }`}
                    onClick={OpenModifFormation}
                  >
                    {"Détails de la formation"}
                  </button>
                )}
              </div>
            </div>
            <button
              onClick={close}
              className="mr-[3%] -mt-[38%] sm:-mt-[80%] md:-mt-[65%] lg:-mt-[45%]"
            >
              <img src={Close} alt="fermer" />
            </button>
          </div>
        </>
  );
}
