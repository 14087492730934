import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import stagiaireService from "./stagiaresService";

const initialState = {
  stagiaires: [],
  currentStagiare: {},
  currentTentative: {},
  currentReponses: {},
  submitetTentative: {},
  currentLevel: 1,
  currentIndep: 1,
  isError: false,
  isSuccess: false,
  isLoading: false,
  terminer: false,
  message: "",
  score: 0,
  organisme: {},
  organismeId: "",
};

export const createStagiaire = createAsyncThunk(
  "createStagiare",
  async (input, thunkAPI) => {
    const stagiaire = input.formData;
    try {
      return await stagiaireService.createStagiaires(stagiaire);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const gettentative = createAsyncThunk(
  "gettentative",

  async (input, thunkAPI) => {
    try {
      return await stagiaireService.getTentative(input);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createStagiaireReponses = createAsyncThunk(
  "createStagiareReponses",
  async (input, thunkAPI) => {
    try {
      return await stagiaireService.createStagiaireReponses(input);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createStagiaireSignature = createAsyncThunk(
  "createStagiareSignature",
  async (input, thunkAPI) => {
    try {
      return await stagiaireService.createSignature(input);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const sendCommentToStagiaire = createAsyncThunk(
  "sendCommentToStagiaire",
  async (input, thunkAPI) => {
    try {
      return await stagiaireService.sendCommentToStagiaire(input);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateCommentStagiaire = createAsyncThunk(
  "updateCommentStagiaire",
  async (input, thunkAPI) => {
    try {
      return await stagiaireService.updateCommentStagiaire(input);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createFormateurSignature = createAsyncThunk(
  "createFormateurSignature",
  async (input, thunkAPI) => {
    try {
      return await stagiaireService.createSignatureFormateur(input);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateStagiaire = createAsyncThunk(
  "updateStagiareReponses",
  async (input, thunkAPI) => {
    try {
      return await stagiaireService.updateStagiaireReponses(input);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createStag = createAsyncThunk(
  "createStag",
  async (input, thunkAPI) => {
    try {
      return await stagiaireService.createStagiaire(input);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOrganismeInfos = createAsyncThunk(
  "getOrganismeInfos",
  async (id, thunkAPI) => {
    try {
      return await stagiaireService.getOrganismeInfos(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getStagiaire = createAsyncThunk("getStagaire", async (id, thunkAPI) => {
  try {
    return await stagiaireService.getStagiaire(id);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const createTentative = createAsyncThunk("createTentative", async (data, thunkAPI) => {
  try {
    return await stagiaireService.createTentative(data);
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const stagiaireSlice = createSlice({
  name: "stagiaire",
  initialState,
  reducers: {
    resetStagiaire: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.terminer = false;
    },
    setCurrentStagiaire: (state, action) => {
      state.currentStagiare = action.payload;
    },
    setCurrentTentative: (state, action) => {
      state.currentTentative = action.payload;
    },
    setCurrentReponse: (state, action) => {
      state.currentReponses = action.payload;
    },
    tentativeFinished: (state) => {
      state.currentReponses = {};
      state.currentTentative = {};
    },
    setfinishetTentative: (state, action) => {
      state.submitetTentative = action.payload;
    },
    setScore: (state, action) => {
      state.score = action.payload;
    },
    setCurrentLevel: (state, action) => {
      state.currentLevel = action.payload;
    },
    setCurrentIndep: (state, action) => {
      state.currentIndep = action.payload;
    },
    setTerminer: (state, action) => {
      state.terminer = action.payload;
    },
    setOrganismeId: (state, action) => {
      state.organismeId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStagiaire.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createStagiaire.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.stagiaires = action.payload;
      })
      .addCase(createStagiaire.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createStagiaireSignature.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createStagiaireSignature.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(createStagiaireSignature.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(getOrganismeInfos.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOrganismeInfos.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.organisme = action.payload;
      })
      .addCase(getOrganismeInfos.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getStagiaire.fulfilled, (state, action) => {
        state.currentStagiare = action.payload;
      })
      .addCase(createStagiaireReponses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createStagiaireReponses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentReponses = {};
        state.currentTentative = {};
        state.submitetTentative = action.payload;
      })
      .addCase(createStagiaireReponses.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(gettentative.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(gettentative.fulfilled, (state, action) => {
        state.isLoading = false;

        state.isSuccess = true;

        state.submitetTentative = action.payload;
      })

      .addCase(createTentative.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentTentative = action.payload;
      })

      .addCase(gettentative.rejected, (state, action) => {
        state.isLoading = false;

        state.isError = true;

        state.message = action.payload;
      });
  },
});

export const {
  resetStagiaire,
  setOrganismeId,
  setCurrentLevel,
  setTerminer,
  setCurrentStagiaire,
  setScore,
  tentativeFinished,
  setCurrentTentative,
  setCurrentReponse,
  setfinishetTentative,
  setCurrentIndep,
} = stagiaireSlice.actions;
export default stagiaireSlice.reducer;
