import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import CardFormClient from "./CardFormClient";
import edit from "../../../images/edit.svg";
import {
  dispatchEditable,
  getOneFormation,
} from "../../../features/formation/formationSlice";
import UserResponseCard from "./components/UserResponseCard/UserResponseCard";
import UserFormulaireCardSkeleton from "./components/UserFormulaireCardSkeleton";
export default function AnimateurDetailFormation() {
  const navigate = useNavigate();
  let { id } = useParams();
  const dispatch = useDispatch();

  const { liveFormation } = useSelector((state) => state.formation);

  const animateur = liveFormation.animateur;

  const editButton = () => {
    dispatch(
      dispatchEditable({
        edit: true,
        step: 3,
        formation: liveFormation,
      })
    );
    navigate("/FabriqueFormation");
  };

  useEffect(async () => {
    if ((id && liveFormation.id !== id ) || id && !liveFormation) {
      dispatch(getOneFormation(id))
    }; 
  }, []);


  const formulaires = () => {

    let str = liveFormation?.animateurFormulaires || "[]";
    let forms = JSON.parse(str);
    let filter = liveFormation?.joinFormulaFormats?.filter((el) => {
      return forms.includes(String(el.formulaireId));
    });
    if(!filter) return [1,2,3].map(i=> <Fragment  key={i}><UserFormulaireCardSkeleton/></Fragment>)
      if(filter.length === 0) return <div className="text-center text-lg font-semibold">Aucun formulaire</div>
      return filter?.map((el) => {
        let element = el.formulaire;
        return (
          <CardFormClient
            name={element.titre}
            date={element.createdAt}
            slug={element.slug}
            number={element.questions?.length}
            id={element.slug}
            key={element.id}

          />
        );
        
      });
 
  };
  const repsonsesCard = () => {
    let str = liveFormation?.animateurFormulaires || "[]";
    let forms = JSON.parse(str);
    let filter = liveFormation?.joinFormulaFormats?.filter((el) => {
      return forms.includes(String(el.formulaireId));
    });
    if(!filter) return [1,2,3].map(i=> <Fragment  key={i+"repsonsesCard"}><UserFormulaireCardSkeleton/></Fragment>)
    if(filter.length === 0) return <div className="text-center text-lg font-semibold">Aucune réponse</div>
    return filter?.map((el) => {
      let element = el.formulaire;
      const currentFormResponse = liveFormation?.animateur?.responses?.filter((res) => res?.formulaireId === element?.id)[0] || null;
      return (
        <UserResponseCard
          name={"Réponse: " + element.titre}
          date={currentFormResponse?.createdAt}
          slug={element.slug}
          number={currentFormResponse ? 1 : 0}
          key={element.id}
          user={animateur}
          userType="animateur"
          formulaireId={element.id}
        />
      );
    });
  };

  return (
    <div className="flex-1 bg-gray-100 flex flex-col px-16 pt-4 ">
      <div className="basis-[15%] flex justify-between border-b-2 items-center ">
        <div className="flex gap-2 items-center">
          <button onClick={() => navigate(`/EspaceLevel/formation`)}>
            <span className=" text-xl font-semibold">FORMATIONS</span>
            <span className=" text-xl font-medium"> LEVEL</span>
          </button>
          <button onClick={() => navigate(-1)}>
            <span className=" text-lg font-medium">
              {" "}
              {"> Détails formation "}
            </span>
          </button>
          <span className=" text-lg font-medium">
            {" "}
            {"> Détails animateur"}
          </span>
        </div>
        <div>
          <button
            onClick={editButton}
            type="button"
            className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
          >
            <img src={edit} className="h-6" />
          </button>
        </div>
      </div>
      <div className="h-fit bg-white flex justify-start items-start py-9 px-16 w-full rounded-2xl flex-col mt-8 mb-2">
        <div className="w-[95%]  gap-10 flex  py-2">
          <div className="flex gap-6">
            <div className="flex gap-1">
              <span className="text-lg font-bold">
                Nom et prénom d'animateur /
              </span>
              <span className="text-lg font-semiBold Time">
                {`${animateur?.nom || "non renseigné"} ${animateur?.prenom || ""}`}
              </span>
            </div>
          </div>
        </div>
        <div className="w-[95%]  gap-10 flex  border-t py-2">
          <div className="flex gap-6">
            <div className="flex gap-1">
              <span className="text-lg font-bold"> Email d'animateur /</span>
              <span className="text-lg font-semiBold Time">
                {animateur?.email || "non renseigné"}
              </span>
            </div>
          </div>
        </div>
        <div className="w-[95%]  gap-10 flex border-t py-2">
          <div className="flex gap-6">
            <div className="flex gap-1">
              <span className="text-lg font-bold">Adresse d'animateur /</span>
              <span className="text-lg font-semiBold Time">
                {animateur?.ville ? animateur?.ville : "-"}{" "}
                {" " + animateur?.codePostal ? animateur?.codePostal : "-"}
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr className="mt-2"/>
      <h3 className="text-lg my-2 mb-4">Formulaires Animateur</h3>
      <div className="gap-8 grid grid-cols-3  mb-8">
        {formulaires()}
      </div>
      <h3 className="text-lg mb-4">Réponses Animateur</h3>
      <div className="gap-8 grid grid-cols-3 mb-8">
        {repsonsesCard()}
      </div>
    </div>
  );
}
