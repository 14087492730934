import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  duplicateFormation,
  deleteFormation,
  dispatchSelectFormation,
} from "../../../features/formation/formationSlice";
import { setRefrechCard } from "../../../features/formulaires/formulaireSlice";
export default function DuplicateDeleteFormation({show, setShow, setShowModal}) {
  const { selectedFormations } = useSelector((state) => state.formation);
  const dispatch = useDispatch();
  const location = useLocation();

  const duplicate = () => {
    if (selectedFormations.length > 0) {
      let arr = [...selectedFormations];
      let newArray = arr.map((el) => {
        let obj = { ...el };
        delete obj.id;
        delete obj.createdAt;
        obj.titre = obj.titre + " copie";
        return obj;
      });
      dispatch(duplicateFormation(newArray));
      dispatch(dispatchSelectFormation([]));
      dispatch(setRefrechCard());
      setShow(false)
    }
  };

  const deleteForm = () => {
    setShowModal(true)
    setShow(false)
  };
  return (
    <>
      {show && (
        <div
          id="dropdownDivider 22"
          className={`z-10 w-[8%] mt-11 bg-white divide-y  divide-gray-100 shadow rounded-3xl  absolute `}
        >
          <div className="  basis-[15%] flex justify-center items-center "></div>
          <div className="  basis-[70%] flex flex-col  justify-center items-center overflow-auto py-2">
          {!location.pathname.includes("ListCate")? <div className=" cursor-pointer" onClick={duplicate}>
              Dupliquer
            </div> : null}
            <div className=" text-red-400 cursor-pointer" onClick={deleteForm}>
              Supprimer
            </div>
          </div>
        </div>
      )}
    </>
  );
}
