import React from "react";
import Rectangle from "../../images/Rectange.svg";
import Home from "../../images/Home.svg";
import Femme from "../../images/Avatar.svg";

export default function Resultcard({
  avatar,
  username,
  id,
  finalResult,
  onToggleShowComment,
  showComment,
  setCurrentStagiareId,
  elementId,
  onOpenComment,
}) {

  return (
    <div className="w-[90%] h-full max-h-[250px] relative" key={id}>
      {avatar !== "female" ? (
        <img
          src={Home}
          className="h-[50%] absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4"
          alt="avatar"
        />
      ) : (
        <img
          src={Femme}
          className="h-[50%] absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4"
          alt="avatar"
        />
      )}

      <div className="h-[15%]"></div>
      <div className="h-[85%] bg-white rounded-t-2xl flex flex-col gap-3 justify-center items-center">
         <div className="validate-test flex p-3 rounded-lg cursor-pointer" onClick={(e)=> onOpenComment(elementId)}>
          <img src={Rectangle} alt="coment" className="h-4"  />
        </div>
        <span className="font text-lg" >{username}</span>
         <span>{finalResult}</span>
      </div>
    </div>
  );
}
