import React, { useEffect, useState } from "react";
import Categories from "../images/Categories.svg";
import moment from "moment"
import Livre from "../images/livre.svg";
import { useDispatch, useSelector } from "react-redux";
import { dispatchSelectCate } from "../features/categorieFormulaires/categorieFormulaireSlice";
import { dispatchSelectCateFormation } from "../features/categorieFormation/categorieFormationSlice";

export default function CartEspaceLevelCatg({name,date,number, id, type,checkbox,children}) {
  const [check, setCheck] = useState(false);
  const { categorieFormulaires, selectedCate} = useSelector((state) => state.categorieFormulaire);
  const { categorieFormations,selectedCateFormation } = useSelector(
    (state) => state.categorieFormation
  );
  const { refrechCard } = useSelector((state) => state.formulaires);
  const dispatch = useDispatch();
  useEffect(() => {
    setCheck(false);
    return ()=>{
      dispatch(dispatchSelectCate([]));
      dispatch(dispatchSelectCateFormation([]));
    }
  },[categorieFormulaires,refrechCard])
  const handleCheckCateFormulaire = (e, elid) => {
    setCheck(!check);
    let arr = [...selectedCate];
    if (e.target.checked) {
      let obj = [...categorieFormulaires].filter((el) => el.id == elid)[0];
      arr.push(obj);
      dispatch(dispatchSelectCate(arr));
    } else {
      arr = arr.filter((el) => el.id !== elid);
      dispatch(dispatchSelectCate(arr));
    }
  };

  const handleCheckCateFormation = (e, elid) => {
    setCheck(!check);
    let arr = [...selectedCateFormation];
    if (e.target.checked) {
      let obj = [...categorieFormations].filter((el) => el.id == elid)[0];
      arr.push(obj);
      dispatch(dispatchSelectCateFormation(arr));
    } else {
      arr = arr.filter((el) => el.id !== elid);
      dispatch(dispatchSelectCateFormation(arr));
    }
  };
  let now = new Date(date)
  const frenchDate =  now.toLocaleDateString("fr-FR", { 
    day: "numeric", 
    month: "long", 
    year: "numeric",
    localeMatcher: 'best fit',
});
  return (
    <div className="card  rounded-2xl custom-b-sh transition-all   w-60 h-60 flex flex-col gap-2 bg-white p-4">
      <div className="flex items-center justify-end pr-2  h-4">
      {checkbox? 
        <input
          checked={check}
          id="link-checkbox"
          type="checkbox"
          value=""
          onChange={  type === "formulaire"?  (e)=>handleCheckCateFormulaire(e ,id) : (e)=>handleCheckCateFormation(e,id)} 
          className=" accent-green-700 text-green-600 bg-green-700 rounded border-gray-300 focus:ring-green-700 "
          ></input> : false }
      </div>

      {children}

      <div className="card-body flex flex-col justify-center items-center border-b-2 h-[48%] ">
        <img src={Categories} alt="Level" className="h-9 w-1/6" />
        <h1 className="card-title font text-auto whitespace-nowrap truncate" >{name}</h1>
        <p className="font-thin">Créer le {frenchDate}.</p>
      </div>
      <div className="flex justify-center items-center flex-1 ">
        <ul>
          <li className="flex justify-center items-center">
            <div
             
              className="flex items-center text-base font-normal text-black"
            >
              <img src={Livre} className='w-6'/>
              <span className="flex-1 ml-3 whitespace-nowrap">{number} formulaires</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
