import axios from "axios";
import axiosData from "../../configAxios";

const API_URL = `${process.env.REACT_APP_API_BASE_URL}/auth`;

// Register user
const register = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/register`, userData);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || err.response.data?.errors?.email || "Probleme connexion";
    if(message?.includes("fails to match the required pattern")) message = "Cette adresse mail est invalide, veuillez essayer avec une autre adresse";
    throw message;
  }
};

const getMonnaie = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/monnaie/${id}`);
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + "/login", userData);
  return response.data;
};

// Login user
const UpdateProfile = async ({userData,id}) => {
  const response = await axiosData.put(
    API_URL + `/updateProfile/${id}`,
    userData
  );
  return response.data;
};

const getAllOrganisme = async () =>{
  const response = await axiosData.get(
    API_URL + `/`
  );
  return response.data;
}

const sendMonnaie = async (data) =>{
  const response = await axiosData.put(
    API_URL + `/send`,data
  );
  return response.data;
}

// Logout user

const authService = {
  register,
  login,
  UpdateProfile,
  getAllOrganisme,
  getMonnaie,
  sendMonnaie
};

export default authService;
