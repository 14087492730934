import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categorieFormulaireService from "./categorieFormulaireService";

const initialState = {
  categorieFormulaires: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  showModelCate:false,
  toEdit : false,
  selectedCate: [],
};

export const getAllCategorieFormulaires = createAsyncThunk(
  "getAllCategorieFormulaires",
  async (id, thunkAPI) => {
    try {
      return await categorieFormulaireService.getAllCategorie(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const assigneFormulaireToCategorie = createAsyncThunk(
  "assigneFormulaireToCategorie",
  async (formulaire, thunkAPI) => {
    try {
      return await categorieFormulaireService.assigneFormulairesToCategories(formulaire);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteFormulaireCategorie = createAsyncThunk(
  "deleteCategoriFormulaire",
  async (data, thunkAPI) => {
    try {
      
      return await categorieFormulaireService.deleteFormulaireCategorie(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const createCategorieFormulaire = createAsyncThunk(
  "createCategorieFormulaire",
  async (form, thunkAPI) => {
   
    try {
      return await categorieFormulaireService.createCategorie(form);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const categorieFormulaireSlice = createSlice({
  name: "categorieFormulaire",
  initialState,
  reducers: {
    resetcategorieFormulaire: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    setModelCate: (state,action) => {
      state.showModelCate = action.payload.showModelCate;
      state.toEdit = action.payload.toEdit;
    },
    dispatchSelectCate : (state,action) =>{
      state.selectedCate = action.payload
     },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategorieFormulaires.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCategorieFormulaires.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categorieFormulaires = action.payload;
      })
      .addCase(getAllCategorieFormulaires.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(assigneFormulaireToCategorie.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assigneFormulaireToCategorie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(assigneFormulaireToCategorie.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createCategorieFormulaire.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCategorieFormulaire.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(createCategorieFormulaire.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteFormulaireCategorie.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFormulaireCategorie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
        state.categorieFormulaires = action.payload;
      })
      .addCase(deleteFormulaireCategorie.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});

export const { resetcategorieFormulaire,setModelCate,dispatchSelectCate} = categorieFormulaireSlice.actions;
export default categorieFormulaireSlice.reducer;
