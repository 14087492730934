import React from "react";
import handCard from "../../images/handcard.svg";

export default function DetailsCredits() {
  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <span className="credits">CREDIT DE LEVEL</span>
        <span className="bienvenu">BIENVENUE</span>
        <span className="offre">Veuillez choisir une offre</span>
        <div>
          <img src={handCard} />
        </div>
      </div>
    </>
  );
}
