import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ResultsComp from "../../components/StagComponents/ResultsComp";
import Avatar from "../../images/Avatar.svg";
import Home from "../../images/Home.svg";
import { groupArrayByquestion } from "../../helper.js";
import socket from ".././../socket";
import { useNavigate } from "react-router-dom";
import { setConnectedStagaires, setRoom } from "../../features/auth/authSlice";
import Line from "../../images/Line.svg";
import StagiaireWorkflow from "./StagiaireWorkflow";
import {
  setCurrentIndep,
  setCurrentLevel,
  setTerminer,
} from "../../features/stagiares/stagiaireSlice";
import { generatedAndSendResultPdf } from "../../helpers/resultEmailPdf.js";
import QuitConfirmModal from "../vueFormateur/QuitConfirmModal/index.jsx";

export default function QuestionsResults() {
  const { submitetTentative, currentStagiare, currentLevel, currentIndep, terminer, currentReponses, organismeId, currentTentative } =
    useSelector((state) => state.stagiaire);
  const { liveFormation } = useSelector((state) => state.formation);

  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const questions = submitetTentative && submitetTentative?.tentativeResponses;
  // const finalTentitiveResponses = currentReponses && currentReponses?.reduce((acc, current) => {
  //   const x = acc.filter(item => item.responseId === current.responseId && item.questionId === current.questionId);
  //   if (!x.length) {
  //     acc.push(current);
  //   }
  //   return acc;
  // }, []);
  const reversedTentativeResponses = currentReponses ? [...currentReponses].reverse() : [];

  const finalTentitiveResponses = reversedTentativeResponses.reduce((acc, current) => {
    const x = acc.filter(item => item.responseId === current.responseId && item.questionId === current.questionId);
    if (!x.length) {
      acc.push(current);
    }
    return acc;
  }, []);
  const uniqueFinalTentitiveResponses = finalTentitiveResponses?.reverse();
  console.log('here questions', uniqueFinalTentitiveResponses)
  const group = questions ? [...questions]
    : uniqueFinalTentitiveResponses ? uniqueFinalTentitiveResponses
      : [];
  const [score, setScore] = useState({
    resultat: 0,
    total: 0,
  });
  const [etapes, setEtapes] = useState([]);
  const [length, setLength] = useState(0);
  const [total, setTotal] = useState(0);
  const [allQuestions, setAllQuestions] = useState([]);
  const { sessionID, room, roomState, RFAllResults } = useSelector((state) => state.auth);
  const isRithmeStagiaire = roomState?.rithme === 'Rythme de stagiaire';
  const pdfRef = useRef(null);
  useEffect(() => {
    let allEtapes = liveFormation?.etapes ? JSON.parse(liveFormation?.etapes) : [];
    setEtapes(allEtapes);

    // send results pdf functionality
    setTimeout(() => {
      // intern flow
      let internFormulaireId = roomState?.currentForm?.id;
      if (currentStagiare?.id && internFormulaireId) {
        generatedAndSendResultPdf(pdfRef, currentStagiare?.id, internFormulaireId);
      }
    }, 1000);
  }, [liveFormation]);
  const reelEtapes = etapes.reduce((acc, element) => {
    if (
      element?.condition?.includes("/") ||
      element?.condition == "Lié sans condition"
    ) {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);

  const indeEtape = etapes.reduce((acc, element) => {
    if (
      element?.condition == "fichier indépendant"
    ) {
      return acc + 1;
    } else {
      return acc;
    }
  }, 0);
  const reponses = Object.values(groupArrayByquestion(group, "questionId"));
  const calculateScore = () => {
    let stagaireResponses = reponses;
    const currentFormulaire = roomState?.currentForm;
    if (!currentFormulaire?.questions?.length) return 0;
    let studentScore = stagaireResponses.reduce((acc, item, currentIndex) => {
      if (item?.length && currentFormulaire?.questions[currentIndex]) {

        const QuestionScore = item[0].points;
        const QuestionCorrectAnswersCount = currentFormulaire?.questions?.[currentIndex]?.reponses.filter(r => r.status === true).length;
        const AnswerWeight = QuestionScore / QuestionCorrectAnswersCount;
        const correctAnswerWeight = AnswerWeight * 1;
        const wrongAnswerWeight = AnswerWeight * -1;
        let currentQuestionScore = 0;
        item.forEach(element => {
          if (element.status === true) {
            currentQuestionScore += correctAnswerWeight;
          } else {
            currentQuestionScore += wrongAnswerWeight;
          }
        });

        if (currentQuestionScore < 0) currentQuestionScore = 0;
        acc += currentQuestionScore;

        return acc < 0 ? 0 : acc;
      } else {
        return acc;
      }
    }, 0);
    console.log("student score", studentScore)
    studentScore = studentScore < 0 ? 0 : studentScore.toFixed(2);
    return studentScore;
  };

  const calculateTotal = () => {
    let total = 0;
    let calcullength = 0;
    roomState?.currentForm?.questions?.map((quest) => {
      if (quest.Type !== "TexteLibre") {
        total += quest.points;
        calcullength += 1;
      }
    });
    return total;
  };
  const isEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b);

  useEffect(() => {
    if (roomState?.currentForm?.questions) {
      let arr = [...roomState.currentForm.questions];
      let questionsArray = arr.filter((el) => el.Type !== "TexteLibre");

      if (!isEqual(questionsArray, allQuestions)) {
        setAllQuestions(questionsArray);
      }

    }
    // let arr = [...roomState?.currentForm?.questions];
    // let questionsArray = arr.filter((el) => el.Type !== "TexteLibre");
    // setAllQuestions(questionsArray);
  }, [roomState]);

  useEffect(() => {
    let totalPoint = 0;
    let calcullength = 0;
    allQuestions?.map((quest) => {
      totalPoint += quest.points;
      calcullength += 1;
    });
    setTotal(totalPoint);
    setLength(calcullength);
    // socket.on("comment",(data)=>{
    //   const { stagiaireId, content } = data;
    //   if(stagiaireId && content){
    //     setMessage(content)
    //   }
    // })
    // return () => {
    //   socket.close("finalResult");
    // };
  }, [allQuestions]);

  const nextFormulaire = () => {
    let condition = roomState?.currentForm?.condition;
    if (condition?.includes("/")) {
      let number = condition.split("/")[0];
      let lasScore = calculateScore();
      if (Number(number) <= lasScore) {
        dispatch(setCurrentLevel(currentLevel + 1));
      }
    }
    if (condition == "Lié sans condition") {
      dispatch(setCurrentLevel(currentLevel + 1));
    } else if (condition == "fichier indépendant") {
      dispatch(setCurrentIndep(currentIndep + 1));
    }
    navigate("/stagiaireWorkflow");
  };

  // const resetFormulaire = () => {
  //   dispatch(setCurrentLevel(1));
  // };

  useEffect(() => {
    let authDATA = {
      username: currentStagiare.Prenom,
      sessionID: sessionID,
      room: room,
      userID: currentStagiare.id,
      role: "stagiaire",
      Genre: currentStagiare.Genre,
      roomState: roomState,
    };
    socket.auth = authDATA;
    socket.connect();

    socket.emit("join_room", authDATA);

    socket.emit("currentScreen", {
      idStagiaire: currentStagiare.id,
      index: `${calculateScore()}/${calculateTotal()}`,
      form: roomState?.currentForm,
    });

    // if (calculateTotal() > 0) {
    //   socket.emit("finalResult", {
    //     resultat: calculateScore(),
    //     total: calculateTotal(),
    //   });
    // }
  }, [score]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 815);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 815);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const hideScore = roomState?.currentForm?.questions?.every(q => q?.Type === "TexteLibre" || q?.Type === "Niveaux" || q?.Type === "ResponseOuvertes");
  const [showConfirmEnd, setShowConfirmEnd] = useState(false);
  const endTraining = () => {
    navigate(`/logout`)
  }

  return (
    <>
      {isMobile ? (
        <>
          <div
            className="h-[90%] bg-slate-100"
            style={{
              maxWidth: 800,
              width: "auto",
              display: "bloc",
              justifyContent: "center",
              // alignItems: "center",
              margin: 10,
            }}
            ref={pdfRef}
          >
            {currentStagiare.Genre !== "male" ? (
              <div
                style={{
                  background: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minWidth: "360px",
                  maxWidth: "790px",
                  width: "96%",
                  height: 50,
                  borderRadius: "10px",
                  margin: 10,
                }}
              >
                <img
                  src={currentStagiare.Genre == "female" ? Avatar : Home}
                  style={{ height: 50, width: 50 }}
                  className=""
                  alt="avatar"
                />
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 30,
                    padding: "0px 10px",
                    width: "96%",
                  }}
                >
                  <span
                    className="font text-md capitalize"
                    style={{ color: "#288911" }}
                  >
                    {currentStagiare.Prenom}
                  </span>
                  <img
                    src={Line}
                    alt="Level"
                    style={{ height: 30, width: 2 }}
                  />
                  {!hideScore && <span className="text-lg font-semibold">{`${calculateScore()}/${calculateTotal()}`}</span>}
                  <div>
                    {message || ""}
                  </div>
                </div>

                {isRithmeStagiaire &&
                  <>
                    {(currentLevel === reelEtapes || reelEtapes == 0) && (currentIndep === indeEtape || indeEtape == 0) ? (
                      <div className="flex gap-2 items-baseline">
                        <div
                          className="button-color flex justify-center rounded-lg items-center mx-2 py-2 cursor-pointer"
                          onClick={() => {
                            nextFormulaire();
                            dispatch(setTerminer(true));
                          }}
                        >
                          <span className=" text-white text-lg">Quitter</span>
                        </div>
                        <button id="danger" className="rounded-full border-2 p-[10px] w-auto inline-block mr-8" onClick={() => setShowConfirmEnd(true)}>Quitter la formation</button>
                      </div>
                    ) : (
                      <div
                        className="button-color flex justify-center rounded-lg items-center mx-2 py-2 cursor-pointer px-2"
                        onClick={nextFormulaire}
                        style={{ width: 60, height: 40 }}
                      >
                        <span className=" text-white text-lg ">Quitter</span>
                      </div>
                    )}
                  </>
                }
              </div>
            ) : (
              <div
                style={{
                  background: "white",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minWidth: "360px",
                  maxWidth: "790px",
                  width: "96%",
                  height: 50,
                  borderRadius: "10px",
                  margin: 10,
                }}
              >
                <img
                  src={Home}
                  style={{ height: 50, width: 50 }}
                  className=""
                  alt="avatar"
                />
                <div
                  className=""
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 15,
                    padding: "0px 10px",
                    width: "100%",
                  }}
                >
                  <span
                    className="font text-sm capitalize"
                    style={{ color: "#288911" }}
                  >
                    {currentStagiare.Prenom}
                  </span>
                  <img
                    src={Line}
                    alt="Level"
                    style={{ height: 30, width: 2 }}
                  />
                  {!hideScore && <span className="text-sm">{`${calculateScore()}/${calculateTotal()}`}</span>}
                  <div>
                    {message || ""}
                  </div>
                </div>
                {isRithmeStagiaire && <>
                  {(currentLevel === reelEtapes || reelEtapes == 0) && (currentIndep === indeEtape || indeEtape == 0) ? (
                    <div className="flex gap-2 items-baseline">
                      <div
                        className="button-color flex justify-center rounded-lg items-center mx-2 py-2 cursor-pointer px-2"
                        onClick={() => {
                          nextFormulaire();
                          dispatch(setTerminer(true));
                        }}
                      >
                        <span className=" text-white text-lg">Quitter</span>
                      </div>
                      <button id="danger" className="rounded-full border-2 p-[10px] w-auto inline-block mr-8" onClick={() => setShowConfirmEnd(true)}>Quitter la formation</button>
                    </div>
                  ) : (
                    <div
                      className="button-color flex justify-center rounded-lg items-center mx-2 py-2 cursor-pointer px-2"
                      cd
                      style={{ width: 60, height: 40 }}
                      onClick={nextFormulaire}
                    >
                      <span className="text-white text-sm">Quitter</span>
                    </div>
                  )}
                </>}
              </div>
            )}
            <div
              style={{
                background: "white",
                display: "table",
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: "360px",
                maxWidth: "790px",
                width: "96%",
                height: "86%",
                borderRadius: "10px",
                margin: 10,
              }}
            >
              {allQuestions?.map((quest, ind) => {
                let studentScorePerQuestion = 0;
                const currentFormulaire = roomState?.currentForm;
                let filtered = reponses.filter((element) => {
                  return element[0].questionId === quest.id;
                });
                studentScorePerQuestion = filtered.reduce((acc, item, currentIndex) => {
                  if (item?.length && currentFormulaire?.questions?.[ind]) {

                    const QuestionScore = item[0].points;
                    const QuestionCorrectAnswersCount = currentFormulaire?.questions?.[ind]?.reponses.filter(r => r.status === true).length;
                    const AnswerWeight = QuestionScore / QuestionCorrectAnswersCount;
                    const correctAnswerWeight = AnswerWeight * 1;
                    const wrongAnswerWeight = AnswerWeight * -1;

                    item.forEach(element => {
                      if (element.status === true) {
                        acc += correctAnswerWeight;
                      } else {
                        acc += wrongAnswerWeight;
                      }
                    });
                    return acc < 0 ? 0 : acc;
                  } else {
                    return acc;
                  }
                }, 0);
                studentScorePerQuestion = studentScorePerQuestion < 0 ? 0 : studentScorePerQuestion.toFixed(2);
                console.log("filtered", filtered);
                console.log("student score per question", studentScorePerQuestion);
                console.log("questions ", currentFormulaire?.questions)

                if (filtered.length == 0 && quest.Type !== "TexteLibre") {
                  return (
                    <Fragment key={ind + "nrml"}>
                      <ResultsComp
                        length={length}
                        quest={{ status: false, points: studentScorePerQuestion }}
                        index={ind}
                        shouldHideScore={hideScore}
                      />
                    </Fragment>
                  );
                } else if (quest.Type === "Niveaux") {
                  let final = filtered[0]?.reduce(
                    (acc, element) => {
                      acc.status = Number(studentScorePerQuestion) !== 0;
                      acc.points = studentScorePerQuestion;

                      return acc;
                    },
                    { status: true }
                  );

                  return (
                    <Fragment key={ind + "nveau"}>
                      <ResultsComp
                        length={allQuestions?.length}
                        quest={final}
                        index={ind}
                        niveaux
                        shouldHideScore={hideScore}
                      />
                    </Fragment>
                  );
                } else if (quest.Type === "VraiFaux") {
                  let final = filtered[0]?.reduce(
                    (acc, element) => {
                      acc.status = Number(studentScorePerQuestion) !== 0;
                      acc.points = studentScorePerQuestion
                      return acc;
                    },
                    { status: true }
                  );

                  return (
                    <Fragment key={ind + "vraifraux"}>
                      <ResultsComp
                        length={allQuestions?.length}
                        quest={final}
                        index={ind}
                        shouldHideScore={hideScore}
                      />
                    </Fragment>
                  );
                } else if (quest.Type !== "TexteLibre") {
                  let final = filtered[0]?.reduce(
                    (acc, element) => {
                      acc.status = Number(studentScorePerQuestion) !== 0;
                      acc.points = studentScorePerQuestion;

                      return acc;
                    },
                    { status: true }
                  );

                  return (
                    <Fragment key={ind + "else"}>
                      <ResultsComp
                        length={allQuestions?.length}
                        quest={final}
                        index={ind}
                        shouldHideScore={hideScore}
                      />
                    </Fragment>
                  );
                }
              })}
            </div>
          </div>
        </>
      ) : (
        <div className="h-[90%] bg-slate-100 flex flex-col p-[3%] gap-9" ref={pdfRef}>
          <span className="text-3xl font-semibold">MES RÉSULTATS</span>
          {show ? <StagiaireWorkflow show={show} /> : null}
          <div className="flex gap-3 h-full ">
            <div className="w-[60%] h-full rounded-xl">
              <div className="grid grid-cols-3 h-[8%]">
                <span className="text-lg flex justify-center items-center">
                  Question
                </span>
                <span className="text-lg flex justify-center items-center">
                  Réponse
                </span>
                <span className="text-lg flex justify-center items-center">
                  Score
                </span>
              </div>
              <div className="h-[92%]  bg-white rounded-2xl overflow-auto">
                {allQuestions?.map((quest, ind) => {
                  let studentScorePerQuestion = 0;
                  const currentFormulaire = roomState?.currentForm;
                  let filtered = reponses.filter((element) => {
                    return element[0].questionId === quest.id;
                  });
                  studentScorePerQuestion = filtered.reduce((acc, item, currentIndex) => {
                    if (item?.length && currentFormulaire?.questions?.[ind]) {

                      const QuestionScore = item[0].points;
                      const QuestionCorrectAnswersCount = currentFormulaire?.questions?.[ind]?.reponses.filter(r => r.status === true).length;
                      const AnswerWeight = QuestionScore / QuestionCorrectAnswersCount;
                      const correctAnswerWeight = AnswerWeight * 1;
                      const wrongAnswerWeight = AnswerWeight * -1;

                      item.forEach(element => {
                        if (element.status === true) {
                          acc += correctAnswerWeight;
                        } else {
                          acc += wrongAnswerWeight;
                        }
                      });
                      return acc < 0 ? 0 : acc;
                    } else {
                      return acc;
                    }
                  }, 0);
                  studentScorePerQuestion = studentScorePerQuestion < 0 ? 0 : studentScorePerQuestion.toFixed(2);
                  console.log("filtered", filtered);
                  console.log("student score per question", studentScorePerQuestion);

                  if (filtered.length == 0 && quest.Type !== "TexteLibre") {
                    return (
                      <Fragment key={ind}>
                        <ResultsComp
                          length={length}
                          quest={{ status: false, points: studentScorePerQuestion }}
                          index={ind}
                          shouldHideScore={hideScore}
                        />
                      </Fragment>
                    );
                  } else if (quest.Type === "Niveaux") {
                    let final = filtered[0]?.reduce(
                      (acc, element) => {
                        acc.status = Number(studentScorePerQuestion) !== 0;
                        acc.points = studentScorePerQuestion;
                        return acc;
                      },
                      { status: true }
                    );

                    return (
                      <ResultsComp
                        length={allQuestions?.length}
                        quest={final}
                        index={ind}
                        niveaux
                        shouldHideScore={hideScore}
                      />
                    );
                  } else if (quest.Type === "VraiFaux") {

                    let final = filtered[0]?.reduce(
                      (acc, element) => {
                        acc.status = Number(studentScorePerQuestion) !== 0;
                        acc.points = studentScorePerQuestion;
                        return acc;
                      },
                      { status: true }
                    );

                    return (
                      <ResultsComp
                        length={allQuestions?.length}
                        quest={final}
                        index={ind}
                        shouldHideScore={hideScore}
                      />
                    );
                  } else if (quest.Type !== "TexteLibre") {

                    let final = filtered[0]?.reduce(
                      (acc, element) => {
                        acc.status = Number(studentScorePerQuestion) !== 0;
                        acc.points = studentScorePerQuestion;
                        return acc;
                      },
                      { status: true }
                    );
                    return (
                      <ResultsComp length={length} quest={final} index={ind} shouldHideScore={hideScore} />
                    );
                  }
                })}
              </div>
            </div>
            <div className="w-[40%] h-full relative flex flex-col ">
              {currentStagiare.Genre !== "male" ? (
                <img
                  src={Avatar}
                  className="h-[25%] absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4"
                  alt="avatar"
                />
              ) : (
                <img
                  src={Home}
                  className="h-[25%] absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4"
                  alt="avatar"
                />
              )}
              <div className="h-[8%]"></div>
              <div className="h-[65%] bg-white rounded-2xl flex flex-col justify-start items-center p-2 pt-7 gap-8">
                <span className="font text-2xl pt-5 capitalize">
                  {currentStagiare.Prenom}
                </span>
                {!hideScore && <span className="text-xl font-semibold">{`${calculateScore()}/${total}`}</span>}
              </div>
              {isRithmeStagiaire && <>{(currentLevel === reelEtapes || reelEtapes == 0) && (currentIndep === indeEtape || indeEtape == 0) ? (
                <div className="flex gap-2 items-baseline">
                  <div
                    className="button-color flex justify-center rounded-lg items-center mt-5 px-10 py-2 cursor-pointer  "
                    onClick={() => {
                      nextFormulaire();
                      dispatch(setTerminer(true));
                    }}
                  >
                    <span className=" text-white text-lg">Quitter</span>
                  </div>
                  <button id="danger" className="rounded-full border-2 p-[10px] w-auto inline-block mr-8" onClick={() => setShowConfirmEnd(true)}>Quitter la formation</button>
                </div>
              ) : (
                <div
                  className="button-color flex justify-center rounded-lg items-center mt-5 px-10 py-2 cursor-pointer  "
                  onClick={() => nextFormulaire()}
                >
                  <span className=" text-white text-lg">
                    Quitter
                  </span>
                </div>
              )}</>}
              {/* <div
                className="button-color flex justify-center rounded-lg items-center mt-5 px-10 py-2 cursor-pointer  "
                onClick={() => resetFormulaire()}
                >
                <span className=" text-white text-lg">reset level</span>
              </div> */}
            </div>
          </div>
        </div>
      )}
      { showConfirmEnd && <QuitConfirmModal onOk={endTraining} setShow={setShowConfirmEnd} isVueFormateur={false}/>}
    </>

  );
}

