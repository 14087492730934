import React, { useEffect, useState } from "react";
import Select from "../components/Select";
import categorie from "../images/Categories.svg";
import Button from "./Button";
import { useDispatch, useSelector } from "react-redux";
import { assigneFormationToCategorie, getAllCategorieFormation, setModelCateFormation } from "../features/categorieFormation/categorieFormationSlice";
import DropDownFormation from "./DropDownFormation";
import DropDownCategorieFormation from "./DropDownCategorieFormation";
import { message } from "antd";


export default function ModalCategori({ showModal, setShowModal }) {
  const dispatch = useDispatch()
  const [showCategorie, setShowCategorie] = useState(false);
  const [showFormation, setShowFormation] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedListeFormation, setSelectedListeFormation] = useState([]);
  const [selectedListeCategorie, setSelectedListeCategorie] = useState([]);
  const {  categorieFormations,toEditCateFormation} = useSelector(
    (state) => state.categorieFormation
  )
  const { user } = useSelector((state) => state.auth);

  const  addFormToCate = async () => {
    await dispatch(assigneFormationToCategorie({
      "formation" : selectedListeFormation,
      "categorie" : selectedListeCategorie
    }))
    dispatch(setModelCateFormation({ showModelCateFormation: false, toEdit: false}))
  }

  const  editCate = async () => {
    messageApi.open({
      type: 'loading',
      content: 'Mise à jour en cours...',
      duration: 0,
    });

    const a =  await dispatch(assigneFormationToCategorie({
      "formation" : selectedListeFormation,
      "categorie" : selectedListeCategorie,
      edit: true
    }))
    .then((res)=>{
      if(res.meta.requestStatus === "fulfilled")
      {
        dispatch(getAllCategorieFormation(user?._id))
        messageApi.destroy()
        message.success("Catégorie modifiée avec succès");
        dispatch(setModelCateFormation({ showModelCateFormation: false, toEdit: false}))
      }
      else {
        messageApi.destroy()
        message.error("Erreur lors de la modification de la catégorie");
      }
    } );
    
  }

  useEffect(()=>{
  if(toEditCateFormation){
    let arr = categorieFormations.filter((element) => element.id == toEditCateFormation)
    let forms = []
    setSelectedListeCategorie(arr)
    arr[0].joCatFormations.map(element=>{
      forms.push(element.formation) 
    })
    setSelectedListeFormation(forms)
  }else{
    setSelectedListeCategorie([])
    setSelectedListeFormation([])
  }
  },[toEditCateFormation])

  return (
    <>
      {showModal && (
        <>
         {contextHolder}
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-900/90">
            {/*content*/}
            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col justify-center gap-6 bg-white opacity-100 outline-none focus:outline-none pt-9 px-16 ">
              {/*body*/}
              <div className="relative flex flex-col items-center">
                <img src={categorie} alt="cate" className=" h-8" />
                <p className=" reset text-3xl">
                  Ajouter un formation à une catégorie
                </p>
                <p className="etape-suivante text-md m-auto">
                  Veuillez choisir le nom du formation et de la catégorie
                </p>
              </div>
              <div className="flex flex-col	gap-4">
              <div className="" >

                  <Select
                    setShow={setShowFormation}
                    show={showFormation}
                    text={selectedListeFormation.length > 0? selectedListeFormation.map(el => el.titre).join() : "Formations" }
                  />
                  <DropDownFormation showFormation={showFormation} selectedListeFormation={selectedListeFormation} setSelectedListeFormation={setSelectedListeFormation} />
                </div>
                {toEditCateFormation?(
                  <div className=" text-center border-2 rounded-3xl py-2" >
                    {selectedListeCategorie[0]
                      ? selectedListeCategorie[0].titre
                      : ""}
                  </div>
                ) : <div className="" >
                  <Select
                    setShow={setShowCategorie}
                    show={showCategorie}
                    text={selectedListeCategorie.length > 0 ? selectedListeCategorie.map(el=> el.titre).join() : "Catégorie LEVEL"}
                  />
                  <DropDownCategorieFormation showCategorie={showCategorie} selectedListeCategorie={selectedListeCategorie} setSelectedListeCategorie={setSelectedListeCategorie} />
                </div>}
              </div>

              {/*footer*/}
              <div className="flex items-center justify-center gap-2 border-t border-solid border-slate-200 rounded-b py-2">
                <button
                  type="submit"
                  className={`group relative  flex justify-center w-[40%]  py-6 px-4 border  border-lime-600  text-sm font-medium    hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-10  items-center  gap-2 `}
                  id="button"
                  onClick={() => dispatch(setModelCateFormation({ showModelCateFormation: false, toEdit: false}))}
                >Annuler</button>

                <Button
                  text={"Ajouter"}
                  classname={"w-[40%]"}
                  func={toEditCateFormation? editCate : addFormToCate}
                />
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}



