import React from "react";
import { useEffect } from "react";
import check from "../images/checkB.svg";
import LinePack from "../images/LinePack.svg";

export default function ({
  selected,
  setSelected,
  number,
  title,
  levels,
  setlevels,
  icon,
}) {
  useEffect(() => {
    if (selected === 1) {
      setlevels("50 euros = 400 LEVELS");
    } else if (selected === 2) {
      setlevels("250 euros = 2500 LEVELS");
    } else if (selected === 3) {
      setlevels("500 euros = 6000 LEVELS");
    }
  }, []);
  return (
    <div
      className={
        selected === number
          ? "bg-white rounded-lg w-[15%]  hover:bg-gray-200 border-2 cursor-pointer card-payement"
          : "bg-white rounded-lg w-[15%]  hover:bg-gray-200 border-2 cursor-pointer "
      }
      onClick={() => {
        setSelected(number);
      }}
    >
      <div className="flex flex-col justify-between items-center m-2">
        <img src={icon} className="m-2" />

        <span>{title}</span>
        <img src={LinePack} />
        <span>{levels}</span>
      </div>
    </div>
  );
}
