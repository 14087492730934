import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAll,
  sendMonnaie,
  setAdminPay,
  setImpayer,
} from "../features/auth/authSlice";
import SelectCredit from "../layout/credits/SelectCredit";
import OrganismeSelect from "./OrganismeSelect";
import Titre from "../images/TitreAdminPayment.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
export default function PaymentAdminModel() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getAll());
  }, []);
  const [org, setOrg] = useState(false);
  const [text, setText] = useState(400);
  let bg = org ? "button-color" : "bg-gray-300";
  const send = async () => {
    const sended = await dispatch(
      sendMonnaie({
        points: text,
        organisme: org,
      })
    );
    sended.type === "sendMonaie/fulfilled" ? toast.success(`Votre demande de crédit a été traitée avec succès`) : toast.error("Vos crédits n'ont pas encore été reçus")
    setOrg(false)
  };

  return (
    <div
      class="flex items-center justify-center bg-black/90 z-50 text-sm w-screen h-screen fixed font-bold px-4 py-3 "
      role="alert"
    >
      <ToastContainer autoClose={3000} />
      <div className="w-[40%] h-[60%] md:w-[90%] bg-white flex justify-center rounded-xl items-center  gap-6 flex-col">
        <img src={Titre} alt="titre" className="h-20" />
        <OrganismeSelect setOrg={setOrg} />
        {/* <SelectCredit text={text}  /> */}
        <select
          className="block  mt-1 rounded-full shadow-sm border-gray-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 w-[50%] outline-none p-3 border-2 text-gray-500"
          onChange={(e) => setText(e.target.value)}
        >
          <option value={400}>400 LEVELS</option>
          <option value={2500}>2500 LEVELS</option>
          <option value={6000}>6000 LEVELS</option>
        </select>
        {isLoading ? (
          <div class=" flex justify-center items-center">
            <div class="animate-spin rounded-full h-24 w-24 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <div className=" w-full flex justify-center gap-2">
            <button
              className={`${bg} py-2 px-5 w-[30%] rounded-xl text-white`}
              onClick={org?send:null}
            >
              Ajouter
            </button>
            <button
              className={` py-2 px-5 w-[30%] rounded-xl text-black border-gray-300 border-2 hover-form`}
              onClick={() => dispatch(setAdminPay(false))}
            >
              Annuler
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
