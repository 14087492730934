import { groupArrayByquestion } from "../../../helper";

export const calculateScore = (formulaire, tentativeResponses) => {
    let stagaireResponses = Object.values(groupArrayByquestion(tentativeResponses, "questionId"));
    const currentFormulaire = formulaire;
    if (!currentFormulaire?.questions?.length) return 0;
    let studentScore = stagaireResponses.reduce((acc, item, currentIndex) => {
        if (item?.length && currentFormulaire?.questions[currentIndex]) {

            const QuestionScore = item[0].points;
            const QuestionCorrectAnswersCount = currentFormulaire?.questions?.[currentIndex]?.reponses.filter(r => r.status === true).length;
            const AnswerWeight = QuestionScore / QuestionCorrectAnswersCount;
            const correctAnswerWeight = AnswerWeight * 1;
            const wrongAnswerWeight = AnswerWeight * -1;
            let currentQuestionScore = 0;
            item.forEach(element => {
                if (element.status === true) {
                    currentQuestionScore += correctAnswerWeight;
                } else {
                    currentQuestionScore += wrongAnswerWeight;
                }
            });

            if (currentQuestionScore < 0) currentQuestionScore = 0;
            acc += currentQuestionScore;

            return acc < 0 ? 0 : acc;
        } else {
            return acc;
        }
    }, 0);
    studentScore = studentScore < 0 ? 0 : studentScore.toFixed(2);
    return studentScore;
};

export const calculateStagiaireScoreInPreviousLevel = (formation, stagiaire, previousFormulaire) => {
    if (!formation || !stagiaire || !previousFormulaire) {
        return;
    };
    let currentStagiaireId = stagiaire.id;
    let currentFormulaireId = previousFormulaire.id
    let totalScore = 0;
    let previousFormulaireTentativeResponses = formation?.stagiaires?.find((el) => el.id === currentStagiaireId)?.tentatives?.find((el) => el.formulaireId === currentFormulaireId)?.tentativeResponses;
    if (!previousFormulaireTentativeResponses || previousFormulaireTentativeResponses?.length === 0) return 0;
    totalScore = calculateScore(previousFormulaire, previousFormulaireTentativeResponses);
    return totalScore;
};

export const isUserAlreadyAnswered = (formation, stagiaire, formulaireId) => {
    if (!formation || !stagiaire || !formulaireId) return false;
    let currentStagiaireId = stagiaire.id;
    let currentFormulaireId = formulaireId
    let previousFormulaireTentativeResponses = formation?.stagiaires?.find((el) => el.id == currentStagiaireId)?.tentatives?.find((el) => el.formulaireId == currentFormulaireId)?.tentativeResponses;
    return previousFormulaireTentativeResponses?.length > 0;
};

export const shouldBeClosedChecker = (index, previousItem, liveFormation, currentStagiare) => {
    // Note: if a formulaire is added twice in the workflow then the second will be opened also .
    // if the student is already passed the level then the response will be saved in the db and when he opens the form in the second time then
    // the form will be opened for him.
    const x = calculateStagiaireScoreInPreviousLevel(liveFormation, currentStagiare, previousItem);
    const stagiaireScoreInPreviousLevel = x;
    const isNotFirstItem = index > 0;
    const hasCondition = previousItem?.condition?.includes("/");
    const isConditionNotMet = stagiaireScoreInPreviousLevel < Number(previousItem?.condition?.split("/")[0]);
    return isNotFirstItem && hasCondition && isConditionNotMet;
    ;
  }