import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import programService from "./programService";

const initialState = {
    formulaire : {},
    type : "",
    userId : null, 
    formationId: null,
    reponses: {},
    isLoading : false,
    isError : false,
    isSuccess: false,
}

export const getProgram = createAsyncThunk(
    "getProgram",
    async (id, thunkAPI) => {
      try {
        return await programService.getProgram(id);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const createTent = createAsyncThunk(
    "createTent",
    async (data, thunkAPI) => {
      try {
        return await programService.createTent(data);
      } catch (error) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );

  export const ProgramSlice = createSlice({
    name: 'Program',
    initialState,
    reducers: {
      setReponses: (state,action) => {
        state.reponses = action.payload
      },
      resetProgram: (state) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.formulaire =  {}
        state.type = ""
        state.userId = null
        state.formationId = null
        state.reponses = {}
        state.isLoading  =  false
      }
    },
    extraReducers:(builder) => {
        builder
        .addCase(getProgram.pending, (state) => {
            state.isLoading = true;
          })
          .addCase(getProgram.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.formulaire =  action.payload.formulaire 
            state.type = action.payload.type
            state.userId = action.payload.userId
            state.formationId = action.payload.formationId
          })
          .addCase(getProgram.rejected, (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
          })
    },
})

export const { setReponses, resetProgram} = ProgramSlice.actions

export default ProgramSlice.reducer
