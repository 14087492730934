import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

export default function OrganismeSelect({setOrg}) {
  // note: the id field is mandatory
  const {allOrganisme} = useSelector((state) => state.auth )
  const [organisme,setOrganisme] = useState(allOrganisme)
 
  const handleOnSearch = (string, results) => {
    // onSearch will have as the first callback parameter
    // the string searched and for the second the results.
    let items = allOrganisme.filter(el=>{
      return el.email?.toLowerCase()?.includes(string?.toLowerCase())
    })
    setOrganisme(items)
  }

  const handleOnSelect = (item) => {
    setOrg(item)
  }

  const onClear = ()=>{
    setOrg(false)
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}> {item.email}</span>
      </>
    )
  }

  return (
   
        <div className='w-[50%] ' >
          <ReactSearchAutocomplete
            items={organisme}
            onSearch={handleOnSearch}
            resultStringKeyName="email"
            onSelect={handleOnSelect}
            onClear={onClear}
            autoFocus
            formatResult={formatResult}
            placeholder={"Organisme"}
          />
        </div>
  )
}

