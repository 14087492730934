export function extractTimeSlots(timeRange) {
    // Regular expression pattern to extract hours and minutes
    const pattern = /De (\d{1,2})h(\d{2}) à (\d{1,2})h(\d{2})/;

    // Extract the hours and minutes using regex match
    const [, startHour, startMinute, endHour, endMinute] =
        timeRange.match(pattern);

    // Create the time object
    const timeObj = {
        start: { h: parseInt(startHour, 10), m: parseInt(startMinute, 10) },
        end: { h: parseInt(endHour, 10), m: parseInt(endMinute, 10) },
    };

    return timeObj;
}

export function extractTimeRanges(timeRange) {
    const timeRangesObj = {};

    // Regular expression pattern to match each time slot
    const pattern = /De (\d{1,2}h\d{2}) à (\d{1,2}h\d{2})/g;

    // Match the time ranges
    const matches = [...timeRange.matchAll(pattern)];

    // Assign the time ranges to the properties
    if (matches.length >= 1) {
        timeRangesObj.morning = matches[0][0];
    }

    if (matches.length >= 2) {
        timeRangesObj.evening = matches[1][0];
    }

    return timeRangesObj;
}

export function createEventsArray(formations) {
    const events = [];

    formations.forEach((formation) => {
      const startDate = new Date(formation.start);
      const endDate = new Date(formation.end);

      const currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const morningStart = new Date(currentDate);
        morningStart.setHours(formation.morning.start.h);
        morningStart.setMinutes(formation.morning.start.m);

        const morningEnd = new Date(currentDate);
        morningEnd.setHours(formation.morning.end.h);
        morningEnd.setMinutes(formation.morning.end.m);

        const eveningStart = new Date(currentDate);
        eveningStart.setHours(formation.evening.start.h);
        eveningStart.setMinutes(formation.evening.start.m);

        const eveningEnd = new Date(currentDate);
        eveningEnd.setHours(formation.evening.end.h);
        eveningEnd.setMinutes(formation.evening.end.m);

        if (
          morningStart.getTime() === morningEnd.getTime() &&
          eveningStart.getTime() === eveningEnd.getTime()
        ) {
          // If both morning and evening have the same start and end times, set allDay to true
          const event = {
            start: morningStart.toISOString(),
            end: morningEnd.toISOString(),
            title: formation.title,
            allDay: true,
            id: formation.id,
            backgroundColor: formation.backgroundColor,
            extendedProps: {
              is:
                formation.extendedProps.is === "formation"
                  ? "formation"
                  : "event",
            },
          };
          events.push(event);
        }

        if (!(morningStart.getTime() === morningEnd.getTime())) {
          const event = {
            start: morningStart.toISOString(),
            end: morningEnd.toISOString(),
            title: formation.title,
            allDay: formation.allDay,
            id: formation.id,
            backgroundColor: formation.backgroundColor,
            extendedProps: {
              is:
                formation.extendedProps.is === "formation"
                  ? "formation"
                  : "event",
            },
          };
          events.push(event);
        }

        if (!(eveningStart.getTime() === eveningEnd.getTime())) {
          const eveningEvent = {
            start: eveningStart.toISOString(),
            end: eveningEnd.toISOString(),
            title: formation.title,
            allDay: formation.allDay,
            id: formation.id,
            backgroundColor: formation.backgroundColor,
            extendedProps: {
              is:
                formation.extendedProps.is === "formation"
                  ? "formation"
                  : "event",
            },
          };
          events.push(eveningEvent);
        }

        currentDate.setDate(currentDate.getDate() + 1);
      }
    });
    return events;
  }