import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import categorieFormationService from "./categoriFormationService";

const initialState = {
  categorieFormations: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  showModelCateFormation: false,
  toEditCateFormation : false,
  selectedCateFormation : []
};

export const getAllCategorieFormation = createAsyncThunk(
  "getAllCategorieFormation",
  async (id, thunkAPI) => {
    try {
      return await categorieFormationService.getAllCategorie(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteFormationCategorie = createAsyncThunk(
  "deleteCategoriFormation",
  async (data, thunkAPI) => {
    try {
      
      return await categorieFormationService.deleteFormulationCategorie(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);


export const createCategorieFormation = createAsyncThunk(
  "createCategorieFormation",
  async (form, thunkAPI) => {
    
    try {
      return await categorieFormationService.createCategorie(form);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const assigneFormationToCategorie = createAsyncThunk(
  "assigneFormationToCategorie",
  async (formulaire, thunkAPI) => {
    try {
      return await categorieFormationService.assigneFormationToCategories(
        formulaire
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const categorieFormationSlice = createSlice({
  name: "categorieFormation",
  initialState,
  reducers: {
    resetcategorieFormation: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
    setModelCateFormation: (state,action) => {
      state.showModelCateFormation = action.payload.showModelCateFormation;
      state.toEditCateFormation = action.payload.toEditCateFormation;
    },
    dispatchSelectCateFormation : (state,action) =>{
      state.selectedCateFormation = action.payload
     }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategorieFormation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCategorieFormation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.categorieFormations = action.payload;
      })
      .addCase(getAllCategorieFormation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(assigneFormationToCategorie.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assigneFormationToCategorie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(assigneFormationToCategorie.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(createCategorieFormation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createCategorieFormation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(createCategorieFormation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteFormationCategorie.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFormationCategorie.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.message = "";
        state.categorieFormations = action.payload;
      })
      .addCase(deleteFormationCategorie.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});

export const { resetcategorieFormation,setModelCateFormation, dispatchSelectCateFormation } = categorieFormationSlice.actions;
export default categorieFormationSlice.reducer