import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dispatchSelectCateFormation } from "../../../features/categorieFormation/categorieFormationSlice";
import { dispatchSelectCate } from "../../../features/categorieFormulaires/categorieFormulaireSlice";
import { dispatchSelectFormation } from "../../../features/formation/formationSlice";
import { dispatchSelect } from "../../../features/formulaires/formulaireSlice";
import CatIcon from "../../../images/tablecatIcon.svg";

export default function RowEspaceLevel({
  name,
  type, // selon ce champ, on peut spécifier le type de table (commun OR category)
  date,
  updatedate,
  id,
  number,
  text,
  children,
  checkbox,
  archive,
}) {
  let now = new Date(date);
  const [check, setCheck] = useState(false);
  const dispatch = useDispatch();
  const { allFormations, selectedFormations } = useSelector(
    (state) => state.formation
  );
  const { categorieFormulaires, selectedCate} = useSelector((state) => state.categorieFormulaire);
  const { categorieFormations,selectedCateFormation } = useSelector(
    (state) => state.categorieFormation
  );
  const { formulaires, selectedForm, refrechCard } = useSelector(
    (state) => state.formulaires
  );
  
  useEffect(() => {
    setCheck(false);
    return ()=>{
    dispatch(dispatchSelectCate([]));
    dispatch(dispatchSelectFormation([]));
    dispatch(dispatchSelect([]));
    dispatch(dispatchSelectCateFormation([]));
    }
  }, [allFormations, refrechCard]);
  const frenchDate = now.toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    localeMatcher: "best fit",
  });

  const handleCheckFormulaire = (e, elid) => {
    setCheck(!check);
    let arr = [...selectedForm];
    if (e.target.checked) {
      let obj = [...formulaires].filter((el) => el.id == elid)[0];
      arr.push(obj);
      dispatch(dispatchSelect(arr));
    } else {
      arr = arr.filter((el) => el.id !== elid);
      dispatch(dispatchSelect(arr));
    }
  };
  const handleCheckFormation = (e, elid) => {
    setCheck(!check);

    var arr = [...selectedFormations];

    if (e.target.checked) {
      let obj = [...allFormations].filter((el) => el.id == elid)[0];

      arr.push(obj);

      dispatch(dispatchSelectFormation(arr));
    } else {
      e.target.checked = true;

      arr = arr.filter((el) => el.id !== elid);

      dispatch(dispatchSelectFormation(arr));
    }
  };
  const handleCheckCateFormulaire = (e, elid) => {
    setCheck(!check);
    let arr = [...selectedCate];
    if (e.target.checked) {
      let obj = [...categorieFormulaires].filter((el) => el.id == elid)[0];
      arr.push(obj);
      dispatch(dispatchSelectCate(arr));
    } else {
      arr = arr.filter((el) => el.id !== elid);
      dispatch(dispatchSelectCate(arr));
    }
  };

  const handleCheckCateFormation = (e, elid) => {
    setCheck(!check);
    let arr = [...selectedCateFormation];
    if (e.target.checked) {
      let obj = [...categorieFormations].filter((el) => el.id == elid)[0];
      arr.push(obj);
      dispatch(dispatchSelectCateFormation(arr));
    } else {
      arr = arr.filter((el) => el.id !== elid);
      dispatch(dispatchSelectCateFormation(arr));
    }
  };

  const onChangeTable = (e) => {
    if(checkbox === "formation"){
      handleCheckFormation(e, id)
    }else if(checkbox ==="cateFormulaire"){
      handleCheckCateFormulaire(e,id)
    }else if(checkbox ==="cateFormation"){
      handleCheckCateFormation(e,id)
    }
    else{
      handleCheckFormulaire(e, id)
    }
  }
  return (
    <tr className="bg-white" style={{ borderBottom: "1.5px solid #E9E9E9" }}>
      {type === "category" && (
        <td className="bg-[#95C11F] px-4 py-2 w-[8%]">
          <img src={CatIcon} className="m-auto " />
        </td>
      )}
      <td className=" px-4 py-2">
        {!archive && (
          <input
            type={"checkbox"}
            onChange={onChangeTable}
            checked={check}
            className="w-4 h-4 accent-green-700 text-green-600 bg-green-700 rounded border-gray-300 focus:ring-green-700 "
          />
        )}
      </td>
      <td className="text-left px-4 py-2">
        <div className="font-semibold">{name}</div>
        <div className="text-gray-400">{date && text + frenchDate}</div>
      </td>
      <td className="text-left px-4 py-2">{number}</td>
      <td className="text-left px-4 py-2">{date && frenchDate}</td>
      <td className="text-left px-4 py-2">{children}</td>
    </tr>
  );
}
