import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { useParams, useNavigate } from "react-router-dom";

export default function AdminstratifSynthetiseurDetail() {
    const navigate = useNavigate();
    const [currentForm, setCurrentForm] = useState({});
    const { allFormationsSynthetiseur } = useSelector((state) => state.synthetiseur);
    let {id} = useParams()
    useEffect(() => {
      let arr = [...allFormationsSynthetiseur].filter((el) => {
        return el.id == id;
      });
      setCurrentForm(arr[0]);
    }, []);
    return (
      <div className=" flex-1 bg-gray-100 flex flex-col px-16  ">
        <div className=" basis-[15%] flex items-center">
          <div className="  flex gap-2 items-center">
            <button onClick={()=>navigate(`/EspaceLevel/synthetiseur`)}>
              <span className=" text-xl font-semibold">ARCHIVES</span>
              <span className=" text-xl font-medium"> LEVEL</span>
            </button>
            <button onClick={()=>navigate(`/EspaceLevel/synthetiseurDetail/${id}`)}>
              <span className=" text-lg font-medium">
                {" "}
                {"> Détails formation "}
              </span>
            </button>
            <span className=" text-lg font-medium">
              {" "}
              {"> Partie administratif"}
            </span>
          </div>
        </div>
        <div className="  basis-[85%] flex  justify-start items-start   overflow-auto py-8 ">
          <div className=" bg-white h-fit-content w-[80%] rounded-2xl p-10 flex flex-col gap-3">
            <div className="flex w-[95%] gap-1 border-b-2 pb-4">
              <span className="text-lg font-bold"> Nom de la formation/</span>
              <span className="text-lg font-semiBold Time">
                {""}
                {currentForm.titre}
              </span>
            </div>
            <div className="w-[95%]  gap-10 flex border-b-2 pb-4">
              <div className="flex gap-6">
                {" "}
                <div className="flex gap-1">
                  <span className="text-lg font-bold"> Horaires/</span>
                  <span className="text-lg font-semiBold Time">
                    {" "}
                    {currentForm.time?.split("&&")[0]}
                  </span>
                </div>
                <span className="text-lg font-bold"> &</span>
                <span className="text-lg font-semiBold Time">
                  {" "}
                  {currentForm.time?.split("&&")[1]}
                </span>
              </div>
            </div>
            <div className="w-[95%]  gap-10 flex border-b-2 pb-4">
              <div className="flex gap-1">
                <span className="text-lg font-bold"> Date/</span>
                <span className="text-lg font-semiBold Time">
                  {" "}
                  De {moment(currentForm.startedAt).format("DD-MM-YYYY")} à{" "}
                  {moment(currentForm.finichedAt).format("DD-MM-YYYY")}
                </span>
              </div>
            </div>
            <div className="w-[95%] flex gap-10 border-b-2 pb-4">
              <div>
                <span className="text-lg font-bold"> Nombre de stagiares/</span>
                <span className="text-lg font-semiBold Time">
                  {" "}
                  {currentForm.stagiaires?.length} stagiaires
                </span>
              </div>
              <div>
                <span className="text-lg font-bold"> Mode/</span>
                <span className="text-lg font-semiBold Time">
                  {" "}
                  {currentForm.Mode}
                </span>
              </div>
            </div>
            <div className="flex w-[95%] gap-1 border-b-2 pb-4">
              <span className="text-lg font-bold">
                {" "}
                Adresse de la formation/
              </span>
              <span className="text-lg font-semiBold Time">
                {`${currentForm.Adress?.split(",")[0]}, ${
                  currentForm?.CodePostal
                }, ${currentForm.Adress?.split(",")[1]}`}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
}
