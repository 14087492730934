import intercepterAxios from "../../../configAxios";



// get link
const getCaldavLink = async (userData) => {
  try {
    let params = {};

    params.username = userData.username;
    params.userId = userData.userId;

    const response = await intercepterAxios.get(`/caldav/fetechLink`,{params});
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const agendaService = {
    getCaldavLink
  };
  
  export default agendaService;