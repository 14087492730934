import { useDispatch, useSelector } from "react-redux";

import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import CardResultats from "../../../components/CardResultats/CardResultats";
import { getAllFormation } from "../../../features/formation/formationSlice";

export default function FormationDetailStagiaire({ setScreen }) {
    const { allFormations } = useSelector((state) => state.formation);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [currentForm, setCurrentForm] = useState({});

  const [resultat, setResultat] = useState(false);
 
  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    let arr = [...allFormations].filter((el) => {
      return el.id == id;
    });
    setCurrentForm(arr[0]);
  }, []);

  useEffect(() => {
    dispatch(getAllFormation(user?._id));
  }, []);
  
 
  return (
    <div className="flex flex-col bg-gray-100 w-[100%]">
      <div className="m-8 ">
        <div className=" basis-[10%] flex justify-between border-b-2 items-center ">
          <div className="  flex gap-2 items-center">
            <button onClick={() => navigate(`/EspaceLevel/formation`)}>
            <span className=" text-xl font-semibold">FORMATIONS</span>
            <span className=" text-xl font-medium"> LEVEL</span>
            </button>
            <button
              onClick={() => navigate(`/EspaceLevel/formationDetail/${id}`)}
            >
              <span className=" text-lg font-medium">
                {" "}
                {"> Détails formation "}
              </span>
            </button>
            <span className=" text-lg font-medium">
              {" "}
              {`> ${currentForm?.titre || "" } `}
            </span>
          </div>
          <div>
          
          </div>
        </div>
        <div className=" basis-[40%]  flex  justify-start items-start   overflow-auto py-8">
        </div>
        <div className="flex gap-[20px] flex-wrap">
          {currentForm?.stagiaires?.map((element,k) => {
           if(element.Nom || element.Prenom){
             return (
              <div className="w-[250px]" key={k}>
                <CardResultats
                  avatar={element.Genre}
                  statusResultat={resultat}
                  element={element}
                  encour
                  />
              </div>
            );
          }else return <></>
          }
          )}
        </div>
      </div>
    </div>
  );
}
