import React from "react";
import Correct from "../images/Correct.svg";
import False from "../images/False.svg";

export default function StudentTableResultats({ tentative, currentFormation }) {
  const resultats = () => {
    let etapes = JSON.parse(currentFormation?.etapes);

    let currentFormulaire = [...etapes].filter(
      (formulaire) => formulaire.id == tentative.formulaireId
    )[0];

    let filteredQuestion = currentFormulaire?.questions?.filter(
      (element) => element.Type !== "TexteLibre"
    );
    let length = filteredQuestion?.length;
    let index = 0;
    let responses = tentative.tentativeResponses;

    return currentFormulaire?.questions.map((el, i) => {

      let finale = {};
      if (responses.length > 0) {
        // console.log(responses, "responses")
        finale = responses.reduce(
          (acc, element) => {
            if (el.Type == "VraiFaux") {
              acc.status = String(element.status) == element.Content;
              acc.points = acc.status ? element.points : 0
            } else if (el.Type !== "Niveaux" && el.Type !== "ResponseOuvertes") {
             
              let studentScorePerQuestion = 0;
              let filtered = responses?.filter((item) => {
                return item.questionId == el.id;
              }); 
  
              studentScorePerQuestion = filtered?.reduce((totalCurrent, item) => {
                const QuestionScore = item.points;
                const QuestionCorrectAnswersCount = currentFormulaire?.questions?.find(x => item.questionId === x.id)?.reponses.filter(r => r.status === true).length;
                // console.log('QuestionCorrectAnswersCount', item)
                const AnswerWeight = QuestionScore / QuestionCorrectAnswersCount;
                const correctAnswerWeight = AnswerWeight * 1;
                const wrongAnswerWeight = AnswerWeight * -1;

                if (item.status === true) {
                  totalCurrent += correctAnswerWeight;
                } else {
                  totalCurrent += wrongAnswerWeight;
                }

                return totalCurrent;
              }, 0);
              studentScorePerQuestion = studentScorePerQuestion < 0 ? 0 : studentScorePerQuestion.toFixed(2);
              // console.log(studentScorePerQuestion)

              // console.log('studentScorePerQuestion', studentScorePerQuestion)
              acc.points = Number(studentScorePerQuestion);
              
              if(Number(studentScorePerQuestion) != 0){
                acc.status = true;
              }else {
                acc.status = false;
              }

            }else if(el.Type === "Niveaux" || el.Type === "ResponseOuvertes"){
              acc.status = true;
              acc.content = element?.Content; 
              // console.log(element)  
            } 
            return acc;
          }, {});



      }
      // console.log(el,  "el.Type")
      if (el.Type !== "TexteLibre" && el.Type !== "Niveaux" && el.Type !== "ResponseOuvertes") {
        index += 1;
        return (
          <tr
            className="bg-white"
            style={{ borderBottom: "1.5px solid #E9E9E9" }}
            key={i+1}
          >
            <td className="text-left px-4 py-2">
              <div className="font-semibold">{`Question ${index} sur ${length}`}</div>
            </td>
            <td className="text-left ">
              {" "}
              {finale?.status ? (
                <img src={Correct} alt="corect" className="h-[70%] " />
              ) : (
                <img src={False} alt="corect" className="h-[70%] " />
              )}
            </td>
            <td className="text-left px-4 py-2">
              {finale?.points}
            </td>
          </tr>
        );
      }
      if(el.Type === "Niveaux" || el.Type === "ResponseOuvertes"){
        index += 1;
        let content = responses?.find(x => x.questionId === el.id)?.Content;
        return (
          <tr
            className="bg-white"
            style={{ borderBottom: "1.5px solid #E9E9E9" }}
            key={i+1}
          >
            <td className="text-left px-4 py-2">
              <div className="font-semibold">{`Question ${index} sur ${length}`}</div>
            </td>
            <td className="text-left ">
              {" "}
              <div className="font-semibold">{"Question: " + el.Content}</div>
              {/* render niveaux response (nb of starts) */}
              {el.Type === "Niveaux" && <div className="font-semibold">{"⭐️ ".repeat(Number(content) * 5)}</div>}
              {el.Type === "ResponseOuvertes" && <div className="font-semibold">{content || ""}</div>}
            </td>
            <td className="text-left px-4 py-2">
              {"-"}
            </td>
          </tr>
        );
      }
    });
  };

  return (
    <>
      <table className="table-auto tableCommunCateg">
        <thead>
          <tr>
            <th className="text-left px-4 py-2">Questions</th>
            <th className="text-left px-8 py-2">Réponse</th>
            <th className="text-left px-4 py-2">Score</th>
          </tr>
        </thead>
        <tbody>{resultats()}</tbody>
      </table>
    </>
  );
}
