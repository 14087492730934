import axios from "../../configAxios";

const getAllCategorie = async (id) => {
    try {
      const response = await axios.get(`/categorieFormation/allCategorie${id}`);
      return response.data;
    } catch (err) {
      let message = err.response.data?.message || "Probleme connexion";
      throw message;
    }
  };

  const createCategorie = async (formData) => {

    try {
      const response = await axios.post(`/categorieFormation/register`, formData)
      return response.data
    } catch (err) {
      let message = err.response.data?.message || "Probleme connexion";
      throw message;
    }
  };
 
  const deleteFormulationCategorie = async (data) => {
    
    try {
      const response = await axios.put(`/categorieFormation/delete`, data);
      return response.data;
    } catch (err) {
      let message = err.response.data?.message || "Probleme connexion";
      throw message;
    }
  };

  const assigneFormationToCategories = async (formData) => {
    try {
      
      const response = await axios.post(`/categorieFormation/assigneCategorie`, formData);
      return response.data;
    } catch (err) {
      let message = err.response.data?.message || "Probleme connexion";
      throw message;
    }
  };


  const categorieFormationService = {
    getAllCategorie,deleteFormulationCategorie,
    assigneFormationToCategories,createCategorie
  };
  
  export default categorieFormationService;