import React, { useState, useEffect } from "react";
//  import Partager from "../../../../";
import eyes from "../../../../images/eyes.svg";
import edit from "../../../../images/edit.svg";
import ChalkboardTeacher from "../../../../images/ChalkboardTeacher.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { dispatchEditable } from "../../../../features/formation/formationSlice";

export default function AnimateurSynthetiseurCard({ date, id, setScreen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [currentForm, setCurrentForm] = useState({});
  const { allFormationsSynthetiseur } = useSelector(
    (state) => state.synthetiseur
  );
  const editButton = () => {
    dispatch(
      dispatchEditable({
        edit: true,
        step: 3,
        formation: currentForm,
      })
    );
    navigate("/FabriqueFormation");
  };
  useEffect(() => {
    let arr = [...allFormationsSynthetiseur].filter((el) => {
      return el.id == id;
    });
    setCurrentForm(arr[0]);
  }, [allFormationsSynthetiseur, id]);

  let now = new Date(date);
  const frenchDate = now.toLocaleDateString("fr-FR", {
    day: "numeric",
    month: "long",
    year: "numeric",
    localeMatcher: "best fit",
  });

  return (
    <div className="card  rounded-lg custom-b-sh transition-all   hover:bg-gray-200  flex flex-col gap-2 bg-white py-10 px-9">
      <div className="flex items-center justify-center">
        <button
          type="button"
          className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
          onClick={() => navigate(`/EspaceLevel/synthetiseurAnimateur/${id}`)}
        >
          <img src={eyes} />
        </button>

        {/* <button
            type="button"
            className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
          >
            <img src={edit} onClick={editButton} />
          </button> */}
      </div>
      <div className="card-body flex flex-col gap-2 justify-center items-center border-b-2 h-[55%]">
        <img src={ChalkboardTeacher} className="h-7" />
        <div className="flex flex-col justify-center items-center ">
          <span className="font ">ARCHIVES</span>
          <span className="font">ANIMATEUR</span>
        </div>
      </div>
      <div className="flex justify-center items-center flex-1 ">
        <ul>
          <li className="flex justify-center items-center">
            <div className="flex items-center text-base font-normal text-black">
              <span className="flex-1 ml-3 whitespace-nowrap">
                Modifié le {frenchDate}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}
