import React from "react";


export default function TableEspaceLevel({ type, children,nom,form}) {

  return (
    <>
      <table className="table-auto tableCommunCatege">
        <thead>
          <tr >
            {type === 'category' &&
              <th className="px-4 py-2" ></th>
            }
            <th className="px-4 py-2"></th>
            <th className="text-left px-4 py-2">{nom}</th>
            <th className="text-left px-4 py-2">{form}</th>
            <th className="text-left px-4 py-2">Modifié le</th>
            <th className="text-left px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
         
          {children}
          
        </tbody>
      </table>
    </>
  );
}
