import React from "react";
import { useSelector } from "react-redux";
import Visualicon from "../images/View.svg";

export default function Table({ stag, setTenta, currentFormation, type }) {
  const { formulaires } = useSelector((state) => state.formulaires);

  let resultats = () => {
    // hide formulaire that are in stagiaireFormulaire.
    let str = currentFormation?.stagiairesFormulaires || "[]";
    let stagiairesFormulaires = JSON.parse(str);

    let formula = currentFormation?.etapes ? JSON.parse(currentFormation?.etapes) : [];
    return stag?.tentatives?.filter(form => {
      if(!stagiairesFormulaires?.includes(String(form.formulaireId))) return form;
    }).map((el, index) => {
      let form = [...formula].filter(
        (formula) => formula.id == el.formulaireId
      )[0];
      let score = 0;
      let total = 0;
      let reponse = 0;
      let totalreponse = 0;
      const reversedTentativeResponses = el?.tentativeResponses ? [...el?.tentativeResponses].reverse() : [];

      const finalTentitiveResponses = reversedTentativeResponses.reduce((acc, current) => {
        const x = acc.filter(item => item.responseId === current.responseId && item.questionId === current.questionId);
        if (!x.length) {
          acc.push(current);
        }
        return acc;
      }, []);
      let isSameQuestion = true;
      const uniqueFinalTentitiveResponses = finalTentitiveResponses?.reverse();
      uniqueFinalTentitiveResponses?.map((tent, index) => {
        let filtered = form?.questions.filter((quest) => {
          return quest.id == tent.questionId;
        });
        if (filtered?.length && filtered[0]?.Type == "VraiFaux") {
          let dot = tent.Content == String(tent.status) ? tent.points : 0;
          score += dot;
          reponse = dot > 0 ? reponse + 1 : reponse
        } else if (filtered?.length && filtered[0]?.Type !== "Niveaux") {
          if(index > 0)
          isSameQuestion = tent.questionId === uniqueFinalTentitiveResponses[index - 1 ]?.questionId;
          const QuestionScore = tent.points;
          const QuestionCorrectAnswersCount = filtered[0]?.reponses.filter(r=> r.status === true).length;
          const AnswerWeight = QuestionScore / QuestionCorrectAnswersCount;
          const correctAnswerWeight = AnswerWeight * 1;
          const wrongAnswerWeight = AnswerWeight * -1;
          if(tent.status === true) {
          score += correctAnswerWeight;
          } else {
            score > 0 && isSameQuestion ? score += wrongAnswerWeight : score += 0;
          }
          if(Number(QuestionScore) === Number(score) ){
            reponse += 1;
          }else {
            reponse += 0;
          }
        } else if (filtered?.length &&  filtered[0]?.Type == "Niveaux") {
          let dot = Number(tent.Content) ? Number(tent.Content) : 0;
          score += dot;
          reponse += 1;
        }
      });
      form?.questions?.map((element) => {
        if (element.Type !== "TexteLibre") {
          totalreponse += 1;
          total += element.points;
        }
      });
      const hideScore = form?.questions?.every((element) => 
        element.Type === "TexteLibre" || element.Type === "ResponseOuvertes" || element.Type === "Niveaux"
      );

      return (
        <tr className="bg-white" key={index}>
          <td className="text-left px-4 py-2">
            <div className="font-semibold">{`Formulaire N°0${index + 1} / ${
              form?.titre || ""
            }`}</div>
          </td>
          {/* <td className="text-left px-4 py-2">{`${reponse}/${totalreponse}`}</td> */}
         {/* { !hideScore && <td className="text-left px-4 py-2">{`${score.toFixed(
            2
          )}/${total}`}</td>} */}
         {/* { hideScore && <td className="text-left px-4 py-2">-</td>} */}
          <td className="text-left px-4 py-2">
            <div className="w-[30px] h-[30px]">
              <img
                className="cursor-pointer"
                alt="visualize"
                src={Visualicon}
                onClick={() => setTenta(el)}
              />
            </div>
          </td>
        </tr>
      );
    });
  };

  let amical = () => {
    let tentatives = currentFormation?.tentatives?.filter((element) => {
      if (type == "client") {
        return element.ClientId;
      } else if (type == "formateur") {
        return element.organismeId;
      }
    });

    return tentatives?.map((el, index) => {
      let form = [...formulaires].filter(
        (formula) => formula.id == el.formulaireId
      )[0];
      let score = 0;
      let total = 0;
      let reponse = 0;
      let totalreponse = 0;
      const reversedTentativeResponses = el?.tentativeResponses ? [...el?.tentativeResponses].reverse() : [];

      const finalTentitiveResponses = reversedTentativeResponses.reduce((acc, current) => {
        const x = acc.filter(item => item.responseId === current.responseId && item.questionId === current.questionId);
        if (!x.length) {
          acc.push(current);
        }
        return acc;
      }, []);
      const uniqueFinalTentitiveResponses = finalTentitiveResponses?.reverse();
      uniqueFinalTentitiveResponses?.map((tent) => {
        let filtered = form?.questions.filter((quest) => {
          return quest.id == tent.questionId;
        });
        if (tent?.status) {
          score += tent.points;
          reponse += 1;
        } else if (filtered?.length &&  filtered[0]?.Type == "Niveaux") {
          let dot = Number(tent.Content) ? Number(tent.Content) : 0;
          score += dot;
          reponse += 1;
        }

      });
      form?.questions?.map((element) => {
        if (element.Type !== "TexteLibre") {
          totalreponse += 1;
          total += element.points;
        }
      });

      return (
        <tr className="bg-white" key={index}>
          <td className="text-left px-4 py-2">
            <div className="font-semibold">{`Formulaire N°0${index + 1} / ${
              form?.titre || ""
            }`}</div>
          </td>
          {/* <td className="text-left px-4 py-2">{`${reponse}/${totalreponse}`}</td> */}
          <td className="text-left px-4 py-2">{`${score}/${total}`}</td>
          <td className="text-left px-4 py-2">
            <div className="w-[30px] h-[30px]">
              <img
                alt="visualize"
                className="cursor-pointer"
                src={Visualicon}
                onClick={() => setTenta(el)}
              />
            </div>
          </td>
        </tr>
      );
    });
  };
  return (
    <>
      <table className="table-auto tableCommunCateg">
        <thead>
          <tr>
            <th className="text-left px-4 py-2">Formulaires</th>
            {/* <th className="text-left px-4 py-2">Bonnes Réponses</th> */}
            {/* <th className="text-left px-4 py-2">Score</th> */}
            <th className="text-left px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>{type == "stag" ? resultats() : amical()}</tbody>
      </table>
    </>
  );
}
