import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import formationService from "./formationService";

const initialState = {
  animateurFormulaires: [],
  animateurs: {},
  allFormations: [],
  client: {},
  clientFormulaires: [],
  diffusion: "En salle",
  edit: true,
  etapes: [],
  files: [],
  feuilleDepresence: false,
  finichedAt: "",
  FormationPartageId: "",
  formationToEdit: {},
  isLoading: false,
  isError: false,
  isSuccess: false,
  listeStagiaire: [],
  liveFormation: {},
  message: "",
  partaged: [],
  programmerLenvoie: [],
  envoiInst: [],
  selectedFormations: [],
  ShowModal: false,
  ShowMultiple: false,
  startedAt: "",
  stagiairesFormulaires: [],
  step: 1,
  step1 : {
    mode: 'Distanciel',
    type : 'intra',
    action: 'rncp'
  },
  isLoadingFormation: false,
};

export const createFormation = createAsyncThunk(
  "createFormation",
  async (formation, thunkAPI) => {
    try {
      return await formationService.formationRegister(formation);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllFormation = createAsyncThunk(
  "getAllFormation",
  async (id, thunkAPI) => {
    try {
      return await formationService.getAllFormations(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllFormationPartaged = createAsyncThunk(
  "getAllFormationPartaged",
  async (id, thunkAPI) => {
    try {
      return await formationService.getAllFormationspartaged(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllFormationLive = createAsyncThunk(
  "getAllFormationLive",
  async (id, thunkAPI) => {
    try {
      return await formationService.getAllFormationsLive(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOneFormation = createAsyncThunk(
  "getOneFormation",
  async (id, thunkAPI) => {
    try {
      return await formationService.getOneFormation(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getFormationBySlug = createAsyncThunk(
  "getFormationBySlug",
  async (slug, thunkAPI) => {
    try {
      return await formationService.getFormationBySlug(slug);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const duplicateFormation = createAsyncThunk(
  "duplicateFormation",
  async (data, thunkAPI) => {
    try {
      return await formationService.duplicateFormation(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteFormation = createAsyncThunk(
  "deleteFormation",
  async (data, thunkAPI) => {
    try {
      return await formationService.deleteFormation(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const activateFormation = createAsyncThunk(
  "activateFormation",
  async (data, thunkAPI) => {
    try {
      return await formationService.activateFormation(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const editFormation = createAsyncThunk(
  "editFormation",
  async (data, thunkAPI) => {
    try {
      return await formationService.EditFormation(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const partageFormation = createAsyncThunk(
  "partageFormation",
  async (data, thunkAPI) => {
    try {
      return await formationService.PartageFormation(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const partageMultpileFormation = createAsyncThunk(
  "partageMiltipleFormation",
  async (data, thunkAPI) => {
    try {
      return await formationService.PartageMultipleFormation(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const formationSlice = createSlice({
  name: "formation",
  initialState,
  reducers: {
    nextStep: (state) => {
      if (state.step < 6) {
        state.step += 1;
      }
    },
    precedentStep: (state) => {
      if (state.step > 1) {
        state.step -= 1;
      }
    },
    startedAt: (state, action) => {
      state.startedAt = action.payload;
    },
    finichedAt: (state, action) => {
      state.finichedAt = action.payload;
    },
    stepOne: (state, action) => {
      state.step1 = action.payload;
    },
    clientData: (state, action) => {
      state.clientFormulaires = action.payload.formulaireId;
      state.client = action.payload;
    },
    formateur: (state, action) => {
      state.animateurs = action.payload;
      state.animateurFormulaires = action.payload.formulaireId;
    },
    stagFormula: (state, action) => {
      state.stagiairesFormulaires = action.payload;
    },
    etapesformulaires: (state, action) => {
      state.etapes = action.payload;
    },
    updateFiles: (state, action) => {
      state.files = action.payload;
    },
    stagiaireListe: (state, action) => {
      state.listeStagiaire = action.payload;
    },
    DiffusionPlace: (state, action) => {
      state.diffusion = action.payload;
    },
    resetFormation: (state) => {
      state.step = 1;
      state.step1 = {
        mode: 'Distanciel',
        type : 'intra',
        action: 'rncp'
      };
      state.startedAt = "";
      state.finichedAt = "";
      state.client = {};
      state.animateurs = {};
      state.etapes = [];
      state.listeStagiaire = [];
      state.animateurFormulaires = [];
      state.stagiairesFormulaires = [];
      state.clientFormulaires = [];
      state.message = "";
      state.liveFormation = {};
      state.selectedFormations = [];
      state.edit = false;
      state.formationToEdit = {};
      state.ShowModal = false;
      state.FormationPartageId = "";
      state.ShowMultiple = false;
      state.diffusion = "En salle";
      state.programmerLenvoie = [];
      state.envoiInst = [];
      state.isLoadingFormation = false;
      state.feuilleDepresence = false;
      state.files = [];
    },
    stepSelected: (state, action) => {
      state.step = action.payload;
    },
    dispatchSelectFormation: (state, action) => {
      state.selectedFormations = action.payload;
    },
    dispatchEditable: (state, action) => {
      state.edit = action.payload.edit;
      state.step = action.payload.step;
      state.formationToEdit = action.payload.formation;
    },
    setFormationToEdit: (state, action) => {
      state.formationToEdit = action.payload;
    },
    setPartageFormation: (state, action) => {
      state.ShowModal = action.payload.show;
      state.FormationPartageId = action.payload.id;
    },
    setLiveFormation: (state, action) => {
      state.liveFormation = action.payload;
    },
    setShowMultiple: (state, action) => {
      state.ShowMultiple = action.payload;
    },
    setProgrammerLenvoi: (state, action) => {
      state.programmerLenvoie = action.payload;
    },
    setEnvoiInst: (state, action) => {
      state.envoiInst = action.payload;
    },
    setfeuilleDepresence: (state, action) => {
      state.feuilleDepresence = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFormation.pending, (state) => {
        state.isLoadingFormation = true;
      })
      .addCase(createFormation.fulfilled, (state, action) => {
        state.isLoadingFormation = false;
        state.isSuccess = true;
      })
      .addCase(createFormation.rejected, (state, action) => {
        state.isLoadingFormation = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllFormation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFormation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allFormations = action.payload;
      })
      .addCase(getAllFormation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllFormationLive.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFormationLive.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allFormations = action.payload;
      })
      .addCase(getAllFormationLive.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOneFormation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneFormation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.liveFormation = action.payload.formation;
      })
      .addCase(getOneFormation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getFormationBySlug.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFormationBySlug.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.liveFormation = action.payload.formation;
      })
      .addCase(getFormationBySlug.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(duplicateFormation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(duplicateFormation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allFormations = action.payload;
      })
      .addCase(duplicateFormation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteFormation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFormation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allFormations = action.payload?.formations || [];
      })
      .addCase(deleteFormation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(editFormation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editFormation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allFormations = action.payload;
      })
      .addCase(editFormation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(activateFormation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(activateFormation.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allFormations = action.payload;
      })
      .addCase(activateFormation.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllFormationPartaged.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFormationPartaged.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.partaged = action.payload;
      })
      .addCase(getAllFormationPartaged.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  nextStep,
  stepSelected,
  setfeuilleDepresence,
  setProgrammerLenvoi,
  setEnvoiInst,
  setLiveFormation,
  setShowMultiple,
  setPartageFormation,
  dispatchEditable,
  dispatchSelectFormation,
  precedentStep,
  startedAt,
  finichedAt,
  stepOne,
  DiffusionPlace,
  clientData,
  formateur,
  stagFormula,
  etapesformulaires,
  updateFiles,
  stagiaireListe,
  resetFormation,
  setFormationToEdit
} = formationSlice.actions;
export default formationSlice.reducer;
