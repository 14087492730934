import React, { useEffect, useRef } from "react";
import Button from "../../../components/Button";
import commentImg from "../../../images/Comment.svg";

export default function QuitConfirmModal({
  setShow,
  onOk,
  isVueFormateur = true
}) {

  return (
    <div
      className={`fixed z-50 w-full h-full bg-gray-700/90 flex justify-center items-center top-0 left-0`}
    >
      <div className="h-[50%] w-[40%] min-w-[500px] py-[25px] bg-white rounded-3xl flex justify-center items-center gap-4 flex-col">
        <img src={commentImg} alt="comment" className="h-10" />
        <div className="flex flex-col justify-center items-center">
          <span className=" text-3xl font-semibold">
          { isVueFormateur && 'Terminer la formation ?'}
          { !isVueFormateur && 'Quitter la formation ?'}
          </span>
          { isVueFormateur && <span className="Time mt-2 p-8 text-center">Tous les stagiaires seront déconnectés</span>}
          { !isVueFormateur && <span className="Time mt-2 p-8 text-center">Êtes-vous sûr de vouloir quitter la formation</span>}
        </div>
        {/* <button onClick={handlePopUp}>Confirmer</button> */}
        <div className="flex w-[90%] justify-center gap-2 ">
          <button
            type="submit"
            className={`group relative  flex justify-center w-[30%]  py-2 px-4 border  border-lime-600  text-sm font-medium    hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-10  items-center  gap-2 `}
            id="button"
            onClick={()=> setShow(false) }
          >
            <span>Annuler</span>{" "}
          </button>
          <Button
            text={ isVueFormateur ?  "Terminer" : "Quitter" }
            classname={"w-[30%] h-10 py-2"}
            func={()=>{
              onOk()
            }}
          />
        </div>
      </div>
    </div>
  );
}
