import React from "react";
import select from "../images/select.svg";


export default function Select({setShow, show,text,classname}) {
  

  return (
    <div className={`${classname}`}>
      <div className="relative"onClick={()=>setShow(!show)} >
        <button
          type={""}
          className="text-md block  py-2 rounded-full w-full h-full
                  bg-white border-2 border-gray-300 placeholder-gray-400 text-gray-900
                  focus:placeholder-gray-500
                  focus:ring-lime-500
                  focus:bg-white 
                  focus:z-10
                  focus:border-gray-400  
                  focus:outline-none"
          
          name={""}
        >{text}</button>
       
        <div className="absolute inset-y-0 right-0 px-5 flex items-center text-sm leading-5 gap-3">
        <div >
          {!show && <img src={select} alt="Level" className="h-2"  />}
          {show && <img src={select} alt="Level" className="h-2 rotate-180 " />}
        </div>
        </div>
      </div>
    </div>
  );
}
