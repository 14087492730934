import React, { useState, useEffect } from "react";
// import Partager from "../../components/Partager";
import eyes from "../images/eyes.svg";
import edit from "../images/edit.svg";
import share from "../images/share.svg";
import Trash from "../images/TrashBlack.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import Sent from "../images/Sent.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  dispatchSelect,
  setPartage,
} from "../features/formulaires/formulaireSlice";
import { setProgrammerLenvoi, setEnvoiInst } from "../features/formation/formationSlice";
export default function CartFormulaire({
  name,
  date,
  slug,
  selected,
  setSelected,
  id,
  number,
  type,
  form
}) {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const [check, setCheck] = useState(false);
  const [programe, setProgram] = useState(false);
  const [inst, setInst] = useState(false);
  const [selecteDate, setselecteDate] = useState("");
  const { step, programmerLenvoie, envoiInst,step1 } = useSelector((state) => state.formation);
  const { selectedForm, formulaires } = useSelector(
    (state) => state.formulaires
  );
  const { user } = useSelector((state) => state.auth);
  
  // Set min date as tomorrow's date
  const minDate = new Date()
  minDate.setDate(minDate.getDate());

  function handleDateChange(event) {
    const selectedDate = new Date(event.target.value);
 
      
    setselecteDate(selectedDate.toISOString().slice(0, 10));
  
    if(step === 4){
      let obj = {
        organismeId : user._id,
        formulaire: form,
        type: "stag",
        DATE : selectedDate 
      }
      let array = [...programmerLenvoie].filter(el=>{
        return !(el.type === "stag" && el.formulaire.id == form.id)
      })
      array.push(obj)
      dispatch(setProgrammerLenvoi(array))
    }else if(step === 3){
      let obj = {
        organismeId : user._id,
        formulaire: form,
        type: 'formateur',
        DATE : selectedDate,
        // email: step1.formateur?.email
      }
      let array = [...programmerLenvoie].filter(el=>{
        return !(el.type === 'formateur' && el.formulaire.id == form.id)
      })
      array.push(obj)
      dispatch(setProgrammerLenvoi(array))
    }else if(step === 2){
      let obj = {
        organismeId : user._id,
        formulaire: form,
        type: 'client',
        DATE : selectedDate,
      }
      let array = [...programmerLenvoie].filter(el=>{
        return !(el.type === 'client' && el.formulaire.id == form.id)
      })
      array.push(obj)
      dispatch(setProgrammerLenvoi(array))
    }
  }
  function handleCheckboxChange(e) {
    
    const selectedDate = new Date(Date.now());
    setselecteDate(selectedDate.toISOString().slice(0, 10));
    setInst(e.target.checked)
    if(e.target.checked){
      if(step === 4){
        let obj = {
          organismeId : user._id,
          formulaire: form,
          type: "stag",
          DATE : selectedDate 
        }
        let array = [...envoiInst].filter(el=>{
          return !(el.type === "stag" && el.formulaire.id == form.id)
        })
        let arrayP = [...programmerLenvoie].filter(el=>{
          return !(el.type === "stag" && el.formulaire.id == form.id)
        })
        array.push(obj)
        dispatch(setEnvoiInst(array))
        dispatch(setProgrammerLenvoi(arrayP))
      }else if(step === 3){
        let obj = {
          organismeId : user._id,
          formulaire: form,
          type: 'formateur',
          DATE : selectedDate,
          email: step1.formateur?.email
        }
        let array = [...envoiInst].filter(el=>{
          return !(el.type === 'formateur' && el.formulaire.id == form.id)
        })
        let arrayP = [...programmerLenvoie].filter(el=>{
          return !(el.type === "formateur" && el.formulaire.id == form.id)
        })
        array.push(obj)
        dispatch(setEnvoiInst(array))
        dispatch(setProgrammerLenvoi(arrayP))
      }else if(step === 2){
        let obj = {
          organismeId : user._id,
          formulaire: form,
          type: 'client',
          DATE : selectedDate,
        }
        let array = [...envoiInst].filter(el=>{
          return !(el.type === 'client' && el.formulaire.id == form.id)
        })
        let arrayP = [...programmerLenvoie].filter(el=>{
          return !(el.type === "client" && el.formulaire.id == form.id)
        })
        array.push(obj)
        dispatch(setEnvoiInst(array))
        dispatch(setProgrammerLenvoi(arrayP))
      }
    }
    else{
      if(step === 4){
        let array = [...envoiInst].filter(el=>{
          return !(el.formulaire.id == form.id)
        })
        dispatch(setEnvoiInst(array))
      }else if(step === 3){
        let array = [...envoiInst].filter(el=>{
          return !(el.formulaire.id == form.id)
        })
        dispatch(setEnvoiInst(array))
      }else if(step === 2){
        let array = [...envoiInst].filter(el=>{
          return !(el.formulaire.id == form.id)
        })
        dispatch(setEnvoiInst(array))
      }
    }
  }
  const handleCheck = (e) => {
    setCheck(!check);
    if (type == "espaceLevel") {
      let arr = [...selectedForm];
      if (e.target.checked) {
        let obj = [...formulaires].filter((el) => el.id == id)[0];
        arr.push(obj);
        dispatch(dispatchSelect(arr));
      } else {
        arr = arr.filter((el) => el.id !== id);
        dispatch(dispatchSelect(arr));
      }
    } else {
      let arr = [...selected];
      if (e.target.checked) {
        arr.push(id);
        setSelected(arr);
      } else {
        arr = arr.filter((el) => el !== id);

        setSelected(arr);
      }
    }
  };

  const deleteSelected = (e) => {
    if (type == "espaceLevel") {
      let arr = [...selectedForm];
      let array = arr.filter((el) => {
        return el.id != e.target.id;
      });
      dispatch(dispatchSelect(array));
    } else {
      let arr = [...selected];
      let array = arr.filter((el) => {
        return el != e.target.id;
      });
      setSelected(array);
    }
    if(step === 4){
      
      let array = [...programmerLenvoie].filter(el=>{
        return !(el.type === "stag" && el.formulaire.id == form.id)
      })
      dispatch(setProgrammerLenvoi(array))

      let arrayI = [...envoiInst].filter(el=>{
        return !(el.type === "stag" && el.formulaire.id == form.id)
      })
      dispatch(setEnvoiInst(arrayI))


    }else if(step === 3){
      let array = [...programmerLenvoie].filter(el=>{
        return !(el.type === 'formateur' && el.formulaire.id == form.id)
      })
      dispatch(setProgrammerLenvoi(array))


      let arrayI = [...envoiInst].filter(el=>{
        return !(el.type === "formateur" && el.formulaire.id == form.id)
      })
      dispatch(setEnvoiInst(arrayI))

    }else if(step === 2){
      let array = [...programmerLenvoie].filter(el=>{
        return !(el.type === 'client' && el.formulaire.id == form.id)
      })
      dispatch(setProgrammerLenvoi(array))

      let arrayI = [...envoiInst].filter(el=>{
        return !(el.type === "client" && el.formulaire.id == form.id)
      })
      dispatch(setEnvoiInst(arrayI))
      
    }
  };

  const shareButton = () => {
    dispatch(
      setPartage({
        show: true,
        id: id,
      })
    );
  };

  useEffect(() => {
    setCheck(false);
    if(step === 4){
      
      let array = [...programmerLenvoie].filter(el=>{
        return el.type === "stag" && el.formulaire.id == form.id
      })
      if(array.length > 0){
        let obj = array[0]     
        setselecteDate(obj.DATE.toISOString().slice(0, 10))
        setProgram(true)
      }
    }else if(step === 3){    
      let array = [...programmerLenvoie].filter(el=>{
        return el.type === 'formateur' && el.formulaire.id == form.id
      })
      if(array.length > 0){
        let obj = array[0]
        setselecteDate(obj.DATE.toISOString().slice(0, 10))
        setProgram(true)
      }
    }else if(step === 2){
     
      let array = [...programmerLenvoie].filter(el=>{
        return el.type === 'client' && el.formulaire.id == form.id
      })
      if(array.length > 0){
        let obj = array[0]
        setselecteDate(obj.DATE.toISOString().slice(0, 10))
        setProgram(true)
      }
      
    }
  }, [formulaires]);

  return (
    <div className="card  rounded-xl custom-b-sh transition-all   hover:bg-gray-200  flex flex-col gap-2 bg-white py-4  min-h-[150px] w-[90%]">
      {type !== "delete" ? (
        <div className="flex items-center justify-end pr-2">
          <input
            checked={check}
            id="link-checkbox"
            type="checkbox"
            value=""
            onChange={handleCheck}
            className="w-4 h-4 accent-green-700 text-green-600 bg-green-700 rounded border-gray-300 focus:ring-green-700"
          ></input>
        </div>
      ) : (
        <div className="flex items-center justify-end pr-2 ">
          <img src={Trash} alt="trash" className="cursor-pointer" id={id} onClick={deleteSelected} />
        </div>
      )}

      <div className="flex items-center justify-center gap-2">
        <button
          type="button"
          className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
        >
          <img
            src={eyes}
            id={slug}
            onClick={(e) =>
              navigate(`/PrevisualisationQuestion/${e.target.id}`)
            }
          />
        </button>
        {type !== "delete" ? (
          <>
            <button
              type="button"
              className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
            >
              <img src={share} onClick={shareButton} />
            </button>
            <button
              type="button"
              className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
            >
              <img
                src={edit}
                id={slug}
                onClick={(e) => navigate(`/CreateQuestions/${e.target.id}`)}
              />
            </button>
          </>
        ) : null}
      </div>
      <div className="card-body flex flex-col justify-center items-center border-b-2 h-[48%]">
        <h1 className="card-title font-black text-center ">{name}</h1>
        <p className="font-thin">Crée le {moment(date).format("DD-MM-YYYY")}</p>
        <div className="py-1 px-1 border-t border-gray-100"></div>
      </div>
      <div className="flex justify-center items-center flex-1 ">
        <ul>
          <li className="flex justify-center items-center">
            <div className="flex items-center text-base font-normal text-black">
              <svg
                width="24"
                height="24"
                viewBox="0 0 38 33"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M34.6044 2H3.41758C2.63467 2 2 2.64919 2 3.45V29.55C2 30.3508 2.63467 31 3.41758 31H34.6044C35.3873 31 36.0219 30.3508 36.0219 29.55V3.45C36.0219 2.64919 35.3873 2 34.6044 2Z"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 10.7H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 16.5H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M9.7959 22.3H28.2245"
                  stroke="black"
                  strokeWidth="2.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              {type ? (
                <span className="flex-1 ml-3 whitespace-nowrap">
                  {number} questions
                </span>
              ) : (
                <span
                  className="flex-1 ml-3 whitespace-nowrap cursor-pointer"
                  src={eyes}
                  id={slug}
                  onClick={(e) => navigate(`/CreateQuestions/${e.target.id}`)}
                >
                  {number} questions
                </span>
              )}
            </div>
          </li>
        </ul>
      </div>
      {type === "delete" ? (
        <div className="flex justify-center">
          {!programe ? (
            <button
              className={`button-color relative  flex justify-center w-[70%]  py-2 px-4 border text-white text-sm font-medium   focus:outline-none  rounded-full h-10  items-center  gap-2 outline-none`}
              id="button"
              onClick={() => setProgram(true)}
              disabled={inst}
            >
              {/* <img src={Sent}></img> */}
              {"Programmer l'envoi "}
            </button>
          ) : (
            <input
              type={"date"}
              inputMode="numeric"
              defaultValue="2000-01-01"
              className="rounded-2xl"
              onChange={handleDateChange}
              value={selecteDate}
              disabled={inst}
              min={minDate.toISOString().slice(0, 10)}
            />
          )}
        </div>
      ) : null}

    
        <div className="flex  rounded-full justify-center items-center gap-2">
                  <input
                    id="mode1"
                    type="checkbox"
                    name="mode"
                    className="w-4 h-4 text-gray-600 bg-gray-100  accent-green-700    "
                    value="envoiInstantane"
                    // checked={sendOption === "envoiInstantane"}
                    // onChange={handleSendOptionChange}
                    onChange={handleCheckboxChange}
                    // checked={true}
                  />
                  <label
                    htmlFor="mode1"
                    className="ml-2 text-lg font-medium text-gray-900 dark:text-gray-300"
                  >
                    Envoi instantané
                  </label>
          </div>
    </div>
  );
}
