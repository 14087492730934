import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { CheckoutForm } from "./checkoutForm";
import NavbarEspaceLevel from "../espaceLevel/NavbarEspaceLevel";
import DetailsPayement from "../credits/DetailsPayement";
import checkb from "../../images/checkB.svg";
import checkA from "../../images/checkA.svg";
import checkOR from "../../images/checkOR.svg";
import { useSelector } from "react-redux";

const stripePromise = loadStripe(
  "pk_test_51ME7vmGkYvLIR5dKK8o9p5fXMYZQuI66xLdYeS4W0fpzymcWlYSjOOQ397xtR6tSlpnENunOOI2Rxzj0ul9XHuI000Hndb2NU4"
);

export default function StripeContainer() {
  const { pack } = useSelector((state) => state.payement);

  return (
    <div className="flex flex-col h-[100vh] bg-gray-100">
      <NavbarEspaceLevel />
      <div className="">
        <DetailsPayement />
        <div className="flex flex-col justify-center items-center p-8 h-[60%] ">
          <div className="pb-8">
            {pack.icon === "b" ? (
              <img src={checkb} />
            ) : pack.icon === "a" ? (
              <img src={checkA} />
            ) : (
              <img src={checkOR} />
            )}
          </div>
          <div className="bg-white rounded-lg sm:w-[20%] md:w-[30%] sm:flex-col md:flex-row items-center justify-center">
            {pack.icon === "b" ? (
              <span>Pack Br / 50 euros = 400 LEVELS</span>
            ) : pack.icon === "a" ? (
              <span>Pack Ar / 250 euros = 2500 LEVELS</span>
            ) : (
              <span>Pack Or / 500 euros = 6000 LEVELS</span>
            )}
          </div>
        </div>
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </div>
    </div>
  );
}
