import axios from "axios";
const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

// Register formation
const payementCard = async (cardData) => {
  try {
    const response = await axios.post(`${apiBaseURL}/payement`, cardData);
    return response;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const PayementService = {
  payementCard,
};

export default PayementService;
