import axios from "axios";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const access_token = JSON.parse(localStorage.getItem("persist:root"))?.user || {};

const axiosInterceptor = axios.create({
  baseURL: apiBaseURL,
  headers: {
    "Content-type": "application/json",
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    'Pragma': 'no-cache',
    'Expires': '0',
    Authorization:  typeof access_token === 'string' ? `Bearer ${JSON.parse(access_token)?.token}`: '{}' 
  },
  withCredentials: false,
});

axiosInterceptor.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    switch (error.response && error.response.status) {
      case 400:
        //handle token delete(we can do lie signout action but from the login is better)
        break;
      case 401:
        //handle token delete(we can do lie signout action but from the login is better)
        // window.location.pathname = "/";
        break;
      case 403:
        //handle token delete(we can do lie signout action but from the login is better)
        window.location.pathname = "/";
        localStorage.removeItem("persist:root");
        break;
      case 404:
        // window.location.pathname = "/error/404";
        break;
      case 500:
        // window.location.pathname = "/";
        break;
      default:
        break;
    }
    return Promise.reject(error);
  }
);

export default axiosInterceptor;
