import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reponseService from "./reponeServices";

const initialState = {
  reponse: {},
  currentReponse: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const createReponse = createAsyncThunk(
  "createReponse",
  async (input, thunkAPI) => {
    try {
      return await reponseService.createReponse(input);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOneReponse = createAsyncThunk(
  "getOneReponse",
  async (id, thunkAPI) => {
    try {
      return await reponseService.getOneReponse(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateReponse = createAsyncThunk(
  "updateReponse",
  async (input, thunkAPI) => {
    const id = input.id
    try {
      return await reponseService.updateReponse(input,id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
);


export const deleteReponse = createAsyncThunk(
  "deleteReponse",
  async (id, thunkAPI) => {
    
    try {
      return await reponseService.deleteOneReponse(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message)
    }
  }
);

export const reponseSlice = createSlice({
  name: "reponse",
  initialState,
  reducers: {
    resetReponse: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createReponse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createReponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.reponse = action.payload;
      })
      .addCase(createReponse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOneReponse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneReponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentReponse = action.payload;
      })
      .addCase(getOneReponse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateReponse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateReponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(updateReponse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteReponse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteReponse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(deleteReponse.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});

export const { resetReponse } = reponseSlice.actions;
export default reponseSlice.reducer;
