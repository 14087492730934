import React from 'react'
import GraduationCap from "../../images/GraduationCap.svg";
export default function SousNavresult({title,number}) {
  return (
    <div className="flex justify-between border-b-2 pb-2" >
        <span className="text-3xl font-semibold ">{title}</span>
        <div className="flex justify-evenly border-2 p-1 w-[5%] items-center  " >
            <img src={GraduationCap} alt="grad" className="w-[30%]" />
            <span>{number}</span>
        </div>
      </div>
  )
}
