import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import CardFormClient from "./CardFormClient";
import edit from "../../../images/edit.svg";

export default function AnimateurDetailSynthetiseur() {
    const navigate = useNavigate();
    let { id } = useParams();
  
    const [currentForm, setCurrentForm] = useState({});
    const [client, setClient] = useState({});
    const { allFormationsSynthetiseur } = useSelector((state) => state.synthetiseur);
  
    useEffect(() => {
      let arr = [...allFormationsSynthetiseur].filter((el) => {
        return el.id == id;
      });
      setCurrentForm(arr[0]);
      setClient(arr[0].Client);
    }, []);
  
    const formulaires = () => {
      let str = currentForm.animateurFormulaires || "[]";
      let forms = JSON.parse(str);
      let filter = currentForm.joinFormulaFormats?.filter((el) => {
        return forms.includes(String(el.formulaireId));
      });
   
      return filter?.map((el) => {
        let element = el.formulaire
        return (
          <CardFormClient
            name={element.titre}
            date={element.createdAt}
            slug={element.slug}
            number={element.questions?.length}
            id={element.id}
            key={element.id}
          />
        );
      });
    };
  
    return (
      <div className=" flex-1 bg-gray-100 flex flex-col px-16  ">
        <div className="basis-[15%] flex justify-between border-b-2 items-center pb-4">
          <div className="  flex gap-2 items-center">
            <button onClick={() => navigate(`/EspaceLevel/formation`)}>
              <span className=" text-xl font-semibold">ARCHIVES</span>
              <span className=" text-xl font-medium"> LEVEL</span>
            </button>
            <button
              onClick={() => navigate(`/EspaceLevel/synthetiseurDetail/${id}`)}
            >
              <span className=" text-lg font-medium">
                {" "}
                {"> Détails formation "}
              </span>
            </button>
            <span className=" text-lg font-medium">
              {" "}
              {"> Formulaires animateur"}
            </span>
          </div>
          <div>
          
          </div>
        </div>
        <div className="gap-2 overflow-auto p-10 grid grid-cols-3">
          {formulaires()}
        </div>
      </div>
    );
}
