import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import eyes from "../../../images/eyes.svg";
import share from "../../../images/share.svg";
import CartEspaceLevelCom from "../../../components/CartEspaceLevelCom";
import { useDispatch, useSelector } from "react-redux";
import { setPartageFormation } from "../../../features/formation/formationSlice";
import TableEspaceLevel from "./TableEspaceLevel";
import RowEspaceLevel from "./RowEspaceLevel";

export default function TabCommuns() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { allFormationsSynthetiseur } = useSelector(
    (state) => state.synthetiseur
  );

  const { trierPar, search } = useSelector((state) => state.formulaires);
  const { tabMode } = useSelector((state) => state.auth);
  const [lisFormation, setListFormation] = useState(allFormationsSynthetiseur);

  useEffect(() => {
    let arr = [...lisFormation];
    if (trierPar === "Plus récent") {
      arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setListFormation(arr);
    } else if (trierPar === "Nom A-Z") {
      arr.sort((a, b) => {
        if (a.titre < b.titre) {
          return -1;
        }
        if (a.titre > b.titre) {
          return 1;
        }
        return 0;
      });
      setListFormation(arr);
    } else if (trierPar === "Plus ancien") {
      setListFormation(allFormationsSynthetiseur);
    } else if (trierPar === "Nom Z-A") {
      arr.sort((a, b) => {
        if (a.titre < b.titre) {
          return 1;
        }
        if (a.titre > b.titre) {
          return -1;
        }
        return 0;
      });
      setListFormation(arr);
    }
  }, [trierPar, allFormationsSynthetiseur]);

  const redirection = (path) => {
    navigate(`${path}`);
  };

  const shareButton = (elid) => {
    dispatch(
      setPartageFormation({
        show: true,
        id: elid,
      })
    );
  };

  return (
    <>
      {!tabMode ? (
        <div className="grid grid-cols-4 gap-4  justify-start basis-[100%] overflow-auto py-8">
          {lisFormation?.map((element, index) => {
            if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
              return (
                <Fragment key={index}>
                  <CartEspaceLevelCom
                    name={element.titre}
                    date={element.startedAt}
                    id={element.id}
                    number={element.joinFormulaFormats?.length}
                    category={"formulaires"}
                    dateText={"Formation prévu le "}
                  >
                    <div className="flex items-center justify-center gap-2">
                      <button
                        type="button"
                        className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                      >
                        <img
                          src={eyes}
                          id={"slug"}
                          onClick={() =>
                            redirection(
                              `/EspaceLevel/synthetiseurDetail/${element.id}`
                            )
                          }
                        />
                      </button>
                      <button
                        type="button"
                        className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                        onClick={() => shareButton(element.id)}
                      >
                        <img src={share} id={"slug"} />
                      </button>
                      {/*   <button
                        type="button"
                        className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                        onClick={() =>
                          redirection(`/session/${element.id}`, {
                            code_session: 99,
                          })
                        }
                      >
                        <img src={play} id={"slug"} />
                      </button> */}
                      {/* <button
                       type="button"
                       className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                     >
                       <img src={Print} id={"slug"} />
                     </button> */}
                    </div>
                  </CartEspaceLevelCom>
                </Fragment>
              );
            }
          })}
        </div>
      ) : (
        <TableEspaceLevel
          type={"commun"}
          nom={"Nom de la formation"}
          form={"Formulaires"}
        >
          {lisFormation?.map((element, index) => {
            if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
              return (
                <Fragment key={"listForamation"+ index}>
                  <RowEspaceLevel
                    type={"commun"}
                    name={element.titre}
                    date={element.startedAt}
                    updatedate={element.updatedAt}
                    id={element.id}
                    number={element.joinFormulaFormats?.length}
                    text={"crée le "}
                    checkbox={"formation"}
                    category={"formulaires"}
                    archive
                  >
                    <div className="flex items-center justify-left gap-2">
                      <button
                        type="button"
                        className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() =>
                          redirection(
                            `/EspaceLevel/synthetiseurDetail/${element.id}`
                          )
                        }
                      >
                        <img src={eyes} id={"slug"} />
                      </button>
                      {/* <button
                        type="button"
                        className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() => shareButton(element.id)}
                      >
                        <img src={share} id={"slug"} />
                      </button> */}
                      {/* <button
                        type="button"
                        className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                        onClick={() => redirection(`/session/${element.id}`)}
                      >
                        <img src={play} id={"slug"} />
                      </button> */}
                    </div>
                  </RowEspaceLevel>
                </Fragment>
              );
            }
          })}
        </TableEspaceLevel>
      )}
    </>
  );
}
