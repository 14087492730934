import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import plus from "../images/+.svg";
import {
  createCategorieFormulaire,
  getAllCategorieFormulaires,
} from "../features/categorieFormulaires/categorieFormulaireSlice";

export default function DropDownCategorie({
  showCategorie,
  selectedListeCategorie,
  setSelectedListeCategorie,
}) {
  const { categorieFormulaires } = useSelector(
    (state) => state.categorieFormulaire
  );
  const dispatch = useDispatch();

  const [cate, setCate] = useState("");
  const [refrech, setRefrech] = useState(false);
  const [listeCategories, setListeCategorie] = useState(categorieFormulaires);
  useEffect(() => {
    setListeCategorie([...categorieFormulaires])
  }, [refrech]);
  const { user } = useSelector((state) => state.auth);

  const onCheck = (e) => {
    let arr = [...selectedListeCategorie];
    if (e.target.checked) {
      let obj = listeCategories.filter((el) => {
        return el.id == e.target.id;
      })[0];
      arr.push(obj);
      setSelectedListeCategorie(arr);
    } else {
      let selected = arr.filter((el) => {
        return el.id != e.target.id;
      });
      setSelectedListeCategorie(selected);
    }
  };
  const create = async () => {
    if (cate.length > 0) {
      let obj = {
        organismeId: user._id,
        titre: cate,
      };
      await dispatch(createCategorieFormulaire(obj));
      await dispatch(getAllCategorieFormulaires(user._id));
      setRefrech(!refrech);
      setCate("");
    }
  };

  const listeToSelect = () => {
    let arr = listeCategories
    return arr.map((el) => {
      if(el.titre !== "partager avec moi"){

        return (
          <li key={el.id}>
          <div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
            <input
              id={el.id}
              type="checkbox"
              value=""
              className="w-4 h-4  accent-green-700"
              onChange={onCheck}
            ></input>
            <label
              htmlFor={el.id}
              className="py-2 ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
            >
              {el.titre}
            </label>
          </div>
        </li>
      );
    }
    });
  };
  const search = (e) => {
    let arr = [...categorieFormulaires].filter((el) => {
      return el.titre?.toLowerCase()?.includes(e.target.value?.toLowerCase());
    });
    setListeCategorie(arr);
  };
  return (
    <>
      {showCategorie && (
        <div className="rounded-lg flex flex-col mt-2">
          <div className=" cateAjout flex rounded-t-3xl py-2 px-4 gap-2 items-center justify-between ">
            <img src={plus} alt="plus" className="h-4 cursor-pointer" onClick={create} />
            <input
              className="cateAjout flex-1 outline-gray-200  "
              placeholder="Ajouter une catégorie"
              onChange={(e) => setCate(e.target.value)}
              value={cate}
            />
            <button
              className="rounded-3xl border-lime-600 border-2  text-black w-[20%]"
              onClick={create}
              id="creer"
            >
              CRÉER
            </button>
          </div>
          <div className=" border-x py-2 px-4 flex justify-between">
            <input
              className="placeholder:text-gray-300 outline-gray-200   w-[60%]"
              placeholder="Chercher une catégorie"
              onChange={search}
            />
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <ul
            className="overflow-y-auto px-3 pb-3 h-24 text-sm text-gray-700 dark:text-gray-200 border-x-2"
            aria-labelledby="dropdownSearchButton"
          >
            {listeToSelect()}
          </ul>
        </div>
      )}
    </>
  );
}
