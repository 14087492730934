import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import CartEspaceLevelCom from "../../../components/CartEspaceLevelCom";
import eyes from "../../../images/eyes.svg";
import edit from "../../../images/edit.svg";
import { setMultiplePartage, setPartage } from "../../../features/formulaires/formulaireSlice";
import share from "../../../images/share.svg";
import TableEspaceLevel from "../synthetiseurDetails/TableEspaceLevel";
import RowEspaceLevel from "../synthetiseurDetails/RowEspaceLevel";
import ModalDelete from "../../../components/ModalDelete";
import Line from "../../../images/Line.svg";
import Filtre from "../../../images/Filtre.svg";
import Partager from "../../../images/Partager.svg";
import DuplicateDelete from "./DuplicateDelete";

export default function DetailCatePartaged() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  
  let { id } = useParams();
  const [currentForm, setCurrentForm] = useState([]);
 
  const { tabMode } = useSelector((state) => state.auth);
  const redirection = (path) => {
    navigate(`${path}`);
  };
  const { trierPar, search, formulaires } = useSelector((state) => state.formulaires);


  const shareButton = (elid) => {
    dispatch(
      setPartage({
        show: true,
        id: elid,
      })
    );
  };
  
  useEffect(() => {
    let arr = currentForm ? [...currentForm]: []
    if (trierPar === "Plus récent") {
      arr.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setCurrentForm(arr);
    } else if (trierPar === "Nom A-Z") {
      arr.sort((a, b) => {
        if (a.titre < b.titre) {
          return -1;
        }
        if (a.titre > b.titre) {
          return 1;
        }
        return 0;
      });
      setCurrentForm(arr);
    } else if (trierPar === "Plus ancien") {
        arr.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        setCurrentForm(arr);
    } else if (trierPar === "Nom Z-A") {
      arr.sort((a, b) => {
        if (a.titre < b.titre) {
          return 1;
        }
        if (a.titre > b.titre) {
          return -1;
        }
        return 0;
      });
      setCurrentForm(arr);
    }
  }, [trierPar]);

  const formul = () => {
    return (
      <>
        {!tabMode ? (
          <div className="grid grid-cols-4 gap-4  justify-start basis-[100%] overflow-auto py-8">
            {currentForm?.map((element) => {
              if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
                return (
                  <>
                    <CartEspaceLevelCom
                      name={element.titre}
                      date={element.createdAt}
                      id={element.id}
                      number={element.questions.length}
                      checkbox={"formulaire"}
                      category={"Questions"}
                      dateText={"Crée le "}
                    >
                      <div className="flex items-center justify-center gap-2">
                        <button
                          type="button"
                          className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                        >
                          <img
                            src={eyes}
                            id={"slug"}
                            onClick={() =>
                              redirection(
                                `/PrevisualisationQuestion/${element.slug}`
                              )
                            }
                          />
                        </button>
                        <button
                          type="button"
                          className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                          onClick={() => shareButton(element.id)}
                        >
                          <img src={share} id={"slug"} />
                        </button>
                        <button
                          type="button"
                          className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2 mb-2"
                          onClick={() =>
                            redirection(`/CreateQuestions/${element.slug}`)
                          }
                        >
                          <img src={edit} id={"slug"} />
                        </button>
                      </div>
                    </CartEspaceLevelCom>
                  </>
                );
              }
            })}
          </div>
        ) : (
          <TableEspaceLevel
            type={"commun"}
            nom={"Nom de la formulaire"}
            form={"Questions"}
          >
            {currentForm?.map((element) => {
              if (search.length === 0 || element.titre?.toLowerCase()?.includes(search?.toLowerCase())) {
                return (
                  <>
                    <RowEspaceLevel
                      type={"commun"}
                      name={element.titre}
                      date={element.createdAt}
                      updatedate={element.updatedAt}
                      id={element.id}
                      number={element.questions.length}
                      text={"créer le "}
                      checkbox={"formulaire"}
                    >
                      <div className="flex items-center justify-left gap-2">
                        <button
                          type="button"
                          className=" button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                          onClick={() =>
                            redirection(
                              `/PrevisualisationQuestion/${element.slug}`
                            )
                          }
                        >
                          <img src={eyes} id={"slug"} />
                        </button>
                        <button
                          type="button"
                          className="button-color rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                          onClick={() => shareButton(element.id)}
                        >
                          <img src={share} id={"slug"} />
                        </button>
                        <button
                          type="button"
                          className="button-color  rounded-lg text-sm px-2 py-1.5 text-center mr-2"
                          onClick={() =>
                            redirection(`/CreateQuestions/${element.slug}`)
                          }
                        >
                          <img src={edit} id={"slug"} />
                        </button>
                      </div>
                    </RowEspaceLevel>
                  </>
                );
              }
            })}
          </TableEspaceLevel>
        )}
      </>
    );
  };
  
  

  useEffect(() => {
    let arr = [...formulaires].filter((el) => {
  
      return el.partager;
    });
    setCurrentForm(arr);
  }, [formulaires]);
  return (
    <div className=" flex-1 bg-gray-100 flex flex-col px-16  ">
      <div className=" basis-[15%] flex items-center  justify-between ">
        <div className="  flex gap-2 items-center">
          <button onClick={() => navigate("/EspaceLevel/formulaires/ListCate")}>
            <span className=" text-xl  font-semibold">FORMULAIRES</span>
            <span className=" text-xl font-medium"> LEVEL</span>
          </button>
          <span className=" text-lg font-medium cursor-pointer" onClick={() => navigate("/EspaceLevel/formulaires/ListCate")}  > {"> Catégories"}</span>
          <span className=" text-lg font-medium">
            {" "}
            {`> Détails partager avec moi`}
          </span>
        </div>
        <div className="  flex justify-end gap-7  ">
              <div className=" w-3/6 ">
                {showModal && <ModalDelete showModal={showModal} setShowModal={setShowModal} />}
              </div>
              {/* <Partager/> */}
              <img
                src={Partager}
                alt="Level"
                className="h-9 "
                onClick={() => {
                  dispatch(setMultiplePartage(true));
                }}
              />
              <img src={Line} alt="Level" className="h-10 " />
              <img
                src={Filtre}
                alt="Level"
                className="h-10 cursor-pointer "
                onClick={() => setShow(!show
                  )}
              />
              <DuplicateDelete show={show} setShow={setShowModal} />
            </div>
      </div>
    
        {formul()}
     
    </div>
  );
}
