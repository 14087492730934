
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import formulaireService from "./formulaireService";

const initialState = {
  formulaires: [],
  currentFormulaire: {},
  selectedForm: [],
  trierPar: "Plus ancien",
  showModalForm : false,
  showModalPartage : false,
  showMultiplePartage: true,
  PartageId: "",
  liveFormulaire: {},
  search : "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  indexLive: 1,
  refrechCard: false,
};

export const createFormulaire = createAsyncThunk(
  "createFormulaire",
  async (formulaire, thunkAPI) => {
   
    
    try {
      return await formulaireService.createFormulaire(formulaire);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getAllFormulaires = createAsyncThunk(
  "getAllFormulaires",
  async (id, thunkAPI) => {
    try {
      return await formulaireService.getAllFormulaires(id);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getOneFormulaire = createAsyncThunk(
  "getOneFormulaire",
  async (slug, thunkAPI) => {
    try {
      return await formulaireService.getOneFormulaire(slug);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const duplicateFormulaire = createAsyncThunk(
  "duplicateFormulaire",
  async (data, thunkAPI) => {
    try {
      return await formulaireService.duplicateFormulaire(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteFormulaire = createAsyncThunk(
  "deleteFormulaire",
  async (data, thunkAPI) => {
    try {
      
      return await formulaireService.deleteFormulaire(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateFormulaire = createAsyncThunk(
  "updateFormulaire",
  async (data, thunkAPI) => {
    try {
      
      return await formulaireService.updateFormulaire(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const partageFormulaire = createAsyncThunk(
  "partageFormulaire",
  async (data, thunkAPI) => {
    try {  
      return await formulaireService.PartageFormulaire(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const partageFormulaireMultiple = createAsyncThunk(
  "partageFormulaireMultiple",
  async (data, thunkAPI) => {
    try {  
      return await formulaireService.PartageFormulaireMultiple(data);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const formulaireSlice = createSlice({
  name: "formulaire",
  initialState,
  reducers: {
    resetFormulaire: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
      state.currentFormulaire = {};
      state.selectedForm = [];
      state.showModalForm = false;
      state.showModalPartage = false;
      state.PartageId = ""
      state.showMultiplePartage= false;
      state.search= ""
      state.indexLive = 1
    },
    selectTrierPar: (state, action) => {
      state.trierPar = action.payload;
    },
    dispatchSearch: (state,action) => {
      state.search = action.payload
    },
   dispatchSelect : (state,action) =>{
    state.selectedForm = action.payload
   },
   dispShowModelForm : (state,action) => {
    state.showModalForm = action.payload
   },
   setPartage : (state,action) => {
    state.showModalPartage = action.payload.show
    state.PartageId = action.payload.id
   },
   setLiveFormulaire : (state,action) => {
    state.liveFormulaire = action.payload 
   },
   setIndexLive : (state,action) => {
    state.indexLive = action.payload
   },
   setMultiplePartage : (state,action) => {
    state.showMultiplePartage = action.payload
   },
   setRefrechCard : (state) => {
    state.refrechCard = !state.refrechCard
   },
   setIsError : (state,action) => {
    state.isError = action.payload
   }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFormulaire.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFormulaire.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentFormulaire = action.payload;
      })
      .addCase(createFormulaire.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getAllFormulaires.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFormulaires.fulfilled, (state, action) => {
        if(action.payload === 'Not authorized') {
          state.isLoading = false;
          state.isSuccess = true;
          state.formulaires = [];
        } else {
          state.isLoading = false;
          state.isSuccess = true;
          state.formulaires = action.payload;
        }
        
      })
      .addCase(getAllFormulaires.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getOneFormulaire.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getOneFormulaire.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentFormulaire = action.payload;
      })
      .addCase(getOneFormulaire.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(duplicateFormulaire.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(duplicateFormulaire.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formulaires = action.payload;
      })
      .addCase(duplicateFormulaire.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(deleteFormulaire.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFormulaire.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.formulaires = action.payload;
      })
      .addCase(deleteFormulaire.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateFormulaire.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFormulaire.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.currentFormulaire = action.payload;
      })
      .addCase(updateFormulaire.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});

export const { resetFormulaire,setIsError,setRefrechCard,setIndexLive,setMultiplePartage,setLiveFormulaire,setPartage,selectTrierPar,dispatchSearch, dispatchSelect,dispShowModelForm } = formulaireSlice.actions;
export default formulaireSlice.reducer;
