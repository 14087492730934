import React, { useEffect, useState } from "react";
import "./styles.css";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDispatch, useSelector } from "react-redux";
import { Button, DatePicker, Input } from "antd";
import {
  createCalDavEvent,
  getAllEventWithCaldav,
} from "../../../../features/event/eventSlice";
import dayjs from "dayjs";
import { Checkbox } from "antd";
import TopHeaderIcon from "./icons/TopHeaderIcon";
import CloseIcon from "./icons/CloseIcon";
import LocalisationIcon from "./icons/LocalisationIcon";
import NotesIcon from "./icons/NotesIcon";
import TextArea from "antd/es/input/TextArea";
import { toast } from "react-toastify";
import dayjsPluginUTC from "dayjs-plugin-utc";

const { RangePicker } = DatePicker;
dayjs.extend(dayjsPluginUTC);
function AddEvent({ closeDialog, selectInfo }) {
  const dispatch = useDispatch();
  const [showTime, setShowTime] = useState(true);
  const [loadings, setLoadings] = useState([]);
  const [data, setData] = useState({
    titre: "",
    startedAt: selectInfo?.start,
    finishedAt: selectInfo?.end,
    description: "",
    emplacement: "",
  });

  useEffect(() => {
    setData((prev) => {
      return {
        titre: "",
        startedAt: selectInfo?.start,
        finishedAt: selectInfo?.end,
        description: "",
        emplacement: "",
      };
    });
  }, [selectInfo]);

  const AddEvent = async () => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[0] = true;
      return newLoadings;
    });

    dispatch(createCalDavEvent(data)).then((res) => {
      if (res.payload) {
        dispatch(getAllEventWithCaldav()).then((res) => {
          setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[0] = false;
            return newLoadings;
          });
          closeDialog();
        });
      } else {
        setLoadings((prevLoadings) => {
          const newLoadings = [...prevLoadings];
          newLoadings[0] = false;
          return newLoadings;
        });
      }
    });
  };

  const handleChange = (e) => {
    if (e?.target?.name) {
      setData((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    } else {
      setData((prev) => {
        return {
          ...prev,
          startedAt: e ? e[0] : Date.now(),
          finishedAt: e ? e[1] : Date.now(),
        };
      });
    }
  };

  const onOk = (value) => {
    setData((prev) => {
      return { ...prev, startedAt: value[0], finishedAt: value[1] };
    });
  };

  return (
    <div className="event-popover__inner">
      <div className="event-popover__top-right-actions">
        <button onClick={closeDialog} type="button" title="Close">
          <span className="material-design-icon close-icon">
            <CloseIcon />
          </span>
        </button>
      </div>
      <div className="illustration-header">
        <TopHeaderIcon />
      </div>
      <div className="property-title">
        <div className="property-title__input">
          <Input
            type="text"
            value={data.titre}
            name="titre"
            onChange={handleChange}
            placeholder="Titre de l'événement"
          />
        </div>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr">
        <div className="start-end-dates">
          <RangePicker
            format="DD-MM-YYYY HH:mm"
            style={{ minWidth: "400px" }}
            showTime={showTime}
            onOk={onOk}
            value={[
              dayjs(data.startedAt.toISOString()),
              dayjs(data.finishedAt.toISOString()),
            ]}
            onChange={handleChange}
            name="date-range"
          />
        </div>
      </LocalizationProvider>
      <div className="property-text">
        <span
          aria-label="Lieu"
          role="img"
          className="material-design-icon map-marker-icon property-text__icon dicon"
        >
          <LocalisationIcon />
        </span>
        <div className="property-text__input">
          <TextArea
            placeholder="Ajouter un lieu"
            rows="1"
            title="Lieu"
            value={data.emplacement}
            name="emplacement"
            onChange={handleChange}
            autoSize={{ minRows: 2, maxRows: 2 }}
          />
        </div>
      </div>
      <div className="property-text">
        <span
          aria-label="Description"
          role="img"
          className="material-design-icon text-box-outline-icon property-text__icon dicon"
        >
          <NotesIcon />
        </span>
        <div className="property-text__input">
          <TextArea
            placeholder="Ajouter une description"
            rows="1"
            title="description"
            value={data.description}
            name="description"
            onChange={handleChange}
            autoSize={{ minRows: 3, maxRows: 3 }}
          />
        </div>
      </div>
      <Button
        type="primary"
        loading={loadings[0]}
        onClick={AddEvent}
        style={{ margin: "20px auto", display: "block" }}
      >
        Enregistrer
      </Button>
    </div>
  );
}

export default AddEvent;
