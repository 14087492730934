import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { deleteFormationCategorie, dispatchSelectCateFormation } from "../features/categorieFormation/categorieFormationSlice";
import {
  deleteFormulaireCategorie,
  dispatchSelectCate,
} from "../features/categorieFormulaires/categorieFormulaireSlice";
import { deleteFormation, dispatchSelectFormation } from "../features/formation/formationSlice";
import {
  deleteFormulaire,
  dispatchSelect,
  setRefrechCard,
} from "../features/formulaires/formulaireSlice";
import Button from "./Button";

export default function ModalDelete({ showModal, setShowModal }) {
  const dispatch = useDispatch();
  const { selectedForm } = useSelector((state) => state.formulaires);
  const { user } = useSelector((state) => state.auth);
  const {  selectedFormations } = useSelector(
    (state) => state.formation
  );
  const { selectedCateFormation } = useSelector(
    (state) => state.categorieFormation
  );
  const location = useLocation();
  const { selectedCate } = useSelector((state) => state.categorieFormulaire);
  const name = location.pathname.includes("ListCate")
    ? "categories"
    : location.pathname.includes("formulaires")
    ? "formulaires"
    : "formations";
  const deleteForm = () => {
    if (location.pathname.includes("formulaires")) {
      if (selectedForm.length > 0 && !location.pathname.includes("ListCate")) {
        let arr = [...selectedForm].map((el) => el.id);
        dispatch(deleteFormulaire({ formulaires: arr, organismeId: user._id }));
        dispatch(dispatchSelect([]));
        dispatch(setRefrechCard());
        
      } else if (
        selectedCate.length > 0 &&
        location.pathname.includes("ListCate")
      ) {
        let arr = [...selectedCate].map((el) => el.id);
        dispatch(
          deleteFormulaireCategorie({ categories: arr, organismeId: user._id })
        );
        dispatch(dispatchSelectCate([]));
        dispatch(setRefrechCard());
      
      }
    } else if (location.pathname.includes("formation") ) {
     
      if (selectedFormations.length > 0 && !location.pathname.includes("ListCate") ) {
        let arr = [...selectedFormations].map((el) => {
          let obj = {};
          obj.id = el.id;
          obj.organismeId = el.organismeId;
          return obj;
        });
        dispatch(deleteFormation(arr));
        dispatch(dispatchSelectFormation([]));
        dispatch(setRefrechCard());
      }else if (
        selectedCateFormation.length > 0 &&
        location.pathname.includes("ListCate")
      ) {
      
        let arr = [...selectedCateFormation].map((el) => el.id);
        dispatch(
          deleteFormationCategorie({ categories: arr, organismeId: user._id })
        );
        dispatch(dispatchSelectCateFormation([]));
        dispatch(setRefrechCard());
      }
    }
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-900/90">
            {/*content*/}
            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col justify-center gap-6 bg-white opacity-100 outline-none focus:outline-none pt-9 px-16 ">
              <span className="text-center">
                Etes vous sûr de vouloir supprimer ces {name}
              </span>
              <div className="flex items-center justify-center gap-2 border-t border-solid border-slate-200 rounded-b py-2">
                <button
                  type="submit"
                  className={`group relative  flex justify-center w-[40%]  py-6 px-4 border  border-lime-600  text-sm font-medium    hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-10  items-center  gap-2 `}
                  id="button"
                  onClick={() => {
                  setShowModal(false)
                  }}
                >
                  Annuler
                </button>

                <Button
                  text={"Confirmer"}
                  classname={"w-[40%]"}
                  func={deleteForm}
                />
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
