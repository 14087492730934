import React, { useState, useEffect } from "react";
import Line from "../../../images/Line.svg";
import Filtre from "../../../images/Filtre.svg";
import Partager from "../../../images/Partager.svg";

import { useSelector, useDispatch} from "react-redux";
import ModalCategori from "../../../components/ModalCategori";
import Empty from "../Empty";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import { getAllFormation, setShowMultiple } from '../../../features/formation/formationSlice';
import DuplicateDeleteFormation from "./DuplicateDeleteFormation";
import { setModelCateFormation } from "../../../features/categorieFormation/categorieFormationSlice";
import ModalDelete from "../../../components/ModalDelete";

export default function Listeformations() {
  let navigate = useNavigate();
  const location = useLocation();
  let form = location.pathname.includes("ListCate") ? "butop" : "but";
  let catégories = location.pathname.includes("ListCate") ? "but" : "butop";
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { allFormations } = useSelector((state) => state.formation);
  const { showModelCateFormation } = useSelector(
    (state) => state.categorieFormation
  )
  useEffect(async () => {
    await  dispatch(getAllFormation(user?._id))
  }, []);
 
  const onclickCatégories = () => {
    navigate("/EspaceLevel/formation/ListCate")
   
  };

  const onclickFormulaires = () => {
    navigate("/EspaceLevel/formation/ListForm")
  
  };

  const handleClick = () => {
    navigate("/FabriqueFormation");
  };

  return (
    <>
      {allFormations.length === 0 ? (
        <Empty
          nouveau="nouvelles"
          majType={"FORMATION"}
          setShowModal={handleClick}
          type="formation"
          one="UNE"
        />
      ) : (
        <div className=" flex-1 bg-gray-100 flex flex-col px-16 max-h-screen">
          <div className="border-b-2 basis-[15%] px-3 flex items-center justify-center">
            <div className=" basis-[40%] flex gap-2">
              <span className=" text-lg font-semibold">FORMATIONS</span>
              <span className=" text-lg font-medium">LEVEL</span>
            </div>
            <div className="basis-[60%] flex justify-end gap-7 ">
              <div className="w-3/6  ">
                <button
                  type="submit"
                  className={`group relative w-full flex justify-center py-3 px-4 border  border-lime-600  text-sm font-medium    hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-10  items-center transition-all duration-300	`}
                  onClick={() => dispatch(setModelCateFormation({ showModelCateFormation: true, toEdit: false}))}
                  id="button"
                >
                  {"AJOUTER  UNE CATÉGORIE"}
                </button>
                { showModelCateFormation && <ModalCategori
                  showModal={showModelCateFormation}
                  setShowModal={setModelCateFormation}
                />}
                {showModal && <ModalDelete showModal={showModal} setShowModal={setShowModal} />}
              </div>
              <img src={Partager} alt="Level" className="h-9 "  onClick={()=>{
                     dispatch(setShowMultiple(true))
              }} />
              <img src={Line} alt="Level" className="h-10 " />
              <img src={Filtre} alt="Level" className="h-10 cursor-pointer" onClick={()=>setShow(!show)} />
              <DuplicateDeleteFormation show={show} setShow={setShow} setShowModal={setShowModal} />
            </div>
          </div>
          <div className="  basis-[15%] flex justify-center items-center ">
            <div className={`w-[49%] h-16 rounded-l-lg ${form} flex`}>
              <button
                className="flex-1 rounded-r-lg text-white text-xl"
                onClick={onclickFormulaires}
              >
                Formations communs
              </button>
            </div>
            <div
              className={`w-[49%] h-16 rounded-r-lg ${catégories} flex`}
              onClick={onclickCatégories}
            >
              <button className="flex-1 text-white text-xl">
                Formations par catégories
              </button>
            </div>
          </div>
          <div className="flex flex-wrap  justify-start gap-5 overflow-auto py-8 px-3">
            <Outlet />
          </div>
        </div>
      )}
    </>
  );
}
