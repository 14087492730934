import React, { useState,useEffect } from "react";
import {  Outlet } from "react-router-dom";
import NavbarEspaceLevel from "../layout/espaceLevel/NavbarEspaceLevel";
import SideBarEvaluation from "../components/SideBarEvaluation";


export default function Evaluation() {

  return (
    <div className="flex flex-col h-screen">
      <NavbarEspaceLevel />
      <div className="flex flex-row flex-1">
        <SideBarEvaluation />
        <Outlet />
      </div>
    </div>
  );
}
