import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import AdminstratifCardSynthetiseur from "./detailsSynthetiseurCards/AdminstratifCardSynthetiseur";
import AnimateurSynthetiseurCard from "./detailsSynthetiseurCards/AnimateurSynthetiseurCard";
import ClientSynthetiseurCard from "./detailsSynthetiseurCards/ClientSynthetiseurCard";
import StagiairesSynthetiseurCard from "./detailsSynthetiseurCards/StagiairesSynthetiseurCard";
import WorkflowSynthetiseur from "./detailsSynthetiseurCards/WorkflowSynthetiseur";
import { getAllFormationSynthetiseur } from "../../../features/synthetiseur/synthetiseurSlice";
import { getOneFormation } from "../../../features/formation/formationSlice";

export default function DetailFormationSynthetiseur() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
  
    let {id} = useParams()
    // const [currentForm, setCurrentForm] = useState({});
    const { allFormationsSynthetiseur } = useSelector((state) => state.synthetiseur);
    const { liveFormation } = useSelector((state) => state.formation);  
    const { user } = useSelector((state) => state.auth);
    const currentForm = liveFormation;  

    useEffect(async() => {
      dispatch(getOneFormation(id));
    //   if(allFormationsSynthetiseur.length === 0) 
    //  { dispatch(getOneFormation(id));
    //   //  dispatch(dispatch(getAllFormationSynthetiseur(user?._id)));
    //   return;
    //  }
    //   let arr = [...allFormationsSynthetiseur].filter((el) => {
    //     return el.id == id;
    //   });
    //   setCurrentForm(arr[0]);
    }, []);
    return (
      <div className=" flex-1 bg-gray-100 flex flex-col px-16 ">
        <div className=" basis-[15%] flex items-center ">
          <div className=" basis-[40%] flex gap-2 items-center">
            <button onClick={()=>navigate("/EspaceLevel/synthetiseur")}>
              <span className=" text-xl font-semibold">Archives</span>
              <span className=" text-xl font-medium"> LEVEL</span>
            </button>
            <span className=" text-lg font-medium"> {"> Détails formation"}</span>
          </div>
        </div>
        <div className="  basis-[85%] grid grid-cols-4 gap-4 pl-2  justify-start  overflow-auto py-8">
          <AdminstratifCardSynthetiseur
            date={currentForm?.createdAt}
            id={currentForm?.id}
          />
          <ClientSynthetiseurCard
            date={currentForm?.createdAt}
            id={currentForm?.id}
          />
          <AnimateurSynthetiseurCard
            date={currentForm?.createdAt}
            id={currentForm?.id}
          />
          <StagiairesSynthetiseurCard
            date={currentForm?.createdAt}
            id={currentForm?.id}
          />
          <WorkflowSynthetiseur
            date={currentForm?.createdAt}
            id={currentForm?.id}
           
          />
        </div>
      </div>
    );
}
