import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  duplicateFormulaire,
  deleteFormulaire,
  dispatchSelect,
  setRefrechCard
} from "../../../features/formulaires/formulaireSlice";
import uuid from "react-uuid";
import { useLocation } from "react-router-dom";
export default function DuplicateDelete({ show,setShow }) {
  const { selectedForm } = useSelector((state) => state.formulaires);
  const { user} = useSelector(
    (state) => state.auth
  )
  const location = useLocation();
  
  const dispatch = useDispatch();
  const duplicate = () => {
    if(selectedForm.length > 0){
    let arr = [...selectedForm];
    let newArray = arr.map((el) => {
      let obj = { ...el };
      delete obj.id;
      delete obj.createdAt
      obj.titre = obj.titre + " copie"
      obj.slug = uuid();
      let questions = [...obj.questions].map((element) => {
        let question = { ...element };
        delete question.id;
        delete question.createdAt
        let reponses = [...question.reponses].map((elem) => {
          let reponse = { ...elem };
          delete reponse.id;
          delete reponse.createdAt
          return reponse;
        });
        question.reponses = reponses;
        return question;
      });
      obj.questions = questions;
      return obj;
    });
    dispatch(duplicateFormulaire(newArray));
    dispatch(dispatchSelect([]))
    dispatch(setRefrechCard())
    
  }
  };

  const deleteForm = () => {
    setShow(true)
  };
  return (
    <>
      {show && (
        <div
          id="dropdownDivider 22"
          className={`z-10 w-[8%] mt-11 bg-white divide-y  divide-gray-100 shadow rounded-3xl  absolute `}
        >
          <div className="  basis-[15%] flex justify-center items-center "></div>
          <div className="  basis-[70%] flex flex-col  justify-center items-center overflow-auto py-2">
           {!location.pathname.includes("ListCate")? <div className=" cursor-pointer" onClick={duplicate}>
              Dupliquer
            </div> : null}
            <div className=" text-red-400 cursor-pointer" onClick={deleteForm} >Supprimer</div>
          </div>
        </div>
      )}
    </>
  );
}
