import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

export default function DropDownFormulaire({
  showFormulaire,
  selectedListeFormulaire,
  setSelectedListeFormulaire,
}) {
  const { formulaires } = useSelector((state) => state.formulaires);
  const [listeFormulaire, setListeFormulaire] = useState([...formulaires]);

  const onCheck = (e) => {
    let arr = [...selectedListeFormulaire];
    if (e.target.checked) {
      let obj = listeFormulaire.filter((el) => {
        return el.id == e.target.id;
      })[0];
      arr.push(obj);
      setSelectedListeFormulaire(arr);
    } else {
      let selected = arr.filter((el) => {
        return el.id != e.target.id;
      });
      setSelectedListeFormulaire(selected);
    }
  };
  const listeToSelect = () => {
    let arr = listeFormulaire;
    return arr.map((el) => {
      let checked = selectedListeFormulaire.find(element=> {
        return element.id == el.id
      } ) 
      checked = checked? true : false
      return (
        <li key={el.id}>
          <div className="flex items-center pl-2 rounded hover:bg-gray-100 dark:hover:bg-gray-600">
            <input
              id={el.id}
              type="checkbox"
              value=""
              className="w-4 h-4  accent-green-700   "
              onChange={onCheck}
              defaultChecked={checked}
            ></input>
            <label
              htmlFor={el.id}
              className="py-2 ml-2 w-full text-sm font-medium text-gray-900 rounded dark:text-gray-300"
            >
              {el.titre}
            </label>
          </div>
        </li>
      );
    });
  };
  const search = (e) => {
    let arr = [...formulaires].filter((el) => {
      return el.titre?.toLowerCase()?.includes(e.target.value?.toLowerCase());
    });
    setListeFormulaire(arr);
  };

  return (
    <>
      {showFormulaire && (
        <div className="rounded-lg flex flex-col mt-2 rounded-t-3xl">
          <div className=" border py-2 px-4 flex justify-between rounded-t-3xl">
            <input
              className="placeholder:text-gray-300 outline-gray-200  w-[60%]"
              placeholder="Chercher une catégorie"
              onChange={search}
            />
            <svg
              className="w-5 h-5 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <ul
            className="overflow-y-auto px-3 pb-3 h-24  text-sm text-gray-700 dark:text-gray-200 border-x-2"
            aria-labelledby="dropdownSearchButton"
          >
            {listeToSelect()}
          </ul>
        </div>
      )}
    </>
  );
}
