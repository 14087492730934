import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import agendaService from "./agendaService";

const initialState = {
  showModalForm: false,
  showModalFormationDetails: false,
  showModalEventDetails: false,
  showModalCreateEvent: false,
  showModalEditFormationDetails: false,
  showModelCaldav: false,
  showModelImport: false,
  showModelDelete: false,
  showEventEditOptions: false,
  isLoadingCalavLink: false,
  caldavData: {},
};

export const getCaldavLink = createAsyncThunk(
  "getCaldavLink",
  async (userData, thunkAPI) => {
    try {
      return await agendaService.getCaldavLink(userData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const agendaSlice = createSlice({
  name: "agendaModalShow",
  initialState,
  reducers: {
    dispShowAgendaModelForm: (state, action) => {
      state.showModalForm = action.payload;
    },
    dispShowModelFormationDetails: (state, action) => {
      state.showModalFormationDetails = action.payload;
    },
    dispShowModelEditFormationDetails: (state, action) => {
      state.showModalEditFormationDetails = action.payload;
    },
    dispShowModelEventDetails: (state, action) => {
      state.showModalEventDetails = action.payload;
    },
    dispShowModelCreateEvent: (state, action) => {
      state.showModalCreateEvent = action.payload;
    },
    dispShowModelCaldav: (state, action) => {
      state.showModelCaldav = action.payload;
    },
    dispShowModelImport: (state, action) => {
      state.showModelImport = action.payload;
    },
    dispShowModelDelete: (state, action) => {
      state.showModelDelete = action.payload;
    },
    dispShowModelEventEditOptions: (state, action) => {
      state.showEventEditOptions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCaldavLink.pending, (state) => {
        state.isLoadingCalavLink = true;
      })
      .addCase(getCaldavLink.fulfilled, (state, action) => {
        state.isLoadingCalavLink = false;
        state.caldavData = action.payload;
        state.isSuccess = true;
      })
      .addCase(getCaldavLink.rejected, (state, action) => {
        state.isLoadingCalavLink = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const {
  dispShowAgendaModelForm,
  dispShowModelFormationDetails,
  dispShowModelEventDetails,
  dispShowModelEditFormationDetails,
  dispShowModelCreateEvent,
  dispShowModelCaldav,
  dispShowModelImport,
  dispShowModelDelete,
  dispShowModelEventEditOptions,
} = agendaSlice.actions;
export default agendaSlice.reducer;
