import React, { useState } from "react";
import Select from "../components/Select";
import DropDownCategorie from "./DropDownCategorie";
import DropDownFormulaire from "./DropDownFormulaire";
import categorie from "../images/Categories.svg";
import Button from "./Button";
import { useSelector, useDispatch } from "react-redux";
import { assigneFormulaireToCategorie } from "../features/categorieFormulaires/categorieFormulaireSlice";
import { useEffect } from "react";

export default function Modal({ showModal, setShowModal }) {
  const dispatch = useDispatch();

  const [showCategorie, setShowCategorie] = useState(false);
  const [showFormulaire, setShowFormulaire] = useState(false);
  const [selectedListeFormulaire, setSelectedListeFormulaire] = useState([]);
  const [selectedListeCategorie, setSelectedListeCategorie] = useState([]);
  const { toEdit, categorieFormulaires } = useSelector(
    (state) => state.categorieFormulaire
  )
  const addFormToCate = async () => {
    await dispatch(
      assigneFormulaireToCategorie({
        formulaire: selectedListeFormulaire,
        categorie: selectedListeCategorie,
      })
    );
    dispatch(setShowModal({ showModelCate: false, toEdit: false }));
  }

  const editCate = async () => {
    await dispatch(
      assigneFormulaireToCategorie({
        formulaire: selectedListeFormulaire,
        categorie: selectedListeCategorie,
        edit: true
      })
    );
    dispatch(setShowModal({ showModelCate: false, toEdit: false }));
  }

  useEffect(() => {
    if (toEdit) {
      let arr = categorieFormulaires.filter((element) => element.id == toEdit)
      let forms = []
      setSelectedListeCategorie(arr);
      arr[0].joCatFormulas.map(element=>{
        forms.push(element.formulaire)
      })
   
      setSelectedListeFormulaire(forms)
    }else{
      setSelectedListeFormulaire([])
      setSelectedListeCategorie([]);

    }
  }, [toEdit]);

  return (
    <>
      {showModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none bg-gray-900/90">
            {/*content*/}
            <div className="border-0 rounded-3xl shadow-lg relative flex flex-col justify-center gap-6 bg-white opacity-100 outline-none focus:outline-none pt-9 px-16 ">
              {/*body*/}
              <div className="relative flex flex-col items-center">
                <img src={categorie} alt="cate" className=" h-8" />
                <p className=" reset text-3xl">
                  Ajouter un formulaire à une catégorie
                </p>
                <p className="etape-suivante text-md m-auto">
                  Veuillez choisir le nom du formulaire et de la catégorie
                </p>
              </div>
              <div className="flex flex-col	gap-4">
                <div className="">
                  <Select
                    setShow={setShowFormulaire}
                    show={showFormulaire}
                    text={
                      selectedListeFormulaire.length > 0
                        ? selectedListeFormulaire.map((el) => el.titre).join()
                        : "Formulaires"
                    }
                  />
                  <DropDownFormulaire
                    showFormulaire={showFormulaire}
                    selectedListeFormulaire={selectedListeFormulaire}
                    setSelectedListeFormulaire={setSelectedListeFormulaire}
                  />
                </div>
                {toEdit ? (
                  <div className=" text-center border-2 rounded-3xl py-2" >
                    {selectedListeCategorie[0]
                      ? selectedListeCategorie[0].titre
                      : ""}
                  </div>
                ) : 
                  (<div className="">
                    <Select
                      setShow={setShowCategorie}
                      show={showCategorie}
                      text={
                        selectedListeCategorie.length > 0
                          ? selectedListeCategorie.map((el) => el.titre).join()
                          : "Catégorie LEVEL"
                      }
                    />
                    
                    <DropDownCategorie
                      showCategorie={showCategorie}
                      selectedListeCategorie={selectedListeCategorie}
                      setSelectedListeCategorie={setSelectedListeCategorie}
                    />
                    
                  </div>
                )}
              </div>
              <div className="flex items-center justify-center gap-2 border-t border-solid border-slate-200 rounded-b py-2">
                <button
                  type="submit"
                  className={`group relative  flex justify-center w-[40%]  py-6 px-4 border  border-lime-600  text-sm font-medium    hover:text-white  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-10  items-center  gap-2 `}
                  id="button"
                  onClick={() => {
                    dispatch(
                      setShowModal({ showModelCate: false, toEdit: false })
                    );
                  }}
                >
                  Annuler
                </button>

                <Button
                  text={"Ajouter"}
                  classname={"w-[40%]"}
                  func={toEdit? editCate : addFormToCate}
                />
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
