import _ from "lodash";
import intercepterAxios from "../../configAxios";

const deleteFile = async ({id, path}) => {
  try {
    const response = await intercepterAxios.delete(
      `/fichier-independant/${id}?path=${path}`
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};

const createFiles = async (files) => {
  try {
    const response = await intercepterAxios.post(
      `/fichier-independant`, files
    );
    return response.data;
  } catch (err) {
    let message = err.response.data?.message || "Probleme connexion";
    throw message;
  }
};


const independentFileService = {
  deleteFile,
  createFiles
};

export default independentFileService;
