import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import CartFormulaire from "../../../components/CartFormulaire";
import CartSynthetiseurFormation from "../../../components/CartSynthetiseurFormation";

export default function DetailCateSynthetiseur() {
  const navigate = useNavigate();
  let { id } = useParams();
  const [currentCate, setCurrentCate] = useState({});
  const { categorieFormations } = useSelector(
    (state) => state.categorieFormation
  );

  const formulaires = () => {
 
    return currentCate?.joCatFormations?.map((element) => {
    
      return (
        <CartSynthetiseurFormation
          name={element?.formation?.titre}
          date={element?.formation?.createdAt}
          slug={element?.formation.slug}
          number={element?.formation?.questions?.length}
          id={element?.formation.id}
          key={element.formation.id}
        />
      );
    });
  };

  useEffect(() => {
    let arr = [...categorieFormations].filter((el) => {
      return el.id == id;
    });
    setCurrentCate(arr[0]);
  }, []);

  return (
    <div className=" flex-1 bg-gray-100 flex flex-col px-16 ">
      <div className=" basis-[15%] flex items-center ">
        <div className=" basis-[40%] flex gap-2 items-center">
          <button
            onClick={() => navigate("/EspaceLevel/synthetiseur/ListCate")}
          >
            <span className=" text-xl font-semibold">ARCHIVES</span>
            <span className=" text-xl font-medium"> LEVEL</span>
          </button>
          <span className=" text-lg font-medium"> {"> Catégories"}</span>
          <span className=" text-lg font-medium">
            {" "}
            {`> Détails ${currentCate?.titre}`}
          </span>
        </div>
      </div>
      <div className="  basis-[85%] grid grid-cols-4 gap-4 pl-2  justify-start  overflow-auto py-8">
        {formulaires()}
      </div>
    </div>
  );
}
