import React from "react";
import "../App.css";

export default function Button({ text, func, classname, disabled }) {
  return (
    <button
      disabled={disabled}
      type="submit"
      className={`group relative w-full flex justify-center py-3 px-4 border border-transparent text-center font-medium  text-white button-color hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full whitespace-nowrap  ${classname}`}
      onClick={() => func("")}
    >
      {text}
    </button>
  );
}
