import React, { useState, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import dayGridDay from "@fullcalendar/daygrid";
import dayGridYear from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { isValid, isWithinInterval, parseISO } from "date-fns";

import frLocale from "@fullcalendar/core/locales/fr";
import Popper from "@mui/material/Popper";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCaldavEvent,
  getAllEventWithCaldav,
  setLiveEvent,
} from "../../../features/event/eventSlice";
import dayjs from "dayjs";
import frLocalee from "dayjs/locale/fr";
import dayjsPluginUTC from "dayjs-plugin-utc";
import AddEvent from "./dialogs/AddEvent";
import EditEvent from "./dialogs/EditEvent";
import { Modal, Tooltip, message } from "antd";
import {
  dispShowModelCaldav,
  dispShowModelEventDetails,
  dispShowModelFormationDetails,
  getCaldavLink,
} from "../../../features/agenda/agendaModalForm/agendaModalSlice";
import { setLiveFormation } from "../../../features/formation/formationSlice";
import "./styles.css";
import CalendarEventDetailModal from "../calendrierDetails/CalendarEventDetailModal";
import { isSameDay } from "date-fns";
import { PickersDay } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box } from "@mui/material";

dayjs.extend(dayjsPluginUTC);

export default function Fullcalendar() {
  const dispatch = useDispatch();
  const fullCalendarRef = useRef(null);
  const editEventSource = useRef(null);
  const { allEventsWithCaldav, fetchNextCloudEventsStatus } = useSelector(
    (state) => state.event
  );
  const [view, setView] = useState("dayGridMonth");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [isLoadingCalavEvents, setIsLoadingCalavEvents] = useState(true);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showOption, setShowOption] = useState(false);
  const [showEditEvent, setShowEditEvent] = useState(false);

  const id = open ? "simple-popover" : undefined;

  const handleEventClick = (eventInfo) => {
    eventInfo.jsEvent.preventDefault();
    const event = {
      ...eventInfo.event.extendedProps,
      title: eventInfo.event.title,
      url: eventInfo.event.url,
      color: eventInfo.event.backgroundColor,
      inviteeList: eventInfo.event.extendedProps.attendee,
      emplacement: eventInfo.event.extendedProps.location,
    };

    if (eventInfo.event.extendedProps.formationId) {
      dispatch(
        setLiveFormation({ id: eventInfo.event.extendedProps.formationId })
      );
      dispatch(dispShowModelFormationDetails(true));
    } else {
      dispatch(setLiveEvent(event));
      setShowEditEvent(true);
    }
  };

  const handleDatesSet = (dateInfo) => {
    const newView = dateInfo.view.type;
    setView(newView);
  };

  dayjs.locale(frLocalee);

  useEffect(() => {
    dispatch(getAllEventWithCaldav()).then((res) => {
      if (res.payload) {
        if (
          res.payload == "Cannot read properties of undefined (reading 'data')"
        ) {
          Modal.error({
            title: "Une erreur s'est produite",
            content:
              "Nous n'avons pas pu configurer correctement votre calendrier, veuillez réessayer plus tard ou contacter l'administration",
          });
        }else {
          setIsLoadingCalavEvents(false);
        }
      } else {
        Modal.error({
          title: "Une erreur s'est produite",
          content:
            "Nous n'avons pas pu configurer correctement votre calendrier, veuillez réessayer plus tard ou contacter l'administration",
        });
      }
    });
  }, []);

  const dateClick = (info) => {
    setAnchorEl(info.dayEl);
    if (view === "dayGridMonth") {
      setAnchorEl(info.dayEl);
    } else {
      setAnchorEl(editEventSource.current);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const events = allEventsWithCaldav?.map((item) => ({
    ...item,
    start: item.startedAt,
    end: item.finishedAt,
    startRecur: item.isRepeated && item.startedAt,
    title: item.title ? item.title : "Événement sans titre",
  }));

  const { user } = useSelector((state) => state.auth);
  const openCaldav = async () => {
    const params = {
      username: user.name,
      userId: user._id,
    };
    dispatch(dispShowModelCaldav(true));
    dispatch(getCaldavLink(params));
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (fullCalendarRef.current && date) {
      fullCalendarRef.current.getApi().gotoDate(date.toDate());
    }
  };

  const toggleShowOptions = () => {
    setShowOption(!showOption);
  };

  const deleteEvent = (e) => {
    dispatch(deleteCaldavEvent({ url: e.url })).then((res) => {
      dispatch(getAllEventWithCaldav()).then((res) => {
        message.success("Événement supprimé avec succès");
      });
    });
  };

  const CustomDay = (props) => {
    const { day } = props;
    const dayDate = new Date(day);

    const eventsOnDay = events.filter(event => {
      const startDate = typeof event.startedAt === "string" ? new Date(event.startedAt) : event.startedAt;
      const endDate = event.finishedAt ? (typeof event.finishedAt === "string" ? new Date(event.finishedAt) : event.finishedAt) : null;
  
      const isSameStartDate = isSameDate(startDate, dayDate);
      const isSameEndDate = endDate ? isSameDate(endDate, dayDate) : false;
  
      return (!endDate && isSameStartDate) || (isSameStartDate && (!endDate || isSameEndDate));
  });
  
    function isSameDate(date1, date2) {
        return date1.getUTCFullYear() === date2.getUTCFullYear() &&
            date1.getUTCMonth() === date2.getUTCMonth() &&
            date1.getUTCDate() === date2.getUTCDate();
    }

    const dotsToShow = eventsOnDay.slice(0, 3);

    return (
      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PickersDay {...props} />
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {dotsToShow.map((event, index) => (
            <Box
              key={index}
              sx={{
                width: "4px",
                height: "4px",
                borderRadius: "50%",
                backgroundColor: event.color || "#0369A1",
                margin: "0 2px",
              }}
            />
          ))}
        </Box>
      </Box>
    );
  };
  return (
    <div className="old-c flex justify-between m-2 w-[80%]">
      <a ref={editEventSource}></a>
      <div className="grow">
        {isLoadingCalavEvents ? (
          <div
            className=" flex justify-center items-center"
            style={{ marginTop: "10%" }}
          >
            <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-gray-900"></div>
          </div>
        ) : (
          <FullCalendar
            timeZone="UTC"
            eventTimeFormat={{
              hour: "2-digit",
              minute: "2-digit",
              meridiem: false,
            }}
            events={events}
            ref={fullCalendarRef}
            plugins={[
              dayGridPlugin,
              dayGridDay,
              dayGridYear,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
            ]}
            eventContent={({ event }) => {
              const color = event?.backgroundColor;
              return (
                <div className={`weekly_event ${color}`}>
                  {!event.allDay && (
                    <p className="event_time">
                      {new Date(event.extendedProps.startedAt).getUTCHours() +
                        ":" +
                        new Date(
                          event.extendedProps.startedAt
                        ).getUTCMinutes() +
                        " - " +
                        new Date(event.extendedProps.finishedAt).getUTCHours() +
                        ":" +
                        new Date(
                          event.extendedProps.finishedAt
                        ).getUTCMinutes()}
                    </p>
                  )}
                  <p className="event_title">{event.title}</p>
                  <p className="event_description">
                    {event.extendedProps.description}
                  </p>
                </div>
              );
            }}
            initialView={view}
            locale={frLocale}
            selectable={true}
            headerToolbar={{
              start: "prev,next today",
              center: "title",
              end: "dayGridMonth,timeGridWeek,timeGridDay",
            }}
            titleFormat={{ year: "numeric", month: "long" }}
            height="100%"
            datesSet={handleDatesSet}
            eventClick={handleEventClick}
          />
        )}
      </div>
      <div className="">
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={frLocalee}>
          <DateCalendar
            value={selectedDate}
            onChange={handleDateChange}
            slots={{ day: CustomDay }}
            showDaysOutsideCurrentMonth
          />
        </LocalizationProvider>
        <div className="relative">
          <div className="flex justify-between px-5 items-center">
            <p>OPTIONS</p>
            <button
              className="text-lg font-semibold"
              onClick={toggleShowOptions}
            >
              . . .
            </button>
          </div>
        </div>
        {showOption && (
          <div className="absolute right-0 m-1 p-1 shadow-md rounded-2xl">
            <p className="p-1 hover:bg-gray-100 rounded-t-2xl">
              <button className="text-left" onClick={openCaldav}>
                Informations de connexion au calendrier
                <br /> Level
              </button>
            </p>
            <p className="p-1 hover:bg-gray-100 rounded-b-2xl">
              {/* <button onClick={openImport}>Importer un calendrier</button> */}
            </p>
          </div>
        )}
      </div>
      {showEditEvent && (
        <CalendarEventDetailModal onClose={() => setShowEditEvent(false)} />
      )}
    </div>
  );
}
