import React, { useState } from "react";
import Paperclip from "../images/Paperclip.svg";
import LinkBu from "../images/LinkBu.svg";

import Input from "../components/Input";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

import Button from "../components/Button";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile } from "../features/auth/authSlice";
import { useEffect } from "react";

export default function UpdateProfile() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState({});
  const [logo, setLogo] = useState("");
  const [text, setText] = useState("");
  useEffect(() => {
    setData(user);
    let textLogo = user?.logo ? user?.logo.split("/")[1] : user?.logo;
    setText(textLogo);
  }, [user]);
  const onChange = (e) => {
    setData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const hanldeImage = (pic) => {
    setLogo(pic);
    setText(pic.name);
  };
  const handleClick = async () => {
    let formData = new FormData();
    data.name && formData.append("name", data.name);
    data.codeApe && formData.append("Code_APE", data.codeApe);
    data.email && formData.append("email", data.email);
    data.siret && formData.append("SIRET", data.siret);
    data.phone && formData.append("Telephone", data.phone);
    data.codeNda && formData.append("Code_NDA", data.codeNda);
    data.codePostal && formData.append("Code_Postal", data.codePostal);
    data.adressePostale &&
      formData.append("Adresse_postale", data.adressePostale);
    data.lienGoogle && formData.append("Lien_Google_Business", data.lienGoogle);
    logo && formData.append("Logo", logo);

    await dispatch(updateProfile({ userData: formData, id: user?._id }));
    window.location.replace("/EspaceLevel/formulaires");
  };
  return (
    <>
      <div className="flex flex-col items-center pt-16">
        <div className="grid grid-cols-3 gap-4 ">
          <Input
            id={"formateur"}
            type={"text"}
            name={"name"}
            autoComplete={""}
            placeholder={"Organisme de formation ou formateur *"}
            label={"Organisme de formation ou formateur   "}
            classname={"input-group-1"}
            value={data?.name || ""}
            onChange={onChange}
          />
          <div className="relative">
            <div className="flex absolute inset-y-0 left-1 items-center pl-3 pointer-events-none">
              Code APE
            </div>
            <input
              type="number"
              id="CodeAPE"
              className=" border   rounded-full block w-full pl-[6rem] py-3 border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-lime-500 focus-within:border-lime-500 input-group-1 "
              placeholder="00000"
              name="codeApe"
              value={data?.codeApe || ""}
              onChange={onChange}
            />
          </div>
          <div className="relative">
            <div className="flex absolute inset-y-0 left-1 items-center pl-3 pointer-events-none">
              SIRET
            </div>
            <input
              type="number"
              id="Siret"
              className=" border   rounded-full block w-full pl-16 py-3 border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-lime-500 focus-within:border-lime-500 input-group-1 "
              placeholder="000  000  000  00000"
              name={"siret"}
              value={data?.siret || ""}
              onChange={onChange}
            />
          </div>
          <PhoneInput
            country={"fr"}
            inputProps={{
              name: "phone",
              required: true,
            }}
            inputStyle={{
              borderRadius: 80,
              height: 50,
              width: "100%",
            }}
            onChange={(phone, country, name) =>
              setData((prevState) => {
                return {
                  ...prevState,
                  [name.target.name]: phone,
                };
              })
            }
            value={data?.phone || ""}
          />
          <div className="relative">
            <div className="flex absolute inset-y-0 left-1 items-center pl-3 pointer-events-none">
              Code NDA
            </div>
            <input
              type="number"
              id="codeNDA"
              className=" border   rounded-full block w-full pl-[6rem] py-3 border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-lime-500 focus-within:border-lime-500 input-group-1 "
              placeholder="00000"
              name="codeNda"
              value={data?.codeNda || ""}
              onChange={onChange}
            />
          </div>
          <Input
            id={"formateur"}
            type={"text"}
            name={"adressePostale"}
            autoComplete={""}
            placeholder={"Adresse postale"}
            label={"Adresse postale"}
            classname={"input-group-1"}
            value={data?.adressePostale || ""}
            onChange={onChange}
          />

          <div className="relative">
            <div className="flex absolute inset-y-0 left-1 items-center pl-3 pointer-events-none">
              <img src={LinkBu} alt="url" />
            </div>
            <input
              id="lien"
              type="url"
              className=" border rounded-full block w-full pl-[3rem] py-3 border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-lime-500 focus-within:border-lime-500 input-group-1 "
              placeholder="Lien Google Business"
              name="lienGoogle"
              value={data?.lienGoogle || ""}
              onChange={onChange}
            />
          </div>

          <div className="relative">
            <div className="flex absolute inset-y-0 left-1 items-center pl-3 pointer-events-none">
              Code postal
            </div>
            <input
              type="number"
              id="codepostale"
              className=" border   rounded-full block w-full pl-[6rem] py-3 border-gray-300 placeholder-gray-500 text-gray-900 focus:ring-lime-500 focus-within:border-lime-500 input-group-1 "
              placeholder="00000"
              name="codePostal"
              value={data?.codePostal || ""}
              onChange={onChange}
            />
          </div>
          <label
            for="file-upload"
            className="relative bg-white block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-full focus:outline-none focus:ring-lime-500 focus:border-gray-500 focus:z-10 md:text-sm h-12 "
          >
            <div className="flex items-center">
              <div className="file">
                <img src={Paperclip} alt="file" />
              </div>
              <span className="pl-8 truncate whitespace-nowrap">
                {text ? text : "Logo"}
              </span>
            </div>
          </label>
          <input
            id="file-upload"
            type="file"
            onChange={(e) => hanldeImage(e.target.files[0])}
          />
        </div>
      </div>
      <div className="flex justify-center items-center gap-4 pt-12">
        <div className="w-[25%] lg:w-[50%]">
          <button
            type="submit"
            className={`group relative w-full flex justify-center py-3 px-4 border border-[#288911] text-lg font-medium  text-black  hover:bg-lime-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lime-600 rounded-full h-12 `}
            id="button"
            onClick={() => {
              window.location.replace("/EspaceLevel/formulaires");
            }}
          >
            Annuler
          </button>
        </div>
        <div className="w-[25%] lg:w-[50%]">
          <Button text={"Enregistrer"} func={handleClick} />
        </div>
      </div>
    </>
  );
}
